import React from 'react';
import { EditModal } from '../BaseComponents/Modals';

const LegalEntitySeparatedEdit = () => {
    return (
        <EditModal id="legalEntitiesSeparatedEditModal" title="LegalEntitySeparated Edit">
            <div className="form-row">
                <div className="form-group col-md-2 small">
                    <label className="control-label" htmlFor="LegalEntitySeparatedID">legalEntitySeparated ID</label>
                    <input type="number" className="form-control form-control-sm" id="legalEntitySeparatedID" name="legalEntitySeparatedID" readOnly />
                </div>
            </div>
        </EditModal>
    );
};

export default LegalEntitySeparatedEdit;