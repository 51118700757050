import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';

import goLiveApp from '../../jscomponents/Main';
import GridComponent from '../../jscomponents/grid.component';
import { withRouter } from 'react-router-dom';

class DataGrid extends Component {

    //constructor(props) {
    //    //you actually dont need this atm
    //    super(props);

    //    console.log('constructor');
    //    this.props.requestUsers();
    //}

    /*
    componentWillMount() {
        // This method runs when the component is first added to the page
        console.log('componentWillMount');
        //this.props.requestUsers();
    }
    */

    //async 
    componentDidMount() {
        goLiveApp.loaderShow();
        //console.log('componentDidMount');
        this.gridInstance = new GridComponent(
            {
                container: this.gridElement,
                model: this.props.model,
                routerHistory: this.props.history,
                filterObject: this.props.filters[this.props.model]
            });
            //new Grid(this.grid, dv, columns, options);
        if (this.props.onGridInit) this.props.onGridInit(this.gridInstance);
    }

    //shouldComponentUpdate(nextProps, nextState) {
    //    //console.log('shouldComponentUpdate');
    //    return false;
    //}

    componentWillUnmount() {
        if (this.props.shouldPersistFilter) {
            const filterObj = this.gridInstance.persistFavoriteFilter();
            this.props.persistFilterObj({ key: this.props.model, data: filterObj });
        }
        this.gridInstance.destroy();
    }

    render() {
        //console.log('render ' + this.props.users.length);
        return (
            <div>
                {this.props.children}
                <div>
                    <div className='grid-container' /*style={{ height: (this.props.containerHeight || 250) + 'px' }}*/>
                        <div id='grid' className='slickgrid-container' ref={el => this.gridElement = el} />
                        <div id='pager' className='grid-pager' />
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(DataGrid));