import React from 'react';
import goLiveApp from '../../jscomponents/Main';

const ProjectRequired = () => {
    goLiveApp.loaderHide();
    return (
        <div>
            <h2>Project Required</h2>
        <p>You are trying to access a resource that requires a known project to be selected.</p>
        </div>
);
};

export default ProjectRequired;