import React from 'react';
import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarCheckBox,
    DataGridToolbarSearchBox,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonAdd,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonDelete,
    DataGridToolbarButtonImport,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';
import DummyEdit from './DummyEdit';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

const Toolbar = () => {
    return (
        <DataGridToolbar
            title="Dummy"
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarCheckBox
                id="showDeletedCheckBox"
                filterOrder="1"
                filterWhenChecked="all"
                filterWhenUnchecked="enabled"
                caption="Show Deleted"
            />
            <DataGridToolbarSearchBox
                filterOrder="2"
                filter="search"
            />
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonAdd />
                <DataGridToolbarButtonEdit />
                <DataGridToolbarButtonDelete />
                <DataGridToolbarButtonImport />
                <DataGridToolbarButtonExport />
                <DataGridToolbarButtonHistory />
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const Dummy = () => {
    return (
        <DataGrid model='dummy'>
            <DummyEdit />
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, Dummy, "Dummy");