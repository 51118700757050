import React from 'react';
import { IfAuthorized, AuthorizationPolicies } from '../BaseComponents/Authorization';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarCheckBox,
    DataGridToolbarSearchBox,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonAdd,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonDelete,
    DataGridToolbarButtonImport,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonDisable
} from '../BaseComponents';
import ApplicationsMnAEdit from './ApplicationsMnAEdit';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';
import DuPont from '../../jscomponents/DuPontApp';
import ProjectRequired from '../BaseComponents/ProjectRequired';

let properties;


const Toolbar = () => {
    const { tempCurrentSpin, crossSpinDataHidden, multiSpin } = DuPont.App.Config;
    var currentSpin = multiSpin ? properties.match.params.project : tempCurrentSpin;
    currentSpin = currentSpin.toUpperCase();
    console.log(`currentSpin: ${currentSpin}`);

    return (
        <DataGridToolbar
            title={currentSpin + " Applications Collection"}
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarCheckBox
                id="showAllCheckBox"
                filterOrder="2"
                filterWhenChecked="all"
                filterWhenUnchecked="enabled"
                caption="Show Disabled Apps"
            />
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarDropdownButton
                    caption="ALL"
                    controlClass={crossSpinDataHidden ? "d-none" : null}
                    glyph="filter"
                    glyphColor='#4189C7'
                    filterOrder="1"
                    filter={crossSpinDataHidden ? currentSpin : "all"}
                    filterReqMsg="Please choose a filter!"
                >
                    <DataGridToolbarDropdownItem filter="DAS" href="#" caption='DAS' />
                    <DataGridToolbarDropdownItem filter="SPECCO" href="#" caption='SPECCO' />
                    <li className="dropdown-divider" />
                    <DataGridToolbarDropdownItem filter="all" href="#" caption='ALL' />
                </DataGridToolbarDropdownButton>
                <IfAuthorized policy={AuthorizationPolicies.AppMnAAllowEdit}>
                    <DataGridToolbarButtonAdd />
                    <DataGridToolbarButtonDisable />
                    <DataGridToolbarButtonEdit />
                    <DataGridToolbarButtonExport />
                </IfAuthorized>
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonHistory />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>

    );
};

const AppMnAs = (props) => {

    const { multiSpin } = DuPont.App.Config;

    if (multiSpin && !props.match.params.project) {
        return (
            <ProjectRequired />
        );
    }

    properties = props;

    return (
        <DataGrid model='appMnA'>
            <ApplicationsMnAEdit />
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, AppMnAs, "AppMnAs");