import React from 'react';
import {
        useParams
} from "react-router-dom";

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarCheckBox,
    DataGridToolbarSplitButton,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonImport,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';

import ProjectRequired from '../BaseComponents/ProjectRequired';

//import ErrorEdit from './ErrorEdit';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

const Toolbar = () => {
    return (
        <DataGridToolbar
            title="Projects"
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}

            <DataGridToolbarButtonsGroup>
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonExport />
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const Projects = (props) => {
    const project = props.match.params.project;


    //if (project == undefined) {
    //    return (< ProjectRequired />);
    //}

    return (
        <DataGrid model='project'>
            <Toolbar />
        </DataGrid>
        );
};

export default withAITracking(ai.reactPlugin, Projects, "Projects");