import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';

import CloudIaasImporting from './CloudIaasImporting';
import CloudIaasListing from './CloudIaasListing';
import CloudIaasForm from './CloudIaasForm';
import Summary from './Iaas/Summary';
import Preview from './Iaas/Preview';



class CloudIaas extends Component {
     constructor(props) {
          super(props);

          this.props.requestLookup(['availabilitySetEnum', 'businessPriorityEnum', 'classificationEnum', 'companyEnum', 'diskSizeEnum', 'environmentsEnum', 'extensionEnum', 'imageEnum', 'instanceSizeEnum', 'protocolEnum', 'regionsEnum', 'resourceGroupEnum', 'roleEnum', 'storageRedundancyEnum', 'storageTypeEnum', 'typeEnum', 'ilbEnum']);

          //if (this.props.action === null) {
          //    this.props.listingAzureObjects(null, null);
          //}
     }

    goToInfra = () => {
        this.props.listingProvisioningInfra();
    }

    goToList = () => {
        this.props.listingAzureObjects(null, null);
    }
    goToImport = () => {
        this.props.importingAzureObjects(this.props.formIaas);
    }
    goToRequest = () => {
        this.props.insertingAzureObjects();
    }
    openPreviewData = () => {
        if (this.props.formIaas.environments) {
            if (this.props.formIaas.environments.length > 0) {
                this.props.previewAzureObjects(true);
            }
        }

    }
    closePreviewData = () => {
        this.props.previewAzureObjects(false);
    }
    showHistory = (newTab) => {
        this.props.showAzureObjHistory(this.props.formIaas.azureObjectID, newTab);
    }

    getEnumValues = (name) => {
        var filteredEnum = this.props.lookups.filter(s => s.id === name)[0];

        return filteredEnum ? filteredEnum.values : [];
    }

    toggleTab = (name, id) => {
        this.props.setTab(name, id);
        if (name === 'compute') {
            try {
                var formData = this.props.formIaas;
                var currentEnvironment = this.props.activeTab.filter(t => t.name === 'environment')[0].tabId;

                formData.environments.map((env, envIdx) => {
                    if (env.environmentName === currentEnvironment) {
                        env.compute.map((comp, compIdx) => {
                            if (comp.availabilitySet === id) {
                                if (comp.vm.loadBalancer) {
                                    this.props.setTab('ilb', comp.vm.loadBalancer[0].loadBalancerName);
                                }
                            }
                        });
                    }
                });
            } catch (e) {
                console.log('no ilb in this environment');
            }
        }
    }

    parseEnum = (type, name) => {

        var filteredEnum = this.props.lookups.filter(s => s.id === name);
        var enumValues = [];
        var enumNames = [];
        var returningObject = {
            type: type,
            enum: [],
            enumNames: []
        };

        try {
            if (filteredEnum.length > 0) {
                filteredEnum[0].values.forEach(current => {
                    if (type === "integer") {
                        enumValues.push(parseInt(current.code));
                    }
                    else {
                        enumValues.push(current.code);
                    }
                    enumNames.push(current.description);
                });
            }
            returningObject.enum = enumValues;
            returningObject.enumNames = enumNames;
        }
        catch (e) {
            console.log(e);
        }
        return returningObject;
    }

    addEnvironment = () => {
        var formData = this.props.formIaas;
        if (formData.environmentsDropdown !== undefined) {
            var used = this.props.lookups.filter(s => s.id === 'environmentsEnum')[0].values.filter(x => x.code === formData.environmentsDropdown)[0];

            if (formData.environments) {
                if (formData.environments.length > 0) {
                    formData.environments.push({
                        environmentName: formData.environmentsDropdown,
                        //compute: Object.assign(formData.environments[0].compute)
                        compute: JSON.parse(JSON.stringify(formData.environments[0].compute ? formData.environments[0].compute : []))
                    });
                }
                else {
                    formData.environments = [{
                        environmentName: formData.environmentsDropdown,
                        compute: []
                    }];
                }
            }
            else {
                formData.environments = [{
                    environmentName: formData.environmentsDropdown,
                    compute: []
                }];
            }

            if (formData.environments.length >= 1) {
                this.props.setTab('environment', formData.environmentsDropdown);
            }
            formData.environmentsDropdown = undefined;


            this.props.setFormIaas(formData);
        }
    }

    duplicateEnvironment = (index) => {
        var formData = this.props.formIaas;

        var copyObj = JSON.parse(JSON.stringify(formData.environments[index] ? formData.environments[index] : {}));
        copyObj.environmentName = this.props.formIaas.environmentsCopyTo;
        formData.environments.push(copyObj);

        this.props.setFormIaas(formData);
    }

    removeEnvironment = (environmentName) => {
        console.log(environmentName + ' remove environment');
        var formData = this.props.formIaas;

        if (formData.environments.length > 1) {
            formData.environments.splice(formData.environments.indexOf(formData.environments.filter(b => b.environmentName === environmentName)[0]), 1);
        }
        else {
            formData.environments = [];
        }

        // setting tab for the first available
        if (this.props.activeTab.filter(tab => tab.name === 'environment')[0]) {
            if (formData.environments[0]) {
                this.props.setTab('environment', formData.environments[0].environmentName);
            }
            else {
                // remove tab environment
                this.props.setTab('environment', '');
            }
        }



        this.props.setFormIaas(formData);
    }

    addCompute = (envIdx) => {
        var formData = this.props.formIaas;
        if (formData.computeDropdown !== undefined) {
            var used = this.props.lookups.filter(s => s.id === 'availabilitySetEnum')[0].values.filter(x => x.code === formData.computeDropdown)[0];

            formData.environments.map((currEnv, idx) => {
                if (currEnv.compute) {
                    formData.environments[idx].compute.push({
                        availabilitySet: formData.computeDropdown,
                        vm: {}
                    });
                }
                else {
                    formData.environments[idx].compute = [{
                        availabilitySet: formData.computeDropdown,
                        vm: {}
                    }];
                }

            });

            if (formData.environments[envIdx].compute.length >= 1) {
                this.props.setTab('compute', formData.computeDropdown);
            }
            formData.computeDropdown = undefined;
            this.props.setFormIaas(formData);
        }
    }

    duplicateCompute = (compIdx) => {
        var formData = this.props.formIaas;

        formData.environments.map((env, id) => {
            var copyObj = JSON.parse(JSON.stringify(formData.environments[id].compute[compIdx] ? formData.environments[id].compute[compIdx] : {}));

            if (copyObj.vm) {
                if (copyObj.vm.loadBalancer) {
                    copyObj.vm.loadBalancer.map((ilb, id) => {
                        if (copyObj.availabilitySet.indexOf(ilb.loadBalancerName) >= 0) {
                            ilb.loadBalancerName = this.props.formIaas.computeCopyTo.replace('-vms', '');// + '-ilb';
                        }
                    });
                }
            }

            copyObj.availabilitySet = this.props.formIaas.computeCopyTo;

            formData.environments[id].compute.push(copyObj);
        });

        this.props.setFormIaas(formData);
    }

    removeCompute = (compIdx) => {
        var formData = this.props.formIaas;

        formData.environments.map((env, envIdx) => {
            if (compIdx >= 0) {
                formData.environments[envIdx].compute.splice(compIdx, 1);
            }
        });

        // setting tab for the first availabe
        if (this.props.activeTab.filter(tab => tab.name === 'compute')[0]) {
            if (formData.environments[0]) {
                if (formData.environments[0].compute[0]) {
                    this.props.setTab('compute', formData.environments[0].compute[0].availabilitySet);
                }
                else {
                    // remove tab compute
                    this.props.setTab('compute', '');
                }
            }
            else {
                // remove tab compute
                this.props.setTab('compute', '');
            }
        }

        this.props.setFormIaas(formData);


    }

    addDataDisk = (envIdx, compIdx) => {
        var formData = this.props.formIaas;
        if (formData.disksDropdown !== undefined) {
            if (formData.environments[envIdx].compute[compIdx].vm.disks) {
                formData.environments[envIdx].compute[compIdx].vm.disks.push({
                    diskSizeGB: formData.disksDropdown,
                    quantity: 1,
                    diskType: 'Premium_LRS',
                    caching: 'ReadOnly'
                });
            }
            else {
                if (formData.environments[envIdx].compute[compIdx].vm === undefined) {
                    formData.environments[envIdx].compute[compIdx].vm = {};
                }

                formData.environments[envIdx].compute[compIdx].vm.disks = [{
                    diskSizeGB: formData.disksDropdown,
                    quantity: 1,
                    diskType: 'Premium_LRS',
                    caching: 'ReadOnly'
                }];
            }
            formData.disksDropdown = undefined;
            this.props.setFormIaas(formData);
        }
    }

    removeDataDisk = (envIdx, compIdx, diskIdx) => {
        console.log(envIdx + ' -> ' + compIdx + ' -> ' + diskIdx);

        var formData = this.props.formIaas;
        //var newDisks = [];
        //formData.environments[envIdx].compute[compIdx].vm.disks.map((d, idx) => {
        //    if (idx !== diskIdx) {
        //        newDisks.push(d);
        //    }
        //});

        //formData.environments[envIdx].compute[compIdx].vm.disks = newDisks;

        formData.environments[envIdx].compute[compIdx].vm.disks.splice(diskIdx, 1);

        this.props.setFormIaas(formData);
    }

    loopLoadBalancerToAllEnvironments = (envIdxSource, formDataRef) => {
        var formData = formDataRef ? formDataRef : this.props.formIaas;

        if (formData.environments[envIdxSource].compute) {


            // looping each compute/vm
            var loadBalancerEnv = [];

            formData.environments[envIdxSource].compute.map((curComp, curCompIdx) => {

                if (curComp.vm) {
                    if (curComp.vm.loadBalancer) {
                        loadBalancerEnv.push({
                            availabilitySet: curComp.availabilitySet,
                            loadBalancer: curComp.vm.loadBalancer
                        });
                    }
                }

            });

            // looping each environment diff for the source to add this loadbalancer
            if (formData.environments.filter(env => env.environmentName !== formData.enrivonments[envIdxSource][0].environmentName)[0]) {

                formData.environments.filter(env => env.environmentName !== formData.enrivonments[envIdxSource][0].environmentName).map(environment => {

                    environment.compute.map(comp => {

                        if (!comp.vm) {
                            comp.vm = {};
                        }

                        if (loadBalancerEnv.filter(ilb => ilb.availabilitySet === comp.availabilitySet)[0]) {
                            comp.vm.loadBalancer = loadBalancerEnv.filter(ilb => ilb.availabilitySet === comp.availabilitySet)[0].loadBalancer;
                        }


                    });

                });

            }

            this.props.setFormIaas(formData);


        }


    }

    addLoadBalancer = (envIdx, computeIdx) => {

        var formData = this.props.formIaas;
        if (formData.ilbDropdown !== undefined) {
            var used = this.props.lookups.filter(s => s.id === 'ilbEnum')[0].values.filter(x => x.code === formData.ilbDropdown)[0];

            var newilb = {};
            if (formData.ilbDropdown === 'web') {
                newilb = {
                    loadBalancerName: used.code,
                    probes: [{
                        healthProbeName: 'web-ilb-probe-0',
                        protocol: 'Tcp',
                        port: 80,
                        uri: '',
                        interval: 5,
                        numberOfProbes: 2,
                        portMappings: [{
                            protocol: 'Tcp',
                            port: 80,
                            enableFloatingIP: 'false',
                            idleTimeoutInMinutes: 20,
                            loadDistribution: "SourceIP"
                        }]
                    },
                    {
                        healthProbeName: 'web-ilb-probe-1',
                        protocol: 'Tcp',
                        port: 443,
                        uri: '',
                        interval: 5,
                        numberOfProbes: 2,
                        portMappings: [
                            {
                                protocol: 'Tcp',
                                port: 443,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 20,
                                loadDistribution: "SourceIP"
                            }]
                    }]

                };
            }

            if (formData.ilbDropdown === 'cluster') {
                newilb = {
                    loadBalancerName: used.code,
                    probes: [{
                        healthProbeName: 'cluster-ilb-probe-0',
                        protocol: 'Tcp',
                        port: 59999,
                        uri: '',
                        interval: 5,
                        numberOfProbes: 2,
                        portMappings: [
                            {
                                protocol: 'Tcp',
                                port: 135,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Udp',
                                port: 137,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Udp',
                                port: 138,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Tcp',
                                port: 139,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Tcp',
                                port: 445,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Udp',
                                port: 445,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Udp',
                                port: 1433,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Udp',
                                port: 2382,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Tcp',
                                port: 2383,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Udp',
                                port: 2383,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Tcp',
                                port: 3343,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Udp',
                                port: 3343,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Tcp',
                                port: 5022,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Tcp',
                                port: 5985,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            },
                            {
                                protocol: 'Tcp',
                                port: 5986,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }
                        ]
                    }]


                };
            }

            if (formData.ilbDropdown === 'fs') {
                newilb = {
                    loadBalancerName: used.code,
                    probes: [{
                        healthProbeName: 'fs-ilb-probe-0',
                        protocol: 'Tcp',
                        port: 80,
                        uri: '',
                        interval: 5,
                        numberOfProbes: 2,
                        portMappings: [{
                            protocol: 'Tcp',
                            port: 22,
                            enableFloatingIP: 'false',
                            idleTimeoutInMinutes: 30,
                            loadDistribution: "SourceIP"
                        },
                        {
                            protocol: 'Tcp',
                            port: 445,
                            enableFloatingIP: 'false',
                            idleTimeoutInMinutes: 30,
                            loadDistribution: "SourceIP"
                        },
                        {
                            protocol: 'Tcp',
                            port: 135,
                            enableFloatingIP: 'false',
                            idleTimeoutInMinutes: 30,
                            loadDistribution: "SourceIP"
                        },
                        {
                            protocol: 'Udp',
                            port: 137,
                            enableFloatingIP: 'false',
                            idleTimeoutInMinutes: 30,
                            loadDistribution: "SourceIP"
                        },
                        {
                            protocol: 'Tcp',
                            port: 3260,
                            enableFloatingIP: 'false',
                            idleTimeoutInMinutes: 30,
                            loadDistribution: "SourceIP"
                        },
                        {
                            protocol: 'Tcp',
                            port: 5985,
                            enableFloatingIP: 'false',
                            idleTimeoutInMinutes: 30,
                            loadDistribution: "SourceIP"
                        }
                        ]
                    }]
                };
            }

            if (formData.ilbDropdown === 'sql') {
                newilb = {
                    loadBalancerName: used.code,
                    probes: [
                        {
                            healthProbeName: 'sql-ilb-probe',
                            protocol: 'Tcp',
                            port: 59998,
                            uri: '',
                            interval: 5,
                            numberOfProbes: 2,
                            portMappings: [
                                {
                                    protocol: 'Tcp',
                                    port: 137,
                                    enableFloatingIP: 'dynamic',
                                    idleTimeoutInMinutes: 30,
                                    loadDistribution: "SourceIP"
                                },
                                {
                                    protocol: 'Tcp',
                                    port: 138,
                                    enableFloatingIP: 'dynamic',
                                    idleTimeoutInMinutes: 30,
                                    loadDistribution: "SourceIP"
                                },
                                {
                                    protocol: 'Tcp',
                                    port: 1433,
                                    enableFloatingIP: 'dynamic',
                                    idleTimeoutInMinutes: 30,
                                    loadDistribution: "SourceIP"
                                }
                            ]
                        }]


                };
            }

            if (formData.ilbDropdown === 'sap-cluster') {
                newilb = {
                    loadBalancerName: used.code,
                    probes: [{
                        healthProbeName: 'sap-cluster-probe-0',
                        protocol: 'Tcp',
                        port: 6200,
                        uri: '',
                        interval: 5,
                        numberOfProbes: 2,
                        portMappings: [
                            {
                                protocol: "Tcp",
                                port: 445,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 3200,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 3201,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 3210,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 3301,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 3600,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 3610,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 3900,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 3910,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 5985,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 8100,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 8110,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 50013,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 50014,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 50016,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 50100,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 50101,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 50113,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 50114,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 51013,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 51014,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 51016,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 51113,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }, {
                                protocol: "Tcp",
                                port: 51114,
                                enableFloatingIP: 'dynamic',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "Default"
                            }]
                    }]


                };
            }


            if (formData.ilbDropdown === 'sap-app') {
                newilb = {
                    loadBalancerName: used.code,
                    probes: [{
                        healthProbeName: 'sap-app-probe-0',
                        protocol: 'Tcp',
                        port: 50100,
                        uri: '',
                        interval: 5,
                        numberOfProbes: 2,
                        portMappings: [
                            {
                                protocol: "Tcp",
                                port: 25,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 2500,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 3201,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 3301,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 3601,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 3901,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 50100,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 50101,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 50104,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 50113,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }, {
                                protocol: "Tcp",
                                port: 50114,
                                enableFloatingIP: 'false',
                                idleTimeoutInMinutes: 30,
                                loadDistribution: "SourceIP"
                            }]
                    }]


                };
            }


            // REMOVING EXISTING PORT MAPPINGS IN OTHERS ILBS
            if (formData.environments[envIdx].compute[computeIdx].vm.loadBalancer) {
                formData.environments[envIdx].compute[computeIdx].vm.loadBalancer.map((ilb, ilbIdx) => {


                    ilb.probes.map((probe, probeIdx) => {

                        if (probe.portMappings) {

                            probe.portMappings.map((mapping, mappingIdx) => {
                                var item = newilb.probes[0].portMappings.filter(f => f.protocol.toString() === mapping.protocol.toString() && f.port.toString() === mapping.port.toString());
                                if (item.length > 0) {
                                    var idx = newilb.probes[0].portMappings.indexOf(item[0]);
                                    if (idx >= 0) {
                                        newilb.probes[0].portMappings.splice(idx, 1);
                                    }
                                }
                            });
                        }

                    });
                });
            }


            formData.environments.map(env => {
                if (env.compute[computeIdx].vm.loadBalancer) {
                    env.compute[computeIdx].vm.loadBalancer.push(newilb);
                }
                else {
                    env.compute[computeIdx].vm.loadBalancer = [newilb];
                }

            });

            if (formData.environments[envIdx].compute[computeIdx].vm.loadBalancer.length >= 1) {
                this.props.setTab('ilb', formData.ilbDropdown);
            }

        }

        this.props.setFormIaas(formData);

    }

    removeLoadBalancer = (compIdx, ilbIdx) => {
        var formData = this.props.formIaas;

        console.log('Remove Load Balancer ' + compIdx + ' ' + ilbIdx);

        formData.environments.map((env, envIdx) => {
            env.compute[compIdx].vm.loadBalancer.splice(ilbIdx, 1);

        });

        this.props.setFormIaas(formData);
    }

    addProbe = (compIdx, ilbIdx) => {
        var formData = this.props.formIaas;

        formData.environments.map((env, envIdx) => {
            var ProbeIdName = formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].loadBalancerName;
            if (formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].probes) {

                formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].probes.push(
                    {
                        healthProbeName: ProbeIdName + '-ilb-probe-' + (formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].probes.length)
                    }
                );
            }
            else {
                formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].probes = [{
                    healthProbeName: ProbeIdName + '-ilb-probe-0'
                }];
            }

        });

        this.props.setFormIaas(formData);
    }

    removeProbe = (compIdx, ilbIdx, portIdx) => {
        var formData = this.props.formIaas;

        formData.environments.map((env, envIdx) => {
            formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].probes.splice(portIdx, 1);
        });

        this.props.setFormIaas(formData);
    }


    addPortMapping = (compIdx, ilbIdx, probeIdx) => {
        //console.log('Add Port Mapping to ilbIdx' + ilbIdx);
        var formData = this.props.formIaas;

        formData.environments.map((env, envIdx) => {
            if (formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].probes[probeIdx].portMappings) {

                formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].probes[probeIdx].portMappings.push(
                    {
                        protocol: 'Tcp',
                        port: '',
                        enableFloatingIP: 'false',
                        idleTimeoutInMinutes: "",
                        loadDistribution: ""
                    }
                );

            }
            else {
                formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].probes[probeIdx].portMappings = [{
                    protocol: 'Tcp',
                    port: '',
                    enableFloatingIP: 'false',
                    idleTimeoutInMinutes: "",
                    loadDistribution: ""
                }];
            }
        });

        this.props.setFormIaas(formData);
    }

    removePortMapping = (compIdx, ilbIdx, probeIdx, portIdx) => {
        var formData = this.props.formIaas;

        formData.environments.map((env, envIdx) => {
            formData.environments[envIdx].compute[compIdx].vm.loadBalancer[ilbIdx].probes[probeIdx].portMappings.splice(portIdx, 1);
        });

        this.props.setFormIaas(formData);
    }


    //downloadFile = (env) => {
    //    this.props.downloadFileAzureObjects(this.props.formIaas.azureObjectID, env);
    //}

    //downloadMiscObjects = () => {
    //    console.log('download Misc');
    //}


    validateForm = () => {
        var formData = this.props.formIaas;
        var validators = [];

        if (formData.regions) {
            if (formData.regions.length <= 0) {
                validators.push({ message: 'Select the region.' });
            }
        }
        else {
            validators.push({ message: 'Select the region.' });
        }

        if (formData.domain) {
            if (formData.domain.length <= 0) {
                validators.push({ message: 'Select the domain.' });
            }
        }
        else {
            validators.push({ message: 'Select the domain.' });
        }


        if (formData.classification) {
            if (formData.classification.length <= 0) {
                validators.push({ message: 'Select the classification.' });
            }
        }
        else {
            validators.push({ message: 'Select the classification.' });
        }

        if (formData.projectManager) {
            if (formData.projectManager.length === 0) {
                validators.push({ message: 'Project manager is required.' });
            }
        }
        else {
            validators.push({ message: 'Inform the name of the project manager' });
        }




        if (formData.environments) {
            if (formData.environments.length > 0) {
                formData.environments.map(function (env, envIdx) {
                    try {
                        if (env.isSAP) {
                            if (env.sapSid) {
                                if (env.sapSid.length <= 0) {
                                    validators.push({ message: 'Inform the SapSid of ' + env.environmentName + ' environment' });
                                }
                            }
                            else {
                                validators.push({ message: 'Inform the SapSid of ' + env.environmentName + ' environment' });
                            }
                        }
                    }
                    catch (e) {
                        // document.getElementById('environments.' + envIdx + '.sapSid').required = true;
                        // errorMessage += 'You need to specify SapSid for each SAP System<br/>';
                    }


                    try {
                        if (env.compute) {
                            if (env.compute.length > 0) {
                                env.compute.map(function (compute, compIdx) {
                                    if (compute.vm) {
                                        if (compute.vm.role) {
                                            if (compute.vm.role.length <= 0) {
                                                validators.push({ message: 'You need to specify the role to ' + compute.availabilitySet + '.' });
                                            }
                                        }
                                        else {
                                            validators.push({ message: 'You need to specify the role to ' + compute.availabilitySet + '.' });
                                        }

                                        if (compute.vm.description) {
                                            if (compute.vm.description.length <= 0) {
                                                validators.push({ message: 'You need to specify the description to ' + compute.availabilitySet + '.' });
                                            }
                                        }
                                        else {
                                            validators.push({ message: 'You need to specify the description to ' + compute.availabilitySet + '.' });
                                        }

                                        if (compute.vm.startSequence) {
                                            if (compute.vm.startSequence <= 0) {
                                                validators.push({ message: 'You need to specify the start sequence to ' + compute.availabilitySet + '.' });
                                            }
                                        }
                                        else {
                                            validators.push({ message: 'You need to specify the start sequence to ' + compute.availabilitySet + '.' });
                                        }

                                        if (compute.vm.count) {
                                            if (compute.vm.count <= 0) {
                                                validators.push({ message: 'You need to specify the quantity to ' + compute.availabilitySet + '.' });
                                            }
                                        }
                                        else {
                                            validators.push({ message: 'You need to specify the quantity to ' + compute.availabilitySet + '.' });
                                        }

                                        if (compute.vm.instanceSize) {
                                            if (compute.vm.instanceSize.length <= 0) {
                                                validators.push({ message: 'You need to specify the instance size to ' + compute.availabilitySet + '.' });
                                            }
                                        }
                                        else {
                                            validators.push({ message: 'You need to specify the instance size to ' + compute.availabilitySet + '.' });
                                        }

                                        if (compute.vm.image) {
                                            if (compute.vm.image.length <= 0) {
                                                validators.push({ message: 'You need to specify the image to ' + compute.availabilitySet + '.' });
                                            }

                                        }
                                        else {
                                            validators.push({ message: 'You need to specify the image to ' + compute.availabilitySet + '.' });
                                        }

                                        //if (compute.vm.loadBalancer) {
                                        //    if (compute.vm.loadBalancer.length > 0) {
                                        //        compute.vm.loadBalancer.map(function (ilb, ilbIdx) {
                                        //            if (ilb.probes) {
                                        //                if (ilb.probes.length > 0) {
                                        //                    ilb.probes.map(function (probe, probeIdx) {


                                        //                    });
                                        //                }
                                        //                else {
                                        //                    validators.push({ message: 'You need to add at least one probe to ' + compute.availabilitySet + ' into ' + ilb.loadBalancerName + '.' });
                                        //                }
                                        //            }
                                        //            else {
                                        //                validators.push({ message: 'You need to add at least one probe to ' + compute.availabilitySet + ' into ' + ilb.loadBalancerName + '.' });
                                        //            }

                                        //        });
                                        //    }
                                        //    else {
                                        //        validators.push({ message: 'You need to add at least one load balancer to ' + compute.availabilitySet + '.' });
                                        //    }

                                        //}
                                        //else {
                                        //    validators.push({ message: 'You need to add at least one load balancer to ' + compute.availabilitySet + '.' });
                                        //}

                                    }
                                    else {
                                        validators.push({ message: 'You need to specify the role to ' + compute.availabilitySet + '.' });
                                        validators.push({ message: 'You need to specify the description to ' + compute.availabilitySet + '.' });
                                        validators.push({ message: 'You need to specify the start sequence to ' + compute.availabilitySet + '.' });
                                        validators.push({ message: 'You need to specify the quantity to ' + compute.availabilitySet + '.' });
                                        validators.push({ message: 'You need to specify the instance size to ' + compute.availabilitySet + '.' });
                                        validators.push({ message: 'You need to specify the image to ' + compute.availabilitySet + '.' });
                                    }
                                });
                            }
                            else {
                                validators.push({ message: 'Add at least one compute set to the environment.' });
                            }

                        }
                        else {
                            validators.push({ message: 'Add at least one compute set to the environment.' });
                        }
                    }
                    catch (e) {
                        console.log(e);
                        // validators.push({ compute: 'Add at least one availability set to the environment' });
                    }

                });
            }
            else {
                validators.push({ message: 'Add at least one environment.' });
            }
        }
        else {
            validators.push({ message: 'Add at least one environment.' });
        }

        this.props.setFormValidators(validators, validators.length === 0 ? true : false);

        if (validators.length === 0) {
            this.props.submitInfraRequest(formData);
        }
        else {
            if (validators.length > 0) {
                const errors =
                    validators.map((error, idx) => {
                        return <div key={idx + 1}>{error.message}</div>;
                    });
                this.props.showModal(true, 'Please fix all errors to request infrastructure deployment:', 'danger', errors);
                return false;
            }
            else {
                return true;
            }
        }
    }

    saveDraft = () => {
        this.validateForm();

        this.props.saveDraft();
    }

    render() {
        let htmlContent = '<div>Nothing to show ' + this.props.action + ' </div>';

        htmlContent = this.props.action === undefined || this.props.action === 'listing' || this.props.action === null ? (<Redirect to='/' />) : htmlContent;

        htmlContent = this.props.action === 'deployed' || this.props.action === 'requested' ? (
            <Preview />) : htmlContent;

        //let htmlContent = this.props.action === 'deployed' ? (<CloudIaasListing
        //    goToList={this.goToInfra}
        //    goToImport={this.goToImport}
        //    goToRequest={this.goToRequest}
        //    closePreviewData={this.closePreviewData}
        //    parseEnum={this.parseEnum}
        //    getEnumValues={this.getEnumValues}
        ///>) : null;


        htmlContent = this.props.action === 'importing' && !this.props.isLoading ? (<CloudIaasImporting
            goToList={this.goToInfra}
            goToImport={this.goToImport}
            goToRequest={this.goToRequest}
            closePreviewData={this.closePreviewData}
            parseEnum={this.parseEnum}
            getEnumValues={this.getEnumValues} />) : htmlContent;

        //htmlContent = this.props.action === 'listing' ? (<CloudIaasListing
        //    goToList={this.goToInfra}
        //    goToImport={this.goToImport}
        //    goToRequest={this.goToRequest}
        //    closePreviewData={this.closePreviewData}
        //    parseEnum={this.parseEnum}
        //    getEnumValues={this.getEnumValues}
        ///>) : htmlContent;

        htmlContent = this.props.action === 'inserting' && !this.props.isLoading ? (<CloudIaasForm
            goToList={this.goToInfra}
            goToImport={this.goToImport}
            goToRequest={this.goToRequest}
            showHistory={this.showHistory}

            closePreviewData={this.closePreviewData}
            openPreviewData={this.openPreviewData}
            parseEnum={this.parseEnum}
            getEnumValues={this.getEnumValues}

            toggleTab={this.toggleTab}

            addEnvironment={this.addEnvironment}
            duplicateEnvironment={this.duplicateEnvironment}
            removeEnvironment={this.removeEnvironment}

            addCompute={this.addCompute}
            duplicateCompute={this.duplicateCompute}
            removeCompute={this.removeCompute}

            addDataDisk={this.addDataDisk}
            removeDataDisk={this.removeDataDisk}

            addLoadBalancer={this.addLoadBalancer}
            removeLoadBalancer={this.removeLoadBalancer}

            addProbe={this.addProbe}
            removeProbe={this.removeProbe}

            addPortMapping={this.addPortMapping}
            removePortMapping={this.removePortMapping}

            importedAzureObjects={this.importedAzureObjects}

            saveDraft={this.saveDraft}

            validateForm={this.validateForm}

        />) : htmlContent;

        htmlContent = this.props.action === 'inserted' && !this.props.isLoading ? (<Summary
            goToList={this.goToInfra}
            goToImport={this.goToImport}
            goToRequest={this.goToRequest}
            title="Congratulations!!!"
            message={this.props.summary}
        />) : htmlContent;


        return (
            <div className="row">
                <div className="col-md-12">
                    {htmlContent}
                </div>
            </div>
        );
    }
}
export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(CloudIaas);
