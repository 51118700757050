import React, { Component } from 'react';

import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, Form, FormGroup, Label, Input, Alert, Card, CardText, CardBody, CardTitle, CardSubtitle, CardLink } from 'reactstrap';
import classnames from 'classnames';
import WebSite from './WebSite';
import Databases from './Databases';


class AppSettings extends Component {

	constructor(props) {
		super(props);

		this.appTier = [
			{
				value: 'B1',
				title: 'Basic B1 - 1 Core',
				text: '1.75 RAM - 10GB Storage'
			},
			{
				value: 'B2',
				title: 'Basic B2 - 2 Cores',
				text: '3.5 RAM - 10GB Storage'
			},
			{
				value: 'B3',
				title: 'Basic B3 - 4 Cores',
				text: '7 RAM - 10GB Storage'
			},
			{
				value: 'S1',
				title: 'Standard S1 - 1 Core',
				text: '1.75 RAM - 50GB Storage'
			}, {
				value: 'S2',
				title: 'Standard S2 - 2 Cores',
				text: '3.5 RAM - 50GB Storage'
			}, {
				value: 'S3',
				title: 'Standard S3 - 4 Cores',
				text: '7 RAM - 50GB Storage'
			},
			{
				value: 'P1v2',
				title: 'Premium P1v2 - 1 Core',
				text: '3.5 RAM - 250GB Storage'
			},
			{
				value: 'P2v2',
				title: 'Premium P2v2 - 2 Cores',
				text: '7 RAM - 250GB Storage'
			},
			{
				value: 'P3v2',
				title: 'Premium P3v2 - 4 Cores',
				text: '14 RAM - 250GB Storage'
			},
			{
				value: 'I1',
				title: 'Isolated I1 - 1 Core',
				text: '3.5 RAM - 1024GB Storage'
			},
			{
				value: 'I2',
				title: 'Isolated I2 - 2 Cores',
				text: '7 RAM - 1024GB Storage'
			},
			{
				value: 'I3',
				title: 'Isolated I3 - 4 Cores',
				text: '14 RAM - 1024GB Storage'
			}
		];

	}

	render() {

		var currentAppTier = this.appTier.filter(f => f.value === this.props.formPaas.settings[this.props.appIdx].appTier);
		currentAppTier = currentAppTier.length > 0 ? currentAppTier[0] : currentAppTier;

		return (
			<div className="applicationSet">

				<FormGroup row>



					
					

					<Col md={2}>
						<FormGroup md={2}>
							<Label for="appTier">App Tier</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.appServicePlan.appTier'}
								id={'settings.' + this.props.appIdx + '.appServicePlan.appTier'}
								value={this.props.formPaas.settings[this.props.appIdx].appServicePlan ? this.props.formPaas.settings[this.props.appIdx].appServicePlan.appTier: ""}
								required
								valid={this.props.formPaas.settings[this.props.appIdx].appServicePlan ? this.props.formPaas.settings[this.props.appIdx].appServicePlan.appTier ? (this.props.formPaas.settings[this.props.appIdx].appServicePlan.appTier.length <= 0 ? false : true) : false: false}
								invalid={this.props.formPaas.settings[this.props.appIdx].appServicePlan ? this.props.formPaas.settings[this.props.appIdx].appServicePlan.appTier ? (this.props.formPaas.settings[this.props.appIdx].appServicePlan.appTier.length <= 0 ? true : false) : true : true}

							>
								<option value=""> Choose an option </option>
								{
									this.props.getEnumValues('appTierEnum').map(c => {
										return <option key={c.code} value={c.code} >{c.description}</option>;
									})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select an App Tier
                                </div>
						</FormGroup>

						

						
					</Col>


					{
						this.props.formPaas.settings[this.props.appIdx].appServicePlan ? 

							this.props.formPaas.settings[this.props.appIdx].appServicePlan.appTier ?
							<Col md={4}><Card>
								<CardBody>
									<CardSubtitle>{currentAppTier.title}</CardSubtitle>
									<CardText>{currentAppTier.text}</CardText>
									<CardLink href="https://azure.microsoft.com/en-us/pricing/details/app-service/plans/" target="_blank">More Info</CardLink>
								</CardBody>
							</Card></Col> : null : null
					}

				</FormGroup>




				<FormGroup row>
					<Col md={3}>
						<legend>WebApp</legend>
					</Col>
					<Col md={3}>
						<Button type="button" color="primary" onClick={() => this.props.addWebApp(this.props.appIdx)}><i className="fa fa-plus" /> Add WebApp </Button>
					</Col>
				</FormGroup>



				{ this.props.formPaas.settings[this.props.appIdx].webSite ? 
					this.props.formPaas.settings[this.props.appIdx].webSite.map((d, websiteIdx) => {
						return (
							<FormGroup row >
								<Col md={12}>
									<WebSite
										key={'ws-' + websiteIdx}
										appIdx={this.props.appIdx}
										webSiteIdx={websiteIdx}
										formPaas={this.props.formPaas}
										getEnumValues={this.props.getEnumValues}
										removeWebApp={this.props.removeWebApp}
									/>
								</Col>
							</FormGroup>
						);
					}) : null
				}


				<FormGroup row>
					<Col md={2}>
						<legend>Databases</legend>
					</Col>
					<Col md={2}>
						<Button type="button" color="primary" onClick={() => this.props.addDatabase(this.props.appIdx)}><i className="fa fa-plus" /> Add </Button>
					</Col>
				</FormGroup>
				{
					this.props.formPaas.settings[this.props.appIdx].database.map((d, dbIdx) => {
						return (
							<FormGroup row >
								<Col md={12}>
									<Databases
										key={'db-' + dbIdx}
										appIdx={this.props.appIdx}
										dbIdx={dbIdx}
										formPaas={this.props.formPaas}

										getEnumValues={this.props.getEnumValues}
										removeDataBase={this.props.removeDatabase}
									/>
								</Col>
							</FormGroup>
						);
					})
				}
			</div>
		);
	}
}

export default AppSettings;
