import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/storeObject';
import { IfAuthorized, AuthorizationPolicies } from '../../BaseComponents/Authorization';

class Preview extends Component {

    constructor(props) {
        super(props);
        this.Data = props.data ? props.data : this.props.formIaas;

        let buttonActions = null;
        let Environments = null;
        let AvailabilitySets = null;
        let Description = null;
        let Role = null;
        let Extension = null;
        let StartSequence = null;
        let Quantity = null;
        let InstanceSize = null;
        let Image = null;
        let osDisk = null;

        //let Publisher = null;
        //let Offer = null;
        //let SKU = null;
        let Disks = null;
        let LoadBalancer = null;
        let ObjData = {
            title: '',
            company: '',
            businessPriority: '',
            projectManager: '',
            applicationName: '',
            resourceGroup: '',
            resourceGroupStatus: '',
            regions: '',
            classification: '',
            subnetRequired: '',
            deployType: '',
            groupWithAccess: ''
        };

        let buttons = props.previewing && props.inserting ? true : false;
        let closebutton = props.onClose && props.previewing && !props.inserting ? true : false;
        let importing = props.importing ? { visibility: 'hidden', display: 'none' } : { visibility: 'visible' };
    }

    render() {

        if (this.Data) {

            this.ObjData = {
                title: this.Data.title,
                company: this.Data.company,
                businessPriority: this.Data.businessPriority,
                projectManager: this.Data.projectManager,
                applicationName: this.Data.applicationName,
                resourceGroup: this.Data.resourceGroup,
                resourceGroupStatus: this.Data.resourceGroupStatus,
                regions: this.Data.regions,
                classification: this.Data.classification,
                subnetRequired: this.Data.subnetRequired,
                deployType: this.Data.deployType,
                groupWithAccess: this.Data.groupWithAccess
            };

            if (this.Data.environments) {
                this.Environments = this.Data.environments.map(env => {
                    let colspan = env.compute ? env.compute.length : 1;
                    if (env.sapSid) {
                        return (<td className="Center borderRightTable" key={Math.random().toString()} colSpan={colspan}>{env.environmentName}
                            <br />
                            (SapSid: {env.sapSid} )
                             <IfAuthorized policy={AuthorizationPolicies.CloudAllowImplement}>
                                <div className="Center">
                                    <button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadFileAzureObjects(env.environmentName)}> <i className="fa fa-cloud-download" /> Parameter File </button>
                                </div>
                            </IfAuthorized>

                        </td>);
                    }
                    else {
                        return (<td className="Center borderRightTable" key={Math.random().toString()} colSpan={colspan}>{env.environmentName}
                            <br />
                            <IfAuthorized policy={AuthorizationPolicies.CloudAllowImplement}>
                                <div className="Center">
                                    <button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadFileAzureObjects(env.environmentName)}> <i className="fa fa-cloud-download" /> Parameter File </button>
                                </div>
                            </IfAuthorized>
                        </td>);
                    }
                });

                this.buttonActions = this.Data.environments.map(env => {
                    let colspan = env.compute ? env.compute.length : 1;

                    if (this.props.inserting) {
                        return (<td className="Center borderRightTable" key={Math.random().toString()} colSpan={colspan}>
                            <button className="btn btn-info btnForm" type='button' onClick={() => this.props.onDuplicate(env.environmentName)}> <i className="glyphicon glyphicon-eye-open" />  Duplicate  </button>
                        </td>);
                    }
                    else {
                        return (<td className="Center borderRightTable" key={Math.random().toString()} colSpan={colspan}>&nbsp;</td>);
                    }

                });




                this.AvailabilitySets = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                return (<td className={cssClass} key={Math.random().toString()}>{comp.availabilitySet} </td>);
                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp;</td>);
                });

                //AvailabilitySets = this.Data.environments.map(env => {
                //    if (env.compute) {
                //        if (env.compute.length > 0) {
                //            return env.compute.map(comp => {
                //                return (<td className="Center" key={Math.random().toString()}>{comp.availabilitySet} </td>);
                //            });
                //        }

                //    }
                //    return (<td key="Math.random()">&nbsp;</td>);
                //});

                this.Description = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                return (<td className={cssClass} key={Math.random().toString()}>{comp.vm.description} </td>);
                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp;</td>);
                });

                this.Role = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                return (<td className={cssClass} key={Math.random().toString()}>{comp.vm.role} </td>);
                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp;</td>);
                });

                this.Extension = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                if (comp.extension) {
                                    return (<td className={cssClass} key={Math.random().toString()}>{comp.extension} </td>);
                                }
                                else {
                                    return (<td className={cssClass} key={Math.random().toString()}> N/A </td>);
                                }


                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp; N/A</td>);
                });


                this.StartSequence = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                return (<td className={cssClass} key={Math.random().toString()}>{comp.vm.startSequence} </td>);
                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp;</td>);
                });

                this.Quantity = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                return (<td className={cssClass} key={Math.random().toString()}>{comp.vm.count} </td>);
                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp;</td>);
                });

                this.InstanceSize = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                return (<td className={cssClass} key={Math.random().toString()}>{comp.vm.instanceSize} </td>);
                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp;</td>);
                });

                this.Image = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                try {
                                    let name = comp.vm.image.split('/');
                                    //{ comp.vm.operatingSystem } /
                                    return (<td className={cssClass} key={Math.random().toString()}> {name[1]} / {name[2]} </td>);
                                }
                                catch (e) {
                                    return (<td className={cssClass} key={Math.random().toString()}>&nbsp;</td>);
                                }
                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp;</td>);
                });

                this.osDisk = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                if (comp.vmosDiskSizeGB) {
                                    return (<td className={cssClass} key={Math.random().toString()}>{comp.vm.osDiskSizeGB} </td>);
                                }
                                else {
                                    return (<td className={cssClass} key={Math.random().toString()}> n/a </td>);
                                }
                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp;</td>);
                });

                //Publisher = this.Data.environments.map(env => {
                //    if (env.compute) {
                //        if (env.compute.length > 0) {
                //            return env.compute.map(comp => {
                //                return (<td className="Center" key={Math.random().toString()}>{comp.vm.publisher} </td>);
                //            });
                //        }

                //    }
                //    return (<td key="Math.random()">&nbsp;</td>);
                //});

                //Offer = this.Data.environments.map(env => {
                //    if (env.compute) {
                //        if (env.compute.length > 0) {
                //            return env.compute.map(comp => {
                //                return (<td className="Center" key={Math.random().toString()}>{comp.vm.offer} </td>);
                //            });
                //        }

                //    }
                //    return (<td key="Math.random()">&nbsp;</td>);
                //});

                //SKU = this.Data.environments.map(env => {
                //    if (env.compute) {
                //        if (env.compute.length > 0) {
                //            return env.compute.map(comp => {
                //                return (<td className="Center" key={Math.random().toString()}>{comp.vm.sku} </td>);
                //            });
                //        }

                //    }
                //    return (<td key="Math.random()">&nbsp;</td>);
                //});

                this.Disks = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map((comp, compIdx) => {
                                cont++;
                                if (comp.vm.disks) {
                                    if (comp.vm.disks.length > 0) {
                                        let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                        return (<td className={cssClass} key={Math.random().toString()}>
                                            {
                                                comp.vm.disks.map(disk => <div key={Math.random().toString()}>{disk.diskSizeGB} (x{disk.quantity}) {disk.letter} {disk.label}</div>)
                                            }
                                        </td>);
                                    }

                                }
                                else {
                                    return (
                                        compIdx === env.compute.length - 1 ? <td key={Math.random().toString()} className={'borderRightTable'}> </td> :
                                            <td key={Math.random().toString()}> &nbsp; </td>);

                                }
                            });
                        }

                    }
                    return (<td key="Math.random().toString()">&nbsp;</td>);
                });

                this.LoadBalancer = this.Data.environments.map(env => {
                    if (env.compute) {
                        if (env.compute.length > 0) {
                            let cont = 0;
                            return env.compute.map(comp => {
                                cont++;
                                let cssClass = (cont === env.compute.length ? 'Center  borderRightTable' : 'Center');
                                if (comp.vm.loadBalancer) {
                                    var ilbNames = '';
                                    comp.vm.loadBalancer.map(ilb => {
                                        ilbNames += ' ' + ilb.loadBalancerName;
                                    });
                                    if (ilbNames !== '') {
                                        return (<td className={cssClass} key={Math.random().toString()}>{ilbNames}</td>);
                                    }
                                    else {
                                        return (<td className={cssClass} key={Math.random().toString()}>False</td>);
                                    }
                                    //if (comp.vm.loadBalancer.loadBalancerName) {
                                    //    if (comp.vm.loadBalancer.loadBalancerName.length > 0) {
                                    //        return (<td className={cssClass} key={Math.random().toString()}>
                                    //            {
                                    //                //comp.vm.loadBalancer.portMappings.map(ports =>
                                    //                //    <div key={ports.port}>{ports.protocol} {ports.port} {ports.enableFloatingIP} {ports.idleTimeoutInMinutes} {ports.loadDistribution}</div>)
                                    //            }
                                    //            True
                                    //    </td>);
                                    //    }
                                    //}
                                }
                                return (<td className={cssClass} key={Math.random().toString()}>False</td>);
                            });
                        }

                    }
                    return (<td key="Math.random()">&nbsp;</td>);
                });

            }
        }

        /* 
         * <span style={{ 'fontWeight': 'bold' }}>Priority: </span> {ObjData.businessPriority} - <br />
                        <span style={{ 'fontWeight': 'bold' }}>Manager: </span> {ObjData.projectManager} - <br />
                        <span style={{ 'fontWeight': 'bold' }}>Region: </span> {ObjData.regions} - <br />
                        <span style={{ 'fontWeight': 'bold' }}>Classification: </span> {ObjData.classification} - <br />
                        <span style={{ 'fontWeight': 'bold' }}>Subnet: </span> {ObjData.subnetRequired} - <br />
                            <span style={{ 'fontWeight': 'bold' }}>Can Read: </span> {ObjData.groupWithAccess}
                            <span style={{ 'fontWeight': 'bold' }}>Type: </span> {ObjData.deployType} - <br />
                 */


        return (
            <div>
                <div style={this.props.importing}>
                    <span style={{ 'fontWeight': 'bold' }}>AssetID: </span> {this.Data.assetID} -
                    <span style={{ 'fontWeight': 'bold' }}>Company: </span> {this.Data.company} -
                    <span style={{ 'fontWeight': 'bold' }}>AppName: </span> {this.Data.applicationName} -
                    <span style={{ 'fontWeight': 'bold' }}>Domain: </span> {this.Data.domain} -
                    <span style={{ 'fontWeight': 'bold' }}>Resource Group: </span> {this.Data.resourceGroup} ({this.Data.resourceGroupStatus}) - <br />
                    <br />
                </div>
                <div className="zui-wrapper">
                    <div className="zui-scroller">
                        <table className="full zui-table">
                            <tbody>
                                <tr className="Title">
                                    <td className="borderRightTable zui-sticky-col">Environments</td>
                                    {this.Environments}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">Availability Set</td>
                                    {this.AvailabilitySets}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">Description</td>
                                    {this.Description}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">Role</td>
                                    {this.Role}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col" >Extension</td>
                                    {this.Extension}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">Start Sequence</td>
                                    {this.StartSequence}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">Quantity</td>
                                    {this.Quantity}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">Instance Size</td>
                                    {this.InstanceSize}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">Image</td>
                                    {this.Image}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">OS Disk</td>
                                    {this.osDisk}
                                </tr>
                                <tr>
                                    <td className="borderRightTable zui-sticky-col">Disks</td>
                                    {this.Disks}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">Load Balancer</td>
                                    {this.LoadBalancer}
                                </tr>

                                <tr>
                                    <td className="borderRightTable zui-sticky-col">&nbsp;</td>
                                    {this.buttonActions}
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <br />
                    <div className="Center">
                        {
                            this.buttons ? <button className="btn btn-info btnForm" type='button' onClick={() => this.props.onClose()}> <i className="glyphicon glyphicon-pencil" /> Edit </button> : null
                        }

                        {
                            (this.buttons && this.Data.AzureObjectID <= 0) ? <button className="btn btn-info btnForm" type='submit' onClick={() => this.props.onDraftSubmit()}>  <i className="glyphicon glyphicon-floppy-disk" /> Save as Draft </button> : null
                        }

                        {
                            this.buttons ? <button className="btn btn-info btnForm" type='submit' onClick={() => this.props.onSubmit()}>  <i className="glyphicon glyphicon-floppy-disk" /> Save </button> : null
                        }

                        {
                            this.closebutton ? <button className="btn btn-info btnForm" type='button' onClick={() => this.props.onClose()}>  <i className="glyphicon glyphicon-eye-close" /> Close </button> : null
                        }
                    </div>

                    <IfAuthorized policy={AuthorizationPolicies.CloudAllowImplement}>
                        <div className="Center">
                            <div>
                                <button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadMiscFiles(this.Data.company.toLowerCase() + 'template')}> <i className="fa fa-cloud-download" /> Misc Files/Folder </button>&nbsp;&nbsp;&nbsp;&nbsp;

                                <button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadFileAzureObjects('deploymentfile')}> <i className="fa fa-cloud-download" /> AzureDeploy</button>&nbsp;&nbsp;&nbsp;&nbsp;

                                <button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadMiscFiles('dscscripts')}> <i className="fa fa-cloud-download" /> DSC Scripts </button>&nbsp;&nbsp;&nbsp;&nbsp;

                                <button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadMiscFiles('vcredist_installers')}> <i className="fa fa-cloud-download" /> Installers </button>
                                
                            </div>
                        </div>
                    </IfAuthorized>

                </div>
            </div>
        );
    }
};

//<tr>
//    <td>Publisher</td>
//    {Publisher}
//</tr>

//<tr>
//    <td>Offer</td>
//    {Offer}
//</tr>

//<tr>
//    <td>SKU</td>
//    {SKU}
//</tr>


export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Preview);
