import $ from 'jquery';

export default GridAutoResizer;

function GridAutoResizer(grid, $grid, $gridContainer) {

    // define some minimum height/width/padding before resizing
    var DATAGRID_MIN_HEIGHT = 180;
    var DATAGRID_MIN_WIDTH = 300;
    var DATAGRID_BOTTOM_PADDING = 35;// 20;

    /** Attach an auto resize trigger on the datagrid, if that is enable then it will resize itself to the available space
     * Options: we could also provide a % factor to resize on each height/width independently
     */
    function init() {
        if (!$grid || typeof $grid.offset() === "undefined") {
            // if we can't find the grid to resize, return without attaching anything
            return null;
        }

        //-- 1st resize the datagrid size on first load (because the onResize is not triggered on first page load)
        resizeToFitBrowserWindow(grid, $grid, $gridContainer);

        //-- 2nd attach a trigger on the Window DOM element, so that it happens also when resizing after first load
        $(window).on("resize", function () {
            // for some yet unknown reason, calling the resize twice removes any stuttering/flickering when changing the height and makes it much smoother
            resizeToFitBrowserWindow(grid, $grid, $gridContainer);
            resizeToFitBrowserWindow(grid, $grid, $gridContainer);
        });

        // in a SPA (Single Page App) environment you SHOULD also call the destroyAutoResize()
    }

    /* destroy the resizer when user leaves the page */
    function destroy() {
        $(window).trigger('resize').off('resize');
    }

    /**
    * Private function, calculate the datagrid new height/width from the available space, also consider that a % factor might be applied to calculation
    * object gridOptions
    */
    function calculateGridNewDimensions() {
        var availableHeight = $(window).height() - $grid.offset().top - DATAGRID_BOTTOM_PADDING;
        var availableWidth = $gridContainer.width();

        var newHeight = availableHeight;
        var newWidth = availableWidth - 2;

        // we want to keep a minimum datagrid size, apply these minimum if required
        if (newHeight < DATAGRID_MIN_HEIGHT) {
            newHeight = DATAGRID_MIN_HEIGHT;
        }
        if (newWidth < DATAGRID_MIN_WIDTH) {
            newWidth = DATAGRID_MIN_WIDTH;
        }

        return {
            height: newHeight,
            width: newWidth
        };
    }

    /** resize the datagrid to fit the browser height & width */
    function resizeToFitBrowserWindow() {
        // calculate new available sizes but with minimum height of 220px
        var newSizes = calculateGridNewDimensions($grid, $gridContainer);

        if (newSizes) {
            // apply these new height/width to the datagrid
            $grid.height(newSizes.height);
            $grid.width(newSizes.width);

            // resize the slickgrid canvas on all browser except some IE versions
            // exclude all IE below IE11
            if (new RegExp('MSIE [6-8]').exec(navigator.userAgent) === null && grid) {
                grid.resizeCanvas();
            }
        }
    }

    init();

    Object.assign(this, {
        destroy,
    });
}