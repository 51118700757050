import React, { Component } from 'react';
import { IfAuthorized, AuthorizationPolicies } from '../BaseComponents/Authorization';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';
import ProjectRequired from '../BaseComponents/ProjectRequired';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonImport,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonExport,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';

import DuPont from '../../jscomponents/DuPontApp';

const Toolbar = (props) => {

    const { crossSpinDataHidden } = DuPont.App.Config;

    return (
        <DataGridToolbar title="User Base Provisioning">
            <IfAuthorized policy={AuthorizationPolicies.AdminOnly}>
                <DataGridToolbarButtonsGroup>
                    <DataGridToolbarButtonSave />
                    <DataGridToolbarButtonDiscard />
                </DataGridToolbarButtonsGroup>
            </IfAuthorized>
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarDropdownButton
                    caption="ALL"
                    controlClass={crossSpinDataHidden ? "d-none" : null}
                    glyph="filter"
                    glyphColor='#4189C7'
                    filterOrder="1"
                    //filter="all"
                    filterReqMsg="Please choose a filter!"
                >
                    <DataGridToolbarDropdownItem filter="DAS" href="#">DAS</DataGridToolbarDropdownItem>
                    <DataGridToolbarDropdownItem filter="SPECCO" href="#">SPECCO</DataGridToolbarDropdownItem>
                    <DataGridToolbarDropdownItem filter="all" href="#">ALL</DataGridToolbarDropdownItem>
                </DataGridToolbarDropdownButton>{' '}
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonExport />
            </DataGridToolbarButtonsGroup>
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const UserBaseProvisioning = (props) => {
    const { multiSpin } = DuPont.App.Config;

    //if (multiSpin && !props.match.params.project) {
    //    return (
    //        <ProjectRequired />
    //    );
    //}
    return (
        <DataGrid model='userBaseProvisioning'>
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, UserBaseProvisioning, "UserBaseProvisioning");