import $ from 'jquery';

function ImportErrorHandler() {

    var errors = [];
    var grid;
    var $errorBtn;
    var $errorBadge;
    

    function init() {
        $errorBtn = $(".btn-error-import").popover({
            //trigger: 'focus click',
            placement: 'left',
            html: true
            //title: function () { return $errorPopoverTitle.html(); },
            //content: function () { return $errorPopoverContent.html(); }
        });

        $errorBadge = $errorBtn.children('.badge');
        $errorBtn.on('inserted.bs.popover', handleOnInsertedPopover);
        $('body').on('click', '.popover-error-import>.popover-body>div>a', handleOnErrClick);
        $('body').on('click', handleOnBodyClick);
    }

    function handleOnInsertedPopover(e) {
        const errorPopover = $(e.target).data('bs.popover').tip;
        $(errorPopover).addClass('popover-error-import');

        //errors.sort((e1, e2) => { return e1.itemId - e2.itemId });

        $(errorPopover).children('.popover-body').append(
            errors.map((err) => err.text).join('')
        );
    }

    function handleOnBodyClick(e) {
        //only buttons
        if ($(e.target).data('toggle') !== 'popover'
            && $(e.target).parents('[data-toggle="popover"]').length === 0
            && $(e.target).parents('.popover-error-import').length === 0
            && $('.popover-error-import').is(':visible')) {
            $errorBtn.popover('hide');
        }
    }

    function handleOnErrClick(e) {
        const $this = $(this);
        const row = grid.getData().getRowById($this.data('itemid'));
        const cell = $this.data('field') ? grid.getColumnIndex($this.data('field')) : grid.getActiveCell().cell;
        grid.focus();
        grid.gotoCell(row, cell, true);
        $errorBtn.popover('hide');
    }

    function add(errorsObj) {
//return { itemId: error.itemId, text: `<div><a href="#" data-itemid='${error.itemId}'>Row#${error.itemId} - <b>${err.code}</b></a> - ${err.description}</div>` };

        if (!$.isEmptyObject(errorsObj)) {

            for (var rowIdx in errorsObj) {
                const row = errorsObj[rowIdx];
                row.forEach((column) => {
                    column.errors.forEach((err) => {
                        //errors.push({ row: idx + 1, column: column.key, errorMessage: err.errorMessage });
                        errors.push({ text: `<div>Row#${parseInt(rowIdx) + 1} - <b>${column.key}</b> - ${err.errorMessage}</div>` });
                    });
                });
            }

            $errorBadge.text(errors.length);
            $errorBtn.removeClass('d-none');
        }
    }

    function reset() {
        $errorBtn.popover('hide');
        $errorBtn.addClass('d-none');
        $errorBadge.text('0');
        errors = [];
    }

    function destroy() {
        reset();
        $('body').off('click');
        $('body').off('click', '.popover-error-import>.popover-body>div>a');
        $errorBtn.off('inserted.bs.popover');
        $errorBtn.popover('dispose');
    }

    init();

    return {
        add: add,
        reset: reset,
        destroy: destroy
    };
};

export default ImportErrorHandler;