import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';
import { IfAuthorized, AuthorizationPolicies } from '../BaseComponents/Authorization';
import DuPont from '../../jscomponents/DuPontApp';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';
import ProjectRequired from '../BaseComponents/ProjectRequired';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButton,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarCheckBox,
    DataGridToolbarSplitButton,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonImport,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';
import ProvisionInfraStatusEdit from './ProvisionInfraStatusEdit';

//const ProvisionInfraStatus = () => {
class ProvisionInfraStatus extends Component {

    constructor(props) {
        super(props);

        this.model = 'provisionInfraStatus';
       
    }

    onGridInit = (gridComponent) => {
        this.gridComponent = gridComponent;        
    }


    requestInfra = (env) => {
        const currentGridItem = this.gridComponent.getCurrentItem();
        if (currentGridItem) {
            this.props.requestInfra(currentGridItem);
        }
    }

    importFiles = (env) => {
        const currentGridItem = this.gridComponent.getCurrentItem();
        if (currentGridItem) {
            this.props.importingAzureObjects(currentGridItem);
        }
    }

    render() {
        const { tempCurrentSpin, crossSpinDataHidden, multiSpin } = DuPont.App.Config;
        console.log(tempCurrentSpin);
        //const { project } = useParams();
        console.log(`Params.Project: ${this.props.match.params.project}`);
        console.log(`Params:`);
        console.log(this.props);
        const currentSpin = multiSpin ? this.props.match.params.project : tempCurrentSpin;



        if (multiSpin && !this.props.match.params.project) {
            return (
                <ProjectRequired />
            );
        }

        return (
            <DataGrid
                model={this.model}
                onGridInit={this.onGridInit}
                shouldPersistFilter
            >
                <ProvisionInfraStatusEdit />
                <DataGridToolbar
                    title={currentSpin + " Provis. Infra. Status"}
                >
                    <DataGridToolbarButtonsGroup>
                        <DataGridToolbarErrorsButton />
                        <DataGridToolbarButtonSave />
                        <DataGridToolbarButtonDiscard />
                    </DataGridToolbarButtonsGroup>{' '}
                    <DataGridToolbarCheckBox
                        id="showAllCheckBox"
                        filterOrder="2"
                        filterWhenChecked="all"
                        filterWhenUnchecked="enabled"
                        caption="Show Disabled Apps"
                    />
                    <DataGridToolbarButtonsGroup>
                        <DataGridToolbarDropdownButton
                            caption="ALL"
                            controlClass={crossSpinDataHidden ? "d-none" : null}
                            glyph="filter"
                            glyphColor='#4189C7'
                            filterOrder="1"
                            filter={crossSpinDataHidden ? currentSpin : "all"}
                            filterReqMsg="Please choose a filter!"
                        >
                            <DataGridToolbarDropdownItem filter="DAS" href="#" caption='DAS' />
                            <DataGridToolbarDropdownItem filter="SPECCO" href="#" caption='SPECCO' />
                            <li className="dropdown-divider" />
                            <DataGridToolbarDropdownItem filter="all" href="#" caption='ALL' />
                        </DataGridToolbarDropdownButton>{' '}
                        <DataGridToolbarButtonRefresh />
                        <IfAuthorized policy={AuthorizationPolicies.CloudAllowView}>
                            <DataGridToolbarButton
                                outline
                                bsStyle='primary'
                                onClick={() => this.requestInfra()}
                                glyph="list-alt"
                                glyphColor='#4189C7'
                                text="Infra Request"
                                tooltip
                                caption
                                captionAlwaysVisible
                            />
                        </IfAuthorized>
                        <IfAuthorized policy={AuthorizationPolicies.CloudAllowImplement}>
                            <DataGridToolbarButton
                                outline
                                bsStyle='primary'
                                onClick={() => this.importFiles()}
                                glyph="list-alt"
                                glyphColor='#4189C7'
                                text="Upload Iaas Files"
                                tooltip
                                caption
                                captionAlwaysVisible
                            />
                        </IfAuthorized>
                        <DataGridToolbarButtonImport />
                        <DataGridToolbarSplitButton
                            bsStyle='info'
                            text="Export"
                            tooltip
                            btnClass="btn-export"
                            glyph="download"
                            glyphColor='#4189C7'
                            modalTarget="#downloadModal"
                            filterReq="1"
                        >
                            <DataGridToolbarDropdownItem
                                btnClass="btn-export-track-changes"
                                modalTarget="#downloadModal"
                                filterReq="1"
                                caption="Export with TrackChanges"
                            />
                        </DataGridToolbarSplitButton>
                        <DataGridToolbarButtonEdit text="Edit Notes" />
                        <DataGridToolbarButtonHistory />
                        <DataGridToolbarFavoritesSplitButton />
                    </DataGridToolbarButtonsGroup>
                </DataGridToolbar>
            </DataGrid>
        );
    }
}

export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withAITracking(ai.reactPlugin, ProvisionInfraStatus, "ProvisionInfraStatus"));
