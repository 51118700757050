import React, { PureComponent } from 'react';
import { Glyphicon, DataGridToolbarDropdownItem } from './DataGridToolbar';
import { Button } from 'reactstrap';

class DataGridToolbarDropdownButton extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            filterText: ''
        };
    }

    handleOnInputChange = (ev) => {
        this.setState({ filterText: ev.target.value });
    };

    handleOnInputKeyUp = (ev) => {
        if (ev.which === 40 && this.rgDropDown.hasChildNodes()) {
            this.rgDropDown.firstChild.focus();
        }
    };

    renderDropDownItems() {
        let result;
        if (this.props.items) {
            if (this.props.items.length > 0) {
                const { filterText } = this.state;
                const items = (filterText ? this.props.items.filter(i => i.toUpperCase().indexOf(filterText.toUpperCase()) > -1) : this.props.items)
                    .map(i => <DataGridToolbarDropdownItem key={i} filter={i} href="#">{i}</DataGridToolbarDropdownItem>);
                if (this.props.searchable) {
                    result = (
                        <React.Fragment>
                            <input
                                key="__search"
                                className="form-control form-control-sm"
                                value={this.state.filterText}
                                onChange={this.handleOnInputChange}
                                onKeyUp={this.handleOnInputKeyUp}
                            />
                            <div className="dropdown-menu-list" ref={el => this.rgDropDown = el}>{items}</div>
                        </React.Fragment>
                        );
                }
            } else {
                result = <DataGridToolbarDropdownItem key="__loading" >Loading...</DataGridToolbarDropdownItem>;
            }
        } else {
            result = this.props.children;
        }
        return result;
    }

    render() {
        const glyph = this.props.glyph ? <Glyphicon glyph={this.props.glyph} style={{ color: this.props.glyphColor }} /> : '';
        return (
            <div className={`btn-group ml-1 ${this.props.controlClass || ''}`}>
                <Button
                    type="button"
                    color='info'
                    className="btn btn-default btn-sm dropdown-toggle"
                    data-toggle="dropdown"
                    data-filter-order={this.props.filterOrder}
                    data-filter={this.props.filter}
                    data-filter-req-msg={this.props.filterReqMsg}
                    data-filter-req={this.props.filterReq}
                >
                    {glyph}
                    <span className='caption'>{this.props.caption}</span>{' '}
                    <span className="caret" />
                </Button>
                <div className="dropdown-menu dropdown-menu-right" role="menu">
                    {this.renderDropDownItems()}
                </div>
            </div>
        );
    }
}

export default DataGridToolbarDropdownButton;