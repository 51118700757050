import React from 'react';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarButton,
    DataGridToolbarErrorsButton,
    DataGridToolbarSearchBox,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarCheckBox,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

const Toolbar = () => {
    return (
        <DataGridToolbar
            title="Users"
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarCheckBox
                id="showDeletedCheckBox"
                filterOrder="1"
                filterWhenChecked="all"
                filterWhenUnchecked="enabled"
                caption="Show Deleted"
            />
            <DataGridToolbarSearchBox
                filterOrder="2"
                filter="search"
            />
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButton
                    outline
                    btnClass="btn-undelete"
                    bsStyle='secondary'
                    glyph="recycle"
                    text="Undelete"
                    tooltip
                    caption
                />
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const UserManager = () => {
    return (
        <DataGrid model='userManager'>
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, UserManager, "UserManager");