import React from 'react';
import { EditModal } from '../BaseComponents/Modals';

const LegalEnityEdit = () => {
    return (
        <EditModal id="legalEntitiesEditModal" title="LegalEnity Edit">
            <div className="form-row">
                <div className="form-group col-md-2 small">
                    <label className="control-label" htmlFor="LegalEntityID">legalEntity ID</label>
                    <input type="number" className="form-control form-control-sm" id="legalEntityID" name="legalEntityID" readOnly />
                </div>
                <div className="form-group col-md-4 small">
                    <label className="control-label" htmlFor="LEC">LEC</label>
                    <input type="text" className="form-control form-control-sm" id="lec" name="lec" />
                </div>
                <div className="form-group col-md-1 small">
                    <label className="control-label" htmlFor="Region">Region</label>
                    <input type="text" className="form-control form-control-sm" id="region" name="region" />
                </div>

            </div>
        </EditModal>
    );
};

export default LegalEnityEdit;