import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';
import { IfAuthorized, AuthorizationPolicies } from '../BaseComponents/Authorization';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';
import ProjectRequired from '../BaseComponents/ProjectRequired';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarCheckBox,
    DataGridToolbarButton,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonImport,
    DataGridToolbarSplitButton,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';

import DuPont from '../../jscomponents/DuPontApp';


//const AppSpins = props => {
class AppSpins extends Component {

    constructor(props) {
        super(props);
    }

    onGridInit = (gridComponent) => {
        this.gridComponent = gridComponent;
    }

    requestPaas = (env) => {
        const currentGridItem = this.gridComponent.getCurrentItem();
        if (currentGridItem) {
            this.props.requestPaas(currentGridItem);
        }
    }


    render() {
        const { tempCurrentSpin, crossSpinDataHidden, multiSpin } = DuPont.App.Config;
        console.log(tempCurrentSpin);
        //const { project } = useParams();
        console.log(`Params.Project: ${this.props.match.params.project}`);
        console.log(`Params:`);
        console.log(this.props);
        const currentSpin = multiSpin ? this.props.match.params.project : tempCurrentSpin;
        
        if (multiSpin && !this.props.match.params.project) {
            return ( < ProjectRequired /  > );
        } else {
            return (
                <DataGrid
                    model='appSpin'
                    onGridInit={this.onGridInit}>

                    <DataGridToolbar
                        title={currentSpin + " Applications"}
                    >
                        <DataGridToolbarButtonsGroup>
                            <DataGridToolbarErrorsButton />
                            <DataGridToolbarButtonSave />
                            <DataGridToolbarButtonDiscard />
                        </DataGridToolbarButtonsGroup>{' '}
                        <DataGridToolbarCheckBox
                            id="showAllCheckBox"
                            filterOrder="2"
                            filterWhenChecked="all"
                            filterWhenUnchecked="enabled"
                            caption="Show Disabled Apps"
                        />
                        <DataGridToolbarButtonsGroup>
                            <DataGridToolbarDropdownButton
                                caption="ALL"
                                controlClass={crossSpinDataHidden ? "d-none" : null}
                                glyph="filter"
                                glyphColor='#4189C7'
                                filterOrder="1"
                                filter={crossSpinDataHidden ? currentSpin : "all"}
                                filterReqMsg="Please choose a filter!"
                            >
                                <DataGridToolbarDropdownItem filter="DAS" href="#" caption='DAS' />
                                <DataGridToolbarDropdownItem filter="SPECCO" href="#" caption='SPECCO' />
                                <li className="dropdown-divider" />
                                <DataGridToolbarDropdownItem filter="all" href="#" caption='ALL' />
                            </DataGridToolbarDropdownButton>
                            <DataGridToolbarButtonRefresh />
                            {/*<DataGridToolbarButtonImport />*/}
                            <DataGridToolbarSplitButton
                                text="Import"
                                tooltip
                                btnClass="btn-import"
                                glyph="upload"
                                glyphColor='green'
                                modalTarget="#uploadModal"
                            >
                                <DataGridToolbarDropdownItem
                                    btnClass="btn-export-track-changes"
                                    modalTarget="#uploadModal"
                                    importUrl="/api/applications/import"
                                    caption="Import from SsoT"
                                />
                            </DataGridToolbarSplitButton>
                            <DataGridToolbarButtonExport />
                            <DataGridToolbarButtonHistory />
                            <IfAuthorized policy={AuthorizationPolicies.CloudAllowView}>
                                <DataGridToolbarButton
                                    outline
                                    bsStyle='primary'
                                    onClick={() => this.requestPaas()}
                                    glyph="list-alt"
                                    glyphColor='#4189C7'
                                    text="Paas Request"
                                    tooltip
                                    caption
                                    captionAlwaysVisible
                                />
                            </IfAuthorized>
                            <DataGridToolbarFavoritesSplitButton />
                        </DataGridToolbarButtonsGroup>
                    </DataGridToolbar>
                </DataGrid>
            );
        }
    }
};

export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withAITracking(ai.reactPlugin, AppSpins, "AppSpins"));