import $ from 'jquery';
import DuPont from './DuPontApp';
import { golLiveAppApi } from './Main';

export const AuthorizationPolicies =
{
    AdminOnly: "AdminOnly",
    AppStatusAllowView: "AppStatusAllowView",
    AppListUser: "AppListUser",
    AppStatusAllowEditBaselineDates: "AppStatusAllowEditBaselineDates",
    ProvisionInfraStatusUser: "ProvisionInfraStatusUser",
    AppModernizationStatusUser: "AppModernizationStatusUser",
    AppSiteAllowView: "AppSiteAllowView",
    AppSiteAllowManage: "AppSiteAllowManage",
    User: "User",
    UserManager: "UserManager",
    HROnly: "HROnly",
    SiteUsers: "SiteUsers",
    HistoryViewer: "HistoryViewer",
    CloudAllowView:"CloudAllowView",
    CloudAllowRequest:"CloudAllowRequest",
    CloudAllowImplement: "CloudAllowImplement",
    AMSAdminOnly: "AMSAdminOnly",
    AMSUser: "AMSUser",
    PowerBI: "PowerBI",
    MasterPlanAllowView: "MasterPlanAllowView",
    MasterPlanAllowEdit: "MasterPlanAllowEdit",
    MnAAllowView: "MnAAllowView",
    SapUserReqAllowSubmit: "SapUserReqAllowSubmit",
    SapUserReqAllowView: "SapUserReqAllowView",
    SapUserReqAllowEdit: "SapUserReqAllowEdit",
    AppMnAAllowView: "AppMnAAllowView",
    AppMnAAllowEdit: "AppMnAAllowEdit",

    LegalEntitiesAllowView: "LegalEntitiesAllowView",
    LegalEntitiesAllowManage: "LegalEntitiesAllowManage"
};

class AuthorizationService {

    constructor() {
        this.authorizations = {};
        this.isInitialized = false;
    }

    initialize() {
        
        if (this.isInitialized) return $.Deferred().resolve();

        if (!this.initializeRequest){
            const requestArgs = {
                url: DuPont.App.SERVER_PATH + 'authorization',
                type: 'POST',
                data: JSON.stringify(Object.keys(AuthorizationPolicies))
            };

            this.initializeRequest = golLiveAppApi.submitRequest(requestArgs)
                .done((result) => {
                    this.authorizations = result;
                })
                .always(() => {
                    this.isInitialized = true;
                    this.initializeRequest = null;
                });
        }

        return this.initializeRequest;
    }

    authorize(policy) {
        return !!this.authorizations[policy];
    }
}

const authorizationService = new AuthorizationService();
export default authorizationService;
