import $ from 'jquery';
import { Slick, Data, Grid, FrozenGrid, Plugins } from '../assets/slickgrid-es6/src';
import goLiveApp, { golLiveAppApi } from './Main';
import DuPont from './DuPontApp';
import GridAutoResizer from './grid.autoresizer';
import GridFooter from './grid.footer';
import GridToolbar from './grid.toolbar';
import ErrorHandler from './grid.errors';
import EditModalErrorHandler from './grid.editmodal.errors';
import FavoritesHandler from './grid.favorites';
import DataLoader from './grid.dataloader';
import ImportHandler from './Import';
import ExportHandler from './Export';

import './grid.component.css';

if (typeof $.when.all === 'undefined') {
    $.when.all = function (deferreds) {
        var toArray = function (args) { return deferreds.length > 1 ? [args] : [[args]]; };
        return $.Deferred(function (def) {
            $.when.apply($, deferreds).then(
                function () {
                    //def.resolveWith(this, [Array.prototype.slice.call(arguments)]);
                    def.resolveWith(this, toArray(arguments));
                },
                function () {
                    //def.rejectWith(this, [Array.prototype.slice.call(arguments)]);
                    def.rejectWith(this, toArray(arguments));
                });
        });
    };
}

export default GridComponent;

// shared across all components on the page
//var scrollbarDimensions;
//var maxSupportedCssHeight;  // browser's breaking point

//////////////////////////////////////////////////////////////////////////////////////////////
// GridComponent class implementation (available as DuPont.GridComponent)
function GridComponent(gridArgs) {
    const container = gridArgs.container;
    let { model, filterExtraPath, routerHistory, filterObject } = gridArgs;
    const self = this;
    let grid;
    let dataView;
    //var data = []; // scope reduced to load function
    let columns;
    let dataLoader;
    let dataLoaderTimeoutId;
    let headerRowFilterTimeoutId;


    // keep a copy of all column for the array of visible columns
    let visibleColumns;
    let filterColumns = {};

    const multiSelectLkp = { data: {}, fields: [] };

    const defaultOptions = {
        columnPicker: {
            //columnTitle: "Columns",
            hideForceFitButton: false,
            hideSyncResizeButton: false,
            forceFitTitle: "Force fit columns",
            syncResizeTitle: "Synchronous resize",
        },
        editable: true,
        enableAddRow: true,
        enableCellNavigation: true,
        multiColumnSort: true,
        tristateMultiColumnSort: true,
        asyncEditorLoading: true,
        autoEdit: false,
        changedCellCssClass: 'changed',
        invalidCellCssClass: 'bulk-invalid',
        showHeaderRow: true,
        headerRowHeight: 26,
        explicitInitialization: true,
        frozenColumn: 0
        //forceFitColumns: true,
        //enableColumnReorder: false,
        //dataItemColumnValueExtractor: getVal,
        //dataItemColumnValueSetter: setVal
    };

    let options = defaultOptions;

    const cellRangeSelectorOptions = {
        cellDecorator: new Plugins.CellRangeDecorator(),
        selectionCss: {
            border: '2px dashed #217346' //'1.5px dashed rgb(0, 123, 255)',
            //'background-color': 'transparent' //'rgba(102, 140, 255, 0.2);'
        },
        offset: {
            top: 0,
            left: 0,
            height: 1,
            width: 0
        }
    };

    const cellSelModOptions = {
        cellRangeSelector: new Plugins.CellRangeSelector(cellRangeSelectorOptions)
    };

    const undoRedoBuffer = {
        commandQueue: [],
        commandCtr: 0,

        queueAndExecuteCommand: function (editCommand) {
            this.commandQueue[this.commandCtr] = editCommand;
            this.commandCtr++;
            editCommand.execute();
        },

        undo: function () {
            if (this.commandCtr == 0) { return; }

            this.commandCtr--;
            var command = this.commandQueue[this.commandCtr];

            if (command && Slick.GlobalEditorLock.cancelCurrentEdit()) {
                command.undo();
            }
        },
        redo: function () {
            if (this.commandCtr >= this.commandQueue.length) { return; }
            var command = this.commandQueue[this.commandCtr];
            this.commandCtr++;
            if (command && Slick.GlobalEditorLock.cancelCurrentEdit()) {
                command.execute();
            }
        }
    };

    function getVal(item, columnDef) {
        //return dataView.getItemById(item.__id)[columnDef.field];
        //return item[columnDef.field];

        return columnDef.formatter ?
            columnDef.formatter(-1, -1, item[columnDef.field], columnDef, item) :
            item[columnDef.field];
    }

    function getSortingVal(item, columnDef) {
        return item[columnDef.field];
    }

    function setVal(item, columnDef, value) {
        if (columnDef.editor) {
            var result;
            if (columnDef.dataSource && getInvertedDataSource(columnDef)) {
                if (columnDef.isMultiSelect)
                    result = value.split(',').filter(s => s).map(s => columnDef.invertedDataSource[s]);
                else
                    result = columnDef.invertedDataSource[value];

            //} else if (columnDef.formatter != null && columnDef.formatter.toString().substr(0, 20) === "function NAFormatter" && value != null && value.toUpperCase() === 'N/A') {
            } else if (columnDef.allowsNA && value != null && value.toUpperCase() === 'N/A') {
                result = null;
            } else {
                result = value;
            }
            item[columnDef.field] = result;
            //    dataView.updateItem(item.__id, item);
            return true;
        } else {
            return false;
        }
    }

    function getInvertedDataSource(columnDef) {
        return columnDef.invertedDataSource ||
            (columnDef.invertedDataSource = invertObject(columnDef.dataSource));
    }

    function invertObject(obj) {
        return Object.keys(obj).reduce((result, k) => {
            result[obj[k]] = k;
            return result;
        }, {});
    }


    //saving
    let newLocalId = 1;
    //let originalItem;
    //let isCurrentRowDirty = false;
    //let isCurrentRowNew = false;
    let currentRowIdx;
    //let originalItems = [];
    let changes = {};
    let invalidItems = {};
    let needsRefresh = false;

    //repository members
    const defaultConfig = { isVirtual: false, dataLoading: 0 };
    let keyField;
    let parentKeyField;
    let controllerpath='';
    let rootExtraPath;
    let dataLoading; // -1-never, 0-onLoad, 1-onReload
    let defaultValuesItem;
    let modalId;
    let modalUpdType;
    let modalEditItem = {};
    let parseDataFromServer;
    let parseDataToServer;
    let transformers;
    const repository = DuPont.App.Repository;
    let repositoryObj;
    let isVirtual;

    const SERVER_PATH = DuPont.App.SERVER_PATH;
    const DEF_LOOKUP_PATH = 'lookupvalues/lookups';
    const defLkpConfig = {
        path: DEF_LOOKUP_PATH,
        key: 'lookupValueID',
        display: 'lookupValueCode',
        req: { url: SERVER_PATH + DEF_LOOKUP_PATH, type: 'POST' }
    };
    let lkpConfigs = [];

    let toolbar;
    let footer;
    let errors;
    let modalEditErrors;
    let favorites;
    let autoResizer;
    let $modal;
    let $timeText;
    let $progressText;
    let $progressBar;
    let filterPlugin;
    let importHandler;
    let exportHandler;

    function init() {
        repositoryObj = repository.getRepositoryObject(model);
        columns = repository.getColumns(model);
        parseDataFromServer = repositoryObj.dataFromServerParser;
        parseDataToServer = repositoryObj.dataToServerParser;
        transformers = repository.getTransformers(model);
        keyField = repositoryObj.keyField;
        parentKeyField = repositoryObj.parentKeyField;
        controllerpath = repositoryObj.controllerpath;
        rootExtraPath = repositoryObj.rootExtraPath;
        defaultValuesItem = repositoryObj.defaultValues || {};
        modalId = repositoryObj.modalId;
        isVirtual = repositoryObj.isVirtual != null ? repositoryObj.isVirtual : defaultConfig.isVirtual;
        dataLoading = repositoryObj.dataLoading != null ? repositoryObj.dataLoading : defaultConfig.dataLoading;
        if (modalId) {
            $modal = $('#' + modalId);
            if (repositoryObj.showUploadProgress) {
                $timeText = $(`#${modalId} .time-text`);
                $progressText = $(`#${modalId} .progress-text`);
                $progressBar = $(`#${modalId} .progress-bar`);
            }
        }
        if (repositoryObj.gridOptions) options = $.extend({}, defaultOptions, repositoryObj.gridOptions);

        setPermissions().always(endInit);
    }

    function endInit() {
        dataView = new Data.DataView(
            { inlineFilters: true }
        );
        //dataView.setItems(data, '__id');
        grid = new FrozenGrid(container, dataView, columns, options);

        // register colfix plguin
        //grid.registerPlugin(new Slick.Plugins.ColFix('firstName'));

        const $container = $(container);
        autoResizer = new GridAutoResizer(grid, $container, $container.parent());

        grid.setSelectionModel(new Plugins.CellSelectionModel(cellSelModOptions));
        //grid.setSelectionModel(new Slick.RowSelectionModel());

        //var pager = new Slick.Controls.Pager(dataView, grid, $container.next('.grid-pager'));
        footer = new GridFooter(dataView, self, $container.next('.grid-pager'));
        //var columnpicker = new Slick.Controls.ColumnPicker(columns, grid, options);

        //const toolbarId = $container.attr('data-toolbar');
        const $toolbarContainer = //toolbarId ? $('#' + toolbarId) :
            $('.grid-toolbar');
        toolbar = new GridToolbar(self, $toolbarContainer);
        errors = new ErrorHandler(self);
        if (modalId) modalEditErrors = new EditModalErrorHandler(self, modalId);
        favorites = new FavoritesHandler(self);

        if (isVirtual) {
            dataLoader = new DataLoader({
                gridComponent: self,
                keyField,
                multiSelectFieldName: repositoryObj.multiSelectFieldName
            });
        }

        grid.registerPlugin(new Plugins.AutoTooltips(null, self));
        initOverlayPlugin();
        // set keyboard focus on the grid
        //grid.getCanvasNode().focus();
        initCellCopyManagerPlugin();
        initHeaderFilterPlugin();

        setupOnEditorCommitHandler();
        setupGridHandlers();
        setupDataViewHandlers();
        setupDocumentHandlers();
        setupCellLinkClickedHandlers();

        importHandler = new ImportHandler({ gridComponent: self, browser: goLiveApp.Browser });
        exportHandler = new ExportHandler({ gridComponent: self, browser: goLiveApp.Browser });

        grid.init();

        // dataLoading: -1-never, 0-onLoad, 1-onReload
        if (filterObject && filterObject.toolbar) {
            applyFavoriteFilter(filterObject, true);
        } else if (dataLoading === 0) {
            refreshCore();
        }
        else {
            dataView.setItems([], '__id');
            goLiveApp.loaderHide();
        }

        //add tooltip fix for IE
        if (goLiveApp.Browser === 'IE') $('body').css('overflow', 'hidden');

        //bootstrap tooltip init
        const gridUID = grid.getUID();
        const headerElementsWithTooltips = columns.filter(c => c.toolTip).map(c => gridUID + c.id);
        
        $('.slick-header-column')
            .filter((index, element) => headerElementsWithTooltips.includes(element.id))
            .attr('data-toggle', 'tooltip')
            .tooltip();
    }

    function setPermissions() {
        return repositoryObj.setPermissions ? repositoryObj.setPermissions({ columns }) : $.when();
    }

    function initOverlayPlugin() {
        var overlayPlugin = new Plugins.Overlays({
            cellRangeSelector: cellSelModOptions.cellRangeSelector,
            cellDecorator: cellRangeSelectorOptions.cellDecorator
        });

        // Event fires when a range is selected
        overlayPlugin.onFillUpDown.subscribe(function (e, args) {
            Slick.GlobalEditorLock.commitCurrentEdit();
            const fields = []; //grid.getColumns().filter((c, i) => i === args.range.fromCell && c.editor).map(c => c.field);

            const columns = grid.getColumns();
            for (var index = 0; index < columns.length; index++) {
                const column = columns[index];
                if (column.editor && index >= args.range.fromCell && index <= args.range.toCell)
                    fields.push({ name: column.field, index });
            }

            // Ensure the column is editable
            if (fields.length === 0) {
                return;
            }

            // Find the initial value
            const item = dataView.getItem(args.range.fromRow);
            const values = fields.map(field => item[field.name]);

            dataView.beginUpdate();

            // Copy the value down
            for (var i = args.range.fromRow + 1; i <= args.range.toRow; i++) {
                //dataView.getItem(i)[column.field] = value;
                //grid.invalidateRow(i);
                var row = dataView.getItem(i);
                fields.forEach((field, idx) => {
                    row[field.name] = values[idx];

                    var eventArgs = {
                        item: row,
                        grid: grid,
                        cell: field.index //args.range.fromCell
                    };

                    grid.onCellChange.notify(eventArgs);
                });


            }
            dataView.endUpdate();
            grid.render();
            grid.getSelectionModel().setSelectedRanges([args.range]);

        });
        grid.registerPlugin(overlayPlugin);
    }

    function initCellCopyManagerPlugin() {
        const pluginOptions = {
            gridComponent: self,
            clipboardCommandHandler: function (editCommand) { undoRedoBuffer.queueAndExecuteCommand.call(undoRedoBuffer, editCommand); },
            readOnlyMode: false,
            //includeHeaderWhenCopying: true,
            newRowCreator: function (count) {
                for (var i = 0; i < count; i++) {
                    const newId = newLocalId++;
                    var item = { __id: newId, num: newId };
                    setChanges(1, item);
                    dataView.addItem(item);
                }
            },
            dataItemColumnValueExtractor: getVal,
            dataItemColumnValueSetter: setVal,
            onPasteSuccess: () => { checkFocus(); }
        };
        const copyManager = new Plugins.CellExternalCopyManager(pluginOptions);
        grid.registerPlugin(copyManager);
        copyManager.onPasteCells.subscribe((e, args) => {
            const currentSelectedRange = grid.getSelectionModel().getSelectedRanges()[0];
            if (currentSelectedRange.fromRow !== currentSelectedRange.toRow)
                validateChanges();
        });
    }

    function initHeaderFilterPlugin() {
        const headerOptions = {
            gridComponent: self,
            browser: goLiveApp.Browser,
            buttonImage:    "../images/down.png",
            filterImage:    "../images/filter.png",
            sortAscImage:   "../images/sort-asc.png",
            sortDescImage:  "../images/sort-desc.png"
        };

        filterPlugin = new Plugins.HeaderFilter(headerOptions);

        filterPlugin.onFilterApplied.subscribe(function (e, args) {
            refreshDataView();
            grid.resetActiveCell();
        });

        filterPlugin.onCommand.subscribe(function (e, args) {
            if (args.command.startsWith('sort-') && args.column.sortable) {
                const column = args.column;
                const fields = []; let includeMultiSelectData = false;
                if (column.isMultiSelect /*&& !column.isCustomMultiSelect*/)
                    includeMultiSelectData = true;
                else
                    fields.push(column.field);
                ensureColumnsData(fields, includeMultiSelectData)
                    .done(() => {
                        grid.setSortColumn(args.column.field, args.command === "sort-asc");

                        if (!options.multiColumnSort) {
                            grid.onSort.notify({
                                multiColumnSort: false,
                                sortCol: column,
                                sortAsc: args.command === "sort-asc"
                            }, null, grid);
                        } else {
                            grid.onSort.notify({
                                multiColumnSort: true,
                                sortCols: [{ sortCol: column, sortAsc: args.command === "sort-asc" }]
                            }, null, grid);
                        }
                    });
            }
        });

        grid.registerPlugin(filterPlugin);
    }

    function getDataFromServer(id, idExtraPath, fallBackId) {
        var urlItems = [DuPont.App.SERVER_PATH + controllerpath];
        if (id != null) {
            urlItems.push(idExtraPath);
            urlItems.push(fallBackId);
            urlItems.push(id);
        }
        else {
            urlItems.push(rootExtraPath);
            urlItems.push(filterExtraPath);
        }

        const url = urlItems.filter(i => i).join('/');
        return submitRequestToApi({ url });
    }

    function loadData(newdata) {
        newdata = newdata || [];
        //const data = parseDataFromServer({ newdata, grid, multiSelectLkp });
        var changedData = transformers.getAll(grid, dataView, newdata, repositoryObj);
        const data = parseDataFromServer({ newdata: (changedData || newdata), grid, multiSelectLkp });

        const visible = { never: 0, whenChanged: 1, always: 2 };
        const showChangesOnly = $('#showChangesOnlyCheckBox').is(':checked');

        if (showChangesOnly && data.length > 1) {
            let prevItem = data[0];
            const visibleColumnIndexes = {};

            for (var itemIdx = 1; itemIdx < data.length; itemIdx++) {
                let item = data[itemIdx];
                for (var colIdx = 0; colIdx < columns.length; ++colIdx) {
                    let field = columns[colIdx].field;
                    if (columns[colIdx].visibility === visible.always || (prevItem[field] !== item[field]))
                        visibleColumnIndexes[colIdx] = true;
                }
                prevItem = item;
            }

            visibleColumns = Object.keys(visibleColumnIndexes).map(k => columns[k]);
            grid.setColumns(visibleColumns);
        } else if (visibleColumns) {
            visibleColumns = null;
            grid.setColumns(columns);
        }

        dataView.beginUpdate();
        dataView.setItems(data, '__id');
        dataView.setFilterArgs(getFilterArgs());
        dataView.setFilter(gridFilter);
        dataView.endUpdate();

        grid.invalidate();
        if (modalUpdType === 1) { grid.updateRowCount(); }
        grid.render();

        // if you don't want the items that are not visible (due to being filtered out
        // or being on a different page) to stay selected, pass 'false' to the second arg
        dataView.syncGridSelection(grid, false);
        //$(container).parent().resizable();

        //if (parent) setRowAdding();
        goLiveApp.loaderHide();
    }

    function ensureLookupDatasourcesExist() {
        return getLookupsDataFromServer()
            .then(initGridLookups);
    }

    function initGridLookups(lkpData) {
        // init datasources
        const datasources = {}; const orderedDatasources = {};
        for (var i = 0; i < lkpConfigs.length; i++) {
            var lkpConfig = lkpConfigs[i];
            (lkpData[i][0] || []).forEach(item => {
                if (!datasources[item.lookup]) {
                    datasources[item.lookup] = {};
                    orderedDatasources[item.lookup] = [];
                }
                datasources[item.lookup][item[lkpConfig.key]] = item[lkpConfig.display];
                orderedDatasources[item.lookup].push({ value: item[lkpConfig.key], text: item[lkpConfig.display], order: item.lookupValueOrder });
            });
        }

        columns
            .filter(colDef => colDef.lookupName)
            .forEach(colDef => {
                colDef.dataSource = datasources[colDef.lookupName];
                const orderedDatasource = orderedDatasources[colDef.lookupName];
                if (orderedDatasource && orderedDatasource.length > 0) {
                    orderedDatasource.sort((a, b) => a.order === b.order ? (a.text.toUpperCase() > b.text.toUpperCase() ? 1 : -1) : (a.order - b.order));
                    colDef.orderedDataSource = orderedDatasource;
                }
                //lkpData.filter(i => i.lookup === colDef.lookupName).reduce((obj, item) => {
                //    obj[item.lookupValueID] = item.lookupValueCode;
                //    return obj
                //}, {});
            });

        // init multiselect lookup data
        multiSelectLkp.data = {}; multiSelectLkp.fields = []; multiSelectLkp.multiSelectFieldName = repositoryObj.multiSelectFieldName;
        if (lkpData[0][0]) {
            const lkpValues = lkpData[0][0];
            columns
                .filter(c => c.isMultiSelect && !c.lookupConfig)
                .forEach(colDef => {
                    multiSelectLkp.fields.push(colDef.field);
                    lkpValues.filter(i => i.lookup === colDef.lookupName)
                        .forEach(item => multiSelectLkp.data[item.lookupValueID] = colDef.field);
                });
        }

        if (!$.isEmptyObject(datasources) && repositoryObj.setLookupDefaultValues) repositoryObj.setLookupDefaultValues(repositoryObj.defaultValues, datasources);
    }

    function setChanges(updtype, item, column, supressHighlightChanges) {
        const itemId = item.__id;

        if (updtype === -1 && (changes[itemId] || {}).updtype === 1) {
            delete changes[itemId];
        }
        else if (updtype !== 0 || !changes.hasOwnProperty(itemId)) {
            changes[itemId] = { updtype: updtype, itemKey: item[keyField], columns: [] };
        }

        if (updtype > -1) {
            if (!changes[itemId].columns[column]) changes[itemId].columns.push(column);
        }

        if (!supressHighlightChanges) highlightChanges(false);
        toolbar.enableSaving(true);
    }

    function highlightChanges(force) {
        const hash = $.isEmptyObject(changes) ? {} :
            Object.keys(changes).reduce((result, k) => {
                result[dataView.getRowById(k)] = changes[k].columns.reduce((columnsResult, changedColumn) => {
                    columnsResult[changedColumn] = options.changedCellCssClass;
                    return columnsResult;
                }, {});
                return result;
            }, {});

        if (!$.isEmptyObject(hash) || force)
            grid.setCellCssStyles(options.changedCellCssClass, hash);
    }

    function highlightInvalidData(force) {
        const hash = $.isEmptyObject(invalidItems) ? {} :
            Object.keys(invalidItems).reduce((result, itemId) => {
                result[dataView.getRowById(itemId)] = invalidItems[itemId];
                return result;
            }, {});

        if (!$.isEmptyObject(hash) || force)
            grid.setCellCssStyles(options.invalidCellCssClass, hash);
    }

    function setupOnEditorCommitHandler() {
        const editController = grid.getEditController();
        const baseCommitCurrentEdit = editController.commitCurrentEdit;

        editController.commitCurrentEdit = function () {
            const result = baseCommitCurrentEdit();

            if (result) {
                // bulk-validate current committed cell 
                // and if it validates remove it from invalidItems object
                const activeCell = grid.getActiveCell();
                if (activeCell) {
                    const item = grid.getDataItem(activeCell.row);
                    if (item) {
                        const column = grid.getColumns()[activeCell.cell];
                        const invalidItem = invalidItems[item.__id];
                        if (invalidItem && invalidItem[column.field]) {
                            const validationResult = column.validator(item[column.field]);
                            if (validationResult.valid) {
                                delete invalidItem[column.field];

                                const activeCellNode = grid.getActiveCellNode();
                                $(activeCellNode).removeClass("bulk-invalid");
                            }
                        }
                    }
                }
            }
            return result;
        };
    }

    const keyCode = Slick.keyCode;
    const navigationKeys = [
        keyCode.HOME, keyCode.END,
        keyCode.PAGE_UP, keyCode.PAGE_DOWN,
        keyCode.UP, keyCode.DOWN,
        keyCode.LEFT, keyCode.RIGHT,
        keyCode.TAB
    ];
    var suppressCellEdit = false;

    function setupGridHandlers() {
        grid.onKeyDown.subscribe(handleGridOnKeyDown);
        grid.onClick.subscribe(handleGridOnClick);
        grid.onDblClick.subscribe(handleGridOnDblClick);
        grid.onBeforeEditCell.subscribe(handleGridOnBeforeEditCell);
        grid.onCellChange.subscribe(handleGridOnCellChange);
        grid.onAddNewRow.subscribe(handleGridOnAddNewRow);
        grid.onBeforeCellEditorDestroy.subscribe(handleGridOnBeforeCellEditorDestroy);
        grid.onActiveCellChanged.subscribe(handleGridOnActiveCellChanged);
        grid.onSort.subscribe(handleGridOnSort);
        grid.onColumnsReordered.subscribe(handleColumnsReordered);
        grid.onHeaderRowCellRendered.subscribe(handleGridOnHeaderRowCellRendered);
        if (dataLoader) grid.onViewportChanged.subscribe(handleGridOnViewportChanged);
        $(grid.getHeaderRow()).on("change keyup", ":input", handleGridHeaderRowChange);
        if (goLiveApp.IsBrowserIEOrEdge()) $(grid.getHeaderRow()).on("mouseup", ":input", handleGridHeaderCellClearedInIE);
    }

    function removeGridHandlers() {
        grid.onKeyDown.unsubscribe(handleGridOnKeyDown);
        grid.onClick.unsubscribe(handleGridOnClick);
        grid.onDblClick.unsubscribe(handleGridOnDblClick);
        grid.onBeforeEditCell.unsubscribe(handleGridOnBeforeEditCell);
        grid.onCellChange.unsubscribe(handleGridOnCellChange);
        grid.onAddNewRow.unsubscribe(handleGridOnAddNewRow);
        grid.onBeforeCellEditorDestroy.subscribe(handleGridOnBeforeCellEditorDestroy);
        grid.onActiveCellChanged.unsubscribe(handleGridOnActiveCellChanged);
        grid.onSort.unsubscribe(handleGridOnSort);
        grid.onHeaderRowCellRendered.unsubscribe(handleGridOnHeaderRowCellRendered);
        if (dataLoader) grid.onViewportChanged.unsubscribe(handleGridOnViewportChanged);
        $(grid.getHeaderRow()).off("change keyup", ":input");
        if (goLiveApp.IsBrowserIEOrEdge()) $(grid.getHeaderRow()).off("mouseup", ":input", handleGridHeaderCellClearedInIE);
    }

    function handleGridOnKeyDown(e, args) {
        suppressCellEdit = false;
        var currentItem;
        var newSelectionRange;
        const cellEditor = grid.getCellEditor();

        if (e.which === keyCode.F2) { //activate editor at F2
            e.which = keyCode.ENTER;
            /*} else if (e.which === keyCode.ESCAPE && !cellEditor) {
                // DISCARD CHANGES
                currentItem = dataView.getItem(args.row);
                if (isCurrentRowNew) {
                    if (args.row > 0) grid.navigateUp();
                    dataView.deleteItem(currentItem.__id);
                    isCurrentRowNew = false;
                    currentRowIdx = null;
                } else if (isCurrentRowDirty) {
                    $.extend(currentItem, originalItem);
                    grid.invalidateRows([args.row]);
                    grid.render();
                    isCurrentRowDirty = false;
                }*/
        } else if (cellEditor && (e.which === keyCode.HOME || e.which === keyCode.END)) {
            if (!cellEditor.keyCaptureList)
                cellEditor.keyCaptureList = [Slick.keyCode.HOME, Slick.keyCode.END, Slick.keyCode.ENTER];
            return;
        } else if ((newSelectionRange = checkForNewSelection(e, args))) {
            //select all cells
            //var rows = [];
            //for (var i = 0; i < dataView.getLength(); i++) {
            //    rows.push(i);
            //}
            //grid.setSelectedRows(rows);

            grid.setActiveCell(newSelectionRange.fromRow, newSelectionRange.fromCell, false, false, true);
            grid.getSelectionModel().setSelectedRanges([newSelectionRange]);
            e.preventDefault();
        } else if (isNavigationKey(e, args)) {
            suppressCellEdit = true;
        } else if (e.ctrlKey && e.shiftKey && e.which === keyCode.F) {
            applyFastFilter();
            e.preventDefault();
            return false;
        } else if (!e.ctrlKey && !cellEditor && grid.getOptions().editable) {
            var inp = e.key.length === 1 ? e.key : '';// String.fromCharCode(event.keyCode);
            if (inp && /^[a-z0-9!"#$%&'()*+,.\/:;<=>?@\[\] ^_`{|}~-]*$/i.test(inp)) {
                grid.editActiveCell();
            }
        }
        //else if (willActiveRowChange(e, args)
        //    && Slick.GlobalEditorLock.commitCurrentEdit()
        //    && (isCurrentRowDirty || isCurrentRowNew)) {
        //    if (!validateAndSave(args.row))
        //        e.stopImmediatePropagation();
        //}
    }

    function isNavigationKey(e, args) {
        return navigationKeys.indexOf(e.which) > -1;
    }

    function checkForNewSelection(e, args) {
        var fromRow = null, fromCell, toRow, toCell;

        if (e.ctrlKey && e.which === keyCode.A) {
            fromRow = 0;
            fromCell = 1;
            toRow = grid.getDataLength() - 1;
            toCell = grid.getColumns().length - 1;
        } else if ((e.ctrlKey || e.shiftKey) && e.which === keyCode.SPACE) {
            const currentSelectedRange = grid.getSelectionModel().getSelectedRanges()[0];
            if (currentSelectedRange) {
                fromRow = e.ctrlKey ? 0 : currentSelectedRange.fromRow;
                fromCell = e.shiftKey ? 1 : currentSelectedRange.fromCell;
                toRow = e.ctrlKey ? grid.getDataLength() - 1 : currentSelectedRange.toRow;
                toCell = e.shiftKey ? grid.getColumns().length - 1 : currentSelectedRange.toCell;
            } else if (e.shiftKey && args.cell === 0) {
                fromRow = args.row;
                fromCell = 1;
                toRow = args.row;
                toCell = grid.getColumns().length - 1;
            }
        }

        return fromRow !== null ? new Slick.Range(fromRow, fromCell, toRow, toCell) : null;
    }

    function applyFastFilter() {
        if (filterPlugin) {
            const currentSelectedRange = grid.getSelectionModel().getSelectedRanges()[0];
            const selectedData = []; const selectedFields = [];
            for (var row = currentSelectedRange.fromRow; row < currentSelectedRange.toRow + 1; row++) {
                selectedData.push(grid.getDataItem(row));
            }

            let includeMultiSelectData = false;
            for (var cell = currentSelectedRange.fromCell; cell < currentSelectedRange.toCell + 1; cell++) {
                var column = grid.getColumns()[cell];

                if (column.isMultiSelect /*&& !column.isCustomMultiSelect*/)
                    includeMultiSelectData = true;
                else
                    selectedFields.push(column.field);

                filterPlugin.setFilterValues(selectedData, column);
            }
            ensureColumnsData(selectedFields, includeMultiSelectData).done(refreshDataView);
            //grid.resetActiveCell();
        }
    }

    function handleGridOnClick(e, args) {
        //suppressCellEdit = !!(args.row === args.grid.getDataLength());
    }

    function handleGridOnDblClick(e, args) {
        suppressCellEdit = false;
    }

    function handleGridOnBeforeEditCell(e, args) {
        if (repositoryObj.onBeforeEditCell) return repositoryObj.onBeforeEditCell(e, args);
        //if (suppressCellEdit) {
        //    suppressCellEdit = false; //reset suppressCellEdit
        //    return false;
        //}
    }

    function handleGridOnCellChange(e, args) {
        const column = args.grid.getColumns()[args.cell];
        setChanges(0, args.item, column.id);
        if (repositoryObj.onFieldChange) {
            args.column = column; repositoryObj.onFieldChange(e, args);
            if (args.changedFields)
                args.changedFields.forEach((field) => { setChanges(0, args.item, field); });
        }
        dataView.updateItem(args.item.__id, args.item);
        //isCurrentRowDirty = true;
    }

    function handleGridOnAddNewRow(e, args) {
        const newId = newLocalId++;
        const item = { __id: newId, num: newId };
        $.extend(item, defaultValuesItem, args.item);

        setChanges(1, item, args.column.id, true);
        dataView.addItem(item);
        highlightChanges(false);
        //isCurrentRowNew = true;
    }

    function handleGridOnBeforeCellEditorDestroy(e, args) {
        grid.focus();
    }

    function handleGridOnActiveCellChanged(e, args) {
        //console.log('cell_changed');
        if (!currentRowIdx || currentRowIdx !== args.row) {
            //originalItem = $.extend({}, dataView.getItem(args.row));
            currentRowIdx = args.row;
        }
        //checkFocus();
    }

    function checkFocus() {
        if (!grid.getCellEditor()) {
            // if this click resulted in some cell child node getting focus,
            // don't steal it back - keyboard events will still bubble up
            // IE9+ seems to default DIVs to tabIndex=0 instead of -1, so check for cell clicks directly.
            var shouldFocus;
            if (goLiveApp.IsBrowserIEOrEdge() && document.activeElement === null) {
                shouldFocus = true;
            } else {
                const $activeElement = $(document.activeElement);
                shouldFocus = ($activeElement.is('body') || $activeElement.hasClass("slick-cell"));
            }
            if (shouldFocus) grid.focus();
        }
    }

    function handleGridOnSort(e, args) {
        const cols = args.sortCols;

        const fields = [];
        let includeMultiSelectData = false;
        cols.forEach(sc => {
            const column = sc.sortCol;
            if (column.isMultiSelect /*&& !column.isCustomMultiSelect*/)
                includeMultiSelectData = true;
            else
                fields.push(column.field);
        });

        ensureColumnsData(fields, includeMultiSelectData)
            .done(() => {
                // using native sort with comparer
                // preferred method but can be very slow in IE with huge datasets
                dataView.sort(function (item1, item2) {
                    for (let i = 0, l = cols.length; i < l; i++) {
                        const col = cols[i];
                        const sign = col.sortAsc ? 1 : -1;
                        let value1 = getSortingVal(item1, col.sortCol),
                            value2 = getSortingVal(item2, col.sortCol);

                        if (value1 != null && value2 == null)
                            return sign;
                        else if (value1 == null && value2 != null)
                            return -sign;
                        else if (value1 !== value2)
                            return (value1 > value2 ? 1 : -1) * sign;
                    }
                    return item1.__id - item2.__id;
                });

                ensureRowsData();
                highlightChanges(false);
                highlightInvalidData(false);
            });
    }

    function handleColumnsReordered(e, args) {
        const active = grid.getActiveCell();
        if (active) {
            const range = new Slick.Range(active.row, active.cell);
            grid.getSelectionModel().setSelectedRanges([range]);
            cellRangeSelectorOptions.cellDecorator.show(range);
        }
    }

    function handleGridOnHeaderRowCellRendered(e, args) {
        if (args.column.id !== 'sel') {
            $(args.node).empty();
            $("<input type='text'>")
                .data("columnId", args.column.id)
                .val(filterColumns[args.column.id])
                .appendTo(args.node);
        }
    }

    function handleGridHeaderRowChange(e) {
        var columnId = $(this).data("columnId");
        if (columnId !== null) {
            if (e.type === 'keyup') {
                const keyCode = Slick.keyCode;
                if (e.which === keyCode.DOWN) {
                    grid.gotoCell(0, grid.getColumnIndex(columnId));
                    return false;
                } else if (e.which !== keyCode.BACKSPACE && e.which !== keyCode.DELETE) {
                    const inp = e.key.length === 1 ? e.key : ''; // String.fromCharCode(event.keyCode);
                    const isInputKey = inp && /^[a-z0-9!"#$%&'()*+,.\/:;<=>?@\[\] ^_`{|}~-]*$/i.test(inp);
                    if (!isInputKey) return false;
                }
            }

            const filterValue = $.trim($(this).val());

            if (filterValue === filterColumns[columnId]) return false;

            if (headerRowFilterTimeoutId !== null) clearTimeout(headerRowFilterTimeoutId);
            headerRowFilterTimeoutId = setTimeout(function () { applyHeaderRowFilter(columnId, filterValue); }, 300);
        }
    }

    function applyHeaderRowFilter(columnId, filterValue) {
        grid.filterTimeStamp = Date.now();
        //console.log('applyHeaderRowFilter', grid.filterTimeStamp);

        const column = grid.getColumns()[grid.getColumnIndex(columnId)];
        const fields = []; let includeMultiSelectData = false;
        if (column.isMultiSelect /*&& !column.isCustomMultiSelect*/)
            includeMultiSelectData = true;
        else
            fields.push(column.field);

        ensureColumnsData(fields, includeMultiSelectData)
            .done(() => {
                if (filterValue)
                    filterColumns[columnId] = filterValue;
                else if (filterColumns[columnId])
                    delete filterColumns[columnId];

                refreshDataView();
            });
    }

    function handleGridHeaderCellClearedInIE(e) {
        var $this = $(this);

        if ($this.val() == "") return;

        // When this event is fired after clicking on the clear button
        // the value is not cleared yet. We have to wait for it.
        var columnId = $(this).data("columnId");
        if (columnId) {
            setTimeout(function () {
                if ($this.val() == "" && filterColumns[columnId]) {
                    delete filterColumns[columnId];
                    refreshDataView();
                }
            }, 50);
        }
    }

    function handleGridOnViewportChanged(e, args) {
        if (dataLoaderTimeoutId !== null) clearTimeout(dataLoaderTimeoutId);
        dataLoaderTimeoutId = setTimeout(ensureRowsData, 100);
    }


    function setupDocumentHandlers() {
        $(document).on('keydown', handleDocumentOnKeyDown);
    }

    function removeDocumentHandlers() {
        $(document).off('keydown', handleDocumentOnKeyDown);
    }

    function handleDocumentOnKeyDown(e) {
        // undo shortcut
        if (e.which == 90 && (e.ctrlKey || e.metaKey)) {    // CTRL + (shift) + Z
            if (e.shiftKey) {
                undoRedoBuffer.redo();
            } else {
                undoRedoBuffer.undo();
            }
        }
    }


    function setupCellLinkClickedHandlers() {
        $(container).on('click', 'a.cell-link', handleCellLinkClicked);
    }

    function removeCellLinkClickedHandlers() {
        $(container).off('click', 'a.cell-link', handleCellLinkClicked);
    }

    function handleCellLinkClicked(e) {
        self.onCellLinkClicked.notify({gridComponent: self}, e, self);
    }


    function setupDataViewHandlers() {
        // wire up model events to drive the grid
        // !! both dataView.onRowCountChanged and dataView.onRowsChanged MUST be wired to correctly update the grid
        // see Issue#91
        dataView.onRowCountChanged.subscribe(handleDataViewOnRowCountChanged);
        dataView.onRowsChanged.subscribe(handleDataViewOnRowsChanged);
        dataView.onPagingInfoChanged.subscribe(handleDataViewOnPagingInfoChanged);
    }

    function removeDataViewHandlers() {
        dataView.onRowCountChanged.unsubscribe(handleDataViewOnRowCountChanged);
        dataView.onRowsChanged.unsubscribe(handleDataViewOnRowsChanged);
        dataView.onPagingInfoChanged.unsubscribe(handleDataViewOnPagingInfoChanged);
    }

    function handleDataViewOnRowCountChanged(e, args) {
        grid.updateRowCount();
        grid.render();
    }

    function handleDataViewOnRowsChanged(e, args) {
        grid.invalidateRows(args.rows);
        grid.render();
    }

    function handleDataViewOnPagingInfoChanged(e, pagingInfo) {
        grid.updatePagingStatusFromView(pagingInfo);
    }

    function logError(xhr, status, errorThrown) {
        //alert("Error!\r\n"+errorThrown);
        console.log("Error: " + errorThrown);
        console.log("Status: " + status);
        console.dir(xhr);
    }

    function destroy() {
        //remove tooltip fix for IE
        if (goLiveApp.Browser === 'IE') $('body').css('overflow', "");

        $('.slick-header-column[data-toggle="tooltip"]').tooltip('dispose');

        autoResizer.destroy();
        errors.destroy();
        if (modalEditErrors) modalEditErrors.destroy();
        favorites.destroy();
        toolbar.destroy();
        footer.destroy();
        importHandler.destroy();
        exportHandler.destroy();

        removeCellLinkClickedHandlers();
        removeDocumentHandlers();
        removeDataViewHandlers();
        removeGridHandlers();
        grid.destroy();
    }

    function getCurrentItem() {
        const activeCell = grid.getActiveCell();
        return activeCell ? grid.getDataItem(activeCell.row) : null;
    }

    //history
    function showHistory(newTab) {
        const currentItem = getCurrentItem();
        if (!currentItem) {
            alert('No active row to show history!');
            return;
        }
        const historyUrl = `hist/${model}/${currentItem[keyField]}`;
        if (newTab)
            window.open(historyUrl, '_blank');
        else
            routerHistory.push(historyUrl);
    }

    //deletion
    function removeCurrentItem() {
        const currentItem = getCurrentItem();
        if (!currentItem) {
            alert('No active row to remove!');
            return;
        }
        //currentItem = dataView.getItem(args.row);
        if (currentItem.__id) {
            onDeletionConfirmed(1, function () {
                //saveChangesToServer(currentItem, -1);
                setChanges(-1, currentItem);
                //dataView.deleteItem(currentItem.__id);//RowID is the actual ID of the row and not the row number
                currentItem.__isDel = true;
                dataView.refresh();
            });
        }
    }

    //deletion
    function removeSelectedItems() {
        const selectedItems = grid.getSelectedRows().map(r => grid.getDataItem(r));

        if (selectedItems.length === 0) {
            alert('No selected row to remove!');
            return;
        }

        onDeletionConfirmed(selectedItems.length, function () {
            for (var i = 0; i < selectedItems.length; ++i) {
                const item = selectedItems[i];
                setChanges(-1, item);
                item.__isDel = true;
            }
            refreshDataView();
        });
    }

    function onDeletionConfirmed(itemsCount, callback) {
        const entitiesText = $('.h-title').text();
        DuPont.App.Dialog.AskUser(
            entitiesText + ' deletion',
            '<p>You are about to delete (' + itemsCount + ') ' + entitiesText + '.' + //', this procedure is irreversible.</p>' +
            '<p>Do you want to proceed ?</p >',
            '<button type="button" class="btn btn-danger btn-yes" >Delete</button>',
            callback);
    }


    //undeletion
    function undeleteSelectedItems() {
        const selectedItems = grid.getSelectedRows().map(r => grid.getDataItem(r)).filter(r=> r.isDeleted);

        if (selectedItems.length === 0) {
            alert('No selected row to restore from deleted!');
            return;
        }

        onUndeletionConfirmed(selectedItems.length, function () {
            for (var i = 0; i < selectedItems.length; ++i) {
                const item = selectedItems[i];
                item.isDeleted = false;
                setChanges(0, item, 'isDeleted');
                dataView.updateItem(item.__id, item);
            }
            refreshDataView();
        });
    }

    function onUndeletionConfirmed(itemsCount, callback) {
        const entitiesText = $('.h-title').text();
        DuPont.App.Dialog.AskUser(
            entitiesText + ' undeletion',
            '<p>You are about to restore from deleted (' + itemsCount + ') ' + entitiesText + '.' + 
            '<p>Do you want to proceed ?</p >',
            '<button type="button" class="btn btn-success btn-yes" >OK</button>',
            callback);
    }

    function alert(msg, callback) {
        const entitiesText = $('.h-title').text();
        DuPont.App.Dialog.ShowMessage(entitiesText, msg, callback);
    }

    //modal editing
    function editCurrentItemWithModal() {
        const item = getCurrentItem();
        if (!item) {
            alert('No active row to edit!');
            return;
        }
        getDataFromServer(item[keyField], repositoryObj.modalEditIdExtraPath, item[parentKeyField])
            // Code to run if the request succeeds (is done);
            // The response is passed to the function
            .done(function (newdata) {
                // $( "<h1>" ).text( json.title ).appendTo( "body" );
                // $( "<div class=\"content\">").html( json.html ).appendTo( "body" );
                //console.log(newdata);
                modalEditItem = parseDataFromServer({ newdata, isModalEdit: true, multiSelectLkp });
                //console.log(data);
                modalUpdType = 0;
                showModalForm(modalEditItem);
            });
    }

    function showModalForm(item) {
        getLookupsDataFromServer()
            .then(initLookups)
            //.done(initGenericLookups)
            .done(() => { if (repositoryObj.modalInit) repositoryObj.modalInit({ repositoryObj, item, $modal }); })
            //.done(() => setupPermissionSelect(item))
            .done(() => endShowModalForm(item));
    }

    function getLookupsDataFromServer() {
        //return submitRequestToApi({ url: SERVER_PATH + 'lookupvalues'});

        initLkpConfigObj();
        const deferreds = lkpConfigs.map(lc => submitRequestToApi(lc.req));
        return $.when.all(deferreds);
        //return $.when.apply($, deferreds);
    }

    function loadDependencies() {
        if (repositoryObj.dependencies) {
            const { currentSpin, crossSpinDataHidden } = DuPont.App.Config;
            const spin = crossSpinDataHidden ? currentSpin : 'all';
            repositoryObj.dependencies
                .forEach(dep => {
                    submitRequestToApi({ url: dep.url.replace('{spin}', spin) })
                        .done(depData => {
                            const data = {}; data[dep.name] = depData;
                            transformers.getAll(grid, dataView, data, repositoryObj);
                        });
                });
        }
    }

    function initLkpConfigObj() {
        const lookups = JSON.stringify(columns.filter(c => c.lookupName && !c.lookupConfig).map(c => c.lookupName));
        lkpConfigs = [$.extend(true, {}, defLkpConfig, { req: { data: lookups } })];

        if (repositoryObj.lookupConfigs) {
            for (var i = 0; i < repositoryObj.lookupConfigs.length; i++) {
                var lc = repositoryObj.lookupConfigs[i];
                lkpConfigs.push(//{ url: SERVER_PATH + lkpConfig.path, type: lkpConfig.type }
                    $.extend(true, {}, defLkpConfig, lc, { req: { url: SERVER_PATH + lc.path } })
                );
            }
        }
    }

    function initLookups(lkpData) {
        // init datasources
        var datasources = {};
        for (var i = 0; i < lkpConfigs.length; i++) {
            var lkpConfig = lkpConfigs[i];
            (lkpData[i][0] || []).forEach(item => {
                if (!datasources[item.lookup]) datasources[item.lookup] = [];
                datasources[item.lookup].push({ lookupValueID: item[lkpConfig.key], lookupValueCode: item[lkpConfig.display], lookupValueOrder: item.lookupValueOrder });
            });
        }

        $('#' + modalId + ' select[data-lookup]').each(function () {
            const lkpName = $(this).attr('data-lookup');
            populateSelect(this, datasources[lkpName], true);
        });

        $('#' + modalId + ' select.select2-multiple[data-lookup]').each(function () {
            const lkpName = $(this).attr('data-lookup');
            $(this).select2MultiCheckboxes({
                templateSelection: function (selected, total) {
                    //console.log(selected);
                    return Array.isArray(selected) ? getText(datasources[lkpName], selected.filter(i => i > "").map(i => parseInt(i))) : ''; // "Selected " + selected.length + " of " + total;
                },
                placeholder: '',//'-',
                allowClear: true
            });
        });

        function getText(datasource, selected) {
            const result = datasource
                ? datasource
                    .filter(item => selected.indexOf(item.lookupValueID) > -1)
                    .map(item => item.lookupValueCode)
                    .sort()
                    .join()
                : null;
            return result;
        }

        $('#' + modalId).off('focus', '.select2.select2-container', onSelect2Focused);
        $('#' + modalId).on('focus', '.select2.select2-container', onSelect2Focused);

        function onSelect2Focused(e) {
            $(this).closest('.form-control').focus();
        }
    }

    /*
    function initGenericLookups(lkpData) {
        $('#' + modalId + ' select[data-generic-lookup]').each(function () {
            const lkpName = $(this).attr('data-generic-lookup');
            populateSelect(this, repositoryObj[lkpName].data, true);
        });
    }
    */

    function populateSelect(select, dataSource, addBlank) {
        $(select).empty();
        if (addBlank) { select.appendChild(new Option('', '')); }
        $.each(dataSource, function (idx, item) {
            select.appendChild(new Option(item.lookupValueCode, item.lookupValueID));
        });
    }

    function endShowModalForm(item) {
        $modal.find('.was-validated .form-control:invalid').closest('.form-group').removeClass('has-error');
        const $form = $modal.find('form');
        $form.removeClass('was-validated');
        $form[0].reset();

        if (modalUpdType === 0) {
            // fill modal form

            for (var key in item) {
                var $elem = $('#' + key);
                if ($elem.is(':checkbox')) {
                    $elem[0].checked = item[key];
                } else if ($elem.is('input[type^="datetime"]')) {
                    const value = item[key];
                    let dv;
                    $elem[0].value = //.val(
                        (value && (dv = new Date(value)).getFullYear() > 1900)
                            ? `${dv.getFullYear()}-${(dv.getMonth() + 1).toString().padStart(2, '0')}-${dv.getDate()}T${dv.getHours()}:${dv.getMinutes()}:${dv.getSeconds()}`
                            : value
                        ;//);
                } else {
                    $elem.val(item[key]);
                    if ($elem.hasClass("select2-multiple")) {
                        $elem.trigger('change');
                    }
                }
            }

            if (controllerpath.endsWith('/history')) {
                $modal.find('.form-control:not(:checkbox)').prop('readonly', true).attr('readonly', true);
                $modal.find('.form-control:checkbox,.btn-save').prop('disabled', true);
            }
        }

        if (repositoryObj.showUploadProgress) {
            $timeText.text('').hide();
            $progressText.text('');
            $progressBar.attr('aria-valuenow', 0).css('width', 0 + '%').parent().hide();
        }

        modalEditErrors.reset();
        $modal.modal('show');
    }

    function editNewItemWithModal() {
        modalEditItem = defaultValuesItem || {};
        modalUpdType = 1;
        showModalForm();
    }

    function commitModalEdit() {
        const form = $modal.find('form')[0];
        const isValid = form.checkValidity();
        form.classList.add('was-validated');
        if (!isValid) {
            $modal.find('.was-validated .form-control:invalid').closest('.form-group').addClass('has-error');
            const $firstInvalid = $modal.find('.was-validated .form-control:invalid').first();
            if ($firstInvalid.length > 0) {
                const $firstInvalidTab = $firstInvalid.closest('.tab-pane');
                //if the element is on another tab page activate it
                if ($firstInvalidTab.length > 0 && !$firstInvalidTab.hasClass('active')) {
                    $modal.off('shown.bs.tab', 'a[data-toggle="tab"]');
                    $modal.on('shown.bs.tab', 'a[data-toggle="tab"]', (e) => {
                        $modal.off('shown.bs.tab', 'a[data-toggle="tab"]');
                        $firstInvalid.focus();
                    });
                    $modal.find('.nav-tabs a[href="#' + $firstInvalidTab[0].id + '"]').tab('show');
                } else {
                    $firstInvalid.focus();
                }
            }
            return false;
        }

        var newItem = $(form).serializeArray().reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
        }, {});

        //uncheckedCheckBoxes
        $(`#${modalId}  input:checkbox:not(:checked)`)
            .get()
            .forEach(item => {
                newItem[item.name] = item.checked ? item.value : "false";
            });

        //multiSelectItems
        $(`#${modalId} select.select2-multiple`)
            .get()
            .forEach(item => {
                newItem[item.name] = $(item).val();
            });

        const changedFields = Object.keys(newItem).filter(field => {
            let oldValue = modalEditItem[field];
            let newValue = newItem[field];
            if (Array.isArray(newValue) || Array.isArray(oldValue)) {
                oldValue = (oldValue || []).sort();
                newValue = newValue || [];
                if (oldValue.length !== newValue.length) {
                    return true;
                } else {
                    return oldValue.some((v, idx) => newValue[idx] !== (v == null ? "" : v.toString()));
                }
            } else {
                return newValue !== (oldValue == null ? "" : oldValue.toString());
            }
        });
        if (modalUpdType === 0) { newItem[keyField] = modalEditItem[keyField]; }
            //$.extend(newItem, modalEditItem);

        modalEditErrors.reset();
        return saveChangesToServer(newItem, modalUpdType, true, changedFields, repositoryObj.modalEditIdExtraPath)
            .done(function (result) {
                //console.log('result', result);
                //if (modalUpdType === 1) { newItem[keyField] = result[keyField]; }
                const itemKey = modalUpdType === 1 ? result[keyField] : modalUpdType === 0 ? modalEditItem[keyField] : null;

                //if (isModalEdit) {
                $modal.modal('hide');
                refreshItem(itemKey, modalUpdType);
                //} else if (updtype === 1) {
                //    dataView.updateItem(item.__id, item);
                //}
            }).fail(function (jqXHR, textStatus, errorThrown) {
                modalEditErrors.add({ itemId: modalEditItem.__id, responseJSON: jqXHR.responseJSON });
            });
        //console.log('save changes');
    }

    function saveChangesToServer(item, updtype, isModalEdit, changedFields, idExtraPath) {
        let type;
        let modifiedItem;
        switch (updtype) {
            case 1: //insert
                type = 'POST';
                modifiedItem = transformers.insert(item);
                break;
            case 0: //update
                type = 'PATCH'; //'PUT';
                modifiedItem = transformers.update(item);
                modifiedItem = changedFields.reduce((result, column) => {
                    result[column] = item[column];
                    return result;
                }, modifiedItem || {});
                modifiedItem[keyField] = item[keyField];
                break;
            case -1: //delete
                type = 'DELETE';
                break;
            default:
                alert('UpdType ' + updtype + ' not implemented!');
                return;
        }

        item = modifiedItem || item;
        if (parseDataToServer) item = parseDataToServer({ item, multiSelectLkp, isModalEdit, updtype, changedFields });

        const data = updtype === 1
            ? item
            : updtype === 0
                ? { item, changedFields }
                : null;

        var urlItems = [DuPont.App.SERVER_PATH + controllerpath, idExtraPath];
        if (updtype < 1) urlItems.push(item[keyField]);

        let progressArgs = null;
        let elapsedTimerId;

        if (modalId && repositoryObj.showUploadProgress) {
            const start = Date.now();
            $timeText.text('00:00').show();
            elapsedTimerId = setInterval(function () {
                const time = new Date(Date.now() - start).toTimeString();
                $timeText.text(`${time.substring(3, 9)}`);
            }, 1000);

            progressArgs = {
                onUploadStart: function () {
                    $progressText.text('Uploading data...');
                    $progressBar.attr('aria-valuenow', 0).css('width', 0 + '%').parent().show();
                },
                onUploadProgress: function (percent, actionText) {
                    if (!actionText) actionText = 'Uploading data';
                    $progressText.text(`${actionText}...${percent}%`);
                    $progressBar.attr('aria-valuenow', percent).css('width', percent + '%');
                },
                onUploadEnd: function () {
                    $progressText.text('Saving...');
                },
                resetProgress: function (actionText) {
                    $progressText.text(actionText);
                    $progressBar.parent().hide();
                    $progressBar.attr('aria-valuenow', 0).css('width', 0 + '%').parent().show();
                }
            };
        }

        const requestArgs = {
            url: urlItems.filter(i => i).join('/'),
            type: type,
            data: JSON.stringify(data)
        };

        return submitRequestToApi(requestArgs, progressArgs)
            .always(() => clearInterval(elapsedTimerId));
    }

    function submitRequestToApi(requestArgs, progressArgs) {
        return golLiveAppApi.submitRequest(requestArgs, progressArgs);
    }

    function refreshItem(itemKey, updType, itemId) {
        //console.log(`refreshItem: itemKey:${itemKey}, updType:${updType}, itemId:${itemId}`);
        return getDataFromServer(itemKey)
            // Code to run if the request succeeds (is done);
            // The response is passed to the function
            .done(function (newdata) {
                // $( "<h1>" ).text( json.title ).appendTo( "body" );
                // $( "<div class=\"content\">").html( json.html ).appendTo( "body" );
                //console.log(newdata);
                var newId = null;
                if (itemId) {
                    $.extend(newdata, { num: itemId, __id: itemId });
                } else if (updType === 1) {
                    newId = newLocalId++;
                    $.extend(newdata, { num: newId, __id: newId });

                    if (isVirtual) dataLoader.addKey(itemKey, newId);
                }

                const newItem = parseDataFromServer(
                    {
                        newdata,
                        isModalEdit: false,
                        multiSelectLkp,
                        newId,
                        isItemRefresh: true
                    });
                //console.log(data);
                if (updType === 1) {
                    dataView.addItem(newItem);
                } else if (updType === 0) {
                    var refreshedItem;
                    var rowIdx;
                    if (itemId) {
                        refreshedItem = dataView.getItemById(itemId);
                        rowIdx = dataView.getRowById(itemId);
                    }
                    else {
                        refreshedItem = dataView.getItem(currentRowIdx);
                        rowIdx = currentRowIdx;
                    }

                    //reset multiselect items
                    multiSelectLkp.fields.forEach(f => refreshedItem[f] = []);

                    $.extend(refreshedItem, newItem);
                    grid.invalidateRows([rowIdx]);
                    grid.render();

                    //if (!itemId) isCurrentRowDirty = false;
                }
            });
    }

    function updateGrid(newdata) {
        if (newdata) {
            const data = parseDataFromServer({ newdata, grid, multiSelectLkp, isVirtual });
            const localIdByKey = dataLoader.getLocalIdByKeyMapping();
            dataView.beginUpdate();
            for (let i = 0; i < data.length; i++) {
                const newItem = data[i];
                newItem.__id = newItem.num = localIdByKey[newItem[keyField]];

                const change = changes[newItem.__id];
                if (change) {
                    const oldItem = dataView.getItemById(newItem.__id);
                    for (let colIdx = 0, changedCols = change.columns.length; colIdx < changedCols; ++colIdx) {
                        const changedCol = change.columns[colIdx];
                        newItem[changedCol] = oldItem[changedCol];
                    }
                }

                dataView.updateItem(newItem.__id, newItem);
            }
            dataView.endUpdate();
            //grid.invalidate();
            //grid.render();
        }
        dataLoader.hideProgress();
    }

    function updateGridColumns(newdata, fields, hasMultiSelectData) {
        if (newdata) {
            const data = hasMultiSelectData ? parseDataFromServer({ newdata, grid, multiSelectLkp, isVirtual }) : newdata;
            const localIdByKey = dataLoader.getLocalIdByKeyMapping();
            dataView.beginUpdate();
            for (var i = 0; i < newdata.length; i++) {
                var newItem = data[i];
                newItem.__id = newItem.num = localIdByKey[newItem[keyField]];
                const oldItem = dataView.getItemById(newItem.__id);

                const change = changes[newItem.__id];
                if (change) {
                    for (let colIdx = 0, changedCols = change.columns.length; colIdx < changedCols; ++colIdx) {
                        const changedCol = change.columns[colIdx];
                        if (newItem.hasOwnProperty(changedCol)) delete newItem[changedCol];
                    }
                }

                if (oldItem) Object.assign(oldItem, newItem);
            }
            dataView.endUpdate();
            //grid.invalidate();
            //grid.render();
        }
        dataLoader.hideProgress();
    }

    function refresh() {
        if (dataLoading > -1) { // when grid is refreshed from outside
            return refreshCore();
        } else {
            return $.when(); //$.Deferred().resolve().promise();
        }
    }

    function refreshCore() {
        // chain asynchronously
        //return $.when.all([ensureLookupDatasourcesExist(), refreshGrid()]);

        //* chain synchronously - refreshGrid needs lookup data to be initialized
        const deferred = $.Deferred();

        ensureLookupDatasourcesExist()
            .done(() => {
                refreshGrid()
                    .done(deferred.resolve)
                    .fail(deferred.reject);
            }).fail(deferred.reject);

        return deferred.promise();
        // */
    }

    function refreshGrid() {
        let req;
        if (isVirtual) {
            loadDependencies();
            req = ensureInitialData();
        } else {
            req = getDataFromServer()
                .done(loadData);
        }

        return req.done(() => {
            goLiveApp.loaderHide();
            newLocalId = dataView.getItems().length + 1;
            changes = {};
            highlightChanges(true);

            invalidItems = {};
            highlightInvalidData(true);
        });
    }

    function ensureInitialData() {
        const filteredOrSortedColumns = [];

        //headerRowFilter
        if (!$.isEmptyObject(filterColumns)) {
            for (var columnId in filterColumns) {
                if (columnId !== undefined && filterColumns[columnId] !== "") {
                    var column = grid.getColumns()[grid.getColumnIndex(columnId)];
                    filteredOrSortedColumns.push(column);
                }
            }
        }

        //columnsFilter
        grid.getColumns()
            .filter(c => c.filterValues && c.filterValues.length > 0)
            .forEach(column => filteredOrSortedColumns.push(column));

        //sortedColumns
        grid.getSortColumns()
            .forEach(sc => {
                var column = grid.getColumns()[grid.getColumnIndex(sc.columnId)];
                filteredOrSortedColumns.push(column);
            });


        const fields = []; let includeMultiSelectData = false;
        if (filteredOrSortedColumns.length > 0) {
            filteredOrSortedColumns.forEach(column => {
                if (column.isMultiSelect /*&& !column.isCustomMultiSelect*/)
                    includeMultiSelectData = true;
                else if (!fields.includes(column.field))
                    fields.push(column.field);
            });
        }

        return dataLoader.ensureColumnsData(fields, includeMultiSelectData, true)
            .done(newdata => {
                const data = includeMultiSelectData ? parseDataFromServer({ newdata, grid, multiSelectLkp, isVirtual }) : newdata;
                displayInitialData(data);
            });
    }

    function displayInitialData(data) {
        data.forEach((item, idx) => {
            item.__id = item.num = idx + 1;
        });
        dataView.beginUpdate();
        dataView.setItems(data, '__id');
        dataView.setFilterArgs(getFilterArgs());
        dataView.setFilter(gridFilter);
        dataView.endUpdate();
        doSort();
        //grid.invalidate();
        //grid.render();
        goLiveApp.loaderHide();
        grid.onViewportChanged.notify();
    }

    function doSort() {
        const sortColumns = grid.getSortColumns();
        if (sortColumns.length > 0) {
            if (!options.multiColumnSort) {
                grid.onSort.notify({
                    multiColumnSort: false,
                    sortCol: (sortColumns.length > 0 ? sortColumns[0] : null),
                    sortAsc: (sortColumns.length > 0 ? sortColumns[0].sortAsc : true)
                }, null, grid);
            } else {
                grid.onSort.notify({
                    multiColumnSort: true,
                    sortCols: sortColumns.map(function (col) {
                        return { sortCol: grid.getColumns()[grid.getColumnIndex(col.columnId)], sortAsc: col.sortAsc };
                    })
                }, null, grid);
            }
        }
    }

    function tryCommitChanges($badge) {
        Slick.GlobalEditorLock.commitCurrentEdit();

        errors.reset();

        const commitChanges = function () {
            let unsavedRows = Object.keys(changes).length;

            if (unsavedRows > 0) {
                $badge.text(unsavedRows);

                dataView.beginUpdate();
                Object.keys(changes).forEach(function (itemId) {
                    itemId = parseInt(itemId);
                    const change = changes[itemId];
                    const updtype = change.updtype;
                    var changedItem;
                    if (updtype === -1) {
                        changedItem = {};
                        changedItem[keyField] = change.itemKey;
                    }
                    else
                        changedItem = dataView.getItemById(itemId);

                    //try {
                    //goLiveApp.loaderShow();

                    saveChangesToServer(changedItem, updtype, false, change.columns)
                        .done(function (result) {
                            //console.log('result', result);

                            if (updtype === -1) {
                                dataView.deleteItem(itemId);
                            } else if (result) {
                                //changedItem[keyField] = result[keyField];
                                $.extend(changedItem, result);
                                dataView.updateItem(itemId, changedItem);
                                if (isVirtual && updtype === 1) dataLoader.addKey(changedItem[keyField], itemId);
                            }

                            $badge.text(--unsavedRows);
                            onItemSavedOrDiscarded(itemId);
                            if (unsavedRows === 0) dataView.endUpdate();
                        })
                        .fail(function (jqXHR, textStatus, errorThrown) {
                            dataView.endUpdate();
                            toolbar.enableSaving(true);
                            errors.add({ itemId, responseJSON: jqXHR.responseJSON });
                        })
                        //.always(goLiveApp.loaderHide())
                        ;
                    //} catch (err) {
                    //    //goLiveApp.loaderHide();
                    //}

                });
            }
        };

        if (!$.isEmptyObject(changes)) {
            validateChanges()
                .done(commitChanges)
                .fail(() => toolbar.enableSaving(true));
        }
        else {
            toolbar.enableSaving(false);
            alert('No changes found!');
        }
    }

    function validateChanges() {
        const gridColumns = grid.getColumns();

        invalidItems = {};
        var firstInvalidRow = null, firstInvalidCell = null;

        Object.keys(changes).forEach((itemId) => {
            itemId = parseInt(itemId);
            const change = changes[itemId];
            const updtype = change.updtype;

            if (updtype > -1) {
                const changedItem = dataView.getItemById(itemId);
                change.invalidColumns = {};
                //validate changed item
                const invalidColumns = gridColumns.reduce((result, column, idx) => {
                    if (column.validator) {
                        const validationResult = column.validator(changedItem[column.field]);
                        if (!validationResult.valid) {
                            result[column.id] = options.invalidCellCssClass;
                            change.invalidColumns[column.id] = validationResult.msg;
                            if (firstInvalidCell === null) firstInvalidCell = idx;
                        }
                    }
                    return result;
                }, {});

                if (!$.isEmptyObject(invalidColumns)) {
                    invalidItems[itemId] = invalidColumns;
                    if (firstInvalidRow == null) firstInvalidRow = dataView.getRowById(itemId);
                }
            }
        });

        const changesAreValid = $.isEmptyObject(invalidItems);

        if (changesAreValid) {
            highlightInvalidData(true);
        } else {
            alert('Changes are not valid!',
                () => {
                    grid.focus();
                    grid.gotoCell(firstInvalidRow, firstInvalidCell, true);
                    highlightInvalidData(true);
                }
            );
        }

        return !changesAreValid
            ? $.Deferred().reject()
            : !repositoryObj.validateUniqueConstraints
                ? $.Deferred().resolve()
                : repositoryObj.validateUniqueConstraints({ gridComponent: self });
    }

    function discardChanges() {
        errors.reset();
        //return refreshCore().done(() => toolbar.enableSaving(false));

        //* refreshing all is faster than discarding one by one
        if (!$.isEmptyObject(changes)) {
            if (Object.keys(changes).length > 25) {
                refreshCore().done(() => toolbar.enableSaving(false));
            } else {
                Object.keys(changes).forEach(function (itemId) {
                    itemId = parseInt(itemId);
                    const change = changes[itemId];
                    const updtype = change.updtype;

                    if (updtype === 1) {
                        dataView.deleteItem(itemId);
                        onItemSavedOrDiscarded(itemId);
                    } else if (updtype === -1) {
                        const discardedItem = dataView.getItemById(itemId);
                        discardedItem.__isDel = null;
                        needsRefresh |= true;
                        //dataView.refresh();
                        onItemSavedOrDiscarded(itemId);
                    } else {
                        refreshItem(change.itemKey, Math.abs(updtype), itemId)
                            .done(() => onItemSavedOrDiscarded(itemId));
                    }
                });
            }
        }
        else
            alert('No changes found!');
        // */
    }

    function onItemSavedOrDiscarded(itemId) {
        //console.log('result', result);

        delete changes[itemId];
        highlightChanges(true);

        delete invalidItems[itemId];
        highlightInvalidData(true);

        if ($.isEmptyObject(changes)) {
            if (needsRefresh) { needsRefresh = false; dataView.refresh(); }
            //dataView.refresh();
            //grid.invalidate();
            //grid.render();
            toolbar.enableSaving(false);
        }
    }

    function getCellInvalidMsg(cell) {
        var result = '';
        if (!$.isEmptyObject(changes)) {
            const change = changes[grid.getDataItem(cell.row).__id];
            if (change && !$.isEmptyObject(change) && change.invalidColumns) {
                result = change.invalidColumns[columns[cell.cell].id];
            }
        }
        return result;
    }

    function getFilterArgs() {
        return { grid, filterColumns, changes, getVal, checkColumnFilter };
    }

    function gridFilter(item, args) {

        if (item.__isDel) { /*don't show deleted items*/
            return false;
        }
        else if (args.changes[item.__id]) { /*always show changed items*/
            return true;
        }

        const filterColumns = args.filterColumns;
        const grid = args.grid;
        const getVal = args.getVal;
        const checkColumnFilter = args.checkColumnFilter;

        for (var columnId in filterColumns) {
            if (columnId !== undefined && filterColumns[columnId] !== "") {
                var c = grid.getColumns()[grid.getColumnIndex(columnId)];
                var itemValue = getVal(item, c);  /*item[c.field]*/
                itemValue = (itemValue == null) ? '' : itemValue.toString();
                var filterValue = filterColumns[columnId];
                if (itemValue.toUpperCase().indexOf(filterValue.toUpperCase()) === -1) {
                    return false; /*didn't pass headerFilter*/
                }
            }
        }

        return !!checkColumnFilter(item, c);
    }

    function checkColumnFilter(item, c) {
        return grid.getColumns()
            .filter(c => c.filterValues && c.filterValues.length > 0)
            .every(c => {
                var value = item[c.field];
                if (value == null) value = '';
                return (c.isMultiSelect && value) ?
                    (value.findIndex(i => c.filterValues.indexOf(i) > -1) > -1) :
                    (c.filterValues.indexOf(value) > -1);
            });
    }

    function clearFilter(apply = true) {
        $(grid.getHeaderRow()).find(":input").val('');

        filterColumns = {};

        grid.getColumns()
            .filter(c => c.filterValues && c.filterValues.length > 0)
            .forEach(c => c.filterValues.length = 0);

        filterPlugin.resetFilterButtons();

        if (apply) refreshDataView();
    }

    function getFilterInfo() {
        //return !$.isEmptyObject(filterColumns) || (grid.getColumns().findIndex(c => c.filterValues && c.filterValues.length > 0) > -1);

        const filterItems = [];
        if (!$.isEmptyObject(filterColumns)) {
            for (var columnId in filterColumns) {
                if (columnId !== undefined && filterColumns[columnId] !== "") {
                    var c = grid.getColumns()[grid.getColumnIndex(columnId)];
                    var filterValue = filterColumns[columnId];
                    filterItems.push(`[${c.name}] includes('${filterValue}')`);
                }
            }
        }

        grid.getColumns()
            .filter(c => c.filterValues && c.filterValues.length > 0)
            .forEach(c => {
                const values = c.filterValues
                    .map(v => {
                        const isMultiSelectColumn = c.isMultiSelect;
                        const allowsNA = c.allowsNA; //c.formatter != null && c.formatter.toString().substr(0, 20) === "function NAFormatter";
                        const isCheckMarkColunm = c.isCheckMark;
                        var filterValue = //((ds ? ds[filterItem] : filterItem) || '').trim() || '(Empty)';
                            (
                                isMultiSelectColumn ? (c.dataSource[v] || v) :
                                    isCheckMarkColunm ? (v ? 'Checked' : 'Unchecked') :
                                        (allowsNA && (v == null || v === '')) ? 'N/A' :
                                            (c.formatter ? c.formatter(-1, -1, v, c, null) : v)
                            );
                        return `'${filterValue}'`;
                    })
                    .join(",");
                filterItems.push(`[${c.name}] in (${values})`);
            });

        return filterItems.join(' AND ');
    }

    function isFiltered() {
        return !$.isEmptyObject(filterColumns) ||
            (grid.getColumns().findIndex(c => c.filterValues && c.filterValues.length > 0) > -1);
    }

    function isSorted() {
        return grid.getSortColumns().length > 0;
    }

    function exportURL(exportExtraPath) {
        return getPrefixedURL(['/export', exportExtraPath].filter(i => i).join('/'));
    }

    function importURL() {
        return DuPont.App.SERVER_PATH + controllerpath + '/import';
    }

    function idsURL() {
        return getPrefixedURL('/ids');
    }

    function qryByKeysURL() {
        return getPrefixedURL('/byids');
    }

    function qryByFieldsURL() {
        return getPrefixedURL('/byfields');
    }

    function multiSelectDataURL() {
        return getPrefixedURL('/multiselectdata');
    }

    function getPrefixedURL(prefix) {
        return [DuPont.App.SERVER_PATH + controllerpath + prefix, rootExtraPath, filterExtraPath].filter(i => i).join('/');
    }

    function refreshDataView(favoriteApplied) {
        const filterArgs = getFilterArgs();
        //console.log('refresh:', JSON.stringify({ filterColumns: filterArgs.filterColumns, timeStamp: filterArgs.grid.filterTimeStamp}));
        dataView.setFilterArgs(filterArgs);
        dataView.refresh();

        highlightChanges(false);
        highlightInvalidData(false);

        footer.updateFilterInfo(getFilterInfo());
        if (!favoriteApplied) favorites.clearActiveFavorite();
        if (dataLoader) ensureRowsData();
    }

    function getFilteredKeys() {
        return dataView.getFilteredItems().map(i => i[repositoryObj.keyField]);
    }

    function ensureRowsData(args) {
        if (isVirtual) {
            let from, to;
            if (args) {
                from = args.from;
                to = args.to;
            } else {
                const vp = grid.getViewport();
                from = vp.top;
                to = vp.bottom;
            }

            return dataLoader.ensureRowsData(from, to)
                .done(updateGrid);
        } else {
            return $.when();
        }
    }

    function ensureColumnsData(fields, includeMultiSelectData) {
        return isVirtual ?
            dataLoader.ensureColumnsData(fields, includeMultiSelectData)
                .done(newdata => updateGridColumns(newdata, fields, includeMultiSelectData))
            :
            $.when();
    }

    function changeDataModel(newModel) {
        destroy();
        filterObject = null;
        model = newModel;
        init();
    }

    function ensureData(range) {
        if (isVirtual && !dataLoader.areRowsLoaded(range.fromRow, range.toRow)) {
            //const colsToGet = range.toCell - range.fromCell + 1;
            //const rowsToGet = range.toRow - range.fromRow;

            const columns = grid.getColumns(), selectedFields = [];
            let includeMultiSelectData = false;
            for (var cell = range.fromCell; cell < range.toCell + 1; cell++) {
                const column = columns[cell];

                if (column.isMultiSelect /*&& !column.isCustomMultiSelect*/)
                    includeMultiSelectData = true;
                else
                    selectedFields.push(column.field);
            }
            return ensureColumnsData(selectedFields, includeMultiSelectData);
            //return ensureRowsData({ from: range.fromRow, to: range.toRow });
        } else {
            return $.when();
        }
    }

    function handledToolbarItem(args) {
        if (repositoryObj.customToolbarHandler) {
            args.item = getCurrentItem();
            return repositoryObj.customToolbarHandler(args);
        }
    }

    function persistFavoriteFilter() {
        const result = {};
        const toolbarFilter = toolbar.persistFilter();
        if (!$.isEmptyObject(toolbarFilter)) result.toolbar = toolbarFilter;
        if (!$.isEmptyObject(filterColumns)) result.gridHeaderFilters = { ...filterColumns };

        const gridColumnFilters =
            grid.getColumns()
                .filter(c => c.filterValues && c.filterValues.length > 0)
                .map(c => ({ id: c.id, filterValues: [...c.filterValues] }));
        if (gridColumnFilters.length > 0) result.gridColumnFilters = gridColumnFilters;

        return result;
    }

    function applyFavoriteFilter(filterObj, forceReload) {
        if (filterObj.toolbar) {
            toolbar
                .applyFilter(filterObj.toolbar, forceReload)
                .done(() => applyGridFilter(filterObj));
        } else {
            applyGridFilter(filterObj);
        }
    }

    function applyGridFilter(filterObj) {
        //reset filters
        clearFilter(false);

        const columnsById = grid.getColumns().reduce((result, column) => {
            result[column.id] = column;
            return result;
        }, {});

        const fields = []; let includeMultiSelectData = false;

        //apply header filters
        if (filterObj.gridHeaderFilters) {
            filterColumns = { ...filterObj.gridHeaderFilters };
            Object.keys(filterColumns)
                .forEach(id => {
                    const column = columnsById[id];
                    if (column.isMultiSelect /*&& !column.isCustomMultiSelect*/)
                        includeMultiSelectData = true;
                    else
                        fields.push(column.field);
                    $(grid.getHeaderRowColumn(id)).children(':first').val(filterColumns[id]);
                });
        }

        //apply filter columns
        if (filterObj.gridColumnFilters && filterObj.gridColumnFilters.length > 0) {
            filterObj.gridColumnFilters.forEach(gcf => {
                const column = columnsById[gcf.id];
                if (column.isMultiSelect /*&& !column.isCustomMultiSelect*/)
                    includeMultiSelectData = true;
                else
                    fields.push(column.field);
                const filterValues = gcf.filterValues.map(fv => ({ [gcf.id]: fv }));
                filterPlugin.setFilterValues(filterValues, column, false);
            });
        }

        ensureColumnsData(fields, includeMultiSelectData).done(() => refreshDataView(true));
    }

    /* DELETED
    function filter(item, args) {
        var value = true;
        //if (args && args.grid) {
        var columns = grid.getColumns();

        for (var i = 0; i < columns.length; i++) {
            var col = columns[i];
            var filterValues = col.filterValues;

            if (filterValues && filterValues.length > 0) {
                value = value & _.includes(filterValues, item[col.field]);
            }
        }
        //}
        return value;
    }
    // DELETED */

    //////////////////////////////////////////////////////////////////////////////////////////////
    // Public API

    $.extend(this, {
        // Events
        onCellLinkClicked: new Slick.Event(),

        // Methods
        refresh,
        destroy,
        getCurrentItem,
        editCurrentItemWithModal,
        editNewItemWithModal,
        commitModalEdit,
        removeCurrentItem,
        removeSelectedItems,
        undeleteSelectedItems,
        commitChanges: tryCommitChanges,
        discardChanges,
        getCellInvalidMsg,
        exportURL,
        importURL,
        idsURL,
        qryByKeysURL,
        qryByFieldsURL,
        multiSelectDataURL,
        updateData: updateGrid,
        loadData,
        ensureRowsData,
        ensureColumnsData,
        ensureData,
        getDataModel: () => model,
        changeDataModel,
        getContainerNode: () => container,
        getInnerGrid: () => grid,
        clearFilter: clearFilter,
        isFiltered: isFiltered,
        isSorted: isSorted,
        getFilterInfo,
        getFilteredKeys,
        setFilterExtraPath: (value) => { filterExtraPath = value; return this; },
        showHistory,
        handledToolbarItem,
        persistFavoriteFilter,
        applyFavoriteFilter
    });

    init();
}