import React, { Component } from 'react';
import { Button, Badge } from 'reactstrap';
import { IfAuthorized, AuthorizationPolicies } from './Authorization';
import $ from 'jquery';
import DuPont from '../../jscomponents/DuPontApp';

export const Glyphicon = props => {
    return (<i className={props.glyph ? 'fa fa-'+props.glyph : ''} /*style={props.style}*/ />);
};

const DataGridToolbarTooltip = props => {
    const result = props.tooltip ? (
            <span data-toggle='tooltip' data-title={props.tooltip}>
                {props.children}
            </span>
        ) :
        props.children;
    return result;
};


export const DataGridToolbarButtonsGroup = props => props.children;
/*
{
    return (
        <FormGroup inline>
            <ButtonToolbar>
                {props.children}
            </ButtonToolbar>
        </FormGroup>
    );
};
*/

export const DataGridToolbarButton = props => {
    const glyph = props.glyph ? <Glyphicon glyph={props.glyph} style={{ color: props.glyphColor }} /> : '';
    const tooltip = props.tooltip === true ? props.text : props.tooltip;
    const captionText = DuPont.App.Config.forceCaptionOnToolbarButton ?
        (props.text || (props.caption === true ? props.text : props.caption) || tooltip) :
        (props.caption === true ? props.text : props.caption);
    const caption = captionText ? <span className={`caption ${(props.captionAlwaysVisible ? 'always-visible' : null)}`}>{captionText}</span> : null;

    return (
        <DataGridToolbarTooltip tooltip={props.modalTarget ? tooltip : null}>
            <Button
                onClick={props.onClick}
                size='sm'
                outline={props.outline}
                color={props.bsStyle}
                className={`${props.btnClass || ''} ml-1`}
                /*style={{ color: props.btnColor }}*/
                id={props.id}
                data-toggle={props.modalTarget ? 'modal' : props.tooltip ? 'tooltip' : null}
                data-target={props.modalTarget}
                title={props.modalTarget ? null : tooltip}
                data-filter={props.filter}
            >
                {glyph}
                {caption}
                {props.children}
            </Button>
        </DataGridToolbarTooltip>
    );
};

export const DataGridToolbarErrorsButton = () => {
    return (
        <button type="button" data-container="body" data-toggle="popover" title="" className="btn btn-sm btn-danger btn-error d-none mr-3" data-original-title="Errors">
            Errors
            <span className="badge badge-light" style={{ color: 'indianred' }}>0</span>
            <span className="sr-only">errors</span>
        </button>
    );
};

/* 
 <Button
            type="button"
            size='sm'
            color="danger"
            data-container="body"
            data-toggle="popover"
            data-placement="right"
            title="Errors"
            className="d-none btn-error"
        >
            Errors
            <Badge style={{ marginLeft: '4px' }}>0</Badge>
            <span className="sr-only">errors</span>
        </Button>
 */

export const DataGridToolbarSearchBox = props => {
    return (
        <div className="input-group-sm form-inline search-group mr-3">
            <input
                type="text"
                className="form-control text-search"
                aria-label="Text for searching"
                data-filter-order={props.filterOrder}
                data-filter={props.filter}
            />
            <DataGridToolbarButton
                outline
                btnClass="btn-search"
                bsStyle='primary'
                glyph="search"
                text="Search"
                tooltip
            />
        </div>
    );
};

export const DataGridToolbarSearchInput = props => {

    return (
        <div className={`input-group-sm form-inline search-group mr-1`}>
            <input
                type={props.inputType || "text"}
                className="form-control text-search"
                aria-label="Text for searching"
                data-filter-order={props.filterOrder}
                data-filter={props.filter}
                data-filter-defvalue={props.filterDefValue}
                data-toggle='tooltip'
                data-title={props.tooltip}
                style={props.inputStyle || null}
            />
        </div>
    );
};

export const DataGridToolbarButtonFilterGroup = props => {

    return (
        <div
            className="btn-filter-group"
            data-filter-order={props.filterOrder}
            data-filter={props.filter}
            data-filter-defvalue={props.filterDefValue}
        >
            {props.children}
        </div>
    );
};

export const DataGridToolbarCheckBox = props => {
    return (
        <div className={`custom-control custom-checkbox custom-control-inline ${props.controlClass || ''}`}>
            <input
                type="checkbox"
                className={`${props.inputClass || ''} custom-control-input`}
                id={props.id}
                defaultChecked={props.defaultChecked}
                data-filter-order={props.filterOrder}
                data-filter-checked={props.filterWhenChecked}
                data-filter-unchecked={props.filterWhenUnchecked}
            />
            <label className="custom-control-label" htmlFor={props.id}>{props.caption}</label>
        </div>
    );
};

/*
        <FormGroup style={{ marginRight: '15px' }}>
            <Input
                type="checkbox"
                className="custom-control-input"
                id={props.id}
                defaultChecked={props.defaultChecked}
                data-filter-order={props.filterOrder}
                data-filter-checked={props.filterWhenChecked}
                data-filter-unchecked={props.filterWhenUnchecked}
            >
                {props.children}
            </Input>
        </FormGroup>
*/ 

export const DataGridToolbarSplitButton = (props) => {
    const glyph = props.glyph ? <Glyphicon glyph={props.glyph} style={{ color: props.glyphColor }} /> : '';
    const tooltip = props.tooltip === true ? props.text : props.tooltip;
    const captionText = DuPont.App.Config.forceCaptionOnToolbarButton ?
        (props.text || (props.caption === true ? props.text : props.caption) || tooltip) :
        (props.caption === true ? props.text : props.caption);
    const caption = captionText ? <span className={`caption ${(props.captionAlwaysVisible ? 'always-visible' : null)}`}>{captionText}</span> : null;

    return (
        <div className="btn-group ml-1">
            <DataGridToolbarTooltip tooltip={props.modalTarget ? tooltip : null}>
                <Button
                    type="button"
                    color={props.bsStyle}
                    outline
                    className={`btn btn-default btn-sm ${props.btnClass || ''}`}
                    data-filter-order={props.filterOrder}
                    data-filter={props.filter}
                    data-filter-req-msg={props.filterReqMsg}
                    data-filter-req={props.filterReq}
                    data-toggle={props.modalTarget ? 'modal' : props.tooltip ? 'tooltip' : null}
                    data-target={props.modalTarget}
                    title={props.modalTarget ? null : tooltip}
                >
                    {glyph}
                    {caption}
                </Button>
            </DataGridToolbarTooltip>
            <Button type="button" color={props.bsStyle} outline className="btn btn-default btn-sm pl-1 dropdown-toggle" data-toggle="dropdown">
                <span className="caret" />
                <span className="sr-only">Toggle Dropdown</span>
            </Button>
            <div className="dropdown-menu dropdown-menu-right" role="menu">
                {props.children}
            </div>
        </div>
    );
};

export const DataGridToolbarFavoritesSplitButton = (props) => {
    const tooltip = (props.tooltip === true ? props.text : props.tooltip) || 'Favorites';
    const captionText = DuPont.App.Config.forceCaptionOnToolbarButton ?
        (props.text || (props.caption === true ? props.text : props.caption) || tooltip) :
        (props.caption === true ? props.text : props.caption);
    const caption = captionText ? <span className='caption'>{captionText}</span> : null;
    const popoverContentHTML = 
        `<form id="favoritesForm" class="needs-validation form-favorites" noValidate onsubmit="return false;">
            <input type="text" class="form-control form-control-sm mr-sm-2 pr-1" id="favoriteName" name="favoriteName" required maxLength="100">
            <div class="invalid-feedback help-block mb-1">
                Please provide a valid <b>Name</b>.
            </div>
            <div class="form-row d-flex justify-content-between mt-1 pl-2 pr-2">
                <button type="button" class="btn btn-primary btn-sm btn-favorites-save my-1 ">Save</button>
                <button type="button" class="btn btn-danger btn-sm btn-favorites-remove my-1">Remove</button>
            </div>
        </form>`;

    return (
        <div className="btn-group ml-1">
            <DataGridToolbarTooltip tooltip={tooltip || null}>
                <Button
                    type="button"
                    id="favoritesEditBtn"
                    color="primary"
                    outline
                    className={`btn btn-sm btn-favorites-edit ${props.btnClass || ''}`}
                    data-container="body"
                    data-toggle="popover"
                    data-placement="bottom"
                    data-content={`${popoverContentHTML}`}
                    title="Add favorite"
                >
                    <Glyphicon glyph='star-o' />
                    {caption}
                </Button>
            </DataGridToolbarTooltip>
            <Button type="button" id="favoritesBtn" color="primary" outline className="btn btn-sm pl-1 dropdown-toggle btn-favorites" data-toggle="dropdown">
                <span className="caret" />
                <span className="sr-only">Toggle Dropdown</span>
            </Button>
            <div id='favoritesList' className="dropdown-menu dropdown-menu-right" role="menu">
                {/*props.children*/}
                {/*<DataGridToolbarDropdownItem btnClass='btn-favorites-manage' caption="Manage Favorites" />
                <li className="dropdown-divider" />
                <DataGridToolbarDropdownItem caption="DAS-NonSaaS-InProgress" />
                <DataGridToolbarDropdownItem caption="DAS-NonSaaS-NotInProgress" />
                <DataGridToolbarDropdownItem caption="DAS-SaaS-InProgress" />
                <DataGridToolbarDropdownItem caption="DAS-SaaS-NotInProgress" />*/}
            </div>
        </div>
    );
};

/*
export const DataGridToolbarDropdownButton = (props) => {
    const glyph = props.glyph ? <Glyphicon glyph={props.glyph} style={{ color: props.glyphColor }} /> : '';
    return (
        <div className={`btn-group ml-1 ${props.controlClass || ''}`}>
            <Button
                type="button"
                color='info'
                className="btn btn-default btn-sm dropdown-toggle"
                data-toggle="dropdown"
                data-filter-order={props.filterOrder}
                data-filter={props.filter}
                data-filter-req-msg={props.filterReqMsg}
                data-filter-req={props.filterReq}
            >
                {glyph}
                <span className='caption'>{props.caption}</span>{' '}
                <span className="caret" />
            </Button>
            <div className="dropdown-menu dropdown-menu-right" role="menu">
                {props.children}
            </div>
        </div>
    );
};
*/

export const DataGridToolbarDropdownItem = (props) => {
    return (
        <button
            type="button"
            className={`dropdown-item small ${props.btnClass || ''}`}
            data-dyn-model={props.dynModel}
            data-filter={props.filter}
            data-toggle={props.modalTarget ? 'modal' : ''}
            data-target={props.modalTarget}
            data-export-url={props.exportUrl}
            data-export-extrapath={props.exportExtrapath}
            data-import-url={props.importUrl}
            data-filter-req={props.filterReq}
            href={props.href || '#'}
        >
            {props.caption || props.children}
        </button>
    );
};

export const DataGridToolbarButtonSave = (props) => {
    return (
        <DataGridToolbarButton outline onClick={props.onClick} btnClass="btn-save" glyph="check" bsStyle='success' btnColor='green' caption="Save" captionAlwaysVisible>
            <Badge style={{ backgroundColor: 'green', paddingBottom: '.4em' }}>0</Badge>
        </DataGridToolbarButton>
    );
};

export const DataGridToolbarButtonDiscard = (props) => {
    return (
        <DataGridToolbarButton outline onClick={props.onClick} btnClass="btn-discard mr-3" glyph="times" bsStyle='danger' btnColor='red' caption="Discard" captionAlwaysVisible/>
    );
};

//export const DataGridToolbarButtonRefresh = () => null;
//*
export const DataGridToolbarButtonRefresh = (props) => {
    return (
        <DataGridToolbarButton
            outline
            onClick={props.onClick}
            bsStyle='success'
            btnClass="btn-refresh"
            glyph="refresh"
            glyphColor='green'
            text="Refresh"
            tooltip
        />
    );
};
//*/

export const DataGridToolbarButtonImport = (props) => {
    return (
        <DataGridToolbarButton
            outline
            onClick={props.onClick}
            id="btnUpload"
            bsStyle='secondary'
            btnClass="btn-import"
            glyph="upload"
            glyphColor='green'
            modalTarget="#uploadModal"
            text="Import"
            tooltip
        />
    );
};

export const DataGridToolbarButtonExport = (props) => {
    return (
        <DataGridToolbarButton
            outline
            onClick={props.onClick}
            bsStyle='info'
            btnClass="btn-export"
            glyph="download"
            glyphColor='#4189C7'
            modalTarget="#downloadModal"
            text="Export"
            tooltip
        />
    );
};

export const DataGridToolbarButtonHistory = (props) => {
    console.log(props);
    return (
        
        <IfAuthorized policy={AuthorizationPolicies.HistoryViewer}>
            <DataGridToolbarButton
                outline
                onClick={props.onClick}
                bsStyle='info'
                btnClass="btn-history"
                glyph="history"
                glyphColor='#4189C7'
                text="History"
                tooltip
            />
        </IfAuthorized>
    );
};

export const DataGridToolbarButtonAdd = (props) => {
    return (
        <DataGridToolbarButton
            outline
            onClick={props.onClick}
            bsStyle='primary'
            btnClass="btn-add"
            glyph="plus"
            glyphColor='green'
            text={props.text || 'Add'}
            tooltip
        >
            {props.children}
        </DataGridToolbarButton>
    );
};

export const DataGridToolbarButtonEdit = (props) => {
    return (
        <DataGridToolbarButton
            outline
            onClick={props.onClick}
            bsStyle='secondary'
            btnClass="btn-edit"
            glyph="pencil"
            glyphColor='#4189C7'
            text={props.text || 'Edit'}
            tooltip
        >
            {props.children}
        </DataGridToolbarButton>
    );
};


export const DataGridToolbarButtonDisable = (props) => {
    return (
        <DataGridToolbarButton
            onClick={props.onClick}
            bsStyle='danger'
            btnClass="btn-delete"
            glyph="trash"
            glyphColor='yellow'
            text={props.text || 'Disable'}
            tooltip
        >
            {props.children}
        </DataGridToolbarButton>
    );
};

export const DataGridToolbarButtonDelete = (props) => {
    return (
        <DataGridToolbarButton
            onClick={props.onClick}
            bsStyle='danger'
            btnClass="btn-delete"
            glyph="trash"
            glyphColor='red'
            text={props.text || 'Delete'}
            tooltip
        >
            {props.children}
        </DataGridToolbarButton>
    );
};

export const DataGridToolbarButtonPreview = (props) => {
    return (
        <DataGridToolbarButton
            outline
            onClick={props.onClick}
            bsStyle='info'
            btnClass="btn-preview"
            glyph="eye-open"
            glyphColor='#4189C7'
            text={props.text || 'Preview'}
            tooltip
        >
            {props.children}
        </DataGridToolbarButton>
    );
};

export class DataGridToolbar extends Component {

    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    componentWillUnmount() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
    }

    render() {
        return (
            <nav className="navbar navbar-expand-md navbar-light bg-light grid-navbar ">
                <h4 className="navbar-test h-title text-capitalize">{this.props.title}</h4>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#gridNavbar" aria-controls="gridNavbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div id="gridNavbar" className="collapse navbar-collapse grid-toolbar btn-toolbar justify-content-end">
                    <div className="nav navbar-nav navbar-right">
                        {this.props.children}
                    </div>
                </div>
            </nav>
        );
    }
};

/*
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2 h-title text-capitalize">{props.title}</h1>
            <div
                id={props.id}
                className="grid-toolbar btn-toolbar mb-2 d-flex justify-content-end"
            >
                <div className="mr-2">
                    {props.children}
                </div>
            </div>
        </div>
  */