"use strict";

const reservedPathNames = ["admin",
    "users", "userpermissions", "sapuserrequests", "userbaseprovisioning",
    "appstatus", "provisioninfrastatus", "appmodernizationstatus", "appspins",
    "sitestatus", "sites", "sitesextended",
    "legalentities", "legalentitiesseparated", "legalentitiesfinance", "legalentitiesseparatedfinance", 
    "spinprogram", "servicedeliveryworkplan", "techtransformation",  
    "lookups", "lookupvalues", "dbfiles", "errors", "emailmessage", "emailmessages", "dummy", "projects",
    "nsglist"];

const projectExceptions = ["sapuserrequests", "lookups", "dbfiles", "errors", "emailmessage", "dummy", "projects"];

// ReSharper disable once InconsistentNaming
class ProjectContext {

    constructor() {
        if (!ProjectContext.instance) {
            this._currentProject = "";
            ProjectContext.instance = this;
        }
    }

    get currentProject() {
        return this._currentProject;
    }

    set currentProject(project) {
        console.log(`Updating currentProject: ${project}`);
        this._currentProject = project;
    }

    isValidProject(project) {
        // TODO: replace placeholder code with real project name validation.
        if ((project != null) && (project !== "") && !reservedPathNames.includes(project.toLowerCase())) {
            return true;
        }

        return false;
    }

    requireProjectException(feature) {
        if ((feature != null) && (feature !== "") && projectExceptions.includes(feature.toLowerCase())) {
            return true;
        }

        return false;
    }
}

const instance = new ProjectContext();
//Object.freeze(instance);

export default instance;