import React from 'react';
import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarCheckBox,
    DataGridToolbarSplitButton,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonImport,
    //DataGridToolbarButtonExport,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';
import SiteStatusEdit from './SiteStatusEdit';
import DuPont from '../../jscomponents/DuPontApp';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';
import ProjectRequired from '../BaseComponents/ProjectRequired';

let properties;

const Toolbar = () => {
    const { tempCurrentSpin, crossSpinDataHidden, multiSpin } = DuPont.App.Config;
    var currentSpin = multiSpin ? properties.match.params.project : tempCurrentSpin;
    currentSpin = currentSpin.toUpperCase();
    console.log(`currentSpin: ${currentSpin}`);

    return (
        <DataGridToolbar
            title= { currentSpin + " Site Status" } >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarCheckBox
                id="showAllCheckBox"
                controlClass="d-none"
                filterOrder="2"
                filterWhenChecked="all"
                filterWhenUnchecked="enabled"
                caption="Show Disabled Apps"
            />
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarDropdownButton
                    caption="ALL"
                    controlClass={crossSpinDataHidden ? "d-none" : null}
                    glyph="filter"
                    glyphColor='#4189C7'
                    filterOrder="1"
                    filter={crossSpinDataHidden ? currentSpin : "all"}
                    filterReqMsg="Please choose a filter!"
                >
                    <DataGridToolbarDropdownItem filter="DAS" href="#">DAS</DataGridToolbarDropdownItem>
                    <DataGridToolbarDropdownItem filter="SPECCO" href="#">SPECCO</DataGridToolbarDropdownItem>
                    <DataGridToolbarDropdownItem filter="all" href="#">ALL</DataGridToolbarDropdownItem>
                </DataGridToolbarDropdownButton>{' '}
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonImport />
                {/*<DataGridToolbarButtonExport />*/}
                <DataGridToolbarSplitButton
                    bsStyle="info"
                    text="Export"
                    tooltip
                    btnClass="btn-export"
                    glyph="download"
                    glyphColor='#4189C7'
                    modalTarget="#downloadModal"
                    filterReq="1"
                >
                    <DataGridToolbarDropdownItem
                        btnClass="btn-export-track-changes"
                        modalTarget="#downloadModal"
                        filterReq="1"
                    >
                        Export with TrackChanges
                    </DataGridToolbarDropdownItem>
                </DataGridToolbarSplitButton>
                <DataGridToolbarButtonEdit text="Edit Notes" />
                <DataGridToolbarButtonHistory />
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const SiteStatus = (props) => {
    const { multiSpin } = DuPont.App.Config;

    if (multiSpin && !props.match.params.project) {
        return (
            <ProjectRequired />
        );
    }

    properties = props;

    return (
        <DataGrid model='siteStatus'>
            <SiteStatusEdit />
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, SiteStatus, "SiteStatus");