import React from 'react';
import './UploadModal.css';

const UploadModal = () => {
    return (
        <div className="modal fade" id="uploadModal" tabIndex="-1" role="dialog" aria-labelledby="uploadModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <form className="needs-validation form-horizontal" noValidate id="uploadForm">
                        <div className="modal-header">
                            <h5 className="modal-title">Import</h5>
                            <button type="button" className="close btn-close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger d-none status-alert" role="alert" />
                            <div className="form-row mb-2" style={{ marginTop: '20px' }}>
                                <input className="col-12" type="file" name="upload" id="upload" style={{ width: '100%' }} />
                            </div>
                            <div id="isSSoTFullSyncContainer" className="form-row d-none" style={{ marginTop: '20px' }}>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="isSSoTFullSync">
                                        <input className="form-check-input" type="checkbox" id="isSSoTFullSync" name="isSSoTFullSync" value="true" />
                                        Is SSoT full sync
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div id="dateContainer" className="form-row d-none" style={{ marginTop: '20px' }}>
                                    <label className="control-label col-md-2" htmlFor="date">File date</label>
                                    <input type="date" className="form-control form-control-sm col-md-4" id="date" name="date" />
                                </div>
                            </div>
                            <div id="ignoreExistingContainer" className="form-row d-none" style={{ marginTop: '20px' }}>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="ignoreExisting">
                                        <input className="form-check-input" type="checkbox" id="ignoreExisting" name="ignoreExisting" value="true" />
                                        Ignore existing
                                    </label>
                                </div>
                            </div>
                            <div className="form-row mt-2" style={{ marginTop: '10px' }}>
                                <label className="col-form-label progress-text" />
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" />
                            </div>
                        </div>
                        <div className="modal-footer" style={{ display: 'flex' }}>
                            <label className="col-form-label time-text mr-2" />
                            <button type="button" className="btn btn-sm btn-danger btn-error-import d-none" data-container="body" data-toggle="popover" title="Errors">
                                Errors
                                <span className="badge badge-light" style={{ color: 'indianred' }}>0</span>
                                <span className="sr-only">errors</span>
                            </button>
                            <div style={{ visibility: 'hidden', flexGrow: '1' }}>ie_fix</div>
                            <button type="button" className="btn btn-sm btn-primary" id="uploadToServer">Upload</button>
                            <button type="button" className="btn btn-sm btn-secondary btn-close" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UploadModal;