import React from 'react';

const GridColumnFilterModal = () => {
    return (
        <div className="modal fade" id="gridColumnFilterModal" tabIndex="-1" role="dialog" aria-labelledby="gridColumnFilterModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Column Filter</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group mb-0">
                            <label htmlFor="filterValues">Filter values:</label>
                            <textarea className="form-control form-control-sm" id="filterValues" rows="10" />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <div className="form-group-sm">
                            <button type="button" className="btn btn-primary btn-yes">Ok</button>
                            <button type="button" className="btn btn-no" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridColumnFilterModal;