import React, { Component } from 'react';
import goLiveApp from '../jscomponents/Main';
import { Link } from "react-router-dom";
import { IfAuthorized, AuthorizationPolicies } from './BaseComponents/Authorization';
import HomePageNavItem from './UI/Home/HomePageNavItem';


import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../TelemetryService';
import DuPont from '../jscomponents/DuPontApp';
import projContext from '../jscomponents/ProjectContext';
import ProjectRequired from './BaseComponents/ProjectRequired';

function projectDefined(proj) {

    if ((proj !== undefined)) {
        const valid = projContext.isValidProject(proj);

        return valid;
    }

    return false;
}

const Home = (props) => {
    const config = DuPont.App.Config;

    goLiveApp.loaderHide();
    const { currentSpin, multiSpin } = DuPont.App.Config;

    let proj = props.match.params.project;

    proj = (proj == undefined) ? currentSpin : proj.toUpperCase();


    if (projContext.currentProject === "" && projContext.isValidProject(proj)) {
        projContext.currentProject = proj;
    }


    if (multiSpin && !props.match.params.project) {
        return (< ProjectRequired />);
    } 

    if (projectDefined(proj)) {
        return (
            <div>
                <h5 style={{ margin: '20px' }}>Quicklinks</h5>
                <ul className="list-group" style={{ maxWidth: '400px', margin: '0 10px' }}>
                    <IfAuthorized policy={AuthorizationPolicies.AppStatusAllowView}> 
                        <HomePageNavItem caption='Users' icon='fa fa-user' to={`/${proj}/users`} /> 
                    </IfAuthorized>

                    <IfAuthorized policy={AuthorizationPolicies.AppStatusAllowView}>
                        <HomePageNavItem caption='App Status' icon='fa fa-list-alt' to= { `/${proj}/appstatus` } />
                    </IfAuthorized>

                    <IfAuthorized policy={AuthorizationPolicies.ProvisionInfraStatusUser}>
                     <HomePageNavItem caption='App Provision Infra Status' icon='fa fa-list-alt' to={ `/${proj}/provisioninfrastatus` } />
                    </IfAuthorized>

                    <IfAuthorized policy={AuthorizationPolicies.ProvisionInfraStatusUser}>
                        <HomePageNavItem caption='Applications' icon='fa fa-list-alt' to={`/${proj}/appspins`} />
                    </IfAuthorized>

                    <IfAuthorized policy={AuthorizationPolicies.AppSiteAllowView}>
                        <HomePageNavItem caption='Sites Status' icon='fa fa-list' to={`/${proj}/sitestatus`} />
                    </IfAuthorized>

                    {/*
                    <IfAuthorized policy={AuthorizationPolicies.CloudAllowView}>
                        <HomePageNavItem caption='Cloud IaaS' icon='fa fa-cloud' to='/cloudiaas' />
                    </IfAuthorized>
                    */}

                    <IfAuthorized policy={AuthorizationPolicies.AdminOnly}>
                        <HomePageNavItem caption='Users Management' icon='fa fa-cog' to='/admin/usermanagement' />
                    </IfAuthorized>
                </ul>
            </div>
            );
    }

    return (
        <div>
        <h5 style={{ margin: '20px' }}>Quicklinks</h5>
            <ul className="list-group" style={{ maxWidth: '400px', margin: '0 10px' }}>
                <IfAuthorized policy={AuthorizationPolicies.User}>
                    <HomePageNavItem caption='Users' icon='fa fa-user' to={`/users`} />
                </IfAuthorized>

                <IfAuthorized policy={AuthorizationPolicies.AppStatusAllowView}>
                    <HomePageNavItem caption='App Status' icon='fa fa-list-alt' to={`/appstatus`} />
                </IfAuthorized>

                <IfAuthorized policy={AuthorizationPolicies.ProvisionInfraStatusUser}>
                    <HomePageNavItem caption='App Provision Infra Status' icon='fa fa-list-alt' to={`/provisioninfrastatus`} />
                </IfAuthorized>

                <IfAuthorized policy={AuthorizationPolicies.ProvisionInfraStatusUser}>
                    <HomePageNavItem caption='Applications' icon='fa fa-list-alt' to={`/appspins`} />
                </IfAuthorized>

                <IfAuthorized policy={AuthorizationPolicies.AppSiteAllowView}>
                    <HomePageNavItem caption='Sites Status' icon='fa fa-list' to={`/sitestatus`} />
                </IfAuthorized>

                {/*
                                <IfAuthorized policy={AuthorizationPolicies.CloudAllowView}>
                                    <HomePageNavItem caption='Cloud IaaS' icon='fa fa-cloud' to='/cloudiaas' />
                                </IfAuthorized>
                                */}

                <IfAuthorized policy={AuthorizationPolicies.AdminOnly}>
                    <HomePageNavItem caption='Users Management' icon='fa fa-cog' to='/admin/usermanagement' />
                </IfAuthorized>
            </ul>
        </div>
    );

};

export default withAITracking(ai.reactPlugin, Home, "Home");
//export default Home;
