import $ from 'jquery';
import Slick from '../assets/slickgrid-es6/src/slick.core'

export default GridFooter;

function GridFooter(dataView, gridComponent, $container, options) {
    var grid = gridComponent.getInnerGrid();
    var $status;
    var $clearFilter;
    var $filterInfo;
    var _options;
    var _defaults = {
        showAllText: "Count: {rowCount} rows",
        showPageText: "Showing page {pageNum} of {pageCount}"
    };

    function init() {
        _options = $.extend(true, {}, _defaults, options);

        dataView.onPagingInfoChanged.subscribe(function (e, pagingInfo) {
            updateFooter(pagingInfo);
        });

        constructFooterUI();
        updateFooter(dataView.getPagingInfo());
    }

    function getNavState() {
        var cannotLeaveEditMode = !Slick.GlobalEditorLock.commitCurrentEdit();
        var pagingInfo = dataView.getPagingInfo();
        var lastPage = pagingInfo.totalPages - 1;

        return {
            canGotoFirst: !cannotLeaveEditMode && pagingInfo.pageSize != 0 && pagingInfo.pageNum > 0,
            canGotoLast: !cannotLeaveEditMode && pagingInfo.pageSize != 0 && pagingInfo.pageNum != lastPage,
            canGotoPrev: !cannotLeaveEditMode && pagingInfo.pageSize != 0 && pagingInfo.pageNum > 0,
            canGotoNext: !cannotLeaveEditMode && pagingInfo.pageSize != 0 && pagingInfo.pageNum < lastPage,
            pagingInfo: pagingInfo
        }
    }

    function setPageSize(n) {
        dataView.setRefreshHints({
            isFilterUnchanged: true
        });
        dataView.setPagingOptions({ pageSize: n });
    }

    function gotoFirst() {
        if (getNavState().canGotoFirst) {
            dataView.setPagingOptions({ pageNum: 0 });
        }
    }

    function gotoLast() {
        var state = getNavState();
        if (state.canGotoLast) {
            dataView.setPagingOptions({ pageNum: state.pagingInfo.totalPages - 1 });
        }
    }

    function gotoPrev() {
        var state = getNavState();
        if (state.canGotoPrev) {
            dataView.setPagingOptions({ pageNum: state.pagingInfo.pageNum - 1 });
        }
    }

    function gotoNext() {
        var state = getNavState();
        if (state.canGotoNext) {
            dataView.setPagingOptions({ pageNum: state.pagingInfo.pageNum + 1 });
        }
    }

    function constructFooterUI() {
        $container.empty();

        var $nav = $("<span class='slick-pager-nav' style='display:none' />").appendTo($container);
        var $settings = $("<span class='slick-pager-settings' style='display:none' />").appendTo($container);
        $status = $("<span class='slick-pager-status slick-pager-left' />").appendTo($container);
        $clearFilter = $("<a class='slick-pager-clear-filter slick-pager-left' href='#' style='display:none; color:red;'>Clear filter</a>").appendTo($container);

        $clearFilter.on('click', function (e) {
            gridComponent.clearFilter();
            e.preventDefault();
        });

        $filterInfo = $("<span class='slick-pager-filter-text' style='display: none'><input type='text' readonly='true'></span>").appendTo($container);

        $settings
            .append("<span class='slick-pager-settings-expanded' style='display:none'>Show: <a data=0>All</a><a data='-1'>Auto</a><a data=25>25</a><a data=50>50</a><a data=100>100</a></span>");

        $settings.find("a[data]").click(function (e) {
            var pagesize = $(e.target).attr("data");
            if (pagesize != undefined) {
                if (pagesize == -1) {
                    var vp = grid.getViewport();
                    setPageSize(vp.bottom - vp.top);
                } else {
                    setPageSize(parseInt(pagesize));
                }
            }
        });

        var icon_prefix = "<span class='ui-state-default ui-corner-all ui-icon-container'><span class='ui-icon ";
        var icon_suffix = "' /></span>";

        $(icon_prefix + "ui-icon-lightbulb" + icon_suffix)
            .click(function () {
                $(".slick-pager-settings-expanded").toggle()
            })
            .appendTo($settings);

        $(icon_prefix + "ui-icon-seek-first" + icon_suffix)
            .click(gotoFirst)
            .appendTo($nav);

        $(icon_prefix + "ui-icon-seek-prev" + icon_suffix)
            .click(gotoPrev)
            .appendTo($nav);

        $(icon_prefix + "ui-icon-seek-next" + icon_suffix)
            .click(gotoNext)
            .appendTo($nav);

        $(icon_prefix + "ui-icon-seek-end" + icon_suffix)
            .click(gotoLast)
            .appendTo($nav);

        $container.find(".ui-icon-container")
            .hover(function () {
                $(this).toggleClass("ui-state-hover");
            });

        $container.children().wrapAll("<div class='slick-pager' />");
    }

    function updateFooter(pagingInfo) {
        var state = getNavState();

        $container.find(".slick-pager-nav span").removeClass("ui-state-disabled");
        if (!state.canGotoFirst) {
            $container.find(".ui-icon-seek-first").addClass("ui-state-disabled");
        }
        if (!state.canGotoLast) {
            $container.find(".ui-icon-seek-end").addClass("ui-state-disabled");
        }
        if (!state.canGotoNext) {
            $container.find(".ui-icon-seek-next").addClass("ui-state-disabled");
        }
        if (!state.canGotoPrev) {
            $container.find(".ui-icon-seek-prev").addClass("ui-state-disabled");
        }

        if (pagingInfo.pageSize == 0) {
            $status.text(_options.showAllText.replace('{rowCount}', pagingInfo.totalRows + "").replace('{pageCount}', pagingInfo.totalPages + ""));
        } else {
            $status.text(_options.showPageText.replace('{pageNum}', pagingInfo.pageNum + "").replace('{pageCount}', pagingInfo.totalPages + ""));
        }
    }

    function updateFilterInfo(filterInfo) {
        const $filterText = $filterInfo.children('input');
        $filterText.val(filterInfo);

        if ($filterText.val()) {
            $clearFilter.show();
            $filterInfo.show();
        }
        else {
            $clearFilter.hide();
            $filterInfo.hide();
        }
    }

    function destroy() {
        $clearFilter.off();
    }

    init();

    return {
        destroy,
        updateFilterInfo
    };
}