import React from 'react';
import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonExport,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';
import ErrorEdit from './ErrorEdit';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

const Toolbar = () => {
    return (
        <DataGridToolbar
            title="Errors"
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonEdit text="Show Error Text" />
                <DataGridToolbarButtonExport />
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const Errors = () => {
    return (
        <DataGrid model='error'>
            <ErrorEdit />
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, Errors, "Errors");