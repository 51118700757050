import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/storeObject';

import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import classnames from 'classnames';

import DataDisks from './DataDisks';
import LoadBalancer from './LoadBalancer';

class Compute extends Component {

    constructor(props) {
        super(props);
        try {
            this.props.setTab('ilb', this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[0].loadBalancerName);
        }
        catch (e) {
            console.log("There's no ilb" + e);
        }
    }

    render() {
        return (
            <div>
                <FormGroup row>
                    <Col md={1}>
                        <FormGroup>
                            <Label for="role">Role</Label>
                            <Input
                                type="select"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.role'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.role'}
                                value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.role}
                                required
                                //onChange={(obj) => this.props.handleChange(obj)}
                            >
                                <option value='' />
                                {
                                    this.props.getEnumValues('roleEnum').map(c => {
                                        return <option key={c.code} value={c.code}>{c.description}</option>;
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="extension">Extension</Label>
                            <Input
                                type="select"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.extension'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.extension'}
                                value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].extension}
                                required
                                //onChange={(obj) => this.props.handleChange(obj)}
                            >
                                <option value="" />
                                {
                                    this.props.getEnumValues('extensionEnum').map(c => {
                                        return <option key={c.code} value={c.code}>{c.description}</option>;
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>

                    {
                        this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (
                            this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].extension ? (
                                <Col md={1}>
                                    <FormGroup>
                                        <Label for="useXDrive">Probe Port</Label>
                                        <Input
                                            type="select"
                                            name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.extensionProbePort'}
                                            id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.extensionProbePort'}
                                            defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].extensionProbePort : 0 }
                                        >
                                            {
                                                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer.map((c, cIdx) => {
                                                        if (c.probes) {
                                                            return (c.probes.map((probe, probeIdx) => {
                                                                if (!this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].extensionProbePort) {
                                                                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].extensionProbePort = probe.port;
                                                            }
                                                            return (<option value={probe.port}>{probe.port}</option>);
                                                        }));
                                                    }
                                                    else {
                                                        return (<option value=''>Add ILB</option>);
                                                    }
                                                }) :  <option value=''>Add ILB</option>
                                        }
                                        </Input>
                                    </FormGroup>
                                </Col>
                            ) : null)
                            : null
                    }




                    <Col md={3}>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="text"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.description'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.description'}

                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.description : null) : null}
                                placeholder="Description"
                                required

                                valid={
                                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.description ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.description.length > 0 ? true : false) : false) : false) : false}
                                invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.description ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.description.length <= 0 ? true : false) : true) : true) : true}

                            />
                            <div className="invalid-feedback help-block">
                                Provide a Description.
                            </div>
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="cloneBase">Clone</Label>
                            <Input
                                type="select"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.isCloneBase'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.isCloneBase'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].isCloneBase ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].isCloneBase : 'false'}
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Input>
                        </FormGroup>
                    </Col>

                    {
                        this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (
                            this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].isCloneBase === 'true' ? (
                                <Col md={1}>
                                    <FormGroup>
                                        <Label for="useXDrive">Use X Drive</Label>
                                        <Input
                                            type="select"
                                            name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.isUseX'}
                                            id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.isUseX'}
                                            defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].isUseX : 'false'}
                                        >
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            ) : null)
                            : null
                    }





                    <Col md={1}>
                        <FormGroup>
                            <Label for="computeCopyTo">Copy To</Label>
                            <Input
                                type="select"
                                name="computeCopyTo"
                                id="computeCopyTo"
                            >
                                <option value="" defaultValue="">Copy</option>
                                {
                                    this.props.getEnumValues('availabilitySetEnum').map(c => {
                                        if (this.props.formIaas.environments) {
                                            if (this.props.formIaas.environments[this.props.envIdx]) {

                                                if (!this.props.formIaas.environments[this.props.envIdx].compute.filter(e => e.availabilitySet === c.code)[0]) {
                                                    return <option key={c.code} value={c.code}>{c.description}</option>;
                                                }
                                            }
                                            else {
                                                return <option key={c.code} value={c.code}>{c.description}</option>;
                                            }
                                        }
                                        else {
                                            return <option key={c.code} value={c.code}>{c.description}</option>;
                                        }
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={1}>
                        <Label>&nbsp;</Label>
                        <Button type="button" color="primary" onClick={() => this.props.duplicateCompute(this.props.compIdx)}><i className="fa fa-files-o" /></Button>


                    </Col>
                    <Col md={1}>
                        <Label for="delete"> &nbsp;</Label>

                        <Button type="button" color="danger" onClick={() => this.props.removeCompute(this.props.compIdx)}><i className="fa fa-trash" />{this.props.computeName}</Button>
                    </Col>
                </FormGroup>
                <FormGroup row>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="startSequence">Start Sequence</Label>
                            <Input
                                type="number"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.startSequence'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.startSequence'}

                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.startSequence : null) : null}
                                placeholder="1, 11, 21..."
                                required
                                valid={
                                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.startSequence ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.startSequence.length > 0 ? true : false) : false) : false) : false}
                                invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.startSequence ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.startSequence.length <= 0 ? true : false) : true) : true) : true}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="count">Quantity</Label>
                            <Input
                                type="number"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.count'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.count'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.count : null) : null}
                                placeholder="Number"
                                required
                                valid={
                                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.count ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.count.length > 0 ? true : false) : false) : false) : false}
                                invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.count ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.count.length <= 0 ? true : false) : true) : true) : true}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="instanceSize">Instance Size</Label>
                            <Input
                                type="select"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.instanceSize'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.instanceSize'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.instanceSize : null) : null}
                                required
                                valid={
                                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.instanceSize ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.instanceSize.length > 0 ? true : false) : false) : false) : false}
                                invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.instanceSize ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.instanceSize.length <= 0 ? true : false) : true) : true) : true}
                            >
                                <option value=''>Choose an option</option>
                                {
                                    this.props.getEnumValues('instanceSizeEnum').map(c => {
                                        return <option key={c.code} value={c.code}>{c.description}</option>;
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="image">Image</Label>
                            <Input
                                type="select"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.image'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.image'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.image : null) : null}
                                required
                                valid={
                                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.image ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.image.length > 0 ? true : false) : false) : false) : false}
                                invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.image ? (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.image.length <= 0 ? true : false) : true) : true) : true}
                            >
                                <option value=''>Choose an option</option>
                                {
                                    this.props.getEnumValues('imageEnum').map(c => {
                                        return <option key={c.code} value={c.code}>{c.description}</option>;
                                    })
                                }
                            </Input>
                            <div className="help-block">
                                {
                                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.image ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.image.toLowerCase().indexOf('smalldisk') >= 0 ? <span className="alertMessage"><b>Attention:</b> We don't recommend smalldisk usage.</span> : '' : '' : '' : ''
                                }

                                {
                                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.image ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.image.toLowerCase().indexOf('sqldev') >= 0 ? <span className="alertMessage"><b>Attention:</b> You are using SQL DEV image.</span> : '' : '' : '' : ''
                                }

                            </div>

                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="enableAcceleratedNetworking">Accelerated Networking</Label>
                            <Input
                                type="select"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.enableAcceleratedNetworking'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.enableAcceleratedNetworking'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].enableAcceleratedNetworking ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].enableAcceleratedNetworking : 'false'}
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Input>
                        </FormGroup>
                    </Col>



                </FormGroup>


                <FormGroup row>
                    <Col md={2}>
                        <legend>Data Disks</legend>
                    </Col>
                    <Col md={2}>
                        <Input
                            type="select"
                            name="disksDropdown"
                            id="disksDropdown"
                            defaultValue={this.props.formIaas.disksDropdown}
                        >
                            <option value=''> Add disk to {this.props.computeName}</option>
                            {
                                this.props.getEnumValues('diskSizeEnum').map(c => {

                                    if (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx]) {
                                        if (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm) {
                                            if (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.disks) {
                                                if (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.disks.filter(d => d.diskSizeGB.toString() === c.code.toString()).length <= 0) {
                                                    return <option key={c.code} value={c.code}>{c.description}</option>;
                                                }
                                            }
                                            else {
                                                return <option key={c.code} value={c.code}>{c.description}</option>;
                                            }
                                        }
                                        else {
                                            return <option key={c.code} value={c.code}>{c.description}</option>;
                                        }
                                    }
                                    else {
                                        return <option key={c.code} value={c.code}>{c.description}</option>;
                                    }
                                })
                            }
                        </Input>
                    </Col>
                    <Col md={2}>
                        <Button color="primary" onClick={() => this.props.addDataDisk(this.props.envIdx, this.props.compIdx)}><i className="fa fa-plus" />  Add Disk </Button>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={12}>
                        <DataDisks
                            envIdx={this.props.envIdx}
                            compIdx={this.props.compIdx}

                            handleChange={this.props.handleChange}

                            formIaas={this.props.formIaas}
                            removeDataDisk={this.props.removeDataDisk} />
                    </Col>
                </FormGroup>



                <FormGroup row>
                    <Col md={3}>
                        <legend>Load Balancer Rules</legend>
                    </Col>
                    <Col md={3}>
                        <Input
                            type="select"
                            name="ilbDropdown"
                            id="ilbDropdown"
                        >
                            <option value=""> Add ILB Rules to {this.props.computeName} </option>
                            {
                                this.props.getEnumValues('ilbEnum').map(i => {

                                    if (this.props.formIaas.environments[this.props.envIdx]) {
                                        if (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx]) {
                                            if (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm) {
                                                if (this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer) {
                                                    if (!this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer.filter(ilb => ilb.loadBalancerName === i.code)[0]) {
                                                        return <option key={i.code} value={i.code}>{i.description}</option>;
                                                    }
                                                }
                                                else {
                                                    return <option key={i.code} value={i.code}>{i.description}</option>;
                                                }
                                            }
                                            else {
                                                return <option key={i.code} value={i.code}>{i.description}</option>;
                                            }
                                        }
                                        else {
                                            return <option key={i.code} value={i.code}>{i.description}</option>;
                                        }
                                    }
                                    else {
                                        return <option key={i.code} value={i.code}>{i.description}</option>;
                                    }
                                })
                            }
                        </Input>
                    </Col>
                    <Col md={2}>
                        <Button color="primary" onClick={() => this.props.addLoadBalancer(this.props.envIdx, this.props.compIdx)}><i className="fa fa-plus" /> Rules</Button>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    {
                        this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (
                            this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (
                                this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer ?
                                    (<Col md={12}>
                                        <Nav tabs>
                                            {this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer.map((c, cIdx) => {
                                                return (
                                                    <NavItem key={'environments' + this.props.envIdx + 'compute' + this.props.compIdx + 'loadBalancer' + cIdx} className={classnames("ilbSet", { active: this.props.activeTab.filter(p => p.name === 'ilb')[0] ? this.props.activeTab.filter(p => p.name === 'ilb')[0].tabId === c.loadBalancerName : false })}>
                                                        <NavLink onClick={() => { this.props.toggleTab('ilb', c.loadBalancerName); }} >
                                                            {c.loadBalancerName}
                                                        </NavLink>
                                                    </NavItem>
                                                );
                                            })}
                                        </Nav>
                                        <TabContent activeTab={this.props.addCompute}>
                                            {this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer.map((c, cIdx) => {
                                                return (
                                                    <TabPane key={'environments' + this.props.envIdx + 'compute' + this.props.compIdx + 'loadBalancer' + cIdx + '-tab'} tabId={c.loadBalancerName} className={classnames("ilbSet", { active: this.props.activeTab.filter(p => p.name === 'ilb')[0] ? this.props.activeTab.filter(p => p.name === 'ilb')[0].tabId === c.loadBalancerName : false })}>
                                                        <Row>
                                                            <Col sm="12">
                                                                <LoadBalancer
                                                                    envIdx={this.props.envIdx}
                                                                    environmentName={this.props.formIaas.environments[this.props.envIdx].environmentName}

                                                                    compIdx={this.props.compIdx}
                                                                    computeName={c.availabilitySet}

                                                                    ilbIdx={cIdx}

                                                                    getEnumValues={this.props.getEnumValues}
                                                                    addDataDisk={this.props.addDataDisk}
                                                                    removeDataDisk={this.props.removeDataDisk}
                                                                    removeCompute={this.props.removeCompute}
                                                                    addLoadBalancer={this.props.addLoadBalancer}
                                                                    removeLoadBalancer={this.props.removeLoadBalancer}

                                                                    addProbe={this.props.addProbe}
                                                                    removeProbe={this.props.removeProbe}

                                                                    addPortMapping={this.props.addPortMapping}
                                                                    removePortMapping={this.props.removePortMapping}

                                                                    handleChange={this.props.handleChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                );
                                            })}
                                        </TabContent>
                                    </Col>)
                                    : null

                            ) : null

                        ) : null

                    }
                </FormGroup>


            </div>
        );

    }
}

export default Compute;