import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';


class TelemetryService {

    constructor() {
        this.reactPlugin = new ReactPlugin();
    }

    initialize(reactPluginConfig) {

        let INSTRUMENTATION_KEY = 'fdde98df-73f6-4a76-b618-3642f90581d0';

        this.appInsights = new ApplicationInsights({

            config: {

                instrumentationKey: INSTRUMENTATION_KEY,

                maxBatchInterval: 0,

                disableFetchTracking: false,

                extensions: [this.reactPlugin],

                extensionConfig: {

                    [this.reactPlugin.identifier]: reactPluginConfig
                }

            }

        });

        this.appInsights.loadAppInsights();

    }

}



export let ai = new TelemetryService();