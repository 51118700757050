import React from 'react';
import DuPont from '../../../jscomponents/DuPontApp';

const NavGoToSpecCoItem = () => {
    const currentSpin = (DuPont.App.Config || {}).currentSpin;

    if (currentSpin === "DAS") {
        return (
            <div
                className="alert alert-danger"
                role="alert"
                style={{
                    padding: '5px',
                    width: '195px',
                    marginRight: '10px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    fontSize: '0.85rem',
                    textAlign: 'center'
                }}
            >
                For <b>SpecCo</b> tracker click <a href="https://golivetracking.dupont.com" style={{ color: 'crimson', fontWeight: 'bold' }}>here</a>.
            </div>
        );
    }
    return null;
};


export default NavGoToSpecCoItem;