import $ from 'jquery';
import 'popper.js';

export default ErrorHandler;

function ErrorHandler (gridComponent) {

    let errors = [];
    let grid;
    let $errorBtn;
    let $errorBadge;

    function init() {
        grid = gridComponent.getInnerGrid();

        $errorBtn = $(".btn-error").popover({
            //trigger: 'focus click',
            placement: 'right',
            html: true
            //title: function () { return $errorPopoverTitle.html(); },
            //content: function () { return $errorPopoverContent.html(); }
        });

        $errorBadge = $errorBtn.children('.badge');
        $errorBtn.on('inserted.bs.popover', handleOnInsertedPopover);
        $('body').on('click', '.popover-error>.popover-body>div>a', handleOnErrClick);
        $('body').on('click', handleOnBodyClick);
    }

    function handleOnInsertedPopover(e) {
        const errorPopover = $(e.target).data('bs.popover').tip;
        $(errorPopover).addClass('popover-error');

        errors.sort((e1, e2) => { return e1.itemId - e2.itemId; });

        $(errorPopover).children('.popover-body').append(
            errors.map((err) => err.text).join('')
        );
    }

    function handleOnBodyClick(e) {
        //only buttons
        if ($(e.target).data('toggle') !== 'popover'
            && $(e.target).parents('[data-toggle="popover"]').length === 0
            && $(e.target).parents('.popover-error').length === 0
            && $('.popover-error').is(':visible')) {
            $errorBtn.popover('hide');
        }
    }

    function handleOnErrClick(e) {
        const $this = $(this);
        const row = grid.getData().getRowById($this.data('itemid'));
        const cell = $this.data('field') ? grid.getColumnIndex($this.data('field')) : grid.getActiveCell().cell;
        grid.focus();
        grid.gotoCell(row, cell, true);
        $errorBtn.popover('hide');
        e.preventDefault();
    }

    function add(error) {
        if (error.responseJSON) {
            if (Array.isArray(error.responseJSON)) {
                const columns = grid.getColumns();
                Array.prototype.push.apply(errors,
                    error.responseJSON.map((err) => {
                        const errGridField = err.code.startsWith('Duplicate') ? err.code.slice(9) : null;
                        let colId = '', colName = '';
                        if (errGridField) {
                            let colIdx = grid.getColumnIndex(errGridField);
                            if (colIdx == null && errGridField[0] === errGridField[0].toUpperCase()) {
                                colIdx = columns.findIndex(c => c.id.toUpperCase() === errGridField.toUpperCase());
                            }
                            colName = colIdx > -1 ? columns[colIdx].name : errGridField;
                            colId = colIdx > -1 ? columns[colIdx].id : '';
                        }
                        return { itemId: error.itemId, text: `<div><a href="#" data-itemid='${error.itemId}' data-field='${colId}'>Row#${error.itemId} - <b>${colName || err.code}</b></a> - ${err.description}</div>` };
                    })
                );
            } else {
                const columns = grid.getColumns();
                Object.keys(error.responseJSON).forEach(function (errField) {
                    const errGridField = errField.startsWith('Item.') ? errField.slice(5) : errField;
                    let colIdx = grid.getColumnIndex(errGridField);
                    if (colIdx == null && errGridField[0] === errGridField[0].toUpperCase()) {
                        colIdx = columns.findIndex(c => c.id.toUpperCase() === errGridField.toUpperCase());
                    }
                    const colName = colIdx > -1 ? columns[colIdx].name : errGridField;
                    const colId = colIdx > -1 ? columns[colIdx].id : '';
                    Array.prototype.push.apply(errors,
                        error.responseJSON[errField].map((errText) => {
                            return { itemId: error.itemId, text: `<div><a href="#" data-itemid='${error.itemId}' data-field='${colId}'>Row#${error.itemId} - <b>${colName}</b></a> - ${errText}</div>` };
                        })
                    );
                    //$errorPopoverContent.append(
                    //    error.responseJSON[field].map((errItem) => $(`<div><a href="#" data-itemid='${error.itemId}'>Row#${error.itemId} - <b>${colName}</b></a> - ${errItem}</div>`))
                    //);
                });
            }
            $errorBadge.text(errors.length);
            $errorBtn.removeClass('d-none');
        }
    }

    function reset() {
        $errorBtn.popover('hide');
        $errorBtn.addClass('d-none');
        $errorBadge.text('0');
        errors = [];
    }

    function destroy() {
        reset();
        $('body').off('click', handleOnBodyClick);
        $('body').off('click', '.popover-error>.popover-body>div>a', handleOnErrClick);
        $errorBtn.off('inserted.bs.popover');
        $errorBtn.popover('dispose');
    }

    init();

    return {
        add,
        reset,
        destroy
    };
};