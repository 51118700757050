import $ from 'jquery';
import Slick from '../assets/slickgrid-es6/src/slick.core';
import Editors from '../assets/slickgrid-es6/src/slick.editors';
//import select2 from '../assets/X-SlickGrid/lib/select2'
//import '../assets/select2/select2';
//import '../assets/select2/select2.css'

import 'select2';
import 'select2/dist/css/select2.css';
import './slick.editors.extenders.select2fix';
import '../assets/select2-multi-checkboxes/select2.multi-checkboxes';

/***
 * Contains basic SlickGrid editors.
 * @module Editors
 * @namespace Slick
 */

Editors.Select2 = Select2Editor;
Editors.ReadOnlyText = ReadOnlyTextEditor;
Editors.SelectPicker = SelectPickerEditor;
Editors.S2MultiCheckboxes = S2MultiCheckboxesEditor;
Editors.NullableInteger = NullableIntegerEditor;
Editors.Date = DateEditor;
Editors.Select2YesNo = Select2YesNoEditor;

const keyCode = {
    BACKSPACE: 8,
    DELETE: 46,
    DOWN: 40,
    END: 35,
    ENTER: 13,
    ESCAPE: 27,
    HOME: 36,
    INSERT: 45,
    LEFT: 37,
    PAGE_DOWN: 34,
    PAGE_UP: 33,
    RIGHT: 39,
    TAB: 9,
    UP: 38,
    F2: 113,
    SPACE: 32,
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90
};

$.extend(true, Slick, { keyCode });

export default Editors;

function Select2Editor(args) {
    var $input;
    var defaultValue;
    var scope = this;
    var calendarOpen = false;
    var valuesByText;
    var allowClear = args.column.allowClear !== undefined ? args.column.allowClear : true;

    this.keyCaptureList = [Slick.keyCode.UP, Slick.keyCode.DOWN, Slick.keyCode.ENTER];

    this.init = function () {
        $input = $('<select></select>');
        $input.width(args.container.clientWidth + 3);

        endInit();
    };

    function endInit() {
        const multiple = !!(args.column.isMultiSelect);

        populateSelect($input[0], false);

        $input.appendTo(args.container);
        $input.focus().select();
        $input.on('select2:select', function (e) {
            $(this).focus().select();
        });

        $input.select2({
            placeholder: '',//'-',
            allowClear: allowClear,
            multiple: multiple
        });

        /*
    if (args.item) $input.val(args.item[args.column.field]); // Select the option with a value of '1'
    $input.trigger('change'); // Notify any JS components that the value changed
    // */
    }

    function populateSelect(select, addBlank) {
        var index, len, newOption;
        if (addBlank) { select.appendChild(new Option('', '')); }
        if (args.column.orderedDataSource) {
            args.column.orderedDataSource.forEach(function (item) {
                newOption = new Option(item.text, item.value);
                select.appendChild(newOption);
            });
        } else {
            $.each(args.column.dataSource, function (value, text) {
                newOption = new Option(text, value);
                select.appendChild(newOption);
            });
        }
    }

    this.destroy = function () {
        $input.select2('destroy');
        $input.remove();
    };

    this.show = function () {
    };

    this.hide = function () {
        //$input.select2('results_hide');
        $input.select2('close');
    };

    this.position = function (position) {
    };

    this.focus = function () {
        $input.select2('input_focus');
    };

    this.loadValue = function (item) {
        defaultValue = item[args.column.field];
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.trigger("change.select2");
    };

    this.serializeValue = function () {
        const v = $input.val();
        return (v == null || isNaN(v)) ? v : parseInt(v);
    };

    this.applyValue = function (item, state, text) {
        if (text && (valuesByText || createValueByText())) {
            state = valuesByText[text];
        }
        item[args.column.field] = state;
    };

    this.isValueChanged = function () {
        return (!($input.val() == "" && defaultValue == null)) && ($input.val() != defaultValue);
    };

    this.validate = function () {
        return {
            valid: true,
            msg: null
        };
    };

    function createValueByText() {
        const ds = args.column.dataSource;
        valuesByText = Object.keys(ds).reduce((obj, k) => {
            obj[ds[k]] = k;
            return obj
        }, {});
        return true;
    }

    this.init();
}

function S2MultiCheckboxesEditor(args) {
    var $input;
    var defaultValue;
    var scope = this;
    var calendarOpen = false;
    var valuesByText;

    this.keyCaptureList = [Slick.keyCode.UP, Slick.keyCode.DOWN, Slick.keyCode.ENTER];

    this.init = function () {
        $input = $('<select class="select2-multiple"></select>');
        $input.width(args.container.clientWidth + 1);

        endInit();
    };

    function endInit() {
        populateSelect($input[0], false);

        $input.appendTo(args.container);
        $input.focus().select();
        //$input.on('select2:select', function (e) {
        //    $(this).focus().select();
        //});

        $input.select2MultiCheckboxes({
            templateSelection: function (selected, total) {
                //console.log(selected);
                return Array.isArray(selected) ? getText(selected) : ''; // "Selected " + selected.length + " of " + total;
            },
            placeholder: '',//'-',
            allowClear: true,
        });

        /*
    if (args.item) $input.val(args.item[args.column.field]); // Select the option with a value of '1'
    $input.trigger('change'); // Notify any JS components that the value changed
    // */
    }

    function populateSelect(select, addBlank) {
        var index, len, newOption;
        if (addBlank) { select.appendChild(new Option('', '')); }
        if (args.column.orderedDataSource) {
            args.column.orderedDataSource.forEach(function (item) {
                newOption = new Option(item.text, item.value);
                select.appendChild(newOption);
            });
        } else {
            $.each(args.column.dataSource, function (value, text) {
                newOption = new Option(text, value);
                select.appendChild(newOption);
            });
        }
    }

    this.destroy = function () {
        $input.select2('destroy');
        $input.remove();
    };

    this.show = function () {
    };

    this.hide = function () {
        //$input.select2('results_hide');
        $input.select2('close');
    };

    this.position = function (position) {
    };

    this.focus = function () {
        $input.select2('input_focus');
    };

    this.loadValue = function (item) {
        defaultValue = item[args.column.field];
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.trigger("change.select2");
    };

    this.serializeValue = function () {
        return $input.val().map(v => isNaN(v) ? v : parseInt(v));
    };

    this.applyValue = function (item, state, text) {
        if (text && (valuesByText || createValueByText())) {
            state = valuesByText[text];
        }
        item[args.column.field] = state;
    };

    this.isValueChanged = function () {
        return (($input.val().length) !== ((defaultValue || []).length))
            ||
            !$input.val().every(i1 => (defaultValue.findIndex(i2 => i1 == i2) > -1));
    };

    this.validate = function () {
        return {
            valid: true,
            msg: null
        };
    };

    function getText(selected) {
        const ds = args.column.dataSource;
        const result = Object.keys(ds)
            .filter(k => selected.includes(k))
            .reduce((arr, k) => {
                arr.push([ds[k]]);
                return arr
            }, [])
            .sort()
            .join();
        return result;
    }

    function createValueByText() {
        const ds = args.column.dataSource;
        valuesByText = Object.keys(ds).reduce((obj, k) => {
            obj[ds[k]] = k;
            return obj
        }, {});
        return true;
    }

    this.init();
}

function Select2YesNoEditor(args) {
    var $input;
    var defaultValue;
    var scope = this;
    var allowClear = args.column.allowClear !== undefined ? args.column.allowClear : false;

    this.keyCaptureList = [Slick.keyCode.UP, Slick.keyCode.DOWN, Slick.keyCode.ENTER];

    this.init = function () {
        $input = $('<select></select>');
        $input.width(args.container.clientWidth + 3);

        endInit();
    };

    function endInit() {
        populateSelect($input[0], false);

        $input.appendTo(args.container);
        $input.focus().select();
        $input.on('select2:select', function (e) {
            $(this).focus().select();
        });

        $input.select2({
            placeholder: '',//'-',
            allowClear: allowClear,
            multiple: false
        });

        /*
       if (args.item) $input.val(args.item[args.column.field]); // Select the option with a value of '1'
       $input.trigger('change'); // Notify any JS components that the value changed
       // */
    }

    function populateSelect(select, addBlank) {
        var index, len, newOption;
        if (addBlank) { select.appendChild(new Option('', '')); }
        select.appendChild(new Option('No', false));
        select.appendChild(new Option('Yes', true));
    }

    this.destroy = function () {
        $input.select2('destroy');
        $input.remove();
    };

    this.show = function () {
    };

    this.hide = function () {
        //$input.select2('results_hide');
        $input.select2('close');
    };

    this.position = function (position) {
    };

    this.focus = function () {
        $input.select2('input_focus');
    };

    this.loadValue = function (item) {
        defaultValue = item[args.column.field].toString();
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.trigger("change.select2");
    };

    this.serializeValue = function () {
        return $input.val() === 'true';
    };

    this.applyValue = function (item, state, text) {
        item[args.column.field] = state;
    };

    this.isValueChanged = function () {
        return (!($input.val() == "" && defaultValue == null)) && ($input.val() != defaultValue);
    };

    this.validate = function () {
        return {
            valid: true,
            msg: null
        };
    };

    this.init();
}

function SelectPickerEditor(args) {
    var $input;
    var defaultValue;
    var scope = this;
    var calendarOpen = false;
    var valuesByText;

    this.keyCaptureList = [Slick.keyCode.UP, Slick.keyCode.DOWN, Slick.keyCode.ENTER];

    this.init = function () {
        $input = $('<select class="selectpicker"></select>');
        $input.width(args.container.clientWidth + 3);

        endInit();
    };

    function endInit() {
        const multiple = !!(args.column.isMultiSelect);

        populateSelect($input[0], args.column.dataSource, false);

        $input.appendTo(args.container);
        //$input.focus().select();
        //$input.on('select2:select', function(e) {
        //    $(this).focus().select();
        //});

        //$input.select2({
        //    placeholder: '',//'-',
        //    allowClear: true,
        //    multiple: multiple
        //});

        if (multiple) $input.attr('multiple', '');
        $input.selectpicker('render');

        /*
        if (args.item) $input.val(args.item[args.column.field]); // Select the option with a value of '1'
        $input.trigger('change'); // Notify any JS components that the value changed
        // */
    }

    function populateSelect(select, dataSource, addBlank) {
        var index, len, newOption;
        if (addBlank) { select.appendChild(new Option('', '')); }
        $.each(dataSource, function (value, text) {
            newOption = new Option(text, value);
            select.appendChild(newOption);
        });
    };

    this.destroy = function () {
        $input.selectpicker('destroy');
        $input.remove();
    };

    this.show = function () {
        $input.selectpicker('show');
    };

    this.hide = function () {
        $input.selectpicker('hide');
    };

    this.position = function (position) {
    };

    this.focus = function () {
        $input.selectpicker('show');
    };

    this.loadValue = function (item) {
        defaultValue = item[args.column.field];
        $input.selectpicker('val', defaultValue);
        $input[0].defaultValue = defaultValue;
    };

    this.serializeValue = function () {
        return $input.val();
    };

    this.applyValue = function (item, state, text) {
        if (text && (valuesByText || createValueByText())) {
            state = valuesByText[text];
        }
        item[args.column.field] = state;
    };

    this.isValueChanged = function () {
        return (!($input.val() == "" && defaultValue == null)) && ($input.val() != defaultValue);
    };

    this.validate = function () {
        return {
            valid: true,
            msg: null
        };
    };

    function createValueByText() {
        const ds = args.column.dataSource;
        valuesByText = Object.keys(ds).reduce((obj, k) => {
            obj[ds[k]] = k;
            return obj
        }, {});
        return true;
    }

    this.init();
}

function ReadOnlyTextEditor(args) {
    var $input;
    var defaultValue;
    var scope = this;

    this.init = function () {
        $input = $("<span class='editor-text'></span>")
            .appendTo(args.container)
            .on("keydown.nav", function (e) {
                if (e.keyCode === $.ui.keyCode.LEFT || e.keyCode === $.ui.keyCode.RIGHT) {
                    e.stopImmediatePropagation();
                }
            })
            .focus()
            .select();
    };

    this.destroy = function () {
        $input.remove();
    };

    this.focus = function () {
        $input.focus();
    };

    this.getValue = function () {
        return $input.text();
    };

    this.setValue = function (val) {
        $input.text(val);
    };

    this.loadValue = function (item) {
        defaultValue = item[args.column.field] || "";
        $input.text(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.select();
    };

    this.serializeValue = function () {
        return $input.text();
    };

    this.applyValue = function (item, state) {
        item[args.column.field] = state;
    };

    this.isValueChanged = function () {
        return (!($input.text() === "" && defaultValue === null)) && ($input.text() !== defaultValue);
    };

    this.validate = function () {
        if (args.column.validator) {
            var validationResults = args.column.validator($input.text());
            if (!validationResults.valid) {
                return validationResults;
            }
        }

        return {
            valid: true,
            msg: null
        };
    };

    this.init();
}

function NullableIntegerEditor(args) {
    var $input;
    var defaultValue;
    var scope = this;
    const allowsNA = args.column.allowsNA; //args.column.formatter != null && args.column.formatter.toString().substr(0, 20) === "function NAFormatter";
    const NA = 'N/A';

    this.init = function () {
        $input = $("<INPUT type=text class='editor-text text-right' />");

        $input.on("keydown.nav", function (e) {
            if (e.keyCode === $.ui.keyCode.LEFT || e.keyCode === $.ui.keyCode.RIGHT) {
                e.stopImmediatePropagation();
            }
        });

        $input.appendTo(args.container);
        $input.focus().select();
    };

    this.destroy = function () {
        $input.remove();
    };

    this.focus = function () {
        $input.focus();
    };

    this.loadValue = function (item) {
        defaultValue = item[args.column.field];
        if (allowsNA && (defaultValue == null || defaultValue === '')) defaultValue = NA;
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.select();
    };

    this.serializeValue = function () {
        const value = $input.val();
        //return parseInt(value, 10) || 0;
        return (value == null || value === '' || (allowsNA && value.toUpperCase() === NA)) ? null : (parseInt(value, 10) || 0);
    };

    this.applyValue = function (item, state) {
        item[args.column.field] = (allowsNA && state != null && state.toString().toUpperCase() === NA) ? null : state;
    };

    this.isValueChanged = function () {
        const value = $input.val();
        if (defaultValue === NA && (value == null || value === '' || value.toUpperCase() === NA))
            return false;
        else if (defaultValue == '0' && value !== '0')
            return true;
        else
            return (value == null ? '' : value) != (defaultValue == null ? '' : defaultValue);
    };

    this.validate = function () {
        const value = $input.val();
        if (isNaN(value) && (!allowsNA || value.toUpperCase() !== NA)) {
            return {
                valid: false,
                msg: "Please enter a valid integer"
            };
        }

        if (args.column.validator) {
            var validationResults = args.column.validator(value);
            if (!validationResults.valid) {
                return validationResults;
            }
        }

        return {
            valid: true,
            msg: null
        };
    };

    this.init();
}

function DateEditor(args) {
    var $input;
    var defaultValue;
    var scope = this;
    var calendarOpen = false;

    this.init = function () {
        $input = $("<INPUT type=text class='editor-text' />");

        $input.on("keydown.nav", function (e) {
            if (e.keyCode === $.ui.keyCode.LEFT || e.keyCode === $.ui.keyCode.RIGHT) {
                e.stopImmediatePropagation();
            }
        });

        $input.appendTo(args.container);
        $input.focus().select();
        $input.datepicker({
            showOn: "button",
            buttonImageOnly: true,
            beforeShow: function () {
                calendarOpen = true
            },
            onClose: function () {
                calendarOpen = false
            }
        });
        $input.width($input.width() - 18);
    };

    this.destroy = function () {
        $.datepicker.dpDiv.stop(true, true);
        $input.datepicker("hide");
        $input.datepicker("destroy");
        $input.remove();
    };

    this.show = function () {
        if (calendarOpen) {
            $.datepicker.dpDiv.stop(true, true).show();
        }
    };

    this.hide = function () {
        if (calendarOpen) {
            $.datepicker.dpDiv.stop(true, true).hide();
        }
    };

    this.position = function (position) {
        if (!calendarOpen) {
            return;
        }
        $.datepicker.dpDiv
            .css("top", position.top + 30)
            .css("left", position.left);
    };

    this.focus = function () {
        $input.focus();
    };

    this.loadValue = function (item) {
        defaultValue = item[args.column.field];
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.select();
    };

    this.serializeValue = function () {
        return $input.val();
    };

    this.applyValue = function (item, state) {
        item[args.column.field] = state;
    };

    this.isValueChanged = function () {
        return ($input.val() == null ? '' : $input.val()) !== (defaultValue == null ? '' : defaultValue);
        //return (!($input.val() == "" && defaultValue == null)) && ($input.val() != defaultValue);
    };

    this.validate = function () {
        let inputVal = $input.val();
        if (args.column.validator) {
            var validationResults = args.column.validator(inputVal);
            if (!validationResults.valid) {
                return validationResults;
            }
        } else if (inputVal != null && inputVal > '') {
            if (/^([1-9]|0[1-9]|1[0-2])\/([1-9]|0[1-9]|1\d|2\d|3[01])$/.test(inputVal)) {
                inputVal += '/' + new Date().getFullYear();
            }
            const d = new Date(inputVal);
            if (isValidDate(d)) {
                $input.val(d.toCleanDateString());
            } else {
                return {
                    valid: false,
                    msg: 'Invalid date.'
                };
            }
        }

        return {
            valid: true,
            msg: null
        };
    };

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    this.init();
}
