import React from 'react';

const NavDropdown = props => {
    const pageURI = window.location.pathname + window.location.search;
    let active = pageURI === props.defPath;
    if (!active && props.children && props.children.length > 0) {
        React.Children.forEach(props.children, child => {
            active = active || (child && child.props && pageURI === child.props.to);
        });
    }

    return (
        <li className={`nav-item dropdown ${active ? "active" : ""}`}>
            <a className="nav-link dropdown-toggle" href='#' id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className={props.icon} />
                {props.name}
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdown01">
                {props.children}
            </div>
        </li>
    );
};

export default NavDropdown;