import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';
import { IfAuthorized, AuthorizationPolicies, authorizationService } from '../BaseComponents/Authorization';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

import Environments from "./Iaas/Environments";
import Modal from '../UI/Modal/Modal';
import Preview from "./Iaas/Preview";

import {
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarButtonHistory,
    DataGridToolbarButton
} from '../BaseComponents';

import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import classnames from 'classnames';



class CloudIaaSForm extends Component {

    constructor(props) {
        super(props);
        this.error = null;

        // Correctly Bind class methods to reacts class instance
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        try {
            this.props.setTab('environment', this.props.formIaas.environments[0].environmentName);
        }
        catch (e) {
            //console.log("There's no environments" + e);
        }

        this.footerMessage = '';

    }

    componentDidMount() {
        if (this.props.formIaas.status === 'requested' && !authorizationService.authorize(AuthorizationPolicies.CloudAllowImplement)) {

            let myForm = document.getElementById('myForm');

            let elem = myForm.getElementsByClassName('form-control');

            for (let input of elem) {
                input.setAttribute("disabled", "disable");
            }
            elem = myForm.getElementsByClassName('form-check-input');
            for (let input of elem) {
                input.setAttribute("disabled", "disable");
            }

            elem = myForm.getElementsByClassName('btn');
            for (let input of elem) {
                input.setAttribute("style", "display:none;");
            }

            this.footerMessage = 'To request a change you need to talk with infrastructure team.';

        }
    }

    toggleModal = () => {
        this.props.showModal(!this.props.modal);
    }

    // This function handle form submission
    handleSubmit = (evt, sender) => {
        evt.preventDefault();
        var formData = this.props.formIaas;
        this.props.validateForm();
    }

    setObjectPropety(obj, prop, type, value) {
        if (prop.indexOf('.') > 0) {
            this.setObjectPropety(obj[prop.substr(0, prop.indexOf('.'))], prop.substr(prop.indexOf('.') + 1, prop.length - prop.indexOf('.')), type, value);
        }
        else {
            obj[prop] = value;
        }
    }

    saveImported = () => {
        this.props.importedAzureObjects();
    }

    handleChange(key) {
        var formData = this.props.formIaas;

        if (key.target.getAttribute('type') === 'checkbox') {
            this.setObjectPropety(formData, key.target.name, key.target.getAttribute('type'), key.target.checked);
        }
        else {
            this.setObjectPropety(formData, key.target.name, key.target.getAttribute('type'), key.target.value);
        }


         //// validating some issues       
        var changed = key.target.name.split('.');


        // making sure all extension have same values into all environments
        try {
            changed[1] = parseInt(changed[1]); // environment idx
            changed[3] = parseInt(changed[3]); // compute idx

            if (changed[0] === 'environments' && changed[2] === 'compute' && changed[4] === 'extension') {
                formData.environments.map((env, envIdx) => {
                    if (envIdx !== changed[1]) { // diff environment
                        formData.environments[envIdx].compute[changed[3]].extension = formData.environments[changed[1]].compute[changed[3]].extension;
                        // formData.environments[envIdx].compute[changed[3]].extension.parse(formData.environments[changed[1]].compute[changed[3]].extension);
                    }
                });

            }
        }
        catch (e) {
            console.log('no vms extension');
        }


        //// making sure all role have same values into all environments
        try {
            changed[1] = parseInt(changed[1]); // environment idx
            changed[3] = parseInt(changed[3]); // compute idx

            if (changed[0] === 'environments' && changed[2] === 'compute' && changed[4] === 'vm' && changed[5] === 'role') {
                formData.environments.map((env, envIdx) => {
                    if (envIdx !== changed[1]) { // diff environment
                        formData.environments[envIdx].compute[changed[3]].vm.role = formData.environments[changed[1]].compute[changed[3]].vm.role;
                        //formData.environments[envIdx].compute[changed[3]].vm.role.parse(formData.environments[changed[1]].compute[changed[3]].vm.role);
                        this.props.addCompute();
                    }
                });

            }
        }
        catch (e) {
            console.log('no vms role');
        }


        //// making sure all ilb config should have same values to all environments

        try {
            changed[1] = parseInt(changed[1]); // environment idx
            changed[3] = parseInt(changed[3]); // compute idx
            changed[6] = parseInt(changed[6]); // ilb idx

            if (changed[0] === 'environments' && changed[2] === 'compute') {
                formData.environments.map((env, envIdx) => {
                    if (envIdx !== changed[1]) { // diff environment
                        formData.environments[envIdx].compute[changed[3]].vm.loadBalancer[changed[6]] = JSON.parse(JSON.stringify(formData.environments[changed[1]].compute[changed[3]].vm.loadBalancer[changed[6]]));
                    }
                });

            }
        }
        catch (e) {
            console.log('no ilb');
        }





        this.props.setFormIaas(formData);
    }



    render() {
        if (this.props.error !== null) {
            this.props.showModal(true, 'Error', 'danger', this.props.error);
        }
        let previewing = this.props.previewing ? (<Modal show={this.props.previewing}
            className='full-modal'
            closeModal={this.props.closePreviewData}
            title="Preview">
            <Preview data={this.props.formIaas}
                previewing={this.props.previewing}
            />
        </Modal>) : null;


        let overwriting = this.props.formIaas.status === 'importing' && this.props.formIaas.azureObjectID > 0 ?
            (<Alert color="danger">
                <h4 className="alert-heading">Attention:</h4>
                <p>
                    You are overwriting existing data
                </p>
            </Alert>) : null;


        return (

            <div className="col-md-12">
                <DataGridToolbar title="Infrastructure Deployment Request">
                    <DataGridToolbarButtonsGroup>

                        <DataGridToolbarButton
                            outline
                            bsStyle='secondary'
                            onClick={() => this.props.goToList()}
                            glyph="list-alt"
                            glyphColor='#4189C7'
                            caption=" List All Requests"
                            captionAlwaysVisible
                        />
                        <DataGridToolbarButtonHistory onClick={(e) => this.props.showHistory(e.ctrlKey)}/>
                    </DataGridToolbarButtonsGroup>
                </DataGridToolbar>
                <br />

                {overwriting}

                {previewing}

                {
                    this.props.modal.show ? (<Modal show={this.props.modal.show}
                        closeModal={this.toggleModal}
                        title={this.props.modal.title}
                    >
                        <Alert color={this.props.modal.color} transition={{ baseClass: '', timeout: 500 }}>

                            <h4>
                                <Fragment>{this.props.modal.text}</Fragment>
                            </h4>
                        </Alert>
                    </Modal>) : null
                }
                

                {this.error}

                <Form id="myForm" onSubmit={this.handleSubmit} onChange={this.handleChange} type="post" className="needs-validation">
                    <FormGroup row>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="assetID">Asset ID</Label>
                                <Input
                                    defaultValue={this.props.formIaas.assetID}
                                    type="text"
                                    name="assetID"
                                    id="assetID"
                                    placeholder="Asset ID"
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="company">Company</Label>
                                <Input
                                    defaultValue={this.props.formIaas.company}
                                    type="text"
                                    name="company"
                                    id="company"
                                    className={this.props.validators.company === false ? 'is-invalid' : null}
                                    placeholder="Company"
                                    readOnly
                                />
                            </FormGroup>
                        </Col>

                        <Col md={3}>
                            <FormGroup>
                                <Label for="applicationName">Application Name</Label>
                                <Input
                                    type="text"
                                    name="applicationName"
                                    id="applicationName"
                                    placeholder="Application Name"
                                    defaultValue={this.props.formIaas.applicationName}
                                    className={this.props.validators.applicationName === false ? 'is-invalid' : null}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>

                        <Col md={3}>
                            <FormGroup>
                                <Label for="resourceGroup">Resource Group</Label>
                                <Input
                                    defaultValue={this.props.formIaas.resourceGroup}
                                    type="text"
                                    name="resourceGroup"
                                    id="resourceGroup"
                                    placeholder="Resource Group"
                                    required
                                    valid={this.props.formIaas.resourceGroup ? (this.props.formIaas.resourceGroup.length <= 0 ? false : true) : false}
                                    invalid={this.props.formIaas.resourceGroup ? (this.props.formIaas.resourceGroup.length <= 0 ? true : false) : true}
                                />
                                <div className="invalid-feedback help-block">
                                    Provide a Resource Group Name
                                </div>

                            </FormGroup>
                        </Col>

                        <Col md={1}>
                            <FormGroup>
                                <Label for="businessPriority">Priority</Label>
                                <Input
                                    type="select"
                                    name="businessPriority"
                                    id="businessPriority"
                                    defaultValue={this.props.formIaas.businessPriority}
                                    required
                                    valid={this.props.formIaas.businessPriority ? (this.props.formIaas.businessPriority.length <= 0 ? false : true) : false}
                                    invalid={this.props.formIaas.businessPriority ? (this.props.formIaas.businessPriority.length <= 0 ? true : false) : true}
                                >
                                    <option value=""> Choose an option </option>
                                    {
                                        this.props.getEnumValues('businessPriorityEnum').map(c => {
                                            return <option key={c.code} value={c.code}>{c.description}</option>;
                                        })
                                    }

                                </Input>
                            </FormGroup>
                        </Col>


                        <Col md={3}>
                            <FormGroup>
                                <Label for="projectManager">Project Manager</Label>
                                <Input
                                    type="text"
                                    name="projectManager"
                                    id="projectManager"
                                    placeholder="Project Manager"
                                    defaultValue={this.props.formIaas.projectManager}
                                    required
                                    valid={this.props.formIaas.projectManager ? (this.props.formIaas.projectManager.length <= 0 ? false : true) : false}
                                    invalid={this.props.formIaas.projectManager ? (this.props.formIaas.projectManager.length <= 0 ? true : false) : true}
                                />
                                <div className="invalid-feedback help-block">
                                    Provide a Project Manager Name
                                </div>
                            </FormGroup>
                        </Col>

                        <Col md={2}>
                            <FormGroup>
                                <Label for="regions">Region</Label>
                                <Input
                                    type="select"
                                    name="regions"
                                    id="regions"
                                    defaultValue={this.props.formIaas.regions}
                                    required
                                    valid={this.props.formIaas.regions ? (this.props.formIaas.regions.length <= 0 ? false : true) : false}
                                    invalid={this.props.formIaas.regions ? (this.props.formIaas.regions.length <= 0 ? true : false) : true}
                                >
                                    <option value=""> Choose an option </option>
                                    {
                                        this.props.getEnumValues('regionsEnum').map(c => {
                                            return <option key={c.code} value={c.code}>{c.description}</option>;
                                        })
                                    }
                                </Input>
                                <div className="invalid-feedback help-block">
                                    Select a Region
                                </div>
                            </FormGroup>
                        </Col>


                        <Col md={2}>
                            <FormGroup>
                                <Label for="domain">Domain</Label>
                                <Input
                                    type="select"
                                    name="domain"
                                    id="domain"
                                    defaultValue={this.props.formIaas.domain}
                                    required
                                    valid={this.props.formIaas.domain ? (this.props.formIaas.domain.length <= 0 ? false : true) : false}
                                    invalid={this.props.formIaas.domain ? (this.props.formIaas.domain.length <= 0 ? true : false) : true}
                                    
                                >
                                    <option value=""> Choose an option </option>
                                    {
                                        this.props.getEnumValues('companyEnum').map(c => {
                                            if ((this.props.formIaas.company === 'DAS' && (c.code === 'phiext' || c.code === 'phibred')) ||
                                                (this.props.formIaas.company === 'SPECCO' && (c.code === 'ddnet1' || c.code === 'ddnet2'))) {
                                                return <option key={c.code} value={c.code} >{c.description}</option>;
                                            }
                                        })
                                    }
                                </Input>
                                <div className="invalid-feedback help-block">
                                    Select a Domain
                                </div>
                            </FormGroup>
                        </Col>

                        <Col md={2}>
                            <FormGroup>
                                <Label for="classification">Classification</Label>
                                <Input
                                    type="select"
                                    name="classification"
                                    id="classification"
                                    defaultValue={this.props.formIaas.classification}
                                    required
                                    valid={this.props.formIaas.classification ? (this.props.formIaas.classification.length <= 0 ? false : true) : false}
                                    invalid={this.props.formIaas.classification ? (this.props.formIaas.classification.length <= 0 ? true : false) : true}
                                >
                                    <option value=""> Choose an option </option>
                                    {
                                        this.props.getEnumValues('classificationEnum').map(c => {
                                            return <option key={c.code} value={c.code}>{c.description}</option>;
                                        })
                                    }
                                </Input>
                                <div className="invalid-feedback help-block">
                                    Select a Classification
                                </div>
                            </FormGroup>
                        </Col>


                        <Col md={3}>
                            <Label>Status Resource Group</Label>

                            <Label check className="radioInline">
                                <Input type="radio"
                                    name="resourceGroupStatus"
                                    value='Missing'
                                    defaultChecked={this.props.formIaas.resourceGroupStatus === 'missing' || this.props.formIaas.resourceGroupStatus === undefined}
                                    className={this.props.validators.resourceGroupStatus === false ? 'is-invalid' : null}
                                />
                                {' '} Missing
                            </Label>
                            <Label check className="radioInline">
                                <Input type="radio"
                                    name="resourceGroupStatus"
                                    value='Requested'
                                    defaultChecked={this.props.formIaas.resourceGroupStatus === 'requested'}
                                    className={this.props.validators.resourceGroupStatus === false ? 'is-invalid' : null}
                                />
                                {' '}Requested
                            </Label>
                            <Label check className="radioInline">
                                <Input type="radio"
                                    name="resourceGroupStatus"
                                    value='Existing'
                                    defaultChecked={this.props.formIaas.resourceGroupStatus === 'existing'}
                                    className={this.props.validators.resourceGroupStatus === false ? 'is-invalid' : null}
                                />
                                {' '}Existing
                            </Label>
                        </Col>


                        <Col md={1}>
                            <FormGroup check inline>
                                <Label check className="checkBoxMiddleLine">
                                    <Input type="checkbox"
                                        name="sqlGroupRequired"
                                        id="sqlGroupRequired"
                                        defaultValue={this.props.formIaas.sqlGroupRequired}
                                        defaultChecked={this.props.formIaas.sqlGroupRequired}
                                        className={this.props.validators.sqlGroupRequired === false ? 'is-invalid' : null}
                                    />{' '}
                                    Azure SQL Groups
                                </Label>
                            </FormGroup>
                        </Col>

                        <Col md={1}>
                            <FormGroup check inline>
                                <Label check className="checkBoxMiddleLine">
                                    <Input type="checkbox" name="subnetRequired"
                                        id="subnetRequired"
                                        defaultValue={this.props.formIaas.subnetRequired}
                                        defaultChecked={this.props.formIaas.subnetRequired}
                                        className={this.props.validators.subnetRequired === false ? 'is-invalid' : null}
                                    />{' '}
                                    Subnet Required
                                </Label>
                            </FormGroup>
                        </Col>

                    </FormGroup>


                    <FormGroup row>
                        <Col md={4}>
                            <Label for="groupsReadOnly">Groups with Read access</Label>
                            <Input type="textarea"
                                name="groupsReadOnly"
                                id="groupsReadOnly"
                                defaultValue={this.props.groupsReadOnly}
                                className={this.props.validators.groupsReadOnly === false ? 'is-invalid' : null}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col md={2}>
                            <legend>Environments</legend>
                        </Col>
                        <Col md={2}>
                            <Input
                                type="select"
                                name="environmentsDropdown"
                                id="environmentsDropdown"
                            >
                                <option value=""> Choose an option </option>
                                {
                                    this.props.getEnumValues('environmentsEnum').map(c => {

                                        if (this.props.formIaas.environments) {
                                            if (!this.props.formIaas.environments.filter(e => e.environmentName === c.code)[0]) {
                                                return <option key={c.code} value={c.code}>{c.description}</option>;
                                            }
                                        }
                                        else {
                                            return <option key={c.code} value={c.code}>{c.description}</option>;
                                        }
                                    })
                                }
                            </Input>
                        </Col>
                        <Col md={2}>
                            <Button type="button" color="primary" onClick={() => this.props.addEnvironment()}><i className="fa fa-plus" /> Add </Button>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        {
                            this.props.formIaas.environments ?
                                (
                                    <Col md={12}>
                                        <Nav tabs>
                                            {this.props.formIaas.environments.map((e, envIdx) => {
                                                return (
                                                    <NavItem key={e.environmentName}
                                                        className={classnames({ active: this.props.activeTab.filter(p => p.name === 'environment')[0] ? this.props.activeTab.filter(p => p.name === 'environment')[0].tabId === e.environmentName : false })}>
                                                        <NavLink onClick={() => { this.props.toggleTab('environment', e.environmentName); }} >
                                                            {e.environmentName}
                                                        </NavLink>
                                                    </NavItem>
                                                );
                                            })}
                                        </Nav>
                                        <TabContent activeTab={this.props.activeTab}>
                                            {this.props.formIaas.environments.map((e, envIdx) => {
                                                return (
                                                    <TabPane key={e.environmentName + '-tab'}
                                                        tabId={e.environmentName}
                                                        className={classnames({ active: this.props.activeTab.filter(p => p.name === 'environment')[0] ? this.props.activeTab.filter(p => p.name === 'environment')[0].tabId === e.environmentName : false })}>
                                                        <Row>
                                                            <Col sm="12">
                                                                <Environments
                                                                    envIdx={envIdx}
                                                                    environmentName={e.environmentName}

                                                                    getEnumValues={this.props.getEnumValues}
                                                                    toggleTab={this.props.toggleTab}


                                                                    validation={this.validation}
                                                                    duplicateEnvironment={this.props.duplicateEnvironment}
                                                                    removeEnvironment={this.props.removeEnvironment}

                                                                    addCompute={this.props.addCompute}
                                                                    duplicateCompute={this.props.duplicateCompute}
                                                                    removeCompute={this.props.removeCompute}

                                                                    addDataDisk={this.props.addDataDisk}
                                                                    removeDataDisk={this.props.removeDataDisk}

                                                                    addLoadBalancer={this.props.addLoadBalancer}
                                                                    removeLoadBalancer={this.props.removeLoadBalancer}

                                                                    addProbe={this.props.addProbe}
                                                                    removeProbe={this.props.removeProbe}

                                                                    addPortMapping={this.props.addPortMapping}
                                                                    removePortMapping={this.props.removePortMapping}

                                                                    handleChange={this.handleChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                );
                                            })}
                                        </TabContent>
                                    </Col>
                                )
                                : null
                        }
                    </FormGroup>



                    {
                        this.props.formIaas.status === 'importing' &&
                            authorizationService.authorize(AuthorizationPolicies.CloudAllowImplement) ?
                            (
                                <FormGroup row>
                                    <Col md={4}>
                                        <Button id='preview' type="button" color="info" onClick={() => this.props.openPreviewData()}>Preview</Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button type="button" onClick={() => this.saveImported()} id='import' color="success" >Finish Import</Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button id='request' onClick={this.props.goToImport} color="info" >Reload Files</Button>
                                    </Col>
                                </FormGroup>
                            ) : null

                    }
                    {
                        (this.props.formIaas.status === 'imported' ||
                            this.props.formIaas.status === 'draft' ||
                            this.props.formIaas.status === 'new') &&
                            authorizationService.authorize(AuthorizationPolicies.CloudAllowRequest) &&
                            this.props.formIaas.environments ?
                            (<FormGroup row>
                                <Col md={4}>
                                    <Button id='preview' type="button" color="info" onClick={() => this.props.openPreviewData()}>Preview</Button>
                                </Col>
                                <Col md={4}>
                                    <Button type="button" onClick={() => this.props.saveDraft()} id='draft' color="info" >Save as Draft</Button>
                                </Col>

                                <Col md={4}>
                                    <Button type="submit" id='request' color="success" >Request Infrastructure Deployment</Button>
                                </Col>
                            </FormGroup>)
                            : null

                    }

                    {

                        this.props.formIaas.status === 'requested' &&
                            authorizationService.authorize(AuthorizationPolicies.CloudAllowImplement) &&
                            this.props.formIaas.environments ?
                            (
                                <FormGroup row>
                                    <Col md={4}>
                                        <Button id='preview' type="button" color="info" onClick={() => this.props.openPreviewData()}>Preview</Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button type="button" onClick={() => this.props.saveDraft()} id='draft' color="info" >Change to Draft</Button>
                                    </Col>

                                    <Col md={4}>
                                        <Button type="submit" id='request' color="success" >Finish Infrastructure Deployment</Button>
                                    </Col>
                                </FormGroup>
                            ) : null
                    }

                    {
                        this.footerMessage !== '' ? (
                            <Alert color='danger' transition={{ baseClass: '', timeout: 500 }}>
                                <p>
                                    {this.footerMessage}
                                </p>
                            </Alert>

                        ) : null
                    }

                </Form >

            </div >
        );
    }

}

export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withAITracking(ai.reactPlugin, CloudIaaSForm, "CloudIaasForm"));

//<Button type="submit" id='request' className={` ${this.props.formValid ? '' : 'disabled'}`} color="success" >Request Infrastructure Deployment</Button>