import React from 'react';
import { IfAuthorized, AuthorizationPolicies } from '../BaseComponents/Authorization';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarCheckBox,
    DataGridToolbarSearchBox,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonAdd,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonDelete,
    DataGridToolbarButtonImport,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem
} from '../BaseComponents';
import LegalEntitiesSeparatedEdit from './LegalEntitiesSeparatedEdit';
import DuPont from '../../jscomponents/DuPontApp';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import ProjectRequired from '../BaseComponents/ProjectRequired';

import { ai } from '../../TelemetryService';

let properties;

const Toolbar = () => {
    const { tempCurrentSpin, crossSpinDataHidden, multiSpin } = DuPont.App.Config;
    var currentSpin = multiSpin ? properties.match.params.project : tempCurrentSpin;
    currentSpin = currentSpin.toUpperCase();
    console.log(`currentSpin: ${currentSpin}`);

    return (
        <DataGridToolbar
            title={currentSpin + " Legal Entities Separated"}
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarCheckBox
                id="showDeletedCheckBox"
                filterOrder="2"
                filterWhenChecked="all"
                filterWhenUnchecked="enabled"
                caption="Show Deleted"
            />
            {/*<DataGridToolbarSearchBox
                filterOrder="2"
                filter="search"
            />*/}
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarDropdownButton
                    caption="ALL"
                    controlClass={crossSpinDataHidden ? "d-none" : null}
                    glyph="filter"
                    glyphColor='#4189C7'
                    filterOrder="1"
                    filter={crossSpinDataHidden ? currentSpin : "all"}
                    filterReqMsg="Please choose a filter!"
                >
                    <DataGridToolbarDropdownItem filter="DAS" href="#" caption='DAS' />
                    <DataGridToolbarDropdownItem filter="SPECCO" href="#" caption='SPECCO' />
                    <li className="dropdown-divider" />
                    <DataGridToolbarDropdownItem filter="all" href="#" caption='ALL' />
                </DataGridToolbarDropdownButton>
                <DataGridToolbarButtonRefresh />
                <IfAuthorized policy={AuthorizationPolicies.LegalEntitiesAllowManage}>
                    <DataGridToolbarButtonAdd />
                    <DataGridToolbarButtonEdit />
                    <DataGridToolbarButtonDelete />
                    <DataGridToolbarButtonImport />
                </IfAuthorized>
                <DataGridToolbarButtonExport />
                <DataGridToolbarButtonHistory />               
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const LegalEntitiesSeparated = (props) => {
    const { multiSpin } = DuPont.App.Config;

    if (multiSpin && !props.match.params.project) {
        return (
            <ProjectRequired />
        );
    }

    properties = props;

    return (
        <DataGrid model='legalEntitiesSeparated'>
            <LegalEntitiesSeparatedEdit />
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, LegalEntitiesSeparated, "LegalEntitiesSeparated");