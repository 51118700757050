import React from 'react';
import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarCheckBox,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonExport,

} from '../BaseComponents';
import DuPont from '../../jscomponents/DuPontApp';
import ProjectRequired from '../BaseComponents/ProjectRequired';

import AppStatusEdit from './AppStatusEdit';
import EmailMessageEdit from './EmailMessageEdit';
import ProvisionInfraStatusEdit from './ProvisionInfraStatusEdit';
import SiteStatusEdit from './SiteStatusEdit';
import UserEdit from './UserEdit';
import DummyEdit from './DummyEdit';
import ProjectContext from '../../jscomponents/ProjectContext';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

const Toolbar = (props) => {
    return (
        <DataGridToolbar
            title={props.title}
        >
            <span className="d-none" data-filter-order="1" data-filter={props.id} />
            <DataGridToolbarCheckBox
                id="showChangesOnlyCheckBox"
                filterOrder="1"
                defaultChecked
                caption="Show Changes Only"
            />
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarButtonRefresh />
                {props.showEdit &&
                    <DataGridToolbarButtonEdit />
                }
                <DataGridToolbarButtonExport />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const History = (props) => {
    const { model, id } = props.match.params;
    const { multiSpin } = DuPont.App.Config;

    if (multiSpin && !props.match.params.project && !ProjectContext.requireProjectException(model)) {
        return (
            <ProjectRequired />
        );
    }

    let editComponent = null;
    switch (model.toLowerCase()) {
        case "appstatussaas":
        case "appstatusnonsaas": editComponent = <AppStatusEdit />; break;
        case "emailmessage": editComponent = <EmailMessageEdit />; break;
        case "provisionInfraStatus": editComponent = <ProvisionInfraStatusEdit />; break;
        case "siteStatus": editComponent = <SiteStatusEdit />; break;
        case "user": editComponent = <UserEdit />; break;
        case "dummy": editComponent = <DummyEdit />; break;
    }

    return (
        <DataGrid model={`${model}#history`}>
            {editComponent}
            <Toolbar title={`${model} History`} id={id} showEdit={editComponent ? true: null} />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, History, "History");