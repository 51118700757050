import $ from 'jquery';
import Slick from '../../src/slick.core';

export default CellExternalCopyManager;

function CellExternalCopyManager(options) {
    /*
      This manager enables users to copy/paste data from/to an external Spreadsheet application
      such as MS-Excel® or OpenOffice-Spreadsheet.
      
      Since it is not possible to access directly the clipboard in javascript, the plugin uses
      a trick to do it's job. After detecting the keystroke, we dynamically create a textarea
      where the browser copies/pastes the serialized data. 
      
      options:
        copiedCellStyle : sets the css className used for copied cells. default : "copied"
        copiedCellStyleLayerKey : sets the layer key for setting css values of copied cells. default : "copy-manager"
        dataItemColumnValueExtractor : option to specify a custom column value extractor function
        dataItemColumnValueSetter : option to specify a custom column value setter function
        clipboardCommandHandler : option to specify a custom handler for paste actions
        includeHeaderWhenCopying : set to true and the plugin will take the name property from each column (which is usually what appears in your header) and put that as the first row of the text that's copied to the clipboard
        bodyElement: option to specify a custom DOM element which to will be added the hidden textbox. It's useful if the grid is inside a modal dialog.
        onCopyInit: optional handler to run when copy action initializes
        onCopySuccess: optional handler to run when copy action is complete
        newRowCreator: function to add rows to table if paste overflows bottom of table
        readOnlyMode: suppresses paste
    */
    var _grid;
    var _self = this;
    var _copiedRanges;
    var _options = options || {};
    var _copiedCellStyleLayerKey = _options.copiedCellStyleLayerKey || "copy-manager";
    var _copiedCellStyle = _options.copiedCellStyle || "copied";
    var _clearCopyTI = 0;
    var _bodyElement = _options.bodyElement || document.body;
    var _onCopyInit = _options.onCopyInit || null;
    var _onCopySuccess = _options.onCopySuccess || null;
    var _onPasteSuccess = _options.onPasteSuccess || null;

    var _gridComponent = _options.gridComponent;

    var keyCodes = {
        'C': 67,
        'V': 86,
        'ESC': 27,
        'INSERT': 45
    };

    function init(grid) {
        _grid = grid;
        _grid.onKeyDown.subscribe(handleKeyDown);

        // we need a cell selection model
        var cellSelectionModel = grid.getSelectionModel();
        if (!cellSelectionModel) {
            throw new Error("Selection model is mandatory for this plugin. Please set a selection model on the grid before adding this plugin: grid.setSelectionModel(new Slick.CellSelectionModel())");
        }
        // we give focus on the grid when a selection is done on it.
        // without this, if the user selects a range of cell without giving focus on a particular cell, the grid doesn't get the focus and key stroke handles (ctrl+c) don't work
        //cellSelectionModel.onSelectedRangesChanged.subscribe(function (e, args) {
        //    _grid.focus();
        //});
    }

    function destroy() {
        _grid.onKeyDown.unsubscribe(handleKeyDown);
    }

    function getDataItemValueForColumn(item, columnDef) {
        if (_options.dataItemColumnValueExtractor) {
            return _options.dataItemColumnValueExtractor(item, columnDef);
        }

        var retVal = '';

        // if a custom getter is not defined, we call serializeValue of the editor to serialize
        if (columnDef.editor) {
            var editorArgs = {
                container: $('<p>'),  // a dummy container
                column: columnDef,
                position: { top: 0, left: 0 },  // a dummy position required by some editors
                grid: _grid
            };
            var editor = new columnDef.editor(editorArgs);
            editor.loadValue(item);
            retVal = editor.serializeValue();
            editor.destroy();
        } else {
            retVal = item[columnDef.field];
        }

        return retVal;
    }

    function setDataItemValueForColumn(item, columnDef, value) {
        if (_options.dataItemColumnValueSetter) {
            return _options.dataItemColumnValueSetter(item, columnDef, value);
        }

        // if a custom setter is not defined, we call applyValue of the editor to unserialize
        if (columnDef.editor) {
            var editorArgs = {
                'container': $("body"),  // a dummy container
                'column': columnDef,
                'position': { 'top': 0, 'left': 0 },  // a dummy position required by some editors
                'grid': _grid
            };
            var editor = new columnDef.editor(editorArgs);
            editor.loadValue(item);
            editor.applyValue(item, value);
            editor.destroy();
        } else {
            item[columnDef.field] = value;
        }
    }


    function _createTextArea(innerText) {
        const result = document.createElement('textarea');
        result.style.position = 'absolute';
        result.style.left = '-1000px';
        result.style.top = document.body.scrollTop + 'px';
        result.value = innerText;
        _bodyElement.appendChild(result);
        result.select();

        return result;
    }

    function _decodeTabularData(_grid, ta) {
        const columns = _grid.getColumns();
        const clipText = ta.value;
        const clipRows = clipText.split(/[\n\f\r]/);
        // trim trailing CR if present
        if (clipRows[clipRows.length - 1] == "") { clipRows.pop(); }

        _bodyElement.removeChild(ta);
        const clippedRange = clipRows.map(r => r.split("\t"));
        var selectedCell = _grid.getActiveCell();
        var ranges = _grid.getSelectionModel().getSelectedRanges();
        var selectedRange = ranges && ranges.length ? ranges[0] : null;   // pick only one selection
        var activeRow = null;
        var activeCell = null;

        if (selectedRange) {
            activeRow = selectedRange.fromRow;
            activeCell = selectedRange.fromCell;
        } else if (selectedCell) {
            activeRow = selectedCell.row;
            activeCell = selectedCell.cell;
        } else {
            // we don't know where to paste
            return;
        }

        if (clippedRange.length > 0 && isFirstRowHeader(columns, activeCell, clippedRange[0])) {
            clippedRange.shift();
        }

        var oneCellToMultiple = false;
        var destH = clippedRange.length;
        var destW = clippedRange.length ? clippedRange[0].length : 0;
        if (clippedRange.length === 1 && clippedRange[0].length === 1 && selectedRange) {
            oneCellToMultiple = true;
            destH = selectedRange.toRow - selectedRange.fromRow + 1;
            destW = selectedRange.toCell - selectedRange.fromCell + 1;
        }

        /*
        var availableRows = _grid.getDataLength() - activeRow;
        var addRows = 0;
        if (availableRows < destH) {
            var d = _grid.getData();
            for (addRows = 1; addRows <= destH - availableRows; addRows++)
                d.push({});
            _grid.setData(d);
            _grid.render();
        }
        */

        var overflowsBottomOfGrid = activeRow + destH > _grid.getDataLength();

        if (_grid.getOptions().enableAddRow) {
            if (_options.newRowCreator && overflowsBottomOfGrid) {
                const newRowsNeeded = activeRow + destH - _grid.getDataLength();
                _options.newRowCreator(newRowsNeeded);
            }
        }

        var clipCommand = {

            isClipboardCommand: true,
            clippedRange: clippedRange,
            oldValues: [],
            cellExternalCopyManager: _self,
            _options: _options,
            setDataItemValueForColumn: setDataItemValueForColumn,
            markCopySelection: markCopySelection,
            oneCellToMultiple: oneCellToMultiple,
            activeRow: activeRow,
            activeCell: activeCell,
            destH: destH,
            destW: destW,
            maxDestY: _grid.getDataLength(),
            maxDestX: _grid.getColumns().length,
            h: 0,
            w: 0,

            execute: function () {
                this.h = 0;
                _grid.getData().beginUpdate();
                //console.time('paste');
                for (var y = 0; y < this.destH; y++) {
                    this.oldValues[y] = [];
                    this.w = 0;
                    this.h++;
                    for (var x = 0; x < this.destW; x++) {
                        this.w++;
                        var desty = activeRow + y;
                        var destx = activeCell + x;

                        if (desty < this.maxDestY && destx < this.maxDestX) {
                            //var nd = _grid.getCellNode(desty, destx);
                            const item = _grid.getDataItem(desty);
                            this.oldValues[y][x] = item[columns[destx]['field']];
                            var cellUpdated;
                            if (oneCellToMultiple) {
                                cellUpdated = this.setDataItemValueForColumn(item, columns[destx], clippedRange[0][0]);
                            } else {
                                cellUpdated = this.setDataItemValueForColumn(item, columns[destx], clippedRange[y] ? clippedRange[y][x] : '');
                            }
                            if (cellUpdated) {
                                _grid.updateCell(desty, destx);
                                _grid.onCellChange.notify({
                                    row: desty,
                                    cell: destx,
                                    item: item,
                                    grid: _grid
                                });
                            }
                        }
                    }
                }

                _grid.getData().endUpdate();
                //console.timeEnd('paste');

                var bRange = new Slick.Range(
                    activeRow,                   // 'fromRow':
                    activeCell,                  // 'fromCell':
                    activeRow + this.h - 1,      // 'toRow':
                    activeCell + this.w - 1      // 'toCell':
                );

                this.markCopySelection([bRange]);
                _grid.getSelectionModel().setSelectedRanges([bRange]);
                this.cellExternalCopyManager.onPasteCells.notify({ ranges: [bRange] });
            },

            undo: function () {
                for (var y = 0; y < this.destH; y++) {
                    for (var x = 0; x < this.destW; x++) {
                        var desty = activeRow + y;
                        var destx = activeCell + x;

                        if (desty < this.maxDestY && destx < this.maxDestX) {
                            var nd = _grid.getCellNode(desty, destx);
                            var dt = _grid.getDataItem(desty);
                            if (oneCellToMultiple)
                                this.setDataItemValueForColumn(dt, columns[destx], this.oldValues[0][0]);
                            else
                                this.setDataItemValueForColumn(dt, columns[destx], this.oldValues[y][x]);
                            _grid.updateCell(desty, destx);
                            _grid.onCellChange.notify({
                                row: desty,
                                cell: destx,
                                item: dt,
                                grid: _grid
                            });
                        }
                    }
                }

                var bRange = {
                    'fromCell': activeCell,
                    'fromRow': activeRow,
                    'toCell': activeCell + this.w - 1,
                    'toRow': activeRow + this.h - 1
                };

                this.markCopySelection([bRange]);
                _grid.getSelectionModel().setSelectedRanges([bRange]);
                this.cellExternalCopyManager.onPasteCells.notify({ ranges: [bRange] });

                /* temporary commented out
                if (addRows > 1) {
                    var d = _grid.getData();
                    for (; addRows > 1; addRows--)
                        d.splice(d.length - 1, 1);
                    _grid.setData(d);
                    _grid.render();
                }
                */
            }
        };

        //not ensuring data here anymore; will take care later when data will be ensured to not overwrite changes
        //const doPaste = function () {
        if (_options.clipboardCommandHandler) {
            _options.clipboardCommandHandler(clipCommand);
        }
        else {
            clipCommand.execute();
        }

        if (_onPasteSuccess) _onPasteSuccess();
        //};

        //data should be ensured; otherwise changes done here will be overwritten later when data will be ensured due to scrolling
        //_gridComponent.ensureRowsData({ from: activeRow, to: activeRow + destH - 1 })
        //    .done(doPaste);
    }

    function isFirstRowHeader(columns, activeCell, firstClippedRow) {
        for (var i = 0; i < firstClippedRow.length; i++) {
            if (columns.length <= (activeCell + i)) break;
            var c = columns[activeCell + i];
            if (c.name !== firstClippedRow[i]) return false;
        }
        return true;
    }

    function handleKeyDown(e, args) {
        var ranges;
        if (!_grid.getEditorLock().isActive() || _grid.getOptions().autoEdit) {
            if (e.which == keyCodes.ESC) {
                if (_copiedRanges) {
                    e.preventDefault();
                    clearCopySelection();
                    _self.onCopyCancelled.notify({ ranges: _copiedRanges });
                    _copiedRanges = null;
                }
            }

            //if ((e.which === keyCodes.C || e.which === keyCodes.INSERT) && (e.ctrlKey || e.metaKey) && !e.shiftKey) {    // CTRL+C or CTRL+INS
            if (e.which === keyCodes.C && (e.ctrlKey || e.metaKey)) {    // CTRL+C or CTRL+INS
                if (_onCopyInit) {
                    _onCopyInit.call();
                }
                const includeHeaderWhenCopying = (e.shiftKey || _options.includeHeaderWhenCopying);

                ranges = _grid.getSelectionModel().getSelectedRanges();
                if (ranges.length !== 0) {
                    _gridComponent
                        .ensureData(ranges[0])
                        .done(() => doCopy(ranges, includeHeaderWhenCopying));

                    if (!window.clipboardData) {
                        e.stopImmediatePropagation();
                        e.preventDefault();
                        return false;
                    }
                }
            }
            else if (!_options.readOnlyMode && (
                (e.which === keyCodes.V && (e.ctrlKey || e.metaKey) && !e.shiftKey)
                || (e.which === keyCodes.INSERT && e.shiftKey && !e.ctrlKey)
            )) {    // CTRL+V or Shift+INS
                const pasteTextArea = _createTextArea('');

                setTimeout(function () {
                    _decodeTabularData(_grid, pasteTextArea);
                }, 100);

                return false;
            }
        }
    }

    function doCopy(ranges, includeHeaderWhenCopying) {
        _copiedRanges = ranges;
        markCopySelection(ranges);
        _self.onCopyCells.notify({ ranges: ranges });

        var columns = _grid.getColumns();
        var clipText = "";

        for (let rangeIdx = 0; rangeIdx < ranges.length; rangeIdx++) {
            const range = ranges[rangeIdx];
            var clipTextRows = [];
            for (let row = range.fromRow; row < range.toRow + 1; row++) {
                var clipTextCells = [];
                var dt = _grid.getDataItem(row);

                if (clipTextRows == "" && includeHeaderWhenCopying) {
                    var clipTextHeaders = [];
                    for (var cell = range.fromCell; cell < range.toCell + 1; cell++) {
                        if (columns[cell].name.length > 0)
                            clipTextHeaders.push(columns[cell].name);
                    }
                    clipTextRows.push(clipTextHeaders.join("\t"));
                }

                for (let cell = range.fromCell; cell < range.toCell + 1; cell++) {
                    clipTextCells.push(getDataItemValueForColumn(dt, columns[cell]));
                }
                clipTextRows.push(clipTextCells.join("\t"));
                if (row % 100 === 0) console.log('copy row: ', row);
            }
            clipText += clipTextRows.join("\r\n") + "\r\n";
        }

        if (window.clipboardData) {
            window.clipboardData.setData("Text", clipText);
        }
        else {
            var focusEl = document.activeElement;

            const copyTextArea = _createTextArea(clipText);
            //copyTextArea.focus();

            //console.log(copyTextArea.value);
            const copyResult = document.execCommand('copy');
            console.log('execCommand copy result: ', copyResult);

            setTimeout(function () {
                _bodyElement.removeChild(copyTextArea);
                // restore focus
                if (focusEl)
                    focusEl.focus();
                else
                    console.log("Not element to restore focus to after copy?");

            }, 100);

            if (_onCopySuccess) {
                var rowCount = 0;
                // If it's cell selection, use the toRow/fromRow fields
                if (ranges.length === 1) {
                    rowCount = (ranges[0].toRow + 1) - ranges[0].fromRow;
                }
                else {
                    rowCount = ranges.length;
                }
                _onCopySuccess.call(this, rowCount);
            }

            if (!copyResult) alert('Copy was unsuccessful!');
        }
    }

    function markCopySelection(ranges) {
        clearCopySelection();

        var columns = _grid.getColumns();
        var hash = {};
        for (var i = 0; i < ranges.length; i++) {
            for (var j = ranges[i].fromRow; j <= ranges[i].toRow; j++) {
                hash[j] = {};
                for (var k = ranges[i].fromCell; k <= ranges[i].toCell && k < columns.length; k++) {
                    hash[j][columns[k].id] = _copiedCellStyle;
                }
            }
        }
        _grid.setCellCssStyles(_copiedCellStyleLayerKey, hash);
        clearTimeout(_clearCopyTI);
        _clearCopyTI = setTimeout(function () {
            _self.clearCopySelection();
        }, 2000);
    }

    function clearCopySelection() {
        _grid.removeCellCssStyles(_copiedCellStyleLayerKey);
    }

    Object.assign(this, {
        init,
        destroy,
        clearCopySelection,
        handleKeyDown,

        onCopyCells: new Slick.Event(),
        onCopyCancelled: new Slick.Event(),
        onPasteCells: new Slick.Event()
    });
}