import React from 'react';
import goLiveApp from '../../jscomponents/Main';

const InvalidAddress = () => {
    goLiveApp.loaderHide();
    return (
        <div>
            <h2>Invalid Address</h2>
            <p>Page not found.</p>
        </div>
    );
};

export default InvalidAddress;