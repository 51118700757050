import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';
import { CustomInput, Form, FormGroup, Label } from 'reactstrap';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

import qs from 'query-string';
import goLiveApp from '../../jscomponents/Main';

class AdminSignIn extends Component {

    componentDidMount() {
        const authCode = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code;
        if (authCode) {
            this.props.setAmsAccess(authCode);
            this.props.history.push(this.props.location.pathname);
        } else {
            this.props.checkAmsAccess();
        }
    }

    render() {
        if (!this.props.loading) goLiveApp.loaderHide();
        return (
            <Form>
                <FormGroup>
                    <Label for="exampleCheckbox">Tokens</Label>
                    <div>
                        <CustomInput type="checkbox" id="accessToken" disabled  checked={!!this.props.accessToken} label="Access Token" />
                        <CustomInput type="checkbox" id="refreshToken" disabled checked={!!this.props.refreshToken} label="Refresh Token" />
                    </div>
                </FormGroup>
            </Form>
        );
    }
};

export default connect(
    state => state.storeObjects.amsAccess,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withAITracking(ai.reactPlugin, AdminSignIn, "AdminSignIn"));