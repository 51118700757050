import React, { Component } from 'react';
import { Alert } from 'reactstrap';

import {
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarButton
} from '../../BaseComponents';

class Summary extends Component {

//    <DataGridToolbarButton
//    onClick={() => this.props.goToRequest()}
//    glyph="plus"
//    glyphColor='green'
//    caption='Add New Request'
///>
//    <DataGridToolbarButton
//        onClick={() => this.props.goToImport()}
//        glyph="import"
//        glyphColor='#4189C7'
//        caption="Import Azure Files"
//    />

    render() {
        return (
            <div>
                <DataGridToolbar
                    title="Azure Infrastructure"
                >
                    <DataGridToolbarButtonsGroup>
                        <DataGridToolbarButton
                            onClick={() => this.props.goToList()}
                            glyph="list-alt"
                            glyphColor='#4189C7'
                            caption="Return to List"
                        />
                        
                    </DataGridToolbarButtonsGroup>
                </DataGridToolbar>
                <br />

                <Alert color={this.props.color} transition={{ baseClass: '', timeout: 500 }}>
                    <h4 className="alert-heading">{this.props.title}</h4>
                    <p>
                        {this.props.message}
                    </p>
                </Alert>

                
            </div>
        );

    }

}

export default Summary;