import React from 'react';
import { Route, Switch } from 'react-router';
import { InvalidAddress, AccessDenied, AuthorizationPolicies, authorizationService, AdminSignIn } from './BaseComponents/Authorization';

import Home from './Home';
import CloudIaas from './CloudApp/CloudIaas';
import CloudPaas from './CloudApp/CloudPaas';


import {
    Users,
    UserPermissions,
    UserBaseProvisioning,
    AppSpins,
    AppStatus,
    ProvisionInfraStatus,
    AppModernizationStatus,
    UserManagement,
    History,
    Sites,
    SiteStatus,
    LookupValues,
    DbFiles,
    Errors,
    SpinProgram,
    ServiceDeliveryWorkPlan,
    EmailMessages,
    NSGList,
    Dummy
} from './DataGrids';
import LegalEntities from './DataGrids/LegalEntities';
import LegalEntitiesFinance from './DataGrids/LegalEntitiesFinance';
import SitesExtended from './DataGrids/SitesExtended';
import LegalEntitiesSeparated from './DataGrids/LegalEntitiesSeparated';
import LegalEntitiesSeparatedFinance from './DataGrids/LegalEntitiesSeparatedFinance';
import TechTransformation from './DataGrids/TechTransformation';

import SapUserRequests from './DataGrids/SapUserRequests';
import Projects from './DataGrids/Projects';
import AppMnAs from './DataGrids/AppMnAs';
import AppMnAProjectScope from './DataGrids/AppMnAProjectScope';
import AppMnADisposition from './DataGrids/AppMnADisposition';

const RestrictedRoute = ({ component: Component, policy, ...rest }) => (
    <Route
        {...rest}
        render={props => authorizationService.authorize(policy)
            ? <Component {...props} />
            : authorizationService.isInitialized
                ? <AccessDenied />
                : null}
    />
);

const Routing = () => {
    console.log(`Routing.render isAuthServInit:${authorizationService.isInitialized}`);
    return (
        <Switch>
            <RestrictedRoute policy={AuthorizationPolicies.AppListUser} path='/:project?/users' component={Users} />
            <RestrictedRoute policy={AuthorizationPolicies.AppListUser} path='/:project?/userpermissions' component={UserPermissions} />
            <RestrictedRoute policy={AuthorizationPolicies.SapUserReqAllowView} path='/sapuserrequests' component={SapUserRequests} />
            <RestrictedRoute policy={AuthorizationPolicies.AdminOnly} path='/:project?/userbaseprovisioning' component={UserBaseProvisioning} />

            <RestrictedRoute policy={AuthorizationPolicies.AppListUser} path='/:project?/appspins' component={AppSpins} />
            <RestrictedRoute policy={AuthorizationPolicies.AppStatusAllowView} path='/:project?/appstatus' component={AppStatus} />
            <RestrictedRoute policy={AuthorizationPolicies.ProvisionInfraStatusUser} path='/:project?/provisioninfrastatus' component={ProvisionInfraStatus} />
            <RestrictedRoute policy={AuthorizationPolicies.AppModernizationStatusUser} path='/:project?/appmodernizationstatus' component={AppModernizationStatus} />

            <RestrictedRoute policy={AuthorizationPolicies.AppSiteAllowView} path='/:project?/sites' component={Sites} />
            <RestrictedRoute policy={AuthorizationPolicies.AppSiteAllowView} path='/:project?/sitestatus' component={SiteStatus} />
            <RestrictedRoute policy={AuthorizationPolicies.AppSiteAllowManage} path='/:project?/sitesextended' component={SitesExtended} />


            <RestrictedRoute policy={AuthorizationPolicies.AppMnAAllowView} path='/:project?/appmna' component={AppMnAs} />
            <RestrictedRoute policy={AuthorizationPolicies.AppMnAAllowView} path='/:project?/appmnascope' component={AppMnAProjectScope} />
            <RestrictedRoute policy={AuthorizationPolicies.AppMnAAllowView} path='/:project?/appmnadisposition' component={AppMnADisposition} />

        <RestrictedRoute policy={AuthorizationPolicies.AMSAdminOnly} path='/adminsignin' component={AdminSignIn} />
            <RestrictedRoute policy={AuthorizationPolicies.AdminOnly} path='/admin/usermanagement' component={UserManagement} />
            <RestrictedRoute policy={AuthorizationPolicies.AdminOnly} path='/lookupvalues' component={LookupValues} />
            <RestrictedRoute policy={AuthorizationPolicies.AdminOnly} path='/dbfiles' component={DbFiles} />
            <RestrictedRoute policy={AuthorizationPolicies.AdminOnly} path='/errors' component={Errors} />
            <RestrictedRoute policy={AuthorizationPolicies.AdminOnly} path='/emailmessages' component={EmailMessages} />
            <RestrictedRoute policy={AuthorizationPolicies.PowerBI} path='/spinprogram' component={SpinProgram} />
            <RestrictedRoute policy={AuthorizationPolicies.PowerBI} path='/techtransformation' component={TechTransformation} />
            <RestrictedRoute policy={AuthorizationPolicies.PowerBI} path='/servicedeliveryworkplan' component={ServiceDeliveryWorkPlan} />
            <RestrictedRoute policy={AuthorizationPolicies.AMSUser} path='/:project?/nsglist' component={NSGList} />

            <RestrictedRoute policy={AuthorizationPolicies.LegalEntitiesAllowView} path='/:project?/legalentities' component={LegalEntities} />
            <RestrictedRoute policy={AuthorizationPolicies.LegalEntitiesAllowView} path='/:project?/legalentitiesseparated' component={LegalEntitiesSeparated} />
            <RestrictedRoute policy={AuthorizationPolicies.LegalEntitiesAllowView} path='/:project?/legalentitiesfinance' component={LegalEntitiesFinance} />
            <RestrictedRoute policy={AuthorizationPolicies.LegalEntitiesAllowView} path='/:project?/legalentitiesseparatedfinance' component={LegalEntitiesSeparatedFinance} />



            <RestrictedRoute policy={AuthorizationPolicies.AppListUser} path='/plants' component={Dummy} />

            <RestrictedRoute policy={AuthorizationPolicies.AdminOnly} path='/dummy' component={Dummy} />

            <RestrictedRoute policy={AuthorizationPolicies.AdminOnly} path='/:project?/projects' component={Projects} />
            <RestrictedRoute policy={AuthorizationPolicies.AdminOnly} path='/projects' component={Projects} />

            <RestrictedRoute policy={AuthorizationPolicies.CloudAllowView} path='/cloudiaas' component={CloudIaas} />
            <RestrictedRoute policy={AuthorizationPolicies.CloudAllowView} path='/cloudpaas' component={CloudPaas} />

            <RestrictedRoute policy={AuthorizationPolicies.HistoryViewer} path='/:project?/hist/:model/:id' component={History} />

            <RestrictedRoute policy={AuthorizationPolicies.User} exact path='/' component={Home} />
            <RestrictedRoute policy={AuthorizationPolicies.AppListUser} exact path='/:project?/' component={Home} />

            <InvalidAddress />
        </Switch>
    );
};

export default Routing;