import React, { Component } from 'react';
import './EditModal.css';
import $ from 'jquery';

class EditModal extends Component {

    componentDidMount() {
        $('.modal-edit .modal-dialog .modal-content')
            .draggable({ handle: ".modal-header" })
            //.resizable({
            //    //alsoResize: ".modal-dialog",
            //    minHeight: 300,
            //    minWidth: 300
            //})
            ;
    }

    render() {
        return (
            <div className="modal fade modal-edit" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby={this.props.id} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <form className="needs-validation" noValidate>
                            <div className="modal-header">
                                <h5 className="modal-title">{this.props.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.props.children}
                            </div>
                            <div className="modal-footer">
                                <label className="small time-text" style={{ display: 'none' }} />
                                <button type="button" className="btn btn-sm btn-danger btn-error-editmodal d-none" data-container="body" data-toggle="popover" title="Errors">
                                    Errors
                                    <span className="badge badge-light" style={{ color: 'indianred' }}>0</span>
                                    <span className="sr-only">errors</span>
                                </button>
                                <div className="progress" style={{ width: '100px', display: 'none' }}>
                                    <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" />
                                </div>
                                <label className="small progress-text" />
                                <div style={{ visibility: 'hidden', flexGrow: '1' }}>ie_fix</div>
                                <button type="button" className="btn btn-sm btn-primary btn-save">Save</button>
                                <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
};

export default EditModal;