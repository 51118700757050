import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/storeObject';

import { Button, Col, FormGroup, Label, Input } from 'reactstrap';

class PortMapping extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        var divStyle = {
            float: 'left'
        };
        var middleStyle = {
            float: 'left',
            paddingLeft: '25px'
        };


        return (




            this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings ? (

                this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings.map((p, pIdx) => {
                    return (
                        <span key={'environment.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx}>
                            <FormGroup row >
                                <Col md={2}>
                                    <Label for="protocol">{pIdx === 0 ? 'Protocol' : null}</Label>
                                    <Input
                                        type="select"
                                        name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.protocol'}
                                        id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.protocol'}
                                        onChange={(obj) => this.props.handleChange(obj)}

                                        value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].protocol}
                                    >
                                        <option value='Tcp'>TCP</option>
                                        <option value='Udp'>UDP</option>
                                        <option value='Http'>HTTP</option>
                                    </Input>
                                </Col>
                                <Col md={2}>
                                    <Label for="port">{pIdx === 0 ? 'Port' : null}</Label>
                                    <Input
                                        type="number"
                                        name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.port'}
                                        id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.port'}

                                        valid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].port > 0 ? true : false : false : false : false : false : false}
                                        invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].port <= 0 ? true : false : true : true : true : true : true}
                                        onChange={(obj) => this.props.handleChange(obj)}
                                        value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].port}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Label check for="enableFloatingIP">{pIdx === 0 ? 'Floating IP' : null}</Label>
                                    <div style={divStyle}>

                                        <Input type="radio"
                                            name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.enableFloatingIP'}
                                            value='true'
                                            defaultChecked={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].enableFloatingIP === 'true' ? true : false}
                                        />
                                        {' '} True
                                    </div>

                                    <div style={middleStyle}>
                                        <Input type="radio"
                                            name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.enableFloatingIP'}
                                            value='false'
                                            defaultChecked={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].enableFloatingIP === 'false' ? true : false}
                                        />
                                        {' '} False
                                    </div>

                                    <div style={divStyle}>
                                        <Input type="radio"
                                            name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.enableFloatingIP'}
                                            value='dynamic'
                                            defaultChecked={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].enableFloatingIP !== 'true' && this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].enableFloatingIP !== 'false' ? true : false}
                                        />
                                        {' '} Dynamic
                                    </div>






                                </Col>


                                <Col md={2}>
                                    <Label for="idleTimeoutInMinutes">{pIdx === 0 ? 'Idle TimeOut' : null}</Label>
                                    <Input
                                        type="number"
                                        name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.idleTimeoutInMinutes'}
                                        id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.idleTimeoutInMinutes'}

                                        valid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].idleTimeoutInMinutes > 0 ? true : false : false : false : false : false : false}
                                        invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].idleTimeoutInMinutes <= 0 ? true : false : true : true : true : true : true}
                                        onChange={(obj) => this.props.handleChange(obj)}
                                        value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].idleTimeoutInMinutes}
                                    />
                                </Col>

                                <Col md={3}>
                                    <Label for="loadDistribution">{pIdx === 0 ? 'Load Distribution' : null}</Label>
                                    <Input
                                        type="select"
                                        name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.loadDistribution'}
                                        id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.loadDistribution'}

                                        valid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].loadDistribution ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].loadDistribution.length > 0 ? true : false : false : false : false : false : false : false}
                                        invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].loadDistribution ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].loadDistribution.length <= 0 ? true : false : true : true : true : true : true : true}
                                        onChange={(obj) => this.props.handleChange(obj)}
                                        value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].loadDistribution}
                                    >
                                        <option value='Default'>Default</option>
                                        <option value='SourceIP'>SourceIP</option>
                                        <option value='SourceIPProtocol'>SourceIPProtocol</option>
                                    </Input>
                                </Col>




                                <Col md={1}>
                                    <Button color="danger" type="button" style={{ marginTop: pIdx === 0 ? '25px' : '5px' }} onClick={() => this.props.removePortMapping(this.props.compIdx, this.props.ilbIdx, this.props.probeIdx, pIdx)}><i className="fa fa-trash" /> </Button>
                                </Col>
                            </FormGroup>
                        </span>
                    );
                })

            ) : null
        );
    }
}

export default PortMapping;

//export default connect(
//    state => state.storeObjects,
//    dispatch => bindActionCreators(actionCreators, dispatch)
//)(PortMapping);


//<Input
//    type="checkbox"
//    className="checkBoxMiddleLine"
//    name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.enableFloatingIP'}
//    id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + this.props.probeIdx + '.portMappings.' + pIdx + '.enableFloatingIP'}
//    defaultChecked={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].enableFloatingIP ? true : false}
//    onChange={(obj) => this.props.handleChange(obj)}
//    value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[this.props.probeIdx].portMappings[pIdx].enableFloatingIP}
///>