import React from 'react';

// add d-none after loadContainer class to disable initial display
const LoadingIndicator = () => (
    <div className="loadContainer">
        <div className="loader">
            <span className="loader-left">
                <span className="loader-bar" />
            </span>
            <span className="loader-right">
                <span className="loader-bar" />
            </span>
            <div className="loader-value">Loading</div>
        </div>
    </div>
);

export default LoadingIndicator;