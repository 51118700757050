import React from 'react';
import { EditModal } from '../BaseComponents/Modals';

const DummyEdit = () => {
    return (
        <EditModal id="dummyEditModal" title="Dummy Edit">
            <div className="form-row">
                <div className="form-group col-md-2 small">
                    <label className="control-label" htmlFor="dummyID">DummyID</label>
                    <input type="number" className="form-control form-control-sm" id="dummyID" name="dummyID" readOnly />
                </div>
                <div className="form-group col-md-2 small">
                    <label className="control-label" htmlFor="dummyKey">Dummy Key</label>
                    <input type="text" className="form-control form-control-sm" id="dummyKey" name="dummyKey" />
                </div>
                <div className="form-group col-md-4 small">
                    <label className="control-label" htmlFor="dummyString">Dummy String</label>
                    <input type="text" className="form-control form-control-sm" id="dummyString" name="dummyString" />
                </div>
                <div className="form-group col-md-1 small">
                    <label className="control-label" htmlFor="dummyNumber">Dummy Number</label>
                    <input type="number" className="form-control form-control-sm" id="dummyNumber" name="dummyNumber" />
                </div>
                <div className="form-group col-md-3 small">
                    <label className="control-label" htmlFor="dummyDropDownID">Dummy DropDown</label>
                    <select type="text" className="form-control form-control-sm" id="dummyDropDownID" name="dummyDropDownID" data-lookup="DummyDropDown" />
                </div>

            </div>
        </EditModal>
    );
};

export default DummyEdit;