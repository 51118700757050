import React from 'react';
import { EditModal } from '../BaseComponents/Modals';

const ApplicationsMnAEdit = () => {
    return (
        <EditModal id="applicationsMnAEditModal" title="Applications MnA ">
            <div className="form-row">

                <div className="form-group col-md-2 small">
                    <label className="control-label" htmlFor="AssetID">Asset ID</label>
                    <input type="text" className="form-control form-control-sm" id="assetID" name="assetID"  />
                </div>

                <div className="form-group col-md-4 small">
                    <label className="control-label" htmlFor="ApplicationName">Application Name</label>
                    <input type="text" className="form-control form-control-sm" id="applicationName" name="applicationName" />
                </div>

                <div className="form-group col-md-4 small">
                    <label className="control-label" htmlFor="SupportedOrganizations">Project</label>
                    <select id="supportedOrganizations" name="supportedOrganizations">
                        <option value="NEPTUNE">NEPTUNE</option>
                    </select>
                </div>
                
            </div>

        </EditModal>
    );
};

export default ApplicationsMnAEdit;