import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal';

let adalConfig;
let authContext;
let withAdalLoginApi;
let amsConfig;

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const getToken = () => adalGetToken(authContext, adalConfig.endpoints.api);

export const initAuthContext = () => {
    return getConfigInfo()
        .then(config => {
            //console.log('initAuthContext');
            amsConfig = config.amsConfig;
            adalConfig = getAdalConfig(config);
            authContext = new AuthenticationContext(adalConfig);
            withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
            return authContext;
        });
};

function getConfigInfo() {
    //console.log('getConfigInfo');
    return fetch('/api/authorization/config')
        .then(response => response.json());
}

function getAdalConfig(config) {
    return {
        //tenant: config.tenantId,  // it's 'common' by default
        clientId: config.clientId,
        endpoints: {
            api: config.clientId
        },
        redirectUri: window.location.origin + (config.callbackPath || ''),
        postLogoutRedirectUri: window.location.origin,
        cacheLocation: 'localStorage'
        //extraQueryParameter: 'scope=openid'
    };
}

export {
    adalConfig,
    authContext,
    withAdalLoginApi,
    amsConfig
};