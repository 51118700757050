import $ from 'jquery';
import { golLiveAppApi } from './Main';
//import DuPont from './DuPontApp';

export default DataLoader;

function DataLoader(options) {

    const OFFSET = 30;

    const _gridComponent = options.gridComponent;
    const _self = this;
    const keyField = options.keyField;
    const multiSelectFieldName = options.multiSelectFieldName;
    //let _evHandler = new Slick.EventHandler();
    let _localIdByKey = {};
    let _loadedRows = {};
    let _loadedFields = {};
    let _multiSelectDataLoaded = false;
    //let _req;
    //let _reqTimeoutId;
    var $loadingIndicator = null;

    function init(grid) {
        //options = $.extend(true, {}, defaults, options);
        //_evHandler.subscribe(grid.onViewPortChanged, handleOnViewPortChanged)

        $loadingIndicator = $("#loadingIndicator");
        if (!$loadingIndicator.length)
            $loadingIndicator = $("<span id='loadingIndicator' class='loading-indicator'><label>Buffering...</label></span>").appendTo(document.body);
        var $g = $(_gridComponent.getContainerNode());

        $loadingIndicator.hide();
    }

    function destroy() {
        //_evHandler.unsubscribeAll();
    }

    //function handleOnViewPortChanged(e, args) {
    //    if (_reqTimeoutId != null) clearTimeout(_reqTimeoutId);
    //    var vp = grid.getViewport();
    //    _reqTimeoutId = setTimeout(() => ensureData(vp.top, vp.bottom), 50);
    //}

    function ensureColumnsData(fields, includeMultiSelectData, isInit) {
        if (isInit) {
            _loadedRows = {};
            _loadedFields = {};
            _localIdByKey = {};
            _multiSelectDataLoaded = false;
        }

        const fieldsToLoad = fields.filter(f => !_loadedFields[f]);
        if (!isInit && fieldsToLoad.length === 0 && (!includeMultiSelectData || _multiSelectDataLoaded)) {
            return $.when();
        } else {
            if (!isInit) showProgress();
            //console.log(`field:${field}`);
            const deferred = $.Deferred();
            getColumnsDataFromServer(fieldsToLoad, includeMultiSelectData, isInit)
                .done(result => {
                    //merge the 2 datasets
                    const newdata = result[0][0];
                    if (result.length === 2) {
                        const multiSelectData = result[1][0].reduce((obj, msItem) => {
                            obj[msItem[keyField]] = msItem[multiSelectFieldName];
                            //obj[msItem[keyField]] = msItem;
                            //delete msItem[keyField];
                            return obj;
                        }, {});

                        newdata.forEach(item => item[multiSelectFieldName] = multiSelectData[item[keyField]] || []);
                    }

                    if (isInit) {
                        for (let idx = 0; idx < newdata.length; ++idx) {
                            _localIdByKey[newdata[idx][keyField]] = idx + 1;
                        }
                    }

                    return deferred.resolveWith(this, [newdata]);
                })
                .fail(deferred.reject);

            return deferred.promise();
        }
    }

    function getColumnsDataFromServer(fieldsToLoad, includeMultiSelectData, isInit) {
        const deferreds = [];

        if (isInit || (fieldsToLoad && fieldsToLoad.length > 0)) {
            fieldsToLoad.unshift(keyField);
            deferreds.push(
                submitRequestToApi({
                    url: _gridComponent.qryByFieldsURL(),
                    type: 'POST',
                    data: JSON.stringify(fieldsToLoad)
                }).done(newdata => {
                    fieldsToLoad.shift();
                    fieldsToLoad.forEach(f => _loadedFields[f] = true);
                })
            );
        }

        if (includeMultiSelectData && !_multiSelectDataLoaded)
            deferreds.push(
                submitRequestToApi({
                    url: _gridComponent.multiSelectDataURL()
                }).done(newdata => {
                    _multiSelectDataLoaded = true;
                })
            );

        return $.when.all(deferreds);
    }

    function ensureRowsData(from, to) {

        const filterKeys = getKeys(from, to);

        if (filterKeys.length === 0) {
            //console.log(`from:${from} to${to} - no req`);
            return $.when();
        } else {
            if (filterKeys.length > 10) showProgress();
            //console.log(`from:${from} to${to} - ${filterIds.length} rows req`);
            const reqData = { filterIds: filterKeys };
            return submitRequestToApi(
                {
                    url: _gridComponent.qryByKeysURL(),
                    type: 'POST',
                    data: JSON.stringify(reqData)
                });
        }
    }

    function getKeys(from, to) {
        const result = [];
        const dataView = _gridComponent.getInnerGrid().getData();

        var startIdx = Math.max(from - OFFSET, 0);
        var endIdx = Math.min(to + OFFSET, dataView.getLength());
        for (var idx = startIdx; idx < endIdx; ++idx) {
            const item = dataView.getItem(idx);
            const key = item[keyField];
            if (key) {
                const localId = item.__id;
                if (!_loadedRows[localId]) {
                    result.push(key);
                    _loadedRows[localId] = true;
                }
            }
        }

        return result;
    }

    function areRowsLoaded(from, to) {
        const dataView = _gridComponent.getInnerGrid().getData();

        for (var idx = from; idx <= to; ++idx) {
            const item = dataView.getItem(idx);
            if (item[keyField] && !_loadedRows[item.__id]) return false;
        }

        return true;
    }

    function submitRequestToApi(requestArgs) {
        return golLiveAppApi.submitRequest(requestArgs);
    }

    //function loadInitialData() {
    //    return submitRequestToApi({ url: _gridComponent.idsURL() })
    //        .done(function (data) {
    //            _loadedRows = {};
    //            _loadedFields = {};
    //            _multiSelectDataLoaded = false;

    //            _localIdByKey = data.reduce((result, value, idx) => {
    //                result[value] = idx + 1;
    //                return result;
    //            }, {});
    //        });
    //}

    function addKey(key, localId) {
        _localIdByKey[key] = localId;
        _loadedRows[localId] = key;
    }

    function removeKey(key, localId) {
        delete _localIdByKey[key];
        delete _loadedRows[localId];
    }

    function showProgress() {
        console.log('showProgress');
        $loadingIndicator.show();
    }

    function hideProgress() {
        console.log('hideProgress');
        $loadingIndicator.fadeOut();
    }

    init();

    return {
        init,
        destroy,
        //loadInitialData,
        ensureRowsData,
        ensureColumnsData,
        getLocalIdByKeyMapping: () => _localIdByKey,
        areRowsLoaded,
        addKey,
        removeKey,
        showProgress,
        hideProgress
    };
}