import $ from 'jquery';
import DuPont from './DuPontApp';
import { golLiveAppApi } from './Main';

class ConfigService {

    constructor() {
        this.config = {};
        this.isInitialized = false;
    }

    initialize() {
        
        if (this.isInitialized) return $.Deferred().resolve();

        if (!this.initializeRequest){
            const requestArgs = {
                url: DuPont.App.SERVER_PATH + 'config/settings'
            };

            this.initializeRequest = golLiveAppApi.submitRequest(requestArgs)
                .done((result) => {
                    this.config = result;
                    DuPont.App.Config = result;
                })
                .always(() => {
                    this.isInitialized = true;
                    this.initializeRequest = null;
                });
        }

        return this.initializeRequest;
    }

    getConfig(key) {
        return this.config[key];
    }
}

const configService = new ConfigService();
export default configService;
