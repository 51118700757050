import React from 'react';
import { EditModal } from '../BaseComponents/Modals';
import './DbFileEdit.css';

const DbFileEdit = () => {
    return (
        <EditModal id="dbFileEditModal" title="DbFile Edit">
            <div className="form-row">
                <div className="form-group col-md-1 small">
                    <label className="control-label" htmlFor="dbFileUpload">Upload</label>
                    <label className="btn btn-sm btn-outline-primary">
                        <i className="fa fa-upload" />
                        <input type="file" className="form-control-file d-none" name="file" id="dbFileUpload"/>
                    </label>
                </div>
                <div className="form-group col-md-11 small">
                    <label className="control-label" htmlFor="fileName">File Name</label>
                    <input type="text" className="form-control form-control-sm" id="fileName" name="fileName" maxLength="500" required />
                    <div className="invalid-feedback help-block">
                        Please provide a valid <b>File Name</b>.
                    </div>
                </div>
            </div>
            <div className="form-row mt-1">
                <div className="form-group col-md-7 small">
                    <label className="control-label" htmlFor="fileContentType">File content type</label>
                    <input type="text" className="form-control form-control-sm" id="fileContentType" name="fileContentType" maxLength="2000" readOnly required/>
                    <div className="invalid-feedback help-block">
                        Please provide a valid <b>File content type</b>.
                    </div>
                </div>
                <div className="form-group col-md-5 small">
                    <label className="control-label" htmlFor="labels">Labels</label>
                    <input type="text" className="form-control form-control-sm" id="labels" name="labels" maxLength="2000" />
                    <div className="invalid-feedback help-block">
                        Please provide a valid <b>Labels</b> value.
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-2 small">
                    <label className="control-label" htmlFor="id">Id</label>
                    <input type="number" className="form-control form-control-sm" id="id" name="id" readOnly />
                </div>
                <div className="form-group col-md-4 small">
                    <label className="control-label" htmlFor="uploaded">Uploaded</label>
                    <input type="datetime-local" className="form-control form-control-sm" id="uploaded" name="uploaded" readOnly />
                </div>
            </div>
        </EditModal>
    );
};

export default DbFileEdit;