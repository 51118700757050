import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/storeObject';

import { Button, Col, FormGroup, Label, Input } from 'reactstrap';

import PortMapping from './ProbesPortMapping';

class Probes extends Component {

    constructor(props) {
        super(props);
    }

    /*
     * <Col md={2}>
                                    <FormGroup>
                                        <Label for="name">Name</Label>
                                        <Input
                                            type="text"
                                            name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx}
                                            id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx}
                                            defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].healthProbeName}
                                            placeholder="Description"
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                */

    render() {
        return (

            this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? (

                this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes.map((p, pIdx) => {
                    return (
                        <span key={'environment.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx}>

                            <FormGroup row>
                                <Col md={8}>
                                    <legend className="col-md-10">Health Probe</legend>
                                </Col>

                            </FormGroup>

                            <FormGroup row>


                                <Col md={4}>
                                    <FormGroup row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="name">Protocol</Label>
                                                <Input
                                                    type="select"
                                                    name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.protocol'}
                                                    id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.protocol'}
                                                    onChange={(obj) => this.props.handleChange(obj)}
                                                    value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].protocol}
                                                >
                                                    <option value='Tcp'>TCP</option>
                                                    <option value='Udp'>UDP</option>
                                                    <option value='Http'>HTTP</option>

                                                </Input>


                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="name">Port</Label>
                                                <Input
                                                    type="number"
                                                    name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.port'}
                                                    id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.port'}

                                                    placeholder="Port Number"

                                                    valid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].port > 0 ? true : false : false : false : false}
                                                    invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].port <= 0 ? true : false : true : true : true}
                                                    onChange={(obj) => this.props.handleChange(obj)}

                                                    value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].port}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="name">Interval</Label>
                                                <Input
                                                    type="number"
                                                    name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.interval'}
                                                    id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.interval'}
                                                    placeholder="5"

                                                    valid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].interval > 0 ? true : false : false : false : false}
                                                    invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].interval <= 0 ? true : false : true : true : true}
                                                    onChange={(obj) => this.props.handleChange(obj)}
                                                    value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].interval}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="name">Num. of Probes</Label>
                                                <Input
                                                    type="number"
                                                    name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.numberOfProbes'}
                                                    id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.numberOfProbes'}
                                                    placeholder="2"

                                                    valid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].numberOfProbes > 0 ? true : false : false : false : false}
                                                    invalid={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx] ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].numberOfProbes <= 0 ? true : false : true : true : true}
                                                    onChange={(obj) => this.props.handleChange(obj)}
                                                    value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].numberOfProbes}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="name">Uri</Label>
                                                <Input
                                                    type="text"
                                                    name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.uri'}
                                                    id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadBalancer.' + this.props.ilbIdx + '.probes.' + pIdx + '.uri'}

                                                    placeholder="http://"
                                                    onChange={(obj) => this.props.handleChange(obj)}
                                                    value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].probes[pIdx].uri}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={12}>
                                            <Label for="delete"> &nbsp;</Label>
                                            <Button type="button" color="danger" onClick={() => this.props.removeProbe(this.props.compIdx, this.props.ilbIdx, pIdx)}><i className="fa fa-trash" /> Delete Health Probe ({pIdx})</Button>
                                        </Col>
                                    </FormGroup>

                                </Col>

                                <Col md={8} className="healthProbeRules">
                                    <FormGroup row>
                                        <Col md={2}>
                                            <legend className="col-md-10">Rules</legend>
                                        </Col>
                                        <Col md={2}>
                                            <Button color="primary" onClick={() => this.props.addPortMapping(this.props.compIdx, this.props.ilbIdx, pIdx)}><i className="fa fa-plus" /> Rule</Button>
                                        </Col>
                                    </FormGroup>
                                    
                                    <PortMapping
                                        envIdx={this.props.envIdx}
                                        compIdx={this.props.compIdx}
                                        ilbIdx={this.props.ilbIdx}
                                        formIaas={this.props.formIaas}
                                        probeIdx={pIdx}
                                        removePortMapping={this.props.removePortMapping}
                                        handleChange={this.props.handleChange}
                                    />
                                </Col>
                            </FormGroup>
                            <hr />
                        </span>
                    );
                })

            ) : null


        );
    }
}

export default Probes;


//export default connect(
//    state => state.storeObjects,
//    dispatch => bindActionCreators(actionCreators, dispatch)
//)(Probes);