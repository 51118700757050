import React from 'react';
import { NavLink } from "react-router-dom";


const NavSplitButton = props => {
    const pageURI = window.location.pathname + window.location.search;
    let active = pageURI === props.defPath;
    if (!active && props.children && props.children.length > 0) {
        React.Children.forEach(props.children, child => {
            active = active || (child && child.props && pageURI === child.props.to);
        });
    }

    return (
        <li className={`btn-group nav-item dropdown ${active ? "active" : ""}`}>
            <NavLink exact className="nav-link" to={props.defPath}>
                <i className={props.icon} />
                {props.name}
            </NavLink>
            <a className="nav-link dropdown-toggle dropdown-toggle-split" href="#"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            >
                <span className="sr-only">Toggle Dropdown</span>
            </a>
            <div className="dropdown-menu">
                {props.children}
            </div>
        </li>
    );
};


export default NavSplitButton;