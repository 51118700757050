import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';
import { IfAuthorized, AuthorizationPolicies, authorizationService } from '../BaseComponents/Authorization';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';


import AppSettings from "./Paas/AppSettings";
import Modal from '../UI/Modal/Modal';
import Preview from "./Paas/Preview";

import {
	DataGridToolbar,
	DataGridToolbarButtonsGroup,
	DataGridToolbarButton
} from '../BaseComponents';

import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, Form, FormGroup, Label, Input, Alert, Card, CardText, CardBody, CardTitle, CardSubtitle, CardLink } from 'reactstrap';
import classnames from 'classnames';



class CloudPaasForm extends Component {

	constructor(props) {
		super(props);
		this.error = null;

		// Correctly Bind class methods to reacts class instance
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		try {
			this.props.setTab('environment', this.props.formPaas.environments[0].environmentName);
		}
		catch (e) {
			//console.log("There's no environments" + e);
		}

		this.footerMessage = '';

	}

	componentDidMount() {
		if (this.props.formPaas.status === 'requested' && !authorizationService.authorize(AuthorizationPolicies.CloudAllowImplement)) {

			let myForm = document.getElementById('myForm');

			let elem = myForm.getElementsByClassName('form-control');

			for (let input of elem) {
				input.setAttribute("disabled", "disable");
			}
			elem = myForm.getElementsByClassName('form-check-input');
			for (let input of elem) {
				input.setAttribute("disabled", "disable");
			}

			elem = myForm.getElementsByClassName('btn');
			for (let input of elem) {
				input.setAttribute("style", "display:none;");
			}

			this.footerMessage = 'To request a change you need to talk with infrastructure team.';

		}
	}

	toggleModal = () => {
		this.props.showModal(!this.props.modal);
	}

	// This function handle form submission
	handleSubmit = (evt, sender) => {
		evt.preventDefault();
		//var formData = this.props.formPaas;
		this.props.validatePaasForm();
	}

	setObjectPropety(obj, prop, type, value) {
		if (prop.indexOf('.') > 0) {
			this.setObjectPropety(obj[prop.substr(0, prop.indexOf('.'))], prop.substr(prop.indexOf('.') + 1, prop.length - prop.indexOf('.')), type, value);
		}
		else {
			obj[prop] = value;
		}
	}

	saveImported = () => {
		this.props.importedPaasObjects();
	}

	handleChange(key) {
		var formData = this.props.formPaas;

		if (key.target.getAttribute('type') === 'checkbox') {
			this.setObjectPropety(formData, key.target.name, key.target.getAttribute('type'), key.target.checked);
		}
		else {
			this.setObjectPropety(formData, key.target.name, key.target.getAttribute('type'), key.target.value);
		}
		this.props.setFormPaas(formData);
	}



	render() {
		if (this.props.error !== null) {
			this.props.showModal(true, 'Error', 'danger', this.props.error);
		}
		let previewing = this.props.previewing ? (<Modal show={this.props.previewing}
			className='full-modal'
			closeModal={this.props.closePreviewData}
			title="Preview">
			<Preview data={this.props.formPaas}
				previewing={this.props.previewing}
			/>
		</Modal>) : null;


		let overwriting = this.props.formPaas.status === 'importing' && this.props.formPaas.azureObjectID > 0 ?
			(<Alert color="danger">
				<h4 className="alert-heading">Attention:</h4>
				<p>
					You are overwriting existing data
                </p>
			</Alert>) : null;

		return (
			<div className="col-md-12">
				<DataGridToolbar title="Application Deployment Request">
					<DataGridToolbarButtonsGroup>

						<DataGridToolbarButton
							outline
							bsStyle='secondary'
							onClick={() => this.props.goToList()}
							glyph="list-alt"
							glyphColor='#4189C7'
							caption=" List All Requests"
							captionAlwaysVisible
						/>
					</DataGridToolbarButtonsGroup>
				</DataGridToolbar>
				<br />

				{overwriting}

				{previewing}

				{
					this.props.modal.show ? (<Modal show={this.props.modal.show}
						closeModal={this.toggleModal}
						title={this.props.modal.title}
					>
						<Alert color={this.props.modal.color} transition={{ baseClass: '', timeout: 500 }}>

							<h4>
								<Fragment>{this.props.modal.text}</Fragment>
							</h4>
						</Alert>
					</Modal>) : null
				}

				{this.error}

				<Form id="myForm" onSubmit={this.handleSubmit} onChange={this.handleChange} type="post" className="needs-validation">
					<FormGroup row>
						<Col md={1}>
							<FormGroup>
								<Label for="assetID">Asset ID</Label>
								<Input
									value={this.props.formPaas.assetID}
									type="text"
									name="assetID"
									id="assetID"
									placeholder="Asset ID"
									readOnly
								/>
							</FormGroup>
						</Col>
						<Col md={1}>
							<FormGroup>
								<Label for="company">Company</Label>
								<Input
									value={this.props.formPaas.company}
									type="text"
									name="company"
									id="company"
									className={this.props.validators.company === false ? 'is-invalid' : null}
									placeholder="Company"
									readOnly
								/>
							</FormGroup>
						</Col>

						<Col md={3}>
							<FormGroup>
								<Label for="applicationName">Application Name</Label>
								<Input
									type="text"
									name="applicationName"
									id="applicationName"
									placeholder="Application Name"
									value={this.props.formPaas.applicationName}
									className={this.props.validators.applicationName === false ? 'is-invalid' : null}
									readOnly
								/>
							</FormGroup>
						</Col>

						<Col md={3}>
							<FormGroup>
								<Label for="resourceGroup">Resource Group</Label>
								<Input
									onChange={this.handleChange}
									value={this.props.formPaas.resourceGroup ? this.props.formPaas.resourceGroup : ""}

									type="text"
									name="resourceGroup"
									id="resourceGroup"
									placeholder="Resource Group"
									required
									valid={this.props.formPaas.resourceGroup ? (this.props.formPaas.resourceGroup.length <= 0 ? false : true) : false}
									invalid={this.props.formPaas.resourceGroup ? (this.props.formPaas.resourceGroup.length <= 0 ? true : false) : true}
								/>

								<div className="invalid-feedback help-block">
									Provide a Resource Group Name
                                </div>

							</FormGroup>
						</Col>

						<Col md={1}>
							<FormGroup>
								<Label for="businessPriority">Priority</Label>
								<Input
									type="select"
									name="businessPriority"
									id="businessPriority"
									value={this.props.formPaas.businessPriority}
									required
									valid={this.props.formPaas.businessPriority ? (this.props.formPaas.businessPriority.length <= 0 ? false : true) : false}
									invalid={this.props.formPaas.businessPriority ? (this.props.formPaas.businessPriority.length <= 0 ? true : false) : true}
								>
									<option value=""> Choose an option </option>
									{
										this.props.getEnumValues('businessPriorityEnum').map(c => {
											return <option key={c.code} value={c.code}>{c.description}</option>;
										})
									}
								</Input>
							</FormGroup>
						</Col>


						<Col md={3}>
							<FormGroup>
								<Label for="projectManager">Project Manager</Label>
								<Input
									type="text"
									name="projectManager"
									id="projectManager"
									placeholder="Project Manager"
									value={this.props.formPaas.projectManager}
									required
									valid={this.props.formPaas.projectManager ? (this.props.formPaas.projectManager.length <= 0 ? false : true) : false}
									invalid={this.props.formPaas.projectManager ? (this.props.formPaas.projectManager.length <= 0 ? true : false) : true}
								/>
								<div className="invalid-feedback help-block">
									Provide a Project Manager Name
                                </div>
							</FormGroup>
						</Col>

						<Col md={2}>
							<FormGroup>
								<Label for="regions">Region</Label>
								<Input
									type="select"
									name="regions"
									id="regions"
									value={this.props.formPaas.regions}
									required
									valid={this.props.formPaas.regions ? (this.props.formPaas.regions.length <= 0 ? false : true) : false}
									invalid={this.props.formPaas.regions ? (this.props.formPaas.regions.length <= 0 ? true : false) : true}
								>
									<option value=""> Choose an option </option>
									{
										this.props.getEnumValues('regionsEnum').map(c => {
											return <option key={c.code} value={c.code}>{c.description}</option>;
										})
									}
								</Input>
								<div className="invalid-feedback help-block">
									Select a Region
                                </div>
							</FormGroup>
						</Col>


						<Col md={2}>
							<FormGroup>
								<Label for="domain">Domain</Label>
								<Input
									type="select"
									name="domain"
									id="domain"
									value={this.props.formPaas.domain}
									required
									valid={this.props.formPaas.domain ? (this.props.formPaas.domain.length <= 0 ? false : true) : false}
									invalid={this.props.formPaas.domain ? (this.props.formPaas.domain.length <= 0 ? true : false) : true}

								>
									<option value=""> Choose an option </option>
									{
										this.props.getEnumValues('companyEnum').map(c => {
											if ((this.props.formPaas.company === 'DAS' && (c.code === 'phiext' || c.code === 'phibred')) ||
												(this.props.formPaas.company === 'SPECCO' && (c.code === 'ddnet1' || c.code === 'ddnet2'))) {
												return <option key={c.code} value={c.code} >{c.description}</option>;
											}
										})
									}
								</Input>
								<div className="invalid-feedback help-block">
									Select a Domain
                                </div>
							</FormGroup>
						</Col>




						<Col md={3}>
							<Label>Status Resource Group</Label>

							<Label check className="radioInline">
								<Input type="radio"
									name="resourceGroupStatus"
									value='Missing'
									defaultChecked={this.props.formPaas.resourceGroupStatus === 'missing' || this.props.formPaas.resourceGroupStatus === undefined}
									className={this.props.validators.resourceGroupStatus === false ? 'is-invalid' : null}
								/>
								{' '} Missing
                            </Label>
							<Label check className="radioInline">
								<Input type="radio"
									name="resourceGroupStatus"
									value='Requested'
									defaultChecked={this.props.formPaas.resourceGroupStatus === 'requested'}
									className={this.props.validators.resourceGroupStatus === false ? 'is-invalid' : null}
								/>
								{' '}Requested
                            </Label>
							<Label check className="radioInline">
								<Input type="radio"
									name="resourceGroupStatus"
									value='Existing'
									defaultChecked={this.props.formPaas.resourceGroupStatus === 'existing'}
									className={this.props.validators.resourceGroupStatus === false ? 'is-invalid' : null}
								/>
								{' '}Existing
                            </Label>
						</Col>

					</FormGroup>

					<FormGroup row>
						<Col md={3}>
							<legend>Applications</legend>
						</Col>
						<Col md={3}>
							<Button type="button" color="primary" onClick={() => this.props.addAppSettings()}><i className="fa fa-plus" /> Add Application </Button>
						</Col>
					</FormGroup>
					{
						this.props.formPaas.settings.map((app, appIdx) => {
							return (
								<AppSettings
									key={'app-' + appIdx}
									appIdx={appIdx}

									formPaas={this.props.formPaas}
									getEnumValues={this.props.getEnumValues}

									addDatabase={this.props.addDatabase}
									removeDatabase={this.props.removeDatabase}

									addWebApp={this.props.addWebApp}
									removeWebApp={this.props.removeWebApp}
								/>
							);
						})
					}


					{
						(this.props.formPaas.status === 'imported' ||
							this.props.formPaas.status === 'draft' ||
							this.props.formPaas.status === 'new') &&
							authorizationService.authorize(AuthorizationPolicies.CloudAllowRequest) ?
							(<FormGroup row>
								<Col md={4}>
									<button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadFilePaasObjects('param')}> <i className="fa fa-cloud-download" /> Param File</button>&nbsp;&nbsp;
                                    <button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadFilePaasObjects('deploy')}> <i className="fa fa-cloud-download" /> Deploy File</button>
								</Col>
								<Col md={4}>
									<Button type="button" onClick={() => this.props.savePaasDraft()} id='draft' color="info" ><i className="fa fa-save" /> Save as Draft</Button>
								</Col>

								<Col md={4}>
									<Button type="submit" id='request' color="success" ><i className="fa fa-share-square" /> Request PaaS Deployment</Button>
								</Col>
							</FormGroup>)
							: null

					}

					{

						this.props.formPaas.status === 'requested' &&
							authorizationService.authorize(AuthorizationPolicies.CloudAllowImplement) &&
							this.props.formPaas.environments ?
							(
								<FormGroup row>
									<Col md={4}>
										<button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadFilePaasObjects('param')}> <i className="fa fa-cloud-download" /> Param File</button>&nbsp;&nbsp;
                                        <button className="btn btn-info btnForm" type="button" onClick={() => this.props.downloadFilePaasObjects('deploy')}> <i className="fa fa-cloud-download" /> Deploy File</button>
									</Col>
									<Col md={4}>
										<Button type="button" onClick={() => this.props.savePaasDraft()} id='draft' color="info" ><i className="fa fa-save" /> Save as Draft</Button>
									</Col>

									<Col md={4}>
										<Button type="submit" id='request' color="success" ><i className="fa fa-share-square" /> Request PaaS Deployment</Button>
									</Col>
								</FormGroup>
							) : null
					}

					{
						this.footerMessage !== '' ? (
							<Alert color='danger' transition={{ baseClass: '', timeout: 500 }}>
								<p>
									{this.footerMessage}
								</p>
							</Alert>

						) : null
					}




				</Form >

			</div >
		);
	}

}

export default connect(
	state => state.storeObjects,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withAITracking(ai.reactPlugin, CloudPaasForm, "CloudPaaSForm"));

//<Button type="submit" id='request' className={` ${this.props.formValid ? '' : 'disabled'}`} color="success" >Request Infrastructure Deployment</Button>