import './NavMenu.css';

import React, { Fragment } from 'react';
import { Link, NavLink } from "react-router-dom";
import { authContext, amsConfig } from '../../../adalConfig';
import { AuthorizationPolicies, authorizationService } from '../../BaseComponents/Authorization';

import NavSplitButton from './NavSplitButton';
import NavDropdown from './NavDropdown';
import NavItem from './NavItem';
import NavGoToSpecCoItem from './NavGoToSpecCoItem';

import configService from '../../../jscomponents/ConfigService';
import projContext from '../../../jscomponents/ProjectContext';

var proj = "";

function projectDefined() {

    if ((proj !== undefined)) {
        const valid = projContext.isValidProject(proj);

        return valid;
    }

    return false;
}

const NavMenu = (props) => {
    const hostName = window.location.hostname.toLocaleLowerCase();
    console.log(props);
    const nonProdEnvLabelText =
        ['dasrptexpotest.pioneer.com', 'golivetrackings.dupont.com'].includes(hostName) ?
            'TEST' :
            ['golivetrackingd.dupont.com', 'golivetracking-prod-dev.azurewebsites.net'].includes(hostName) ?
                'DEV' :
                null;
    const navMenuTitle = ['mnadb.dupont.com'].includes(hostName) ? 'M&A SSOT' : 'Go Live Tracker'; // should be based off appsettings.json...

    const nonProdEnvLabel = nonProdEnvLabelText ?
        <span className="navbar-brand navbar-test-env">{nonProdEnvLabelText} ENV</span>
        : null;

    let amsAuthUrl = '/';
    if (authorizationService.authorize(AuthorizationPolicies.AdminOnly)) {
        const amsAuthUrlComponents = [
            ["resource", amsConfig.resource],
            ["response_mode", "query"],
            ["response_type", "code"],
            ["scope", `${amsConfig.resource}user_impersonation offline_access`],
            ["client_id", amsConfig.clientId],
            ["redirect_uri", window.location.origin + amsConfig.redirectUrl],
            ["login_hint", authContext.getCachedUser().userName]
        ];

        amsAuthUrl = amsConfig.authCodeUrl + "?" + amsAuthUrlComponents.map(c => `${c[0]}=${encodeURIComponent(c[1])}`).join("&");
    }

    console.log("...Retrieving currentSpin from configService");
    const currentSpin = configService.getConfig("currentSpin");
    const multiSpin = configService.getConfig("multiSpin");

    const defaultCurrentSpin = "";
    const loc = window.location.pathname;

    let defaultSpinValue = multiSpin ? defaultCurrentSpin : (
        (currentSpin === "" || currentSpin === undefined) ? defaultCurrentSpin : currentSpin);

    console.log(`DefaultCurrentSpin: ${defaultSpinValue}`);

    if (!projContext.currentProject && multiSpin) {
        let path = loc.split("/");

        if (path.length >= 2) {
            let temp = path[1].length > 0 ? path[1] : proj;

            if (projContext.isValidProject(temp)) {
                proj = temp;
            }

        } else {
            proj = projContext.currentProject ? projContext.currentProject : defaultSpinValue;
        }

        if (projContext.isValidProject(proj)) {
            projContext.currentProject = proj;
        }
    } else {
        proj = projContext.currentProject ? projContext.currentProject : defaultSpinValue;
    }

    proj = proj.toUpperCase();


    // removed 2/5/2021 Azure Menu (Matt Hoss Request)
    // {
    //    projectDefined() && authorizationService.authorize(AuthorizationPolicies.AMSUser) &&
    //    <NavSplitButton name="Azure" defPath={`/${proj}/nsglist`} icon="fa fa-cloud">

    //        <NavLink className="dropdown-item" to={`/${proj}/nsglist`}>NSG List</NavLink>

    //        {
    //            authorizationService.authorize(AuthorizationPolicies.AMSAdminOnly) &&
    //            <a className="dropdown-item" href={amsAuthUrl}>AMS Access Init</a>
    //        }
    //    </NavSplitButton>
    //}

    //{
    //    !projectDefined() && authorizationService.authorize(AuthorizationPolicies.AMSUser) &&
    //    <NavSplitButton name="Azure" defPath={`/nsglist`} icon="fa fa-cloud">

    //        <NavLink className="dropdown-item" to={`/nsglist`}>NSG List</NavLink>

    //        {
    //            authorizationService.authorize(AuthorizationPolicies.AMSAdminOnly) &&
    //            <a className="dropdown-item" href={amsAuthUrl}>AMS Access Init</a>
    //        }
    //    </NavSplitButton>
    //}
    // <NavLink className="dropdown-item" to={`/${proj}/users`} >Users</NavLink>
    // <NavLink className="dropdown-item" to={`/${proj}/userpermissions`}>User Permissions</NavLink>
    //<NavLink className="dropdown-item" to='/projects'>Projects</NavLink>

    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-dark main-navbar">
            <Link className="navbar-brand" to={`/${proj}/`}>{navMenuTitle}</Link>
            {nonProdEnvLabel}
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavbar" aria-controls="mainNavbar" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="mainNavbar">
                <ul className="navbar-nav mr-auto">
                    {authorizationService.authorize(AuthorizationPolicies.User) && projectDefined() &&
                        <Fragment>
                            <NavItem>
                                    <NavLink className="nav-link" to={`/${proj}/`} exact>
                                        <i className="fa fa-home" />
                                        Home
                                    </NavLink>
                            </NavItem>
                            {
                                authorizationService.authorize(AuthorizationPolicies.AppSiteAllowView) &&
                            <NavSplitButton name="Users" defPath={`/sapUserRequests`} icon="fa fa-user">
                                    {authorizationService.authorize(AuthorizationPolicies.SapUserReqAllowView) &&
                                        <NavLink className="dropdown-item" to="/sapUserRequests">SAP User Requests</NavLink>
                                    }
                                    {authorizationService.authorize(AuthorizationPolicies.AdminOnly) &&
                                        <NavLink className="dropdown-item" to={`/userBaseProvisioning`}>User Base Provisioning</NavLink>
                                    }
                                </NavSplitButton>
                            }


                            {
                                authorizationService.authorize(AuthorizationPolicies.AppStatusAllowView) &&
                                <NavSplitButton name="Applications" defPath={`/${proj}/appstatus`} icon="fa fa-list-alt">

                                    <NavLink className="dropdown-item" to={`/${proj}/appstatus`}>App Status</NavLink>
                                    {
                                        authorizationService.authorize(AuthorizationPolicies.ProvisionInfraStatusUser) &&
                                        <NavLink className="dropdown-item" to={`/${proj}/provisioninfrastatus`} >App Provision Infra Status</NavLink>
                                    }

                                    {
                                        authorizationService.authorize(AuthorizationPolicies.AppModernizationStatusUser) &&
                                        <NavLink className="dropdown-item" to={`/${proj}/appmodernizationstatus`}>App Modernization Status</NavLink>
                                    }

                                    {
                                        authorizationService.authorize(AuthorizationPolicies.AppListUser) &&
                                        <NavLink className="dropdown-item" to={`/${proj}/appspins`} >Applications</NavLink>
                                    }

                                </NavSplitButton>
                            }


                            {
                                authorizationService.authorize(AuthorizationPolicies.AppSiteAllowView) &&
                                <NavSplitButton name="Sites" defPath={`/${proj}/sites`} icon="fa fa-list-alt">
                                    {authorizationService.authorize(AuthorizationPolicies.AppSiteAllowView) &&
                                        <NavLink className="dropdown-item" to={`/${proj}/sitestatus`}>Sites Status</NavLink>}
                                    {authorizationService.authorize(AuthorizationPolicies.AppSiteAllowView) &&
                                        <NavLink className="dropdown-item" to={`/${proj}/sites`}>Sites List</NavLink>}
                                    {authorizationService.authorize(AuthorizationPolicies.AppSiteAllowManage) &&
                                        <NavLink className="dropdown-item" to={`/${proj}/sitesextended`}>Sites Extended</NavLink>}

                                </NavSplitButton>
                            }

                            {
                                authorizationService.authorize(AuthorizationPolicies.LegalEntitiesAllowView) &&
                                <NavSplitButton name='Legal Entities' defPath={`/${proj}/legalentities`} icon="fa fa-list-alt">

                                    <NavLink className="dropdown-item" to={`/${proj}/legalentities`} >Legal Entities List</NavLink>

                                    <NavLink className="dropdown-item" to={`/${proj}/legalentitiesseparated`} >Legal Entities Separated</NavLink>

                                    <NavLink className="dropdown-item" to={`/${proj}/legalentitiesfinance`} >Legal Entities Finance List</NavLink>

                                    <NavLink className="dropdown-item" to={`/${proj}/legalentitiesseparatedfinance`} >Legal Entities Finance Separated</NavLink>

                                </NavSplitButton>
                            }


                            {
                                authorizationService.authorize(AuthorizationPolicies.PowerBI) &&
                                <NavDropdown name='PowerBI' icon="fa fa-cog">
                                    <NavLink className="dropdown-item" to='/spinprogram'>Spin Program</NavLink>
                                    <NavLink className="dropdown-item" to='/servicedeliveryworkplan'>Service Delivery Work Plan</NavLink>
                                    <NavLink className="dropdown-item" to='/techtransformation'>Tech Transformation</NavLink>
                                </NavDropdown>
                            }

                            {
                                authorizationService.authorize(AuthorizationPolicies.AdminOnly) &&
                                <NavDropdown name='Admin' icon="fa fa-cog">

                                    <NavLink className="dropdown-item" to='/admin/usermanagement'>Users</NavLink>
                                    <NavLink className="dropdown-item" to='/lookupvalues'>Lookups</NavLink>
                                    <NavLink className="dropdown-item" to='/dbfiles'>Db Files</NavLink>
                                    <NavLink className="dropdown-item" to='/errors'>Errors</NavLink>
                                    <NavLink className="dropdown-item" to='/emailmessages'>Emails</NavLink>
                                    <NavLink className="dropdown-item" to='/dummy'>Dummy</NavLink>
                                </NavDropdown>
                            }
                        </Fragment>
                    }

                    {authorizationService.authorize(AuthorizationPolicies.User) && !projectDefined() &&
                        <Fragment>
                            <NavItem>
                                {
                                    <NavLink className="nav-link" to="/" exact>
                                        <i className="fa fa-home" />
                                        Home
                                    </NavLink>
                                }
                            </NavItem>
                            {   authorizationService.authorize(AuthorizationPolicies.AppSiteAllowView) &&
                            <NavSplitButton name="Users" defPath="/sapUserRequests" icon="fa fa-user">
                                    {authorizationService.authorize(AuthorizationPolicies.SapUserReqAllowView) &&
                                        <NavLink className="dropdown-item" to="/sapUserRequests">SAP User Requests</NavLink>
                                    }
                                    {authorizationService.authorize(AuthorizationPolicies.AdminOnly) &&
                                        <NavLink className="dropdown-item" to="/userBaseProvisioning">User Base Provisioing</NavLink>
                                    }
                                </NavSplitButton>
                            }

                            {
                                //authorizationService.authorize(AuthorizationPolicies.AppStatusAllowView) &&
                                //<NavSplitButton name="Applications" defPath="/appstatus" icon="fa fa-list-alt">

                                //    <NavLink className="dropdown-item" to="/appstatus">App Status</NavLink>
                                //    {
                                //        authorizationService.authorize(AuthorizationPolicies.ProvisionInfraStatusUser) &&
                                //        <NavLink className="dropdown-item" to="/provisioninfrastatus">App Provision Infra Status</NavLink>
                                //    }

                                //    {
                                //        authorizationService.authorize(AuthorizationPolicies.AppModernizationStatusUser) &&
                                //        <NavLink className="dropdown-item" to="/appmodernizationstatus">App Modernization Status</NavLink>
                                //    }

                                //    {
                                //        authorizationService.authorize(AuthorizationPolicies.AppListUser) &&
                                //        <NavLink className="dropdown-item" to="/appspins">Applications</NavLink>
                                //    }

                                //</NavSplitButton>
                            }

                            {
                                //authorizationService.authorize(AuthorizationPolicies.MnAAllowView) &&
                                //<NavSplitButton name="Sites" defPath="/sites" icon="fa fa-list-alt">

                                //    <NavLink className="dropdown-item" to="/sitestatus">Sites Status</NavLink>
                                //    <NavLink className="dropdown-item" to="/sites">Sites List</NavLink>
                                //    <NavLink className="dropdown-item" to="/sitesextended">Sites Extended</NavLink>

                                //</NavSplitButton>
                            }

                            {
                                //authorizationService.authorize(AuthorizationPolicies.LegalEntitiesAllowView) &&
                                //<NavSplitButton name='Legal Entities' defPath="/legalentities" icon="fa fa-list-alt">

                                //    <NavLink className="dropdown-item" to="/legalentities" >Legal Entities List</NavLink>
                                //    <NavLink className="dropdown-item" to="/legalentitiesseparated" >Legal Entities Separated</NavLink>
                                //    <NavLink className="dropdown-item" to="/legalentitiesfinance" >Legal Entities Finance List</NavLink>
                                //    <NavLink className="dropdown-item" to="/legalentitiesseparatedfinance" >Legal Entities Finance Separated</NavLink>

                                //</NavSplitButton>
                            }

                            {
                                authorizationService.authorize(AuthorizationPolicies.PowerBI) &&
                                <NavDropdown name='PowerBI' icon="fa fa-cog">
                                    <NavLink className="dropdown-item" to='/spinprogram'>Spin Program</NavLink>
                                    <NavLink className="dropdown-item" to='/servicedeliveryworkplan'>Service Delivery Work Plan</NavLink>
                                    <NavLink className="dropdown-item" to='/techtransformation'>Tech Transformation</NavLink>
                                </NavDropdown>
                            }

                            {
                                authorizationService.authorize(AuthorizationPolicies.AdminOnly) &&
                                <NavDropdown name='Admin' icon="fa fa-cog">

                                    <NavLink className="dropdown-item" to='/admin/usermanagement'>Users</NavLink>
                                    <NavLink className="dropdown-item" to='/lookupvalues'>Lookups</NavLink>
                                    <NavLink className="dropdown-item" to='/dbfiles'>Db Files</NavLink>
                                    <NavLink className="dropdown-item" to='/errors'>Errors</NavLink>
                                    <NavLink className="dropdown-item" to='/emailmessages'>Emails</NavLink>
                                    <NavLink className="dropdown-item" to='/dummy'>Dummy</NavLink>
                                    

                                </NavDropdown>
                        }
                        

                        </Fragment>
                    }




                </ul>
                <NavGoToSpecCoItem />
                <ul className="nav navbar-nav navbar-right">
                    <li className="navbar-text">{authContext.getCachedUser().userName}</li>
                    <li className="nav-item">
                        <a className="nav-link active" href='#' onClick={(e) => { authContext.logOut(); e.preventDefault(); return false; }}>Sign out</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};


export default NavMenu;