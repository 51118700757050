import $ from 'jquery';
import Slick from '../../src/slick.core';

export default CellRangeDecorator;

/***
* Displays an overlay on top of a given cell range.
*
* TODO:
* Currently, it blocks mouse events to DOM nodes behind it.
* Use FF and WebKit-specific "pointer-events" CSS style, or some kind of event forwarding.
* Could also construct the borders separately using 4 individual DIVs.
*
* @param {Grid} grid
* @param {Object} options
*/
function CellRangeDecorator(options) {
    const handleClassName = 'handle-overlay';
    const _self = this;
    let _grid;
    const _defaults = {
        decoratorWidth: '2px' //'1.5px'
        //selectionCssClass: 'slick-range-decorator',
        //selectionCss: {
        //    "zIndex": "9999",
        //    "border": "2px dashed red"
        //},
        //offset: {
        //    top: -1,
        //    left: -1,
        //    height: -2,
        //    width: -2
        //}
    };
    let _range;
    const _handler = new Slick.EventHandler();
    let $dragHandle;
    let $colHeaderOverlayR, $colHeaderOverlayL, $rowHeaderOverlay;

    let decoratorL, decoratorR, frozenColumn;

    options = $.extend(true, {}, _defaults, options);

    {/* OLD
        function show(range) {
            if (!_elem) {
                _elem = $("<div></div>", { css: options.selectionCss })
                    .addClass(options.selectionCssClass)
                    .css("position", "absolute")
                    .appendTo(grid.getCanvasNode());
            }

            var from = grid.getCellNodeBox(range.fromRow, range.fromCell);
            var to = grid.getCellNodeBox(range.toRow, range.toCell);

            _elem.css({
                top: from.top + options.offset.top,
                left: from.left + options.offset.left,
                height: to.bottom - from.top + options.offset.height,
                width: to.right - from.left + options.offset.width
            });

            return _elem;
        }

        function hide() {
            if (_elem) {
                _elem.remove();
                _elem = null;
            }
        }
        */}

    function init(grid) {
        _grid = grid;
        frozenColumn = grid.getOptions().frozenColumn;
        const $canvases = grid.getCanvases();
        decoratorL = new Overlay($canvases.parent()[0], 'selection-');
        decoratorR = new Overlay($canvases.parent()[1], 'selection-');
        $dragHandle = $('<div>').addClass(handleClassName);
        createColHeaderOverlay(500);
        createRowHeaderOverlay($canvases.parent()[0], 500);

        _handler
            .subscribe(grid.onColumnsResized, handleGridColumnsResized)
            .subscribe(grid.onActiveCellChanged, handleGridActiveCellChanged)
            .subscribe(grid.onScroll, handleGridOnScroll)
            //.subscribe(grid.getSelectionModel().onSelectedRangesChanged, handleSelectedRangesChanged)
            ;

        _self.$dragHandle = $dragHandle;
    }

    function createColHeaderOverlay(zIndex) {
        $colHeaderOverlayR = $('<div>')
            .addClass("header-overlay")
            .css("position", "absolute")
            .css("z-index", zIndex)
            .prependTo('.slick-header.slick-header-right');

        if (frozenColumn > 0) {
            $colHeaderOverlayL = $('<div>')
                .addClass("header-overlay")
                .css("position", "absolute")
                .css("z-index", zIndex)
                .prependTo('.slick-header.slick-header-left');
        }

    }

    function createRowHeaderOverlay(target, zIndex) {
        $rowHeaderOverlay = $('<div>')
            .addClass("header-overlay")
            .css("position", "absolute")
            .css("z-index", zIndex)
            .prependTo(target);
    }
    function destroy() {
        _handler.unsubscribeAll();
    }

    //function handleSelectedRangesChanged(e, ranges) {
    //    if (ranges.length > 0) show(ranges[0]);
    //}

    function handleGridActiveCellChanged(e, args) {
        if (args.row > -1)
            show(new Slick.Range(args.row, args.cell));
        else
            hide();
    }

    function handleGridColumnsResized(e, args) {
        if (_range) {
            show(_range);
            showHandle({ to: _grid.getCellNodeBox(_range.toRow, _range.toCell) });
        }
    }

    function handleGridOnScroll(e, args) {
        //updateHeaderOverlays({ fromScroll: true });
        updateRowHeaderOverlays();
    }

    function show(range) {
        /*
        const columns = _grid.getColumns();
        var hash = {};

        for (var row = range.fromRow; row <= range.toRow; row++) {
            hash[row] = {};
            for (var cell = range.fromCell; cell <= range.toCell; cell++) {
                hash[row][columns[cell].id] =
                    (row === range.fromRow ? ' sel-top' : '')
                    + (row === range.toRow ? ' sel-bottom' : '')
                    + (cell === range.fromCell ? ' sel-left' : '')
                    + (cell === range.toCell ? ' sel-right' : '');
            }
        }

        _grid.setCellCssStyles(options.changedCellCssClass, hash);

        console.log(range);
        */
        _range = range;
        var from = _grid.getCellNodeBox(range.fromRow, range.fromCell);
        var to = _grid.getCellNodeBox(range.toRow, range.toCell);
        var frozen = frozenColumn ? _grid.getCellNodeBox(range.toRow, frozenColumn) : null;

        //console.log('sr:', range.fromRow, 'sc:', range.fromCell, 'er:', range.toRow, 'ec:', range.toCell);

        (range.fromCell > frozenColumn ? decoratorR : decoratorL).updateLeft(from, to);
        (range.toCell   > frozenColumn ? decoratorR : decoratorL).updateRight(from, to);

        //left side decorator
        if (range.fromCell <= frozenColumn) {
            const toRight = range.toCell > frozenColumn ? frozen.right : to.right;
            decoratorL.updateTopAndBottom(from, to, from.left, toRight);
            decoratorL.toggle(true);
        }
        else {
            decoratorL.toggle(false);
        }

        if (range.toCell > frozenColumn) {
            const fromLeft = range.fromCell > frozenColumn ? from.left : 0;
            decoratorR.updateTopAndBottom(from, to, fromLeft, to.right);
            decoratorR.toggle(true);
        }
        else {
            decoratorR.toggle(false);
        }

        if (frozenColumn && range.fromCell <= frozenColumn && range.toCell > frozenColumn) {
            decoratorL.$right.hide();
            decoratorR.$left.hide();
        }

        showHandle({ to, visibility: (range.fromRow === range.toRow ? 'visible' : 'hidden') });
        updateHeaderOverlays({ range });
    }

    function showHandle(args) {
        (_range.toCell > frozenColumn ? decoratorR : decoratorL).addHandle($dragHandle);
        var to = args.to;
        $dragHandle.css({
            left: to.right - (_range.toCell === frozenColumn ? 6 : 4),
            top: to.bottom - 4,
            width: 1,
            height: 1,
            visibility: args.visibility
        });
    }

    function updateRowHeaderOverlays() {
        if (_range) {
            for (var row = _range.fromRow; row <= _range.toRow; row++) {
                const $cell = $(_grid.getCellNode(row, 0));
                $cell.addClass('selected-header');
            }
        }
    }

    function updateHeaderOverlays(args) {
        //if (typeof args.cell != 'undefined') {
        //    currentColumn = args.cell;
        //} else {
        //    if (!currentColumn) {
        //        return;
        //    }
        //}

        //if (!grid.getActiveCell()) {
        //    $rowOverlay.hide();
        //    return;
        //}

        //$rowOverlay.show();

        //$(".slick-header > div > div")
        $('.slick-header-column.selected-header')
            .removeClass("selected-header");

        const columns = _grid.getColumns();
        const range = args.range;
        for (var cell = range.fromCell; cell <= range.toCell; cell++) {
            $('[id$="' + columns[cell].id + '"]', '.slick-header')
                .addClass('selected-header');
        }

        $('.slick-row .slick-cell.cell-selection.selected-header')
            .removeClass("selected-header");
        for (var row = range.fromRow; row <= range.toRow; row++) {
            const $cell = $(_grid.getCellNode(row, 0));
            $cell.addClass('selected-header');
        }

        var headerHeight = $('.slick-header').height();

        $rowHeaderOverlay.show(); //.toggle(cellPosition.visible);
        $colHeaderOverlayR.show(); //.toggle(cellPosition.visible);

        const decoratorRTop = decoratorR.$top[0];
        $colHeaderOverlayR.css({
            left: decoratorRTop.offsetLeft+1, //args.rightLeft + 1, //cellPosition.left, //- 2,
            top: headerHeight - 2, //gridPosition.top + headerHeight - 1, //- 2,
            width: decoratorRTop.offsetWidth-1, // args.rightWidth - 1, //headerWidth - 3,
            height: 2
        });

        if ($colHeaderOverlayL) {
            $colHeaderOverlayL.show();
            const decoratorLTop = decoratorL.$top[0];
            $colHeaderOverlayL.css({
                left: decoratorLTop.offsetLeft + 1, //args.rightLeft + 1, //cellPosition.left, //- 2,
                top: headerHeight - 2, //gridPosition.top + headerHeight - 1, //- 2,
                width: decoratorLTop.offsetWidth - 1, // args.rightWidth - 1, //headerWidth - 3,
                height: 2
            });
        }

        const rowHeaderOverlayLeft = _grid.getCellNodeBox(0, 0).right - 2;
        const decoratorLeft = decoratorL.$left.is(':visible') ? decoratorL.$left[0] : decoratorR.$left[0];
        
        $rowHeaderOverlay.css({
            left: rowHeaderOverlayLeft,
            top: decoratorLeft.offsetTop,
            width: 2,
            height: decoratorLeft.offsetHeight
        });
    }

    function hide() {
        if (decoratorL) decoratorL.toggle(false);
        if (decoratorR) decoratorR.toggle(false);
        $dragHandle.css('visibility', 'hidden');
    }

    function Overlay(target, prefix) {
        var className = (prefix || '') + 'cell-overlay';

        this.$left = $('<div>')
            .addClass(className)
            .addClass('left')
            .appendTo(target);

        this.$right = $('<div>')
            .addClass(className)
            .addClass('right')
            .appendTo(target);

        this.$top = $('<div>')
            .addClass(className)
            .addClass('top')
            .appendTo(target);

        this.$bottom = $('<div>')
            .addClass(className)
            .addClass('bottom')
            .appendTo(target);

        this.toggle = function (showOrHide) {
            this.$left.toggle(showOrHide);
            this.$right.toggle(showOrHide);
            this.$top.toggle(showOrHide);
            this.$bottom.toggle(showOrHide);
        };

        this.addHandle = function ($handle) {
            if ($handle.parent()[0] !== target) {
                $handle.detach();
                $handle.appendTo(target);
            }
        };

        this.updateLeft = function (from, to) {
            this.$left.css({
                top: from.top - 2,               //from.top - 2,
                left: from.left - (_range.fromCell === frozenColumn + 1 ? 0.5 : 1),              //from.left - (range.fromCell === frozenColumn + 1 ? 0 : 2), //-2
                height: to.bottom - from.top + 2,   //to.bottom - from.top + 2,
                width: options.decoratorWidth
            });
        };

        this.updateRight = function (from, to) {
            this.$right.css({
                top: from.top - 2,                  //from.top - 2,
                left: to.right - 1.5 - (_range.toCell === frozenColumn ? 1 : 0),            //to.right - (range.toCell === frozenColumn ? 2 : 0), //- 1,
                height: to.bottom - from.top + 2,   //to.bottom - from.top + 2,
                width: options.decoratorWidth
            });
        };

        this.updateTopAndBottom = function (from, to, fromLeft, toRight) {
            this.$top.css({
                top: from.top - (_range.fromRow > 0 ? 2 : 0),                  //from.top - 2,
                left: fromLeft - 1,                //from.left - 2,
                height: options.decoratorWidth,
                width: toRight - fromLeft + 1     //toRight - from.left + 2
            });

            //headerOverlaysArgs.leftLeft = from.left - 2;
            //headerOverlaysArgs.leftWidth = toRight - from.left + 1;

            this.$bottom.css({
                top: to.bottom - 1,                 // to.bottom - 1,
                left: fromLeft - 1,                //from.left - 2,
                height: options.decoratorWidth,
                width: toRight - fromLeft + 1.5     //toRight - from.left + 3
            });
        };
    }

    Object.assign(this, {
        init,
        destroy,
        show,
        hide,
        getRange: () => _range
    });
}