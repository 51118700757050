import $ from 'jquery';
import Slick from '../../src/slick.core';

export default Overlays;

function Overlays(options) {
    //var $headerOverlay;
    //var $rowOverlay;

    //var currentColumn;
    var handleDragging;
    var grid;
    var self = this;
    //var handler = new Slick.EventHandler();
    var dragDecorator;
    var $dragHandle;

    var defaults = {
        buttonCssClass: null,
        buttonImage: "../images/down.gif",
        decoratorWidth: 2
    };

    function init(g) {
        options = $.extend(true, {}, defaults, options);
        grid = g;

        dragDecorator = options.cellDecorator; //new overlayRangeDecorator(grid);
        $dragHandle = dragDecorator.$dragHandle;

        //$headerOverlay = createRowHeaderOverlay(1200);
        //$rowOverlay = createRowHeaderOverlay(500);
        initDecoratorHandle();

        /*
        handler
            .subscribe(grid.onActiveCellChanged, activeCellChanged)
            .subscribe(grid.onColumnsResized, columnResized)
            .subscribe(grid.onScroll, gridScrolled)
            ;
        */
    }

    function destroy() {
        //handler.unsubscribeAll();
        $dragHandle
            .off("dragstart", handleOverlayDragStart)
            .off('drag', handleOverlayDrag)
            .off('dragend', handleOverlayDragEnd);
    }

    function initDecoratorHandle() {
        $dragHandle
            .on('dragstart', handleOverlayDragStart)
            .on('drag', handleOverlayDrag)
            .on('dragend', handleOverlayDragEnd);
    }

    {/*
        function createRowHeaderOverlay(zIndex) {
            return $('<div>')
                .addClass("header-overlay")
                .css("position", "absolute")
                .css("z-index", zIndex)
                .appendTo('body');
        }

        function activeCellChanged(e, args) {
            //dragDecorator.hide();

            //moveSelectionOverlay(e, args);
            moveHeaderRowOverlays(e, args);
        }

        function columnResized(e, args) {
            moveHeaderRowOverlays(e, args);
            //moveSelectionOverlay(e, args);
        }

        function gridScrolled(e, args) {
            moveHeaderRowOverlays(e, args);
            //moveSelectionOverlay(e, args);
        }

        function moveHeaderRowOverlays(e, args) {
            if (typeof args.cell != 'undefined') {
                currentColumn = args.cell;
            } else {
                if (!currentColumn) {
                    return;
                }
            }

            if (!grid.getActiveCell()) {
                $rowOverlay.hide();
                return;
            }

            $rowOverlay.show();

            var column = grid.getColumns()[currentColumn];

            $(".slick-header > div > div")
                .removeClass("selected-header");

            $('[id$="' + column.id + '"]', '.slick-header')
                .addClass('selected-header');

            var headerHeight = $('.slick-header').height();
            var cellPosition = grid.getActiveCellPosition();
            var gridPosition = grid.getGridPosition();

            $rowOverlay.toggle(cellPosition.visible);
            $headerOverlay.toggle(cellPosition.visible);

            var headerWidth = Math.min(cellPosition.width + 3,
                gridPosition.width - cellPosition.left + 4);

            $headerOverlay.css({
                left: cellPosition.left, //- 2,
                top: gridPosition.top + headerHeight - 1, //- 2,
                width: headerWidth - 3,
                height: 2
            });

            $rowOverlay.css({
                left: gridPosition.left,
                top: cellPosition.top,
                width: 2,
                height: cellPosition.height
            });
        }
    */}

    {/*
        function moveSelectionOverlay(e, args) {
            var activeCell = grid.getActiveCell();

            if (!activeCell) {
                selectionOverlay.toggle(false);
                return;
            }

            var column = grid.getColumns()[activeCell.cell];
            selectionOverlay.toggle(true);

            // Only show the handle if the cell is editable
            selectionOverlay.$handle.toggle(typeof (column.editor) !== 'undefined');

            var position = grid.getCellNodeBox(activeCell.row, activeCell.cell);

            // Not coming through on the property so re-calculated
            position.height = position.bottom - position.top;
            position.width = position.right - position.left;

            selectionOverlay.$left.css({
                left: position.left - 2,
                top: position.top,
                width: 2,
                height: position.height
            });

            selectionOverlay.$right.css({
                left: position.left + position.width - 1,
                top: position.top,
                width: 2,
                height: position.height
            });

            selectionOverlay.$top.css({
                left: position.left - 2,
                top: position.top - 2,
                width: position.width + 3,
                height: 2
            });

            selectionOverlay.$bottom.css({
                left: position.left - 2,
                top: position.top + position.height - 1,
                width: position.width + 3,
                height: 2
            });

            selectionOverlay.$handle.css({
                left: position.left + position.width - 4,
                top: position.top + position.height - 4,
                width: 1,
                height: 1
            });
        }
        */}

    function handleOverlayDragStart(e, dd) {
        var cell = grid.getActiveCell();
        const decoratorRange = dragDecorator.getRange();

        if (grid.canCellBeSelected(cell.row, cell.cell)) {
            handleDragging = true;
            e.stopImmediatePropagation();
        }

        if (!handleDragging) {
            return null;
        }

        grid.focus();

        dd.range = {
            start: { row: decoratorRange.fromRow, cell: decoratorRange.fromCell },
            end: { row: decoratorRange.toRow, cell: decoratorRange.toCell }
        };// { start: cell, end: {} };

        $(this).css({
            "background-color": "transparent",
            "border-color": "transparent"
        });

        //return dragDecorator.show(new Slick.Range(cell.row, cell.cell));
        //return dragDecorator.show(decoratorRange);
    }

    function handleOverlayDrag(e, dd) {
        if (!handleDragging) {
            return;
        }

        var canvas = grid.getCanvasNode();

        e.stopImmediatePropagation();

        var end = grid.getCellFromPoint(
            e.pageX - $(canvas).offset().left,
            e.pageY - $(canvas).offset().top);

        if (!grid.canCellBeSelected(end.row, end.cell)) {
            return;
        }

        dd.range.end.row = end.row;

        //console.log('start.row:', dd.range.start.row, 'end.row:', dd.range.end.row);

        //grid.scrollCellIntoView(end.row, end.cell);

        dragDecorator.show(new Slick.Range(
            dd.range.start.row,
            dd.range.start.cell,
            dd.range.end.row, //end.row,
            dd.range.end.cell //dd.range.start.cell
        ));

        checkForScrolling(e, dd);
    }

    function handleOverlayDragEnd(e, dd) {
        stopScrolling();
        if (!handleDragging) {
            return;
        }

        handleDragging = false;

        $(this).css({
            "background-color": "",
            "border-color": ""
        });

        //dragDecorator.hideHandle();

        self.onFillUpDown.notify({ grid: grid, range: dragDecorator.getRange() }, e, self);

        e.preventDefault();
        e.stopPropagation();
    }

    // autoscroll for selection
    var timerID, scrollTopDir, scrollLeftDir;

    function checkForScrolling(e, args) {
        const $viewport = $('.slick-viewport-top.slick-viewport-right');
        const offset = $viewport.offset();
        const diffYT = ((e.pageY - offset.top));
        const diffXR = ((offset.left + $viewport.width()) - e.pageX);
        const diffYB = ((offset.top + $viewport.height()) - e.pageY);
        const diffXL = ((e.pageX - offset.left));

        //console.log(JSON.stringify(args.range));
        //console.log(JSON.stringify({ diffYT, diffXR, diffYB, diffXL, pot: offset.top, pY: e.pageY, pol: offset.left, pX: e.pageX }));
        //console.log('Y:', e.pageY, 'viewPortBottom:', (offset.top + $viewport.height()));

        scrollTopDir = diffYB < 18 ? 1 : (diffYT < 3 ? -1 : 0);
        //scrollLeftDir = diffXR < 18 ? 1 : (diffXL < 3 ? -1 : 0);

        if (scrollTopDir || scrollLeftDir) {
            grid.scrollCellIntoView(args.range.end.row, args.range.end.cell);

            if (!timerID)
                timerID = setInterval(doScrolling, 50, $viewport[0], args.range);

            e.preventDefault();
            return false;
            //console.log({ x: diffX });
            //$slickviewport.scrollLeft($slickviewport.scrollLeft() + 10);
        } else {
            stopScrolling();
        }

        return scrollTopDir || scrollLeftDir;
    }

    function doScrolling(vpNode, range) {
        const selectionRange = grid.getSelectionModel().getSelectedRanges()[0];
        const frozenColumn = grid.getOptions().frozenColumn;
        const step = 50;

        if (scrollTopDir) {
            if (scrollTopDir === -1 && vpNode.scrollTop > 0) {
                vpNode.scrollTop -= step;//Math.min(step, vpNode.scrollTop);
            } else if (scrollTopDir === 1 && vpNode.scrollHeight > (vpNode.offsetHeight + vpNode.scrollTop)) {
                vpNode.scrollTop += step;
            }
            else {
                scrollTopDir = 0;
            }
        }

        if (!scrollTopDir && !scrollLeftDir) stopScrolling();
    }

    function stopScrolling() {
        if (timerID) {
            console.log('stopScroll');
            clearInterval(timerID);
            timerID = 0;
        }
    }

    Object.assign(this, {
        init,
        destroy,
        onFillUpDown: new Slick.Event()
    });
}

