import React from 'react';
import { EditModal } from '../BaseComponents/Modals';

const ErrorEdit = () => {
    return (
        <EditModal id="errorEditModal" title="Error Text">
            <div className="form-group mb-0">
                {/*<label htmlFor="errorText">Error Text:</label>*/}
                <textarea className="form-control form-control-sm" id="errorText" name="errorText" rows="15" readOnly />
            </div>
        </EditModal>
    );
};

export default ErrorEdit;