import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';

import Preview from "./Iaas/Preview";
import Modal from '../UI/Modal/Modal';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarButton,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory
} from '../BaseComponents';

class CloudIaaSListing extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.refreshData();
        this.gridComponent.onCellLinkClicked.subscribe(this.onGridCellLinkClicked);
    }

    componentDidUpdate() {
        this.gridComponent.loadData(this.props.azureObjects);
    }

    componentWillUnmount() {
        this.gridComponent.onCellLinkClicked.unsubscribe(this.onGridCellLinkClicked);
    }

    onGridInit = (gridComponent) => {
        this.gridComponent = gridComponent;
    }

    onGridCellLinkClicked = (e, args) => {
        this.downloadFile(e.target.text);
    }

    refreshData = () => {
        this.props.listingAzureObjects(0, 1000);
    }

    editItem = () => {
        const currentGridItem = this.gridComponent.getCurrentItem();
        if (currentGridItem) {
            const current = this.props.azureObjects.filter(item => item.azureObjectID === currentGridItem.azureObjectID);
            if (current) {
                this.props.editingAzureObjects(current[0]);
            }
        }
    }

    previewData = () => {
        const currentGridItem = this.gridComponent.getCurrentItem();
        if (currentGridItem) {
            const current = this.props.azureObjects.filter(item => item.azureObjectID === currentGridItem.azureObjectID);
            if (current) {
                this.props.setFormIaas(current[0]);
                this.props.previewAzureObjects(true);
            }
        }
    }

    downloadFile = (env) => {
        const currentGridItem = this.gridComponent.getCurrentItem();
        if (currentGridItem) {
            this.props.downloadFileAzureObjects(currentGridItem.azureObjectID, env);
        }
    }
    

    render() {

        //<DataGridToolbarButton
        //    outline
        //    bsStyle='primary'
        //    onClick={() => this.props.goToImport()}
        //    glyph="upload"
        //    glyphColor='#4189C7'
        //    caption=" Import Azure Files"
        ///>
        //<DataGridToolbarButton
        //    outline
        //    bsStyle='primary'
        //    onClick={() => this.props.goToRequest()}
        //    glyph="plus"
        //    glyphColor='green'
        //    caption=' Add New Request'
        ///>

        return (
            <div>
                <DataGrid model='azureIaaS' onGridInit={this.onGridInit}>
                    <DataGridToolbar
                        title="Azure IaaS List"
                    >
                        <DataGridToolbarButtonsGroup>
                            <DataGridToolbarButton
                                outline
                                bsStyle='success'
                                onClick={this.refreshData}
                                glyph="refresh"
                                glyphColor='green'
                                text=" Refresh"
                                tooltip
                            />
                            
                            <DataGridToolbarButton
                                outline
                                bsStyle='secondary'
                                onClick={this.editItem}
                                glyph="pencil"
                                glyphColor='#4189C7'
                                text=" Edit"
                                tooltip
                            />
                            <DataGridToolbarButton
                                outline
                                bsStyle='info'
                                onClick={this.previewData}
                                glyph="eye"
                                glyphColor='#4189C7'
                                text=" Preview"
                                tooltip
                            />
                            <DataGridToolbarButton
                                outline
                                bsStyle='info'
                                onClick={() => this.downloadFile('deploymentfile')}
                                glyph="download"
                                glyphColor='#4189C7'
                                text=" Azure Deploy"
                                caption
                            />
                            
                            <DataGridToolbarButtonExport />
                            <DataGridToolbarButtonHistory />
                        </DataGridToolbarButtonsGroup>
                    </DataGridToolbar>
                </DataGrid>

                <Modal show={this.props.previewing}
                    closeModal={this.props.closePreviewData}
                    title="Preview"
                >
                    <Preview data={this.props.formIaas}
                        previewing={this.props.previewing}
                    />
                </Modal>

            </div>
        );
    }
}

export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(CloudIaaSListing);
