import $ from 'jquery';

export default Dialog;

function Dialog() {

    var
        $modal,
        $title,
        $body,
        $btnYes,
        $btnNo,
        $btnClose,
        $defaultBtnYes,

        yesCallback,
        noCallback,
        dialogResult = false,


        // methods
        init = function () {
            $modal = $('#confirmModal');
            $title = $modal.find('.modal-title');
            $body = $modal.find('.modal-body');
            $btnYes = $modal.find('.btn-yes');
            $btnNo = $modal.find('.btn-no');

            $modal.on('click', '.btn-yes', function () {
                dialogResult = true;
                if (yesCallback) yesCallback();
                $modal.modal('hide');
            });

            $modal.on('hidden.bs.modal', function () {
                if (!dialogResult && noCallback) noCallback();
                return true;
            });
        },
        confirm = function (title, message, btnYes, onYes, onNo) {
            if ($modal.length === 0) init();

            dialogResult = false;
            yesCallback = onYes;
            noCallback = onNo;

            if (title) { $title.html(title); }
            if (message) { $body.html(message); }
            if (btnYes) {
                const $result = $btnYes.replaceWith(btnYes);
                if (!$defaultBtnYes) $defaultBtnYes = $result;
            } else if ($defaultBtnYes) {
                $btnYes.replaceWith($defaultBtnYes);
            }

            $btnYes = $modal.find('.btn-yes');

            $btnYes.show();
            $btnNo.text('Cancel');

            $modal.modal('show');
        },
        showMessage = function (title, message, onClose) {
            if ($modal.length === 0) init();

            dialogResult = false;
            yesCallback = null;
            noCallback = onClose;

            if (title) { $title.html(title); }
            if (message) { $body.html(message); }

            $btnYes.hide();
            $btnNo.text('Close');

            $modal.modal('show');
        }
        ;


    init();

    return {
        AskUser: confirm,
        ShowMessage: showMessage
    };
}