import React from 'react';
import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarCheckBox,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';
import DuPont from '../../jscomponents/DuPontApp';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';
import ProjectRequired from '../BaseComponents/ProjectRequired';
let properties;
const Toolbar = () => {
    //const { currentSpin, crossSpinDataHidden } = DuPont.App.Config;
    const { tempCurrentSpin, crossSpinDataHidden, multiSpin } = DuPont.App.Config;
    var currentSpin = multiSpin ? properties.match.params.project : tempCurrentSpin;
    currentSpin = currentSpin.toUpperCase();
    console.log(`currentSpin: ${currentSpin}`);
    return (
        <DataGridToolbar
            title={currentSpin + " App Modernization Status"}
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarCheckBox
                id="showAllCheckBox"
                filterOrder="2"
                filterWhenChecked="all"
                filterWhenUnchecked="enabled"
                caption="Show Disabled Apps"
            />
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarDropdownButton
                    caption="ALL"
                    controlClass={crossSpinDataHidden ? "d-none" : null}
                    glyph="filter"
                    glyphColor='#4189C7'
                    filterOrder="1"
                    filter={crossSpinDataHidden ? currentSpin : "all"}
                    filterReqMsg="Please choose a filter!"
                >
                    <DataGridToolbarDropdownItem filter="DAS" href="#" caption='DAS' />
                    <DataGridToolbarDropdownItem filter="SPECCO" href="#" caption='SPECCO' />
                    <li className="dropdown-divider" />
                    <DataGridToolbarDropdownItem filter="all" href="#" caption='ALL' />
                </DataGridToolbarDropdownButton>{' '}

                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonExport />
                {/*<DataGridToolbarButtonImport />*/}
                <DataGridToolbarButtonHistory />
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const AppModernizationStatus = (props) => {

    const { multiSpin } = DuPont.App.Config;

    if (multiSpin && !props.match.params.project) {
        return (
            <ProjectRequired />
        );
    }
    properties = props;
    return (
        <DataGrid model='appModernizationStatus'>
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, AppModernizationStatus, "AppModernizationStatus");