import $ from 'jquery';
import Slick from '../../src/slick.core';

export default CellRangeSelector;

function CellRangeSelector(options) {
    var _grid;
    var _selectionModel;
    var _currentlySelectedRange;
    var _canvas;
    var _dragging;
    var _selectEntireRow;
    var _decorator;
    var _self = this;
    var _handler = new Slick.EventHandler();
    var _defaults = {
        selectionCss: {
            "border": "2px dashed blue"
        }
    };

    var _h_selectionchanges = null;
    var _prevrange = {};

    function init(grid) {
        options = $.extend(true, {}, _defaults, options);
        _decorator = options.cellDecorator || new Slick.CellRangeDecorator(grid, options);
        _grid = grid;
        _selectionModel = grid.getSelectionModel()
        _canvas = _grid.getCanvasNode();
        _handler
            .subscribe(_grid.onClick, handleOnClick)
            .subscribe(_grid.onDragInit, handleDragInit)
            .subscribe(_grid.onDragStart, handleDragStart)
            .subscribe(_grid.onDrag, handleDrag)
            .subscribe(_grid.onDragEnd, handleDragEnd)
            .subscribe(_selectionModel.onSelectedRangesChanged, handleSelectedRangesChanged)
            ;
        if (_decorator.init) _decorator.init(grid);
    }

    function destroy() {
        _handler.unsubscribeAll();
        if (_decorator.destroy) _decorator.destroy();
    }

    function getCellDecorator() {
        return _decorator;
    }

    function handleOnClick(e, args) {
        var active, selRange;
        if (args.cell === 0) {
            if (e.shiftKey) {
                active = _grid.getActiveCell() || {};
                selRange = {
                    start: { row: (active.row || args.row), cell: 1 },
                    end: { row: args.row, cell: _grid.getColumns().length - 1 }
                };
                if (active.cell !== 1 && active.row != null)
                    _grid.setActiveCell(active.row, 1);//, false, preClickModeOn, suppressActiveCellChangedEvent);
            } else {
                selRange = {
                    start: { row: args.row, cell: 1 },
                    end: { row: args.row, cell: _grid.getColumns().length - 1 }
                };
                _grid.setActiveCell(args.row, 1);//, false, preClickModeOn, suppressActiveCellChangedEvent);
            }

        } else if (e.shiftKey && (active = _grid.getActiveCell()) != null) {
            selRange = {
                start: { row: active.row, cell: active.cell },
                end: { row: args.row, cell: args.cell }
            };
        }

        if (selRange) {
            _currentlySelectedRange = selRange;
            window.clearTimeout(_h_selectionchanges);
            _h_selectionchanges = window.setTimeout(onSelectionChanged, 25, { range: selRange });

            if (e.shiftKey) e.stopImmediatePropagation();
            //return false;
        }
    }

    function handleDragInit(e, args) {
        if ($('.slick-header-menu').is(":visible") ||
            $('.slick-columnpicker').is(":visible")
        ) {
            return;
        }

        // prevent the grid from cancelling drag'n'drop by default
        e.stopImmediatePropagation();
    }

    function handleDragStart(e, args) {
        var cell = _grid.getCellFromEvent(e);
        if (_self.onBeforeCellRangeSelected.notify(cell) !== false) {
            if (_grid.canCellBeSelected(cell.row, cell.cell)) {
                //_dragging = true;
                _selectEntireRow = false;
                //e.stopImmediatePropagation();
            } else if (cell.cell === 0) {
                _selectEntireRow = true;
            }
            _dragging = true;
            e.stopImmediatePropagation();
        }
        if (!_dragging) {
            return;
        }

        _grid.focus();

        var start = _grid.getCellFromPoint(
            args.startX - $(_canvas).offset().left,
            args.startY - $(_canvas).offset().top);

        if (_selectEntireRow) start.cell = 1;

        _prevrange = { start: start, end: { row: -1, cell: -1 } };
        args.range = { start: start, end: {} };
        _currentlySelectedRange = args.range;
        //return _decorator.show(new Slick.Range(start.row, start.cell));
    }

    function handleDrag(e, args) {
        if (!_dragging) return;

        e.stopImmediatePropagation();

        var end = _grid.getCellFromPoint(
            e.pageX - $(_canvas).offset().left,
            e.pageY - $(_canvas).offset().top);

        if (!_grid.canCellBeSelected(end.row, end.cell) && !_selectEntireRow) {
            return;
        }

        if (_selectEntireRow) end.cell = _grid.getColumns().length - 1;
        if (end.row < 0) end.row = 0;
        //console.log('old_er:', args.range.end.row, 'old_ec:', args.range.end.cell, 'er:', end.row, 'ec:', end.cell);
        args.range.end = end;

        if (shouldScroll(e, args)) return;

        //console.log(args.range);

        if (!didSelectedRangeChange(args.range)) return;
        $.extend(_prevrange, args.range);

        _currentlySelectedRange = args.range;

        //_handler.unsubscribe(_selectionModel.onSelectedRangesChanged, handleSelectedRangesChanged)
        //_decorator.show(new Slick.Range(args.range.start.row, args.range.start.cell, end.row, end.cell));

        //console.log('sr:', args.range.start.row, 'sc:', args.range.start.cell, 'er:', end.row, 'ec:', end.cell);

        //_self.onCellRangeSelected.notify({
        //    range: new Slick.Range(
        //        args.range.start.row,
        //        args.range.start.cell,
        //        args.range.end.row,
        //        args.range.end.cell
        //    )
        //});

        if (!_selectEntireRow) _grid.scrollCellIntoView(end.row, end.cell, false);

        window.clearTimeout(_h_selectionchanges);
        _h_selectionchanges = window.setTimeout(onSelectionChanged, 25, args);
    }

    function handleSelectedRangesChanged(e, ranges) {
        var active, range;
        if (ranges.length > 0) {
            if (ranges[0].isSingleCell()) _grid.scrollCellIntoView(ranges[0].fromRow, ranges[0].fromCell, false);
            _decorator.show(ranges[0]);
        }
        else if ((active = _grid.getActiveCell()) != null) {
            _grid.scrollCellIntoView(active.row, active.cell, false);
            _decorator.show(new Slick.Range(active.row, active.cell));
        }
        else
            _decorator.hide();
    }

    function didSelectedRangeChange(selRange) {
        var result = (JSON.stringify(_prevrange) !== JSON.stringify(selRange));
        return result;
    }

    function onSelectionChanged(args) {
        _self.onCellRangeSelected.notify({
            range: new Slick.Range(
                args.range.start.row,
                args.range.start.cell,
                args.range.end.row,
                args.range.end.cell
            )
        });
        //_handler.subscribe(_selectionModel.onSelectedRangesChanged, handleSelectedRangesChanged)
    }

    function handleDragEnd(e, args) {
        stopScrolling();

        if (!_dragging) return;

        _dragging = false;
        e.stopImmediatePropagation();

        //_decorator.hide();

        //_self.onCellRangeSelected.notify({
        //    range: new Slick.Range(
        //        args.range.start.row,
        //        args.range.start.cell,
        //        args.range.end.row,
        //        args.range.end.cell
        //    )
        //});
    }

    function getCurrentRange() {
        return _currentlySelectedRange;
    }

    // autoscroll for selection
    var timerID, scrollTopDir, scrollLeftDir;

    function shouldScroll(e, args) {
        const $viewport = $(e.currentTarget).closest('.slick-viewport'); //$(_grid.getActiveViewportNode(e));// $('.slick-viewport-top.slick-viewport-right');
        const vpRightWidth = $viewport.is('.slick-viewport-left') ? $('.slick-viewport-top.slick-viewport-right').width() : 0;
        const vpRightScrollLeft = 0; //$viewport.is('.slick-viewport-left') ? $('.slick-viewport-top.slick-viewport-right')[0].scrollLeft : 0;
        const offset = $viewport.offset();
        const diffYT = ((e.pageY - offset.top));
        const diffXR = ((offset.left + $viewport.width() + vpRightWidth) - e.pageX);
        const diffYB = ((offset.top + $viewport.height()) - e.pageY);
        const diffXL = ((e.pageX - offset.left - vpRightScrollLeft));

        //console.log(JSON.stringify(args.range));
        //console.log(JSON.stringify({ diffYT, diffXR, diffYB, diffXL, pot: offset.top, pY: e.pageY, pol: offset.left, pX: e.pageX }));
        //console.log('Y:', e.pageY, 'viewPortBottom:', (offset.top + $viewport.height()));

        scrollTopDir = diffYB < 18 ? 1 : (diffYT < 3 ? -1 : 0);
        scrollLeftDir = diffXR < 18 ? 1 : (diffXL < 3 ? -1 : 0);

        if (scrollLeftDir === -1 && _selectEntireRow) scrollLeftDir = 0;

        if (scrollTopDir || scrollLeftDir) {
            if (!timerID)
                timerID = setInterval(doScrolling, 100, $viewport[0], args.range);

            e.preventDefault();
            return false;
            //console.log({ x: diffX });
            //$slickviewport.scrollLeft($slickviewport.scrollLeft() + 10);
        } else {
            stopScrolling();
        }

        return scrollTopDir || scrollLeftDir;
    }

    function doScrolling(vpNode, range) {
        const selectionRange = _grid.getSelectionModel().getSelectedRanges()[0];
        if (!selectionRange) { stopScrolling(); return; }
        const frozenColumn = _grid.getOptions().frozenColumn;
        const step = 1;


        if (scrollTopDir) {
            if (scrollTopDir === -1) {
                if (range.end.row < range.start.row || selectionRange.toRow <= range.start.row) {
                    if (selectionRange.fromRow > 0) {
                        // increase row selection up
                        selectionRange.fromRow -= step;
                        _grid.getSelectionModel().setSelectedRanges([selectionRange]);
                        _grid.scrollCellIntoView(selectionRange.fromRow, selectionRange.fromCell, false);
                    }
                } else {
                    // decrease row selection up
                    selectionRange.toRow -= step;
                    _grid.getSelectionModel().setSelectedRanges([selectionRange]);
                    const toCell = _selectEntireRow ? 1 : selectionRange.toCell;
                    _grid.scrollCellIntoView(selectionRange.toRow, toCell, false);
                }
            } else if (scrollTopDir === 1) {
                //const bottomRow = grid.getViewport().bottom;
                if (range.end.row > range.start.row) {
                    if (selectionRange.toRow < _grid.getDataLength()) {
                        // increase row selection down
                        selectionRange.toRow += step;
                        _grid.getSelectionModel().setSelectedRanges([selectionRange]);
                        const toCell = _selectEntireRow ? 1 : selectionRange.toCell;
                        _grid.scrollCellIntoView(selectionRange.toRow, toCell, false);
                    }
                } else {
                    // decrease row selection down
                    selectionRange.fromRow += step;
                    _grid.getSelectionModel().setSelectedRanges([selectionRange]);
                    _grid.scrollCellIntoView(selectionRange.fromRow, selectionRange.fromCell, false);
                }
            }
            else {
                scrollTopDir = 0;
            }
        }

        if (scrollLeftDir) {
            if (scrollLeftDir === -1) {
                if (range.end.cell < range.start.cell || selectionRange.toCell <= range.start.cell) {
                    if (selectionRange.fromCell > 1) {
                        // increase cell selection left
                        //selectionRange.fromCell -= step;
                        //_grid.getSelectionModel().setSelectedRanges([selectionRange]);
                        //_grid.scrollCellIntoView(selectionRange.fromRow, selectionRange.fromCell, false);
                        if (selectionRange.fromCell = frozenColumn) {
                            _grid.scrollCellIntoView(selectionRange.fromRow, frozenColumn + 1, false);
                            scrollLeftDir = 0;
                        }
                    }
                } else if (selectionRange.toCell > 1) {
                    // decrease cell selection left
                    selectionRange.toCell -= step;
                    _grid.getSelectionModel().setSelectedRanges([selectionRange]);
                    _grid.scrollCellIntoView(selectionRange.toRow, selectionRange.toCell, false);
                }
            } else if (scrollLeftDir === 1) {
                if (range.end.cell > range.start.cell) {
                    if (selectionRange.toCell < _grid.getColumns().length - 1) {
                        // increase cell selection right
                        selectionRange.toCell += step;
                        _grid.getSelectionModel().setSelectedRanges([selectionRange]);
                        _grid.scrollCellIntoView(selectionRange.toRow, selectionRange.toCell, false);
                    }
                } else {
                    if (selectionRange.fromCell < _grid.getColumns().length - 1) {
                        // decrease cell selection right
                        selectionRange.fromCell += step;
                        _grid.getSelectionModel().setSelectedRanges([selectionRange]);
                        _grid.scrollCellIntoView(selectionRange.fromRow, selectionRange.fromCell, false);
                    }
                }
            }
            else {
                scrollLeftDir = 0;
            }
        }

        if (!scrollTopDir && !scrollLeftDir) stopScrolling();
    }

    function stopScrolling() {
        if (timerID) {
            console.log('stopScroll');
            clearInterval(timerID);
            timerID = 0;
        }
    }

    Object.assign(this, {
        init,
        destroy,
        getCellDecorator,
        getCurrentRange,

        onBeforeCellRangeSelected: new Slick.Event(),
        onCellRangeSelected: new Slick.Event()
    });
}