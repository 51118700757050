/* eslint react/no-multi-comp: 0, react/prop-types: 0 */


import React, { Component } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class UIModal extends Component {

    constructor(props) {
        super(props);
        //this.state = {
        //    modal: false
        //};

        //this.toggle = this.toggle.bind(this);
    }

    //shouldComponentUpdate(nextProps, nextState) {
    //    return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
    //}

    componentWillUpdate() {
        console.log('[Modal] WillUpdate');
    }
    //<Backdrop show={this.props.show} clicked={this.props.modalClosed} />
    render() {
        let submit = this.props.submit ? <Button color="primary" onClick={() => this.props.showModal()}>Continue</Button> : null;
        let close = this.props.closeModal ? <Button color="primary" onClick={() => this.props.closeModal()}>Close</Button> : null;

        return (
            <Modal isOpen={this.props.show} fade={false} size="lg" className="Modal">
                <ModalHeader>{this.props.title}</ModalHeader>
                <ModalBody>
                    {this.props.children}
                </ModalBody>
                <ModalFooter>
                    {submit}
                    {close}
                </ModalFooter>
            </Modal>
        );
    }
}

export default UIModal;






//class ModalExample extends React.Component {
//    constructor(props) {
//        super(props);
//        this.state = {
//            modal: false
//        };

//        this.toggle = this.toggle.bind(this);
//    }

//    toggle() {
//        this.setState({
//            modal: !this.state.modal
//        });
//    }

//}
//}
