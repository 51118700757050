import fileDownload from 'react-file-download';
import { adalApiFetch } from '../adalConfig';
import { push } from 'react-router-redux';

const requestLookup = 'REQUEST_LOOKUP';
const receiveLookup = 'RECEIVE_LOOKUP';

const previewAzureObjects = 'PREVIEW_AZURE_OBJECTS';

const insertingAzureObjects = "INSERTING_AZURE_OBJECTS";
const reinsertingAzureObjects = "REINSERTING_AZURE_OBJECTS";
const submitAzureObjects = 'SUBMIT_AZURE_OBJECTS';
const submittedAzureObjects = 'SUBMITTED_AZURE_OBJECTS';

const listingProvisioningInfra = 'LISTING_PROVISIONING_INFRA';

const listingAzureObjects = 'LISTING_AZURE_OBJECTS';
const receivedAzureObjects = 'RECEIVED_AZURE_OBJECTS';
const editingAzureObjects = 'EDITING_AZURE_OBJECTS';
const showAzureObjHistory = 'SHOW_AZURE_OBJ_HISTORY'
const importingAzureObjects = "IMPORTING_AZURE_OBJECTS";
const importedAzureObjects = 'IMPORTED_AZURE_OBJECTS';
const summaryAzureObjects = "SUMMARY_AZURE_OBJECTS";

const formValidAzureObjects = 'FORM_VALID_AZURE_OBJECTS';

const setFormValidators = 'SET_FORM_VALIDATORS';
const saveDraft = 'SAVE_DRAFT';
const deployed = 'DEPLOYED';

const setTab = 'SET_TAB';
const showModal = 'SHOW_MODAL';

const setFormIaas = 'SET_FORM_IAAS';

//const duplicateAzureEnvironment = 'DUPLICATE_AZURE_ENVIRONMENT';
const errors = 'ERRORS';

//const requestInfra = 'REQUEST_INFRA';
//const submitInfraRequest = 'SUBMIT_INFRA_REQUEST';
const persistFilterObj = 'PERSIST_FILTER_OBJ';

const requestPaas = 'REQUEST_PAAS';
const editingPaasObjects = 'EDITING_PAAS_OBJECTS';
const setFormPaas = 'SET_FORM_PAAS';
const savePaasDraft = 'SAVE_PAAS_DRAFT';

const amsAccessChecked = 'AMS_ACCESS_CHECKED';

const requestNsgResourceGroups = 'REQUEST_NSG_RESOURCE_GROUPS';

const initialState = {
    isLoading: false,
    startIndex: 1,
    pageSize: 5,
    activeTab: [],
    error: null,
    modal: {
        show: false,
        title: '',
        color: '',
        text: ''
    },
    action: null, // importing, imported, listing, inserting, inserted, deployed
    lookups: [],
    previewing: false,
    summary: false,

    azureObjects: [],
    validators: {
        environments: []
    },
    formIaas: {},
    formPaas: {
        settings: []
    },
    formValid: false,
    filters: {},
    amsAccess: {
        loading: true,
        accessToken: false,
        refreshToken: false
    },
    nsgResourceGroups:[]
};

export const actionCreators = {

    requestLookup: (lookupNames) => async (dispatch, getState) => {
        //if (this.state.lookup.contains(lookupName)) {
        //    // Don't issue a duplicate request (we already have or are loading the requested data)
        //    return;
        //}
        dispatch({ type: requestLookup });
        const url = 'api/LookupValues/lookups';

        adalApiFetch(fetch, url, {
            method: 'POST',
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(lookupNames)
        })
            .then((response) => {
                console.log(response);
                response.json()
                    .then((resultData) => {
                        var _lookups = getState().storeObjects.lookups || [];

                        //var resultData = action.resultData;

                        var currentLookupsRead = [...new Set(resultData.map(x => x.lookup))];

                        currentLookupsRead.forEach((current, idx) => {

                            var filtered = resultData
                                .filter(
                                    item => item.lookup === current
                                ).map(
                                    id => {
                                        return { code: id.lookupValueCode, description: id.lookupValueDescription };
                                    });

                            if (_lookups.filter(item => item.id === current).length > 0) {
                                _lookups[current] = filtered;
                            }
                            else {
                                _lookups.push({ id: current, values: filtered });
                            }


                            if (idx === currentLookupsRead.length - 1) {
                                dispatch({ type: receiveLookup, lookups: _lookups });
                            }
                        });

                    });
            })
            .catch((error) => {
                console.error(error);
            });

    },

    requestInfra: (formData) => async (dispatch, getState) => {
        const url = `api/Azure/RequestInfra?provInfraStatusID=${formData.provisionInfraStatusID}`;

        adalApiFetch(fetch, url, {})
            .then((response) => {
                response.json()
                    .then((azureObject) => {
                        try {
                            dispatch({ type: setTab, name: 'environment', tabId: azureObject.environments[0].name });
                        }
                        catch (e) {
                            dispatch({ type: setTab, name: 'environment', tabId: '' });
                        }

                        azureObject.assetID = formData.assetID;
                        azureObject.applicationName = formData.applicationName;
                        azureObject.resourceGroup = formData.resourceGroupID;
                        azureObject.company = formData.supportedOrganizations;
                        azureObject.provisionInfraStatusID = formData.provisionInfraStatusID;




                        // Setting resourcegroup status
                        if (formData.resourceGroupCreated === 'true') {
                            azureObject.resourceGroupStatus = 'existing';
                        }
                        else if (formData.resourceGroupSubmitted === 'true') {
                            azureObject.resourceGroupStatus = 'requested';
                        }
                        else {
                            azureObject.resourceGroupStatus = 'missing';
                        }




                        // Action based on azureobject status
                        if (azureObject.status === 'deployed') {
                            dispatch({ type: deployed, formData: azureObject, action: azureObject.status });

                        }
                        else {
                            dispatch({ type: editingAzureObjects, formData: azureObject });

                        }

                        dispatch(push('/cloudiaas'));
                        //dispatch({ type: redirect, formData: azureObject });
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    },

    submitInfraRequest: (formData) => async (dispatch, getState) => {

        const url = 'api/Azure/SubmitInfraRequest';

        adalApiFetch(fetch, url, {
            method: 'POST',
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then((response) => {
                response.json()
                    .then((azureObject) => {
                        azureObject.status = "requested";

                        //azureObject.assetID = formData.assetID;
                        //azureObject.company = formData.company;
                        //azureObject.applicationName = formData.applicationName;
                        //azureObject.resourceGroup = formData.resourceGroup;
                        //azureObject.resourceGroupStatus = formData.resourceGroupStatus;
                        //azureObject.status = formData.status;
                        //azureObject.provisionInfraStatusID = formData.provisionInfraStatusID;

                        dispatch({ type: summaryAzureObjects, formData: azureObject, summary: "Your Infrastructure has been requested." });
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    },

    importingAzureObjects: (formData) => async (dispatch, getState) => {
        var azureObject = {
            status: 'importing',
            assetID: formData.assetID,
            applicationName: formData.applicationName,
            resourceGroup: formData.resourceGroupID,
            company: formData.supportedOrganizations ? formData.supportedOrganizations : formData.company,
            provisionInfraStatusID: formData.provisionInfraStatusID
        };

        if (formData.resourceGroupCreated === 'true') {
            azureObject.resourceGroupStatus = 'existing';
        }
        else if (formData.resourceGroupSubmitted === 'true') {
            azureObject.resourceGroupStatus = 'requested';
        }
        else {
            azureObject.resourceGroupStatus = 'missing';
        }

        dispatch({ type: importingAzureObjects, formData: azureObject });
        dispatch(push('/cloudiaas'));
    },

    importAzureObjects: (formData) => async (dispatch, getState) => {

        //dispatch({ type: submitAzureObjects, formData });

        let data = new FormData();
        //formData.Files.map((item, i) => {
        //    data.append(item.id, item.files[0]);
        //});

        for (var property in formData.Files) {
            data.append(property, formData.Files[property]);
        }

        for (var propertyObj in formData.ParamFiles) {
            data.append(propertyObj, formData.ParamFiles[propertyObj]);
        }

        data.append('provisionInfraStatusID', formData.provisionInfraStatusID);
        data.append('assetID', formData.assetID);

        const url = 'api/Azure/ToAzureObject';

        adalApiFetch(fetch, url, {
            method: 'POST',
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                //"Accept": 'application/json',
                //"Content-Type": "multipart/form-data"
                //Accept: "application/x-www-form-urlencoded",
            },
            body: data
        })
            .then((response) => {


                if (response.status === 200) {
                    response.json()
                        .then((azureObject) => {
                            azureObject.status = "importing";

                            azureObject.assetID = formData.assetID;
                            azureObject.company = formData.company;
                            azureObject.applicationName = formData.applicationName;
                            azureObject.resourceGroup = formData.resourceGroup;
                            azureObject.resourceGroupStatus = formData.resourceGroupStatus;
                            azureObject.status = formData.status;
                            azureObject.provisionInfraStatusID = formData.provisionInfraStatusID;

                            dispatch({ type: editingAzureObjects, formData: azureObject });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                else {
                    formData.status = 'importing';
                    //dispatch({
                    //    type: setFormIaas, formIaas: formData
                    //});

                    let error = "There's an error processing your files, please try again.";
                    let modal = {
                        show: true,
                        title: 'Error',
                        color: 'danger',
                        text: error
                    };
                    dispatch({ type: showModal, modal });
                }






            })
            .catch((error) => {
                console.error(error);
            });
    },

    importedAzureObjects: () => async (dispatch, getState) => {
        var url = '';

        var formData = getState().storeObjects.formIaas;
        formData.status = 'imported';

        if (formData.azureObjectID > 0) {
            url = 'api/Azure/NewRequest';
        }
        else {
            url = 'api/Azure/Import';
        }


        adalApiFetch(fetch, url, {
            method: 'POST',
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json()
                        .then((data) => {
                            //if (response.)
                            dispatch({ type: submittedAzureObjects, status: 'imported' });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                else {
                    formData.status = 'importing';
                    dispatch({
                        type: setFormIaas, formIaas: formData
                    });

                    let error = "";
                    response.json().then((data) => {
                        for (var propertyName in data) {
                            error += propertyName + '-> ' + data[propertyName] + '. ';
                        }
                        let modal = {
                            show: true,
                            title: 'Error',
                            color: 'danger',
                            text: error
                        };
                        dispatch({ type: showModal, modal });
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });

    },

    insertingAzureObjects: () => async (dispatch, getState) => {

        dispatch({ type: insertingAzureObjects });
    },

    editingAzureObjects: formData => async (dispatch, getState) => {
        //dispatch({ type: editingAzureObjects, formData });

        //console.log(formData);

        const url = `api/Azure/GetRequest?ID=${formData.azureObjectID}`;

        //const response = await fetch(url).then(response => response.json()).then(azureObjects => {
        //    dispatch({ type: receivedAzureObjects, startIndex, azureObjects });
        //});

        adalApiFetch(fetch, url, {})
            .then((response) => {
                response.json()
                    .then((azureObject) => {
                        try {
                            dispatch({ type: setTab, name: 'environment', tabId: azureObject.environments[0].name });
                        }
                        catch (e) {
                            dispatch({ type: setTab, name: 'environment', tabId: '' });
                        }
                        dispatch({ type: editingAzureObjects, formData: azureObject });
                    });
            })
            .catch((error) => {
                console.error(error);
            });


    },

    submitAzureObjects: (formData) => async (dispatch, getState) => {
        const url = 'api/Azure/NewRequest';

        var formData = getState().storeObjects.formIaas;
        formData.status = 'requested';

        // OS System for each image
        if (formData.environments) {
            if (formData.environments.length > 0) {
                //validators.environments = [];
                formData.environments.map(function (env, envIdx) {
                    if (env.compute) {
                        env.compute.map(function (comp, compIdx) {
                            if (comp.vm.image) {
                                try {
                                    let name = comp.vm.image.split('/')[0];
                                    if (name === "MicrosoftWindowsServer") {
                                        comp.vm.operatingSystem = "Windows";
                                    }
                                    if (name === "MicrosoftSQLServer") {
                                        comp.vm.operatingSystem = "Windows";
                                    }
                                    if (name === "Redhat") {
                                        comp.vm.operatingSystem = "Linux";
                                    }
                                }
                                catch (e) {
                                    console.log('No image selected');
                                }

                            }
                        });
                    }
                });
            }
        }


        adalApiFetch(fetch, url, {
            method: 'POST',
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json()
                        .then((resultData) => {

                            let modal = {
                                show: true,
                                title: 'Request Infrastructure',
                                color: 'success',
                                text: "Request Submitted to Infrastructure Team successfully"
                            };
                            dispatch({ type: showModal, modal });


                            dispatch({ type: saveDraft, formData: resultData });

                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                else {
                    let error = "";
                    response.json().then((data) => {
                        for (var propertyName in data) {
                            error += ' ' + propertyName + '-> ' + data[propertyName] + '.';
                        }
                        let modal = {
                            show: true,
                            title: 'Error',
                            color: 'danger',
                            text: error
                        };
                        dispatch({ type: showModal, modal });
                    });
                }


            })
            .catch((error) => {
                console.error(error);
            });


    },

    previewAzureObjects: preview => async (dispatch, getState) => {
        dispatch({ type: previewAzureObjects, preview });
    },

    formValidAzureObjects: formValid => async (dispatch, getState) => {
        dispatch({ type: formValidAzureObjects, formValid });
    },

    listingProvisioningInfra: () => async (dispatch, getState) => {
        var azureObjects = {};
        dispatch({ type: receivedAzureObjects, startIndex: 0, azureObjects }); // to-do: remove
        dispatch(push('/provisioninfrastatus'));
    },

    showAzureObjHistory: (azureObjectID, newTab) => async (dispatch, getState) => {
        if (!azureObjectID) {
            alert('No active row to show history!');
            return Promise.resolve();
        }
        const historyUrl = `hist/azureIaaS/${azureObjectID}`;
        if (newTab) {
            window.open(historyUrl, '_blank');
            return Promise.resolve();
        } else {
            dispatch(push(historyUrl));
        }
    },


    listingAzureObjects: (startIndex, pageSize) => async (dispatch, getState) => {
        //if (startDateIndex === getState().azureObjects.startDateIndex) {
        //    // Don't issue a duplicate request (we already have or are loading the requested data)
        //    return;
        //}

        dispatch({ type: listingAzureObjects, startIndex, pageSize });

        if (startIndex === null) {
            startIndex = 1;
        }
        if (pageSize === null) {
            pageSize = 1000;
        }
        const url = `api/Azure/ListRequests?startIndex=${startIndex}&pageSize=${pageSize}`;

        adalApiFetch(fetch, url, {})
            .then((response) => {
                response.json()
                    .then((azureObjects) => {
                        dispatch({ type: receivedAzureObjects, startIndex, azureObjects });
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    },

    setFormIaas: formIaas => async (dispatch, getState) => {
        dispatch({ type: setFormIaas, formIaas });
    },

    setFormValidators: (validators, formValid) => async (dispatch, getState) => {
        dispatch({ type: setFormValidators, validators: validators, formValid: formValid });
    },

    saveDraft: () => async (dispatch, getState) => {
        const url = 'api/Azure/NewRequest';

        var formData = getState().storeObjects.formIaas;
        formData.status = 'draft';

        // OS System for each image
        if (formData.environments) {
            if (formData.environments.length > 0) {
                //validators.environments = [];
                formData.environments.map(function (env, envIdx) {
                    if (env.compute) {
                        env.compute.map(function (comp, compIdx) {
                            if (comp.vm.image) {
                                try {
                                    let name = comp.vm.image.split('/')[0];
                                    if (name === "MicrosoftWindowsServer") {
                                        comp.vm.operatingSystem = "Windows";
                                    }
                                    if (name === "MicrosoftSQLServer") {
                                        comp.vm.operatingSystem = "Windows";
                                    }
                                    if (name === "Redhat") {
                                        comp.vm.operatingSystem = "Linux";
                                    }
                                }
                                catch (e) {
                                    console.log('No image selected');
                                }

                            }
                        });
                    }
                });
            }
        }


        adalApiFetch(fetch, url, {
            method: 'POST',
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json()
                        .then((resultData) => {

                            let modal = {
                                show: true,
                                title: 'Draft',
                                color: 'success',
                                text: "Draft saved successfully"
                            };
                            dispatch({ type: showModal, modal });


                            dispatch({ type: saveDraft, formData: resultData });
                            //dispatch({ type: submittedAzureObjects, resultData, status: 'draft' });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                else {
                    let error = "";
                    response.json().then((data) => {

                        //var errors =
                        //    data.map((property, idx) => {
                        //        return (<div key={idx + 1}>{property} -> {data.message}</div>);
                        //    });


                        for (var propertyName in data) {
                            error += ' ' + propertyName + '-> ' + data[propertyName] + '.';
                        }
                        let modal = {
                            show: true,
                            title: 'Error',
                            color: 'danger',
                            text: error
                        };
                        dispatch({ type: showModal, modal });
                    });
                }


            })
            .catch((error) => {
                console.error(error);
            });
    },

    setTab: (name, tabId) => async (dispatch, getState) => {
        dispatch({ type: setTab, name, tabId });
    },

    showModal: (show, title, color, text) => async (dispatch, getState) => {
        let modal = {
            show: show,
            title: title,
            color: color,
            text: text
        };
        dispatch({ type: showModal, modal });
    },

    downloadFileAzureObjects: (env) => async (dispatch, getState) => {
        var formData = getState().storeObjects.formIaas;

        if (env !== 'deploymentfile') {
            const url = 'api/Azure/DownloadFile?id=' + formData.azureObjectID + '&env=' + env;

            adalApiFetch(fetch, url, {})
                .then((response) => {
                    response.json()
                        .then((fileDownloaded) => {
                            fileDownload(JSON.stringify(fileDownloaded), 'azuredeploy.parameter-' + env.toLowerCase() + '.json');
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        if (env === 'deploymentfile') {
            const url = 'api/Azure/DownloadDeployFile?id=' + formData.azureObjectID;

            adalApiFetch(fetch, url, {})
                .then((response) => {
                    response.json()
                        .then((fileDownloaded) => {
                            fileDownload(JSON.stringify(fileDownloaded), 'azuredeploy.json');
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    },

    downloadMiscFiles: (labelfile) => async (dispatch, getState) => {
        var formData = getState().storeObjects.formIaas;



        const url = 'api/dbfiles/download/' + labelfile;

        adalApiFetch(fetch, url, {})
            .then(response => response.blob())
            .then(data => {
                fileDownload(data, labelfile + '.zip');
            })
            .catch((error) => {
                console.error(error);
            });


    },


    //duplicateAzureEnvironment: (formData, env) => async (dispatch, getState) => {

    //    dispatch({ type: reinsertingAzureObjects });

    //    var newEnvironment = formData.environments.filter(environment => {
    //        return environment.environmentName === env;
    //    });
    //    if (newEnvironment.length > 0) {
    //        formData.environments.push(newEnvironment[0]);
    //    }

    //    dispatch({ type: editingAzureObjects, formData });
    //},

    persistFilterObj: (filterObj) => async (dispatch, getState) => {
        dispatch({ type: persistFilterObj, filterObj });
    },


    requestPaas: (formData) => async (dispatch, getState) => {
        const url = `api/Paas/RequestPaas?ID=${formData.applicationSpinID}`;

        adalApiFetch(fetch, url, {})
            .then((response) => {
                response.json()
                    .then((paasObject) => {
                        //try {
                        //    dispatch({ type: setTab, name: 'environment', tabId: azureObject.environments[0].name });
                        //}
                        //catch (e) {
                        //    dispatch({ type: setTab, name: 'environment', tabId: '' });
                        //}

                        //paasObject.assetID = formData.assetID;
                        //paasObject.applicationName = formData.applicationName;
                        //paasObject.resourceGroup = formData.resourceGroup;
                        //paasObject.company = formData.supportedOrganizations;
                        //paasObject.provisionInfraStatusID = formData.provisionInfraStatusID;




                        // Setting resourcegroup status
                        if (formData.resourceGroupCreated === 'true') {
                            paasObject.resourceGroupStatus = 'existing';
                        }
                        else if (formData.resourceGroupSubmitted === 'true') {
                            paasObject.resourceGroupStatus = 'requested';
                        }
                        else {
                            paasObject.resourceGroupStatus = 'missing';
                        }




                        // Action based on azureobject status
                        if (paasObject.status === 'deployed') {
                            //dispatch({ type: deployed, formData: azureObject, action: azureObject.status });

                        }
                        else {
                            dispatch({ type: editingPaasObjects, formData: paasObject });

                        }

                        dispatch(push('/cloudpaas'));
                        //dispatch({ type: redirect, formData: azureObject });
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    },

    setFormPaas: formPaas => async (dispatch, getState) => {
        dispatch({ type: setFormPaas, formPaas });
    },

    savePaasDraft: () => async (dispatch, getState) => {
        const url = 'api/Paas/NewRequest';

        var formData = getState().storeObjects.formPaas;
        formData.status = 'draft';

        adalApiFetch(fetch, url, {
            method: 'POST',
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json()
                        .then((resultData) => {

                            let modal = {
                                show: true,
                                title: 'Draft',
                                color: 'success',
                                text: "Draft saved successfully"
                            };
                            dispatch({ type: showModal, modal });


                            dispatch({ type: savePaasDraft, formData: resultData });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                else {
                    let error = "";
                    response.json().then((data) => {

                        //var errors =
                        //    data.map((property, idx) => {
                        //        return (<div key={idx + 1}>{property} -> {data.message}</div>);
                        //    });


                        for (var propertyName in data) {
                            error += ' ' + propertyName + '-> ' + data[propertyName] + '.';
                        }
                        let modal = {
                            show: true,
                            title: 'Error',
                            color: 'danger',
                            text: error
                        };
                        dispatch({ type: showModal, modal });
                    });
                }


            })
            .catch((error) => {
                console.error(error);
            });
    },

    listingAppSpin: () => async (dispatch, getState) => {
        //var azureObjects = {};
        //dispatch({ type: receivedAzureObjects, startIndex: 0, azureObjects }); // to-do: remove
        dispatch(push('/appspins'));
    },

    downloadFilePaasObjects: (env) => async (dispatch, getState) => {
        var formData = getState().storeObjects.formPaas;

        var type = env === 'deploy' ? '&type=deploy' : '&type=param';
        var fileName = env === 'deploy' ? 'azuredeploy.json' : 'azuredeploy.parameter.json';

        //formData.paasObjectID = 1;

        const url = 'api/Paas/DownloadFile?id=' + formData.paasObjectID + type;

        adalApiFetch(fetch, url, {})
            .then((response) => {
                response.json()
                    .then((fileDownloaded) => {
                        fileDownload(JSON.stringify(fileDownloaded), fileName);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    },

    setAmsAccess: (authCode) => async (dispatch, getState) => {
        const url = `api/authorization/setamsaccess`;
        const options = {
            method: 'POST',
            body: JSON.stringify({ authCode: authCode }),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        adalApiFetch(fetch, url, options)
            .then((response) => {
                response.json()
                    .then((amsAccessObject) => {
                        dispatch({ type: amsAccessChecked, amsAccessObject: amsAccessObject });
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    },

    checkAmsAccess: () => async (dispatch, getState) => {
        const url = `api/authorization/checkamsaccess`;

        adalApiFetch(fetch, url, {})
            .then((response) => {
                response.json()
                    .then((amsAccessObject) => {
                        dispatch({ type: amsAccessChecked, amsAccessObject: amsAccessObject });
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    },

    requestNsgResourceGroups: () => async (dispatch, getState) => {
        const url = `api/nsglist/resourcegroups`;

        adalApiFetch(fetch, url, {})
            .then((response) => {
                response.json()
                    .then((data) => {
                        dispatch({ type: requestNsgResourceGroups, data });
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }

};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestLookup) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === receiveLookup) {

        return {
            ...state,
            lookups: action.lookups,
            isLoading: false
        };
    }


    if (action.type === insertingAzureObjects) {
        return {
            ...state,
            formIaas: {},
            errors: null,
            action: 'inserting'
        };
    }
    if (action.type === reinsertingAzureObjects) {
        return {
            ...state,
            action: null,
            errors: null
        };
    }
    if (action.type === editingAzureObjects) {

        var formData = action.formData;

        if (!formData.status) {
            formData.status = 'new';
        }


        return {
            ...state,
            azureObjects: [],
            formIaas: action.formData,
            action: 'inserting',
            errors: null,
            formValid: false,
            previewing: false
        };
    }

    if (action.type === submitAzureObjects) {
        return {
            ...state,
            formData: action.formData,
            isLoading: true,
            errors: null
        };
    }
    if (action.type === previewAzureObjects) {
        return {
            ...state,
            previewing: action.preview,
            errors: null
        };
    }
    if (action.type === formValidAzureObjects) {
        return {
            ...state,
            formValid: action.formValid,
            errors: null
        };
    }
    if (action.type === submittedAzureObjects) {

        var summary = false;

        if (action.status) {
            switch (action.status) {
                case 'draft':
                    summary = 'Your IaaS Deployment Request Draft has been saved.';
                    break;
                case 'imported':
                    summary = 'Your IaaS Deployment Files has been imported.';
                    break;
                default:
                    summary = "Your IaaS Deployment Request has been saved.";
            }
        }

        return {
            ...state,
            summary: summary,
            errors: null,
            action: 'inserted',
            isLoading: false,
            previewing: false
        };
    }

    if (action.type === listingAzureObjects) {
        return {
            ...state,
            startDateIndex: action.startDateIndex,
            pageSize: action.pageSize,
            isLoading: true,
            errors: null
        };
    }

    if (action.type === setFormIaas) {
        return {
            ...state,
            errors: null,
            modified: new Date().getMilliseconds(),
            formIaas: action.formIaas
        };
    }

    if (action.type === setTab) {

        var activeTab = state.activeTab;
        if (activeTab) {
            activeTab = activeTab.filter(t => t.name !== action.name);
            activeTab.push({
                name: action.name,
                tabId: action.tabId
            });
        }
        else {
            activeTab = [{
                name: action.name,
                tabId: action.tabId === undefined ? 'none' : action.tabId
            }];
        }

        return {
            ...state,
            activeTab: activeTab
        };
    }

    if (action.type === showModal) {
        return {
            ...state,
            modal: action.modal
        };
    }

    if (action.type === importingAzureObjects) {
        return {
            ...state,
            azureObjects: [],
            formIaas: action.formData,
            errors: null,
            action: 'importing',
            summary: false,
            formValid: false,
            previewing: false
        };
    }
    if (action.type === summaryAzureObjects) {
        return {
            ...state,
            summary: action.summary,
            formIaas: action.formData,
            action: 'inserted',
            previewing: false,
            errors: null,
            activeTab: []
        };
    }

    if (action.type === setFormValidators) {
        return {
            ...state,
            validators: action.validators,
            formValid: action.formValid
        };
    }

    if (action.type === saveDraft) {
        state.formIaas.azureObjectID = action.formData.azureObjectID;
        return {
            ...state,
            previewing: action.previewing ? action.previewing : state.previewing
        };
    }

    if (action.type === errors) {
        return {
            ...state,
            error: action.error
        };
    }

    if (action.type === deployed) {
        return {
            ...state,
            azureObjects: [],
            formIaas: action.formData,
            action: action.action,
            errors: null,
            formValid: false,
            previewing: true
        };

    }

    if (action.type === persistFilterObj) {
        return {
            ...state,
            filters: {
                ...state.filters,
                [action.filterObj.key]: action.filterObj.data
            }
        };
    }



    /* PAAS */


    if (action.type === editingPaasObjects) {


        if (!action.formData.status) {
            action.formData.status = 'new';
        }
        if (!action.formData.settings) {
            action.formData.settings = [];
        }
       
        //if (!action.formData.app.database) {
        //    action.formData.app.database = [];
        //}

        return {
            ...state,
            azureObjects: [],
            formPaas: action.formData,
            action: 'inserting',
            errors: null,
            formValid: false,
            previewing: false
        };
    }

    if (action.type === setFormPaas) {
        return {
            ...state,
            errors: null,
            modified: new Date().getMilliseconds(),
            formPaas: action.formPaas
        };
    }

    if (action.type === savePaasDraft) {
       // state.formPaas.paasObjectID = action.formData.paaseObjectID;
        return {
            ...state,
            previewing: action.previewing ? action.previewing : state.previewing,
            formPaas: action.formData
        };
    }

    if (action.type === amsAccessChecked) {
        return {
            ...state,
            errors: null,
            amsAccess: {
                ...action.amsAccessObject,
                loading:false
            }
        };
    }

    if (action.type === requestNsgResourceGroups) {
        return {
            ...state,
            nsgResourceGroups: action.data
        };
    }

    return state;
};
