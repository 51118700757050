import React from 'react';
import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonImport,
    DataGridToolbarCheckBox
} from '../BaseComponents';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

const Toolbar = () => {
    return (
        <DataGridToolbar
            title="Tech Transformation"
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarCheckBox
                    id="showAllCheckBox"
                    filterOrder="1"
                    filterWhenChecked="all"
                    filterWhenUnchecked="enabled"
                    caption="Show Deleted"
                />
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonImport />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const TechTransformation = () => {
    return (
        <DataGrid model='techTransformation'>
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, TechTransformation, "TechTransformation");