import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';
import { IfAuthorized, AuthorizationPolicies } from '../BaseComponents/Authorization';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarCheckBox,
    DataGridToolbarButton,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonImport,
    DataGridToolbarSplitButton,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';

import DuPont from '../../jscomponents/DuPontApp';

export class SapUserRequests extends Component {

    constructor(props) {
        super(props);
    }

    onGridInit = (gridComponent) => {
        this.gridComponent = gridComponent;
    }

    render() {
        const { currentSpin, crossSpinDataHidden } = DuPont.App.Config;

        return (
            <DataGrid model='sapUserRequests' onGridInit={this.onGridInit}>
                <DataGridToolbar title="SAP User Requests">
                    <IfAuthorized policy={AuthorizationPolicies.SapUserReqAllowSubmit}>
                        <DataGridToolbarButtonsGroup>
                            <DataGridToolbarButtonSave />
                            <DataGridToolbarButtonDiscard />
                        </DataGridToolbarButtonsGroup>
                    </IfAuthorized>
                    <DataGridToolbarButtonsGroup>
                        <DataGridToolbarButtonRefresh />
                        <IfAuthorized policy={AuthorizationPolicies.SapUserReqAllowSubmit}>
                            <DataGridToolbarButtonImport />
                        </IfAuthorized>
                    <DataGridToolbarButtonExport />
                    </DataGridToolbarButtonsGroup>
                    <DataGridToolbarButtonsGroup>
                        <DataGridToolbarButtonHistory />
                        <DataGridToolbarFavoritesSplitButton />
                    </DataGridToolbarButtonsGroup>
                </DataGridToolbar>
            </DataGrid>

            );
    }
}
export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withAITracking(ai.reactPlugin, SapUserRequests, "SapUserRequests"));