import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/storeObject';


import { Button, Col, FormGroup, Label } from 'reactstrap';
import Probes from './Probes';

class LoadBalancer extends Component {

    constructor(props) {
        super(props);
        try {
            this.props.setTab('ilb', this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[0].loadBalancerName);
        }
        catch (e) {
            console.log("There's no ilb" + e);
        }
    }

    /*
     *                     <Col md={2}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                                type="text"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].loadBalancerName + '-ilb'}
                                placeholder="Description"
                                readOnly
                            />
                        </FormGroup>
                    </Col>
                    */

    render() {
        return (
            <div>
                <FormGroup row>

                    <Col md={2}>
                        <Label for="delete"> &nbsp;</Label>
                        <Button type="button" color="danger" onClick={() => this.props.removeLoadBalancer(this.props.compIdx, this.props.ilbIdx)}><i className="fa fa-trash" /> Delete {this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].loadBalancerName + '-ilb'}</Button>

                    </Col>
                    <Col md={6}>
                        <Label for="delete"> &nbsp;</Label>
                        <Button color="primary" onClick={() => this.props.addProbe(this.props.compIdx, this.props.ilbIdx)}><i className="fa fa-plus" />Add Health Probe</Button>
                    </Col>
                </FormGroup>

                <Probes
                    envIdx={this.props.envIdx}
                    compIdx={this.props.compIdx}
                    ilbIdx={this.props.ilbIdx}

                    formIaas={this.props.formIaas}

                    removeProbe={this.props.removeProbe}
                    addPortMapping={this.props.addPortMapping}
                    removePortMapping={this.props.removePortMapping}
                    handleChange={this.props.handleChange}
                />

            </div>
        );

    }
}

//export default LoadBalancer;
export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(LoadBalancer);

/*
 *  <FormGroup row>
                    <Col md={8}>
                        <legend className="col-md-10">Rules</legend>
                    </Col>
                    <Col md={2}>
                        <Button color="primary" onClick={() => this.props.addPortMapping(this.props.compIdx, this.props.ilbIdx)}><i className="fa fa-plus" /> Rule</Button>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col md={12}>
                        <PortMapping
                            envIdx={this.props.envIdx}
                            compIdx={this.props.compIdx}
                            ilbIdx={this.props.ilbIdx}
                            removePortMapping={this.props.removePortMapping} />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col md={12}>
                        <legend className="col-md-12">Health Probe</legend>
                    </Col>
                </FormGroup>

                <FormGroup row>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="name">Protocol</Label>
                            <Input
                                type="text"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.protocol'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.protocol'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe.protocol : null}
                                placeholder="TCP/UDP/HTTP"

                            />
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="name">Port</Label>
                            <Input
                                type="text"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.port'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.port'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe.port : null}
                                placeholder="Port Number"

                            />
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="name">Uri</Label>
                            <Input
                                type="text"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.uri'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.uri'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe.uri : null}
                                placeholder="http://"

                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="name">Uri</Label>
                            <Input
                                type="text"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.interval'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.interval'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe.interval : null}
                                placeholder="5"

                            />
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="name">Number of Probes</Label>
                            <Input
                                type="text"
                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.numberOfProbes'}
                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.loadbalancer.' + this.props.ilbIdx + '.healthProbe.numberOfProbes'}
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe ? this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.loadBalancer[this.props.ilbIdx].healthProbe.numberOfProbes : null}
                                placeholder="2"

                            />
                        </FormGroup>
                    </Col>

                </FormGroup>
                */