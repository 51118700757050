import React from 'react';
import NavMenu from './UI/NavMenu/NavMenu';

const Layout = props => (
    <div>
        <NavMenu />
        <main role="main" className="container">
            {props.children}
        </main>
    </div>
);

export default Layout;

/*
 export default props => (
  <Grid fluid>
    <Row>
      <Col sm={3}>
        <NavMenu />
      </Col>
      <Col sm={9}>
        {props.children}
      </Col>
    </Row>
  </Grid>
);*/