import React from 'react';
import { EditModal } from '../BaseComponents/Modals';

const AppStatusEdit = () => {
    return (
        <EditModal id="appStatusEditModal" title="App Status Edit">
            <div className="form-group mb-0">
                <label htmlFor="notes">Notes:</label>
                <textarea className="form-control form-control-sm" id="notes" name="notes" rows="15" />
            </div>
        </EditModal>
    );
};

export default AppStatusEdit;