import $ from 'jquery';
import 'select2';

$.fn.select2.amd.require(
    [
        'select2/dropdown/attachBody'
    ],
    function (AttachBody) {

        AttachBody.prototype._attachPositioningHandler =
            function (decorated, container) {
                // override code here

                //var self = this;

                //var scrollEvent = 'scroll.select2.' + container.id;
                //var resizeEvent = 'resize.select2.' + container.id;
                //var orientationEvent = 'orientationchange.select2.' + container.id;

                //var $watchers = this.$container.parents().filter(Utils.hasScroll);
                //$watchers.each(function () {
                //  $(this).data('select2-scroll-position', {
                //    x: $(this).scrollLeft(),
                //    y: $(this).scrollTop()
                //  });
                //});

                //$watchers.on(scrollEvent, function (ev) {
                //  var position = $(this).data('select2-scroll-position');
                //  $(this).scrollTop(position.y);
                //});

                //$(window).on(scrollEvent + ' ' + resizeEvent + ' ' + orientationEvent,
                //  function (e) {
                //  self._positionDropdown();
                //  self._resizeDropdown();
                //});
            };

        //// simple override
        //AttachBody.prototype._positionDropdown = function () {
        //    // override code here
        //};

        //// or if you want to keep access to the original method
        //AttachBody.prototype._positionDropdown = (function (originalMethod) {

        //    return function () {
        //        // override code here
        //    }

        //}(AttachBody.prototype._positionDropdown));

    },
    undefined,
    true
);