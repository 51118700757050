import React from 'react';

const DownloadModal = () => {
    return (
        <div className="modal fade" id="downloadModal" tabIndex="-1" role="dialog" aria-labelledby="downloadModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <form className="needs-validation form-horizontal" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Export</h5>
                            <button type="button" className="close btn-close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger d-none status-alert" role="alert" />

                            <div className="form-group row d-none">
                                <label htmlFor="exportRows" className="col-md-2 col-form-label">Export</label>
                                <div className="col-md-4">
                                    <select type="text" className="form-control form-control-sm" id="exportRows" name="exportRows">
                                        <option value="0">all rows</option>
                                        <option value="1">filtered rows</option>
                                    </select>
                                </div>
                            </div>
                            <div id="withTrackChangesContainer" className="form-group row d-none">
                                <div className="col-md-2">
                                    <label className="form-check-label" htmlFor="withTrackChanges">
                                        Track changes
                                </label>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="withTrackChanges" name="withTrackChanges" value="true" />
                                    </div>
                                </div>
                            </div>

                            <div className="form-row mt-2">
                                <label className="col-form-label progress-text" />
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <label className="col-form-label time-text" />
                            <div style={{ visibility: 'hidden', flexGrow: '1' }}>ie_fix</div>
                            <button type="button" className="btn btn-sm btn-primary d-none" id="downloadFromServer">Download</button>
                            <button type="button" className="btn btn-sm btn-secondary btn-close" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DownloadModal;