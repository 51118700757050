import React, { Component } from 'react';

import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, Form, FormGroup, Label, Input, Alert, Card, CardText, CardBody, CardTitle, CardSubtitle, CardLink } from 'reactstrap';
import classnames from 'classnames';


class WebSite extends Component {

	constructor(props) {
		super(props);

		this.appTier = [
			{
				value: 'B1',
				title: 'Basic B1 - 1 Core',
				text: '1.75 RAM - 10GB Storage'
			},
			{
				value: 'B2',
				title: 'Basic B2 - 2 Cores',
				text: '3.5 RAM - 10GB Storage'
			},
			{
				value: 'B3',
				title: 'Basic B3 - 4 Cores',
				text: '7 RAM - 10GB Storage'
			},
			{
				value: 'S1',
				title: 'Standard S1 - 1 Core',
				text: '1.75 RAM - 50GB Storage'
			}, {
				value: 'S2',
				title: 'Standard S2 - 2 Cores',
				text: '3.5 RAM - 50GB Storage'
			}, {
				value: 'S3',
				title: 'Standard S3 - 4 Cores',
				text: '7 RAM - 50GB Storage'
			},
			{
				value: 'P1v2',
				title: 'Premium P1v2 - 1 Core',
				text: '3.5 RAM - 250GB Storage'
			},
			{
				value: 'P2v2',
				title: 'Premium P2v2 - 2 Cores',
				text: '7 RAM - 250GB Storage'
			},
			{
				value: 'P3v2',
				title: 'Premium P3v2 - 4 Cores',
				text: '14 RAM - 250GB Storage'
			},
			{
				value: 'I1',
				title: 'Isolated I1 - 1 Core',
				text: '3.5 RAM - 1024GB Storage'
			},
			{
				value: 'I2',
				title: 'Isolated I2 - 2 Cores',
				text: '7 RAM - 1024GB Storage'
			},
			{
				value: 'I3',
				title: 'Isolated I3 - 4 Cores',
				text: '14 RAM - 1024GB Storage'
			}
		];

	}

	render() {

		var currentAppTier = this.appTier.filter(f => f.value === this.props.formPaas.settings[this.props.appIdx].appTier);
		currentAppTier = currentAppTier.length > 0 ? currentAppTier[0] : currentAppTier;

		return (
			<div className="applicationSet">
				<FormGroup row>
					<Col md={2}>
						<FormGroup>
							<Label for="location">Location</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.siteLocation'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.siteLocation'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteLocation : ""}
								required
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteLocation ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteLocation.length <= 0 ? false : true) : false : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteLocation ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteLocation.length <= 0 ? true : false) : true : true}

							>
								<option value=""> Choose an option </option>
								{
									this.props.getEnumValues('locationEnum').map(c => {
										return <option key={c.code} value={c.code} >{c.description}</option>;
									})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select a Location
                                </div>
						</FormGroup>
					</Col>



					<Col md={2}>
						<FormGroup>
							<Label for="classification">Classification</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.classification'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.classification'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].classification : ""}
								required
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].classification ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].classification.length <= 0 ? false : true) : false : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].classification ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].classification.length <= 0 ? true : false) : true : true}
							>
								<option value=""> Choose One Option </option>
								{
									this.props.getEnumValues('classificationEnum').map(c => {
										return <option key={c.code} value={c.code}>{c.description}</option>;
									})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select a Classification
                                </div>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<Label for="appDomain">App Name</Label>
							<Input
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteName : ""}
								type="text"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.siteName'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.siteName'}
								placeholder=""
								required
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteName ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteName.length <= 0 ? false : true) : false : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteName ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].siteName.length <= 0 ? true : false) : true : true}
							/>
							<div className="invalid-feedback help-block">
								Provide a valid app name
                                </div>

						</FormGroup>
					</Col>

					<Col md={2}>
						<FormGroup md={2}>
							<Label for="appTier">Kind</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.kind'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.kind'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].kind : ""}
								required
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].kind ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].kind.length <= 0 ? false : true) : false : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].kind ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].kind.length <= 0 ? true : false) : true : true}
							>
								<option value=""> Choose an option </option>
								<option value="app">App </option>
								<option value="functionapp"> Function App</option>
							</Input>
							<div className="invalid-feedback help-block">
								Select a kind of application
                                </div>
						</FormGroup>




					</Col>

					<Col md={3}>
						<FormGroup>
							<Label for="appDomain">App Domain / URL </Label>
							<Input
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].appDomain : ""}
								type="text"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.appDomain'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.appDomain'}
								placeholder="http://"
								required
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].appDomain ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].appDomain.length <= 0 ? false : true) : false : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].appDomain ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].appDomain.length <= 0 ? true : false) : true : true}
							/>
							<div className="invalid-feedback help-block">
								Provide a valid URL
                                </div>

						</FormGroup>
					</Col>

				</FormGroup>


				<FormGroup row>
					<Col md={1}>
						<FormGroup check inline>
							<Label check className=" form-check-label">
								<Input type="checkbox"
									name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.alwaysOn'}
									id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.alwaysOn'}
									value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].alwaysOn}
									defaultChecked={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].alwaysOn}
								/>{' '}
								Always ON
                                </Label>
						</FormGroup>
					</Col>

					<Col md={2}>
						<Label>Runtime</Label>

						<Label check className="radioInline">
							<Input type="radio"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.appRuntime'}
								value='32'
								defaultChecked={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].appRuntime === '32' || this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].appRuntime === undefined}

							/>
							{' '} 32 Bits
                            </Label>
						<Label check className="radioInline">
							<Input type="radio"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.appRuntime'}
								value='64'
								defaultChecked={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].appRuntime === '64'}
							/>
							{' '}64 Bits
                            </Label>

					</Col>

					<Col md={1}>
						<FormGroup check>
							<Label check className="form-check-label">
								<Input type="checkbox"
									disabled
									name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.devEnvironment'}
									id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.devEnvironment'}
									value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].devEnvironment}
									defaultChecked
								/>{' '}
								DEV
                                </Label>

							<Label check className="position-relative form-check-label">
								<Input type="checkbox"
									disabled
									name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.testEnvironment'}
									id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.testEnvironment'}
									value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].testEnvironment}
									defaultChecked
								/>{' '}
								TEST
                                </Label>

							<Label check className="position-relative form-check-label">
								<Input type="checkbox"
									disabled
									name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.prodEnvironment'}
									id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.prodEnvironment'}
									defaultChecked
								/>{' '}
								PROD
                                </Label>

						</FormGroup>
					</Col>
					<Col md={1}>
						<FormGroup check inline>
							<Label check className=" form-check-label">
								<Input type="checkbox"
									name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.vnetIntegration'}
									id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.vnetIntegration'}
									value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].vnetIntegration : ""}
									defaultChecked={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].vnetIntegration}
								/>{' '}
								VNET Integration
                                </Label>
						</FormGroup>
					</Col>

					<Col md={2}>
						<FormGroup>
							<Label for="classification">Identity</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.identity'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.identity'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].identity : ""}
								required
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].identity ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].identity.length <= 0 ? false : true) : false : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx] ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].identity ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].identity.length <= 0 ? true : false) : true : true}
							>
								<option value=""> Choose One Option </option>
								<option value="None">None</option>
								<option value="systemAssigned">System Assigned</option>
								<option value="userAssigned">User Assigned</option>

							</Input>
							<div className="invalid-feedback help-block">
								Select an Identity
                                </div>
						</FormGroup>
					</Col>


					<Col md={2}>
						<FormGroup>
							<Label for="phpVersion">PHP Version</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.phpVersion'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.phpVersion'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].phpVersion}
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].phpVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].phpVersion.length <= 0 ? false : true) : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].phpVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].phpVersion.length <= 0 ? true : false) : true}
							>
								<option value="">Select one option</option>
								{
									this.props.getEnumValues('phpVersionEnum').map(c => {
										return <option key={c.code} value={c.code}>{c.description}</option>;
									})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select a phpVersion
                                </div>
						</FormGroup>
					</Col>

					<Col md={2}>
						<FormGroup>
							<Label for="dotNetVersion">.NET Version</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.dotNetVersion'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.dotNetVersion'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].dotNetVersion}
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].dotNetVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].dotNetVersion.length <= 0 ? false : true) : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].dotNetVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].dotNetVersion.length <= 0 ? true : false) : true}
							>
								<option value="">Select one option</option>
								{
									this.props.getEnumValues('dotNetVersionEnum').map(c => {
										return <option key={c.code} value={c.code}>{c.description}</option>;
									})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select a .Net Version
                                </div>
						</FormGroup>
					</Col>

					{
						/*

					<Col md={2}>
						<FormGroup>
							<Label for="nodeVersion">Node Version</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.nodeVersion'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.nodeVersion'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].nodeVersion}
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].nodeVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].nodeVersion.length <= 0 ? false : true) : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].nodeVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].nodeVersion.length <= 0 ? true : false) : true}
							>
								<option value=""> None </option>
								{
									//this.props.getEnumValues('dotNetVersionEnum').map(c => {
									//	return <option key={c.code} value={c.code}>{c.description}</option>;
									//})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select a Node Version
                                </div>
						</FormGroup>
					</Col>

					<Col md={2}>
						<FormGroup>
							<Label for="javaVersion">Java Version</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.javaVersion'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.javaVersion'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaVersion}
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaVersion.length <= 0 ? false : true) : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaVersion.length <= 0 ? true : false) : true}
							>
								<option value=""> None </option>
								{
									//this.props.getEnumValues('dotNetVersionEnum').map(c => {
									//	return <option key={c.code} value={c.code}>{c.description}</option>;
									//})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select a Java Version
                                </div>
						</FormGroup>
					</Col>
					<Col md={2}>
						<FormGroup>
							<Label for="javaContainerVersion">Java Container </Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.javaContainer'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.javaContainer'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainer}
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainer ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainer.length <= 0 ? false : true) : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainer ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainer.length <= 0 ? true : false) : true}
							>
								<option value=""> None </option>
								{
									//this.props.getEnumValues('dotNetVersionEnum').map(c => {
									//	return <option key={c.code} value={c.code}>{c.description}</option>;
									//})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select a Java Container
                                </div>
						</FormGroup>
					</Col>
					<Col md={2}>
						<FormGroup>
							<Label for="javaContainerVersion">Java Container Version</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.javaContainerVersion'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.javaContainerVersion'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainerVersion}
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainerVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainerVersion.length <= 0 ? false : true) : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainerVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].javaContainerVersion.length <= 0 ? true : false) : true}
							>
								<option value=""> None </option>
								{
									//this.props.getEnumValues('dotNetVersionEnum').map(c => {
									//	return <option key={c.code} value={c.code}>{c.description}</option>;
									//})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select a Java Container Version
                                </div>
						</FormGroup>
					</Col>

					<Col md={2}>
						<FormGroup>
							<Label for="pythonVersion">Python Version</Label>
							<Input
								type="select"
								name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.pythonVersion'}
								id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.pythonVersion'}
								value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].pythonVersion}
								valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].pythonVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].pythonVersion.length <= 0 ? false : true) : false}
								invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].pythonVersion ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].pythonVersion.length <= 0 ? true : false) : true}
							>
								<option value=""> None </option>
								{
									//this.props.getEnumValues('dotNetVersionEnum').map(c => {
									//	return <option key={c.code} value={c.code}>{c.description}</option>;
									//})
								}
							</Input>
							<div className="invalid-feedback help-block">
								Select a Python Version
                                </div>
						</FormGroup>
					</Col>

					*/
					}
				</FormGroup>

				<FormGroup row>
					<Col md={3}>
						<FormGroup check inline>
							<Label check className=" form-check-label">
								<Input type="checkbox"
									name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.azureAdAuthentication'}
									id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.azureAdAuthentication'}
									value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication}
									defaultChecked={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication}
								/>{' '}
								Azure AD Authentication
                                </Label>
						</FormGroup>

						{
							this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication ?
								<span>
									<Col md={12}>
										<FormGroup>
											<Label for="tenantID">Tenant ID </Label>
											<Input
												value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication === true ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].tenantId : ""}
												type="text"
												name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.tenantId'}
												id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.tenantId'}
												placeholder="GUID"
												disabled={!this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication === true}
												valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication === true ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].tenantId ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].tenantId.length <= 0 ? false : true) : false) : false}

												invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication === true ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].tenantId ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].tenantId.length <= 0 ? true : false) : true) : null}
											/>
											<div className="invalid-feedback help-block">
												Provide a valid GUID
											</div>

										</FormGroup>
									</Col>

									<Col md={12}>
										<FormGroup>
											<Label for="clientID">APP Client ID </Label>
											<Input
												value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication === true ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].clientID : ""}
												type="text"
												name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.clientID'}
												id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.clientID'}
												placeholder="GUID"

												disabled={!this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication === true}
												valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication === true ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].clientID ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].clientID.length <= 0 ? false : true) : false) : false}

												invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].azureAdAuthentication === true ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].clientID ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].clientID.length <= 0 ? true : false) : true) : null}
											/>
											<div className="invalid-feedback help-block">
												Provide a valid GUID
											</div>
										</FormGroup>
									</Col>
								</span> : null
						}
					</Col>








					<Col md={3}>
						<FormGroup check inline>
							<Label check className=" form-check-label">
								<Input type="checkbox"
									name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.httpsRequired'}
									id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.httpsRequired'}
									value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired}
									defaultChecked={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired}
								/>{' '}
								Https Required
                                </Label>
						</FormGroup>

						{
							this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired ?
								<span>
									<Col md={12}>
										<FormGroup>
											<Label for="tenantID">Certificate PFX</Label>
											<Input
												value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired === true ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePFX : ""}
												type="text"
												name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.certificatePFX'}
												id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.certificatePFX'}
												placeholder="PFX 64 Certificate file"
												disabled={!this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired === true}
												valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired === true ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePFX ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePFX.length <= 0 ? false : true) : false) : false}

												invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired === true ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePFX ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePFX.length <= 0 ? true : false) : true) : null}
											/>
											<div className="invalid-feedback help-block">
												Provide a valid PFX 64 Certificate value
                                </div>

										</FormGroup>
									</Col>

									<Col md={12}>
										<FormGroup>
											<Label for="tenantID">Certificate Password</Label>
											<Input
												value={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired === true ? this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePassword : ""}
												type="password"
												name={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.certificatePassword'}
												id={'settings.' + this.props.appIdx + '.webSite.' + this.props.webSiteIdx + '.certificatePassword'}
												placeholder="PFX 64 Certificate Password"
												disabled={!this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired === true}
												valid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired === true ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePassword ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePassword.length <= 0 ? false : true) : false) : false}

												invalid={this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].httpsRequired === true ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePassword ? (this.props.formPaas.settings[this.props.appIdx].webSite[this.props.webSiteIdx].certificatePassword.length <= 0 ? true : false) : true) : null}
											/>
											<div className="invalid-feedback help-block">
												Provide the certificate password
                                </div>

										</FormGroup>
									</Col>
								</span> : null
						}
					</Col>

				</FormGroup>

				<FormGroup row>
					<Col md={9}>&nbsp;</Col>
					<Col md={3}>
						<Button type="button" color="primary" onClick={() => this.props.removeWebApp(this.props.appIdx, this.props.webSiteIdx)}><i className="fa fa-trash" /> Remove Web App </Button>
					</Col>

				</FormGroup>
			</div>
		);
	}
}

export default WebSite;
