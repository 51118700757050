import React from 'react';
import { EditModal } from '../BaseComponents/Modals';

const EmailMessageEdit = () => {
    return (
        <EditModal id="emailMessageEditModal" title="Email Message Text">
            <div className="">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="navEditTab" data-toggle="tab" href="#navedit" role="tab" aria-controls="navedit" aria-selected="true">Details</a>
                        <a className="nav-item nav-link" id="navContentEditTab" data-toggle="tab" href="#navcontentedit" role="tab" aria-controls="navcontentedit" aria-selected="true">Content</a>
                        <a className="nav-item nav-link" id="navContentPreviewTab" data-toggle="tab" href="#navcontentpreview" role="tab" aria-controls="navcontentpreview" aria-selected="true">Preview</a>
                    </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade in show active" id="navedit" role="tabpanel" aria-labelledby="navEditTab">
                        <div className="form-row mt-1">
                            <div className="form-group col-md-4 small">
                                <label className="control-label" htmlFor="templateCode">Template Code</label>
                                <input type="text" className="form-control form-control-sm" id="templateCode" name="templateCode" required />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Template Code</b>.
                                </div>
                            </div>
                            <div className="form-group col-md-2 small" />
                            <div className="form-group col-md-1 small form-check">
                                <label className="control-label form-check-label" htmlFor="isHtml">Is Html</label>
                                <input type="checkbox" className="form-control form-control-sm form-check-input" id="isHtml" name="isHtml" value="true" style={{height:'15px'}} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12 small">
                                <label className="control-label" htmlFor="subject">Subject</label>
                                <input type="text" className="form-control form-control-sm" id="subject" name="subject" maxLength="150" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Subject</b>.
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12 small form-check">
                                <label className="control-label" htmlFor="fromAddresses">From Address</label>
                                <input type="text" className="form-control form-control-sm" id="fromAddresses" name="fromAddresses" maxLength="150" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>From Address</b>.
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12 small">
                                <label className="control-label" htmlFor="toAddresses">To Addresses</label>
                                <textarea className="form-control form-control-sm" id="toAddresses" name="toAddresses" rows="4" required />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>To Addresses</b>.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="navcontentedit" role="tabpanel" aria-labelledby="navContentEditTab">
                        <div className="form-row">
                            <div className="form-group col-md-12 small mt-2 mb-0">
                                {/*<label htmlFor="content">Content:</label>*/}
                                <textarea className="form-control form-control-sm" id="content" name="content" rows="19" readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="navcontentpreview" role="tabpanel" aria-labelledby="navContentPreviewTab">
                        <div className="form-row">
                            <div className="form-group col-md-12 small mb-0" id="contentPreview" style={{ minHeight: '385px' }}>
                                {/*<label htmlFor="errorText">Preview:</label>*/}
                                {/*<textarea className="form-control form-control-sm" id="contentPreview" rows="21" readOnly />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </EditModal>
    );
};

export default EmailMessageEdit;