import React from 'react';

const ConfirmModal = () => {
    return (
        <div className="modal fade" id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Confirm</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body" />

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btn-yes">Ok</button>
                        <button type="button" className="btn btn-secondary btn-no" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;