import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';


import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarButton,
    DataGridToolbarButtonFilterGroup,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarSearchInput,
    DataGridToolbarButtonRefresh,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';
import ProjectRequired from '../BaseComponents/ProjectRequired';
import DuPont from '../../jscomponents/DuPontApp';

const Toolbar = props => {
    return (
        <DataGridToolbar
            title="NSG List"
        >
            <DataGridToolbarDropdownButton
                caption="Choose a resource group"
                searchable
                tooltip="Resource group"
                filterOrder="2"
                mr="1"
                items={props.nsgResourceGroups}
            />{' '}
            <DataGridToolbarDropdownButton
                caption="All"
                glyph="filter"
                glyphColor='#4189C7'
                filterOrder="3"
                filter="all"
                controlClass="mr-2"
            >
                <DataGridToolbarDropdownItem filter="all" href="#">All</DataGridToolbarDropdownItem>
                <DataGridToolbarDropdownItem filter="inbound" href="#">Inbound</DataGridToolbarDropdownItem>
                <DataGridToolbarDropdownItem filter="outbound" href="#">Outbound</DataGridToolbarDropdownItem>
            </DataGridToolbarDropdownButton>{' '}
            <DataGridToolbarSearchInput
                tooltip="Source Address Prefix"
                filterOrder="4"
                filterDefValue="none"
                mr="1"
                inputStyle={{width:'160px'}}
                hideButton
            />
            <DataGridToolbarSearchInput
                inputType="number"
                tooltip="Source Port"
                filterOrder="5"
                filterDefValue="none"
                mr="1"
                inputStyle={{ width: '90px' }}
                hideButton
            />
            <DataGridToolbarSearchInput
                tooltip="Destination Address Prefix"
                filterOrder="6"
                filterDefValue="none"
                mr="1"
                inputStyle={{ width: '160px' }}
                hideButton
            />
            <DataGridToolbarSearchInput
                inputType="number"
                tooltip="Destination Port"
                filterOrder="7"
                filterDefValue="none"
                inputStyle={{ width: '90px' }}
            />
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarButtonFilterGroup
                    filterOrder="1"
                    filter="local"
                    filterDefValue="local"
                >
                    <DataGridToolbarButton
                        outline
                        bsStyle='primary'
                        glyph="cloud-download"
                        glyphColor='#4189C7'
                        text="Import from Azure"
                        tooltip
                        caption
                        filter="fromazure"
                    />
                    <DataGridToolbarButtonRefresh />
                </DataGridToolbarButtonFilterGroup>
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

class NSGList extends PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        
        this.props.requestNsgResourceGroups();
    }

    render() {
        const { multiSpin } = DuPont.App.Config;

        if (multiSpin && !this.props.match.params.project) {
            return (
                <ProjectRequired />
            );
        }

        return (
            <DataGrid model='nsg'>
                <Toolbar nsgResourceGroups={this.props.nsgResourceGroups} />
            </DataGrid>
        );
    }
}

export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withAITracking(ai.reactPlugin, NSGList, "NSGList"));