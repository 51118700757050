import $ from 'jquery';
import goLiveApp from './Main';
//import DuPont from './DuPontApp';

export default GridToolbar;

function GridToolbar(gridComponent, container) {

    function init() {

        enableSaving(false);

        gridComponent.setFilterExtraPath(getFilterExtraPath());

        //toolbar buttons click handler
        $(container).on('click', '.btn', function (e) {

            const $this = $(this);

            if (!gridComponent.handledToolbarItem({ e, $btn: $this })) {
                if ($this.hasClass('btn-search')) {
                    reloadGrid($this);
                } else if ($this.hasClass('btn-add')) {
                    gridComponent.editNewItemWithModal();
                } else if ($this.hasClass('btn-edit')) {
                    gridComponent.editCurrentItemWithModal();
                } else if ($this.hasClass('btn-delete')) {
                    gridComponent.removeSelectedItems();
                } else if ($this.hasClass('btn-undelete')) {
                    gridComponent.undeleteSelectedItems();
                } else if ($this.hasClass('btn-refresh')) {
                    gridComponent
                        .setFilterExtraPath(getFilterExtraPath())
                        .refresh()
                        .always(() => gridComponent.getInnerGrid().focus());
                } else if ($this.hasClass('btn-save')) {
                    $this.prop('disabled', true)
                        .removeClass('btn-outline-success').addClass('btn-success')
                        .children('.caption')
                        .text('Saving')
                        .next('.badge')
                        .show();
                    $(container).find('.btn-discard').prop('disabled', true);
                    gridComponent.commitChanges($this.children('.badge'));
                } else if ($this.hasClass('btn-discard')) {
                    $this.prop('disabled', true)
                        .removeClass('btn-outline-danger').addClass('btn-danger')
                        .children('.caption')
                        .text('Discarding...');
                    $(container).find('.btn-save').prop('disabled', true);
                    gridComponent.discardChanges();
                } else if ($this.hasClass('btn-history')) {
                    gridComponent.showHistory(e.ctrlKey);
                    gridComponent.getInnerGrid().focus();
                } else {
                    return true;
                }
            }

            //$(".btn-error").popover('hide');

            e.preventDefault();
            return false;
        });

        //handle dynamic models
        $(container).on('click', '.dropdown-item[data-filter]', function (e) {
            const $this = $(this);
            const $parentBtn = $this.closest('.btn-group').children('button:first-of-type');
                //$this.parent().parent().prev().prev();

            $parentBtn.attr('data-filter', $this.attr('data-filter'));
            reloadGrid($this);
            //$parentBtn.text($this.text());
            $parentBtn.children('.caption').text($this.text());
            e.preventDefault();
            //return false;
        });

        //handle button filter groups
        $(container).on('click', '.btn-filter-group .btn[data-filter]', function (e) {
            const $this = $(this);
            const $parent = $this.parent();

            const filterValue = $this.attr('data-filter') || $parent.attr('data-filter-defvalue');
            $parent.attr('data-filter', filterValue);
            try { reloadGrid($this); }
            finally { $parent.attr('data-filter', $parent.attr('data-filter-defvalue'));}
            e.preventDefault();
            //return false;
        });

        $(container).on('change', 'input[type=checkbox][data-filter-order]', function (e) {
            reloadGrid($(this));
            e.preventDefault();
            return false;
        });

        $(container).on('change', '#showChangesOnlyCheckBox', function (e) {
            gridComponent.refresh();
            e.preventDefault();
            //return false;
        });

        $(container).on('keypress', '.text-search', function (e) {
            if (e.which === 13) {
                reloadGrid($(this));
                e.preventDefault();
                return false;
            }
        });

        setExportURL();

        $(container).find('.btn-import').data('import-url', gridComponent.importURL());

        $('.modal .btn-save').on('click', function (e) {
            e.preventDefault();
            gridComponent.commitModalEdit();
            return false;
        });


        //$('.navbar-nav .nav-link').click(function (e) {
        //    e.preventDefault();
        //    //return false;
        //});

        /* to capture data from sender
        $('#exampleModal').on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget) // Button that triggered the modal
            var recipient = button.data('whatever') // Extract info from data-* attributes
            // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
            // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
            var modal = $(this)
            modal.find('.modal-title').text('New message to ' + recipient)
            modal.find('.modal-body input').val(recipient)
        })
        */
    }

    function reloadGrid($srcEl) {

        goLiveApp.loaderShow();
        try {
            const currentModel = gridComponent.getDataModel();
            const newModel = $srcEl ? $srcEl.attr("data-dyn-model") : null;

            gridComponent.setFilterExtraPath(getFilterExtraPath());


            if (newModel && newModel !== currentModel) {
                gridComponent.changeDataModel(newModel);
                gridComponent.getInnerGrid().focus();
            }
            else {
                setExportURL();
                gridComponent.getInnerGrid().focus();
                return gridComponent.refresh();
            }
        } finally {
            goLiveApp.loaderHide();
        }
    }

    function setExportURL() {
        //$(container).find('.btn-export,.btn-export-track-changes').data('export-url', gridComponent.exportURL());
        //$(container).find('[class*="btn-export"]:not([export-url])').data('export-url', gridComponent.exportURL());
        $(container)
            .find('[class*="btn-export"]:not([data-export-url])')
            .each((idx, elem) => {
                const $elem = $(elem);
                $elem.data('export-url', gridComponent.exportURL($elem.data('export-extrapath')));
            });
    }


    function getFilterExtraPath() {
        return $(container)
            .find('[data-filter-order]')
            .sort(function (a, b) {
                return +a.getAttribute('data-filter-order') - +b.getAttribute('data-filter-order');
            })
            .map(function () {
                //return this.getAttribute('data-filter');
                const $this = $(this);
                var result;
                if ($this.is(':checkbox')) {
                    result = $this.is(':checked') ? $this.attr('data-filter-checked') : $this.attr('data-filter-unchecked');
                } else if ($this.is('input:text, input[type="number"]')) {
                    result = [$this.attr('data-filter'), encodeURIComponent($this.val() || $this.attr('data-filter-defvalue') || '')].filter(i => i).join('/');
                } else {
                    result = $this.attr('data-filter');
                }
                return result;
            })
            .get()
            .join('/');
    }

    function setVisibility(show, buttons) {
        const $buttons = $(container).find(buttons);
        return show ? $buttons.show() : $buttons.hide();
        //return show ? $buttons.removeClass('d-none') : $buttons.addClass('d-none');
    }

    function enableSaving(enable) {
        $(container).find('.btn-save')
            .prop('disabled', false)
            .removeClass('btn-success').addClass('btn-outline-success')
            .children('.caption')
            .text('Save')
            .next('.badge')
            .hide();

        $(container).find('.btn-discard')
            .prop('disabled', false)
            .removeClass('btn-danger').addClass('btn-outline-danger')
            .children('.caption')
            .text('Discard');

        setVisibility(enable, '.btn-save,.btn-discard');
    }

    function destroy() {
        $(container).off();
        $('.modal .btn-save').off();
    }

    function persistFilter() {
        return $(container)
            .find('[data-filter-order]')
            .get()
            .reduce((result, item) => {
                const $item = $(item);
                let filterValue;

                if ($item.is(':checkbox')) {
                    filterValue = $item.is(':checked') ? $item.attr('data-filter-checked') : $item.attr('data-filter-unchecked');
                } else if ($item.is('input:text')) {
                    filterValue = $item.val();
                } else {
                    filterValue = $item.attr('data-filter');
                }
                result[$item.attr('data-filter-order')] = filterValue;
                return result;
            }, {});
    }

    function applyFilter(toolbarFilter, forceReload) {
        let $dynModelElem = null;
        let filterChanged = false;
        $(container)
            .find('[data-filter-order]')
            .sort(function (a, b) {
                return +a.getAttribute('data-filter-order') - +b.getAttribute('data-filter-order');
            })
            .each((idx, elem) => {
                const filterOrder = elem.getAttribute('data-filter-order');
                const filterValue = toolbarFilter[filterOrder];
                if (filterValue !== undefined) {
                    const $elem = $(elem);
                    if ($elem.is(':checkbox')) {
                        const checked = filterValue === $elem.attr('data-filter-checked');
                        if (elem.checked !== checked) {
                            elem.checked = checked;
                            filterChanged = true;
                        }
                    } else if ($elem.is('input:text')) {
                        if ($elem.val() !== filterValue) {
                            $elem.val(filterValue);
                            filterChanged = true;
                        }
                    } else {
                        if (filterValue !== $elem.attr('data-filter')) {
                            if ($elem.is('.dropdown-toggle')) {
                                const $filterItem = $elem.parent().find(`.dropdown-item[data-filter='${filterValue}']`);
                                if ($filterItem.length > 0) {
                                    $elem.attr('data-filter', filterValue);
                                    $elem.find('span.caption').text($filterItem.text());
                                    if ($elem.attr("data-dyn-model")) $dynModelElem = $elem;
                                    filterChanged = true;
                                }
                            } else {
                                $elem.attr('data-filter', filterValue);
                                filterChanged = true;
                            }
                        }
                    }
                }
            });
        return filterChanged || forceReload ? reloadGrid($dynModelElem) : $.when();
    }

    /*
    function getActiveGridComponent() {
        return DuPont.App.Grids[0];
    }

    function invokeMenuItem($target) {
        var controller = $target.attr("data-model");
        if (controller) {
            $('.h-title').text($target.text().trim());

            if (DuPont.App.Repository.getRepositoryObject(controller).modalId)
                $addBtn.show();
            else
                $addBtn.hide();

            if (getActiveGridComponent()) {
                getActiveGridComponent().destroy();
                getActiveGridComponent() = null;
            }

            getActiveGridComponent() = new DuPont.GridComponent('#myId',controller);

            $('.sidebar-sticky .nav-link.active').removeClass('active');
            $target.addClass('active');
        }
    }

    function invokeMenuItemForce(controller) {
        if (controller) {
            if (DuPont.App.Repository.getRepositoryObject(controller).modalId)
                $addBtn.show();
            else
                $addBtn.hide();

            if (getActiveGridComponent()) {
                getActiveGridComponent().destroy();
                getActiveGridComponent() = null;
            }

            getActiveGridComponent() = new DuPont.GridComponent(controller);
        }
    }

    function invokeActiveMenuItem() {
        var $target = $('.sidebar-sticky .nav-link.active');
        if ($target) { invokeMenuItem($target); }
    }
    */

    init();

    return {
        enableSaving,
        destroy,
        getFilterExtraPath,
        persistFilter,
        applyFilter
        //invokeMenuItemForce: invokeMenuItemForce,
        //invokeActiveMenuItem: invokeActiveMenuItem,
        //invokeMenuItem: invokeMenuItem
    };
}