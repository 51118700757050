import $ from 'jquery';
import Editors from './slick.editors.extenders';
import DuPont from './DuPontApp';
import { golLiveAppApi } from './Main';
import authorizationService, { AuthorizationPolicies } from './AuthorizationService';
import { validate, isLVal } from '@babel/types';
import validateFormData from 'react-jsonschema-form/lib/validate';

export default Repository;

function Repository(dialog) {
    const visible = { never: 0, whenChanged: 1, always: 2 };
    const BS_SHOW_MODAL_EVENT = 'show.bs.modal';
    const BS_HIDE_MODAL_EVENT = 'hide.bs.modal';
    const BS_SHOWN_TAB_EVENT = 'shown.bs.tab';

    const
        repositoryObj =
        {
            appStatus:
            {
                keyField: "environmentDetailID",
                controllerpath: "ApplicationStatus",
                dataLoading: -1, // never
                modalId: "appStatusEditModal",
                gridOptions: { editable: false, enableAddRow: false, showHeaderRow: false },
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 100, toolTip: "SsoT AssetID" },
                        { id: "applicationName", name: "App Name", field: "applicationName", sortable: true, filterable: true, width: 250, toolTip: "Application Name" },
                        { id: "statusID", name: "Status", field: "statusID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Env.Status", width: 100, formatter: Select2Formatter },
                        { id: "lntOwner", name: "Project Owner", field: "lntOwner", sortable: true, filterable: true, width: 100 },
                        { id: "lntAppType", name: "Asset Type", field: "lntAppType", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "ApplicationType", name: "Application Type", field: "ApplicationType", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "complexityID", name: "Complexity", field: "complexityID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Env.Complexity", width: 100, formatter: Select2Formatter },
                        { id: "environmentTypeName", name: "Environment Type Name", field: "environmentTypeName", sortable: true, filterable: true, width: 160 },
                        { id: "disposition", name: "Disposition Type", field: "disposition", sortable: true, filterable: true, editor: Editors.Select2, dataSource: { 0: 'None', 1: 'CloneDT', 2: 'CloneOVA', 3: 'DataExportImport', 4: 'DataExtract', 5: 'EucThisIsNotSaaS', 6: 'ExportImport', 7: 'FreshInstall', 8: 'HeterogeneousSystemCopy', 9: 'HomogenousSystemCopy', 10: 'OutOfScope', 11: 'PaaS', 12: 'Preserve', 13: 'SaaSMigration', 14: 'SaaSWithEccBoltOnComponent', 15: 'SiteApp', 16: 'TBD', 17: 'TBDIssue99', 18: 'WebExportImport' }, width: 150, formatter: Select2Formatter },
                        { id: "notes", name: "Notes", field: "notes", sortable: true, filterable: true, width: 150 },
                        { id: "lntAppHosting", name: "AppHosting", field: "lntAppHosting", sortable: true, filterable: true, width: 120 },
                        { id: "expectedStart", name: "ExpectedStart", field: "expectedStart", sortable: true, filterable: true, editor: Editors.Text, width: 110 },
                        { id: "actualStart", name: "ActualStart", field: "actualStart", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "expectedEnd", name: "ExpectedEnd", field: "expectedEnd", sortable: true, filterable: true, editor: Editors.Text, width: 110 },
                        { id: "actualEnd", name: "ActualEnd", field: "actualEnd", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "dnpOwner", name: "D&P Owner", field: "dnpOwner", sortable: true, filterable: true, width: 100 },
                        { id: "mneOwner", name: "M&E Owner", field: "mneOwner", sortable: true, filterable: true, width: 100 },
                        { id: "remediationOwner", name: "Remediation Owner", field: "remediationOwner", sortable: true, filterable: true, width: 150 },
                        { id: "lntFocalContact", name: "Local Contact", field: "lntFocalContact", sortable: true, filterable: true, width: 150 },
                        { id: "lntSecondaryContact", name: "Secondary Contact", field: "lntSecondaryContact", sortable: true, filterable: true, width: 150 },
                        { id: "remediationOrganization", name: "Remediation Organization", field: "remediationOrganization", sortable: true, filterable: true, width: 150 },
                        { id: "supportedOrganizations", name: "Spin", field: "supportedOrganizations", sortable: true, filterable: true, width: 100 },
                        { id: "applicationStatus", name: "Application Status", field: "applicationStatus", sortable: true, filterable: true, width: 130 },
                        { id: "releaseType", name: "Release Type", field: "releaseType", sortable: true, filterable: true, width: 100 },
                        { id: "goLiveDate", name: "GoLive Date", field: "goLiveDate", sortable: true, filterable: true, width: 100 },
                        { id: "targetSid", name: "Sid", field: "targetSid", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "isDisabled", name: "Is Disabled", field: "isDisabled", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                //dataFromServerParser: function (args) {}
                //dataToServerParser: function (args) { return args.item; }
            },
            appStatusNonSaaS:
            {
                keyField: "environmentDetailID",
                historyKeyField: "environmentDetailHistoryID",
                controllerpath: "ApplicationStatus",
                modalId: "appStatusEditModal",
                gridOptions: { enableAddRow: false, frozenColumn: 3 },
                isVirtual: true,
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 60, historyVisibility: visible.always, toolTip: "SsoT AssetID" },
                        { id: "applicationName", name: "App Name", field: "applicationName", sortable: true, filterable: true, width: 180, historyVisibility: visible.never, toolTip: "Application Name" },
                        { id: "complexityID", name: "In Scope", field: "complexityID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Env.Complexity", width: 100, formatter: Select2Formatter },
                        { id: "disposition", name: "Purposed Disposition Type", field: "disposition", sortable: true, filterable: true, width: 250 },
                        { id: "testScope", name: "Test Scope", field: "testScope", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "environmentTypeName", name: "Environment", field: "environmentTypeName", sortable: true, filterable: true, width: 85, historyVisibility: visible.always },
                        { id: "lntAppType", name: "Application Type", field: "lntAppType", sortable: true, filterable: true, width: 150, historyVisibility: visible.never },
                        { id: "applicationType", name: "Asset Type", field: "applicationType", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "statusID", name: "Application Status", field: "statusID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Env.Status", width: 150, formatter: Select2Formatter },
                        { id: "notes", name: "Notes", field: "notes", sortable: true, filterable: true, editor: Editors.Text, width: 150 },
                        { id: "lntAppHosting", name: "AppHosting", field: "lntAppHosting", sortable: true, filterable: true, width: 120 },
                        { id: "provisionInfra", name: "ProvisionInfra", field: "provisionInfra", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 115 },
                        { id: "provisionBaselineStart", name: "ProvisionBaselineStart", field: "provisionBaselineStart", sortable: true, filterable: true, width: 250 },
                        { id: "provisionBaselineEnd", name: "ProvisionBaselineEnd", field: "provisionBaselineEnd", sortable: true, filterable: true, width: 250 },
                        { id: "provisionActualEnd", name: "ProvisionActualEnd", field: "provisionActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 250 },

                        { id: "export", name: "Export", field: "export", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 105 },
                        { id: "exportBaselineStart", name: "ExportBaselineStart", field: "exportBaselineStart", sortable: true, filterable: true, width: 145 },
                        { id: "exportBaselineEnd", name: "ExportBaselineEnd", field: "exportBaselineEnd", sortable: true, filterable: true, width: 145 },
                        { id: "exportActualEnd", name: "ExportActualEnd", field: "exportActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 125 },

                        { id: "dataSegregation", name: "DataSegregation", field: "dataSegregation", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 125 },
                        { id: "dataSegregBaselineStart", name: "DataSegregBaselineStart", field: "dataSegregBaselineStart", sortable: true, filterable: true, width: 145 },
                        { id: "dataSegregBaselineEnd", name: "DataSegregBaselineEnd", field: "dataSegregBaselineEnd", sortable: true, filterable: true, width: 145 },
                        { id: "dataSegregeActualEnd", name: "DataSegregeActualEnd", field: "dataSegregeActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 130 },

                        { id: "importMigration", name: "ImportMigration", field: "importMigration", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 125 },
                        { id: "importBaselineStart", name: "ImportBaselineStart", field: "importBaselineStart", sortable: true, filterable: true, width: 145 },
                        { id: "importBaselineEnd", name: "ImportBaselineEnd", field: "importBaselineEnd", sortable: true, filterable: true, width: 145 },
                        { id: "importActualEnd", name: "ImportActualEnd", field: "importActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 130 },

                        { id: "technicalRemediation", name: "TechnicalRemediation", field: "technicalRemediation", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 160 },
                        { id: "techRemBaselineStart", name: "TechRemBaselineStart", field: "techRemBaselineStart", sortable: true, filterable: true, width: 160 },
                        { id: "techRemBaselineEnd", name: "TechRemBaselineEnd", field: "techRemBaselineEnd", sortable: true, filterable: true, width: 160 },
                        { id: "techRemActualEnd", name: "TechRemActualEnd", field: "techRemActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 145 },

                        { id: "security", name: "Security", field: "security", sortable: true, filterable: true, width: 100, editor: Editors.Text },

                        { id: "functionalRemediation", name: "FunctionalRemediation", field: "functionalRemediation", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 165 },
                        { id: "funcRemBaselineStart", name: "FuncRemBaselineStart", field: "funcRemBaselineStart", sortable: true, filterable: true, width: 160 },
                        { id: "funcRemBaselineEnd", name: "FuncRemBaselineEnd", field: "funcRemBaselineEnd", sortable: true, filterable: true, width: 160 },
                        { id: "funcRemActualEnd", name: "FuncRemActualEnd", field: "funcRemActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 145 },

                        { id: "purchasingSOW", name: "PurchasingSOW", field: "purchasingSOW", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 125 },
                        { id: "purchasingSOWBaselineStart", name: "PurchasingSOWBaselineStart", field: "purchasingSOWBaselineStart", sortable: true, filterable: true, width: 145 },
                        { id: "purchasingSOWBaselineEnd", name: "PurchasingSOWBaselineEnd", field: "purchasingSOWBaselineEnd", sortable: true, filterable: true, width: 145 },
                        { id: "purchasingSOWActualEnd", name: "PurchasingSOWActualEnd", field: "purchasingSOWActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 130 },

                        { id: "lntFocalContact", name: "Focal Contact", field: "lntFocalContact", sortable: true, filterable: true, width: 250, historyVisibility: visible.never },
                        { id: "lntSecondaryContact", name: "Secondary Contact", field: "lntSecondaryContact", sortable: true, filterable: true, width: 250, historyVisibility: visible.never },
                        { id: "remediationOrganization", name: "Build Partner", field: "remediationOrganization", sortable: true, filterable: true, width: 250, historyVisibility: visible.never },

                        { id: "dnpOwner", name: "DuPont Contact", field: "dnpOwner", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "mneOwner", name: "Partner Contact", field: "mneOwner", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "remediationOwner", name: "LnT Contact", field: "remediationOwner", sortable: true, filterable: true, width: 150, historyVisibility: visible.never },

                        //{ id: "isDisabled", name: "Is Disabled", field: "isDisabled", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true, historyVisibility: visible.never },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                setPermissions: function (args) {
                    if (authorizationService.authorize(AuthorizationPolicies.AppStatusAllowEditBaselineDates)) {
                        args.columns
                            .forEach((c, idx, arr) => {
                                if (c.field.endsWith('BaselineStart') || c.field.endsWith('BaselineEnd'))
                                    arr[idx] = Object.assign({}, c, { editor: Editors.Date });
                            });
                    } else if (!authorizationService.authorize(AuthorizationPolicies.AppListUser)) {
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }
                    return $.when();
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
                onFieldChange: function (e, args) {
                    //export, technicalRemediation, functionalRemediation
                    /*
                    if (['exportActualEnd', 'techRemActualEnd', 'funcRemActualEnd'].includes(args.column.id)) {
                        const field = args.column.id.slice(0, args.column.id.length - 9) + 'ExpectedEnd';
                        if (!args.item[field]) {
                            args.item[field] = args.item[args.column.id];
                            args.changedFields = [field];
                        }
                    }
                    */
                    const observedFields = ['inventoryBaselineEnd', 'migApproachBaselineEnd', 'alignBaselineEnd', 'requestBaselineEnd', 'provisionBaselineEnd', 'exportBaselineEnd', 'importBaselineEnd', 'enableRemBaselineEnd', 'techRemBaselineEnd', 'funcRemBaselineEnd'];
                    const nextFieldIdx = 1 + observedFields.indexOf(args.column.id);
                    if (nextFieldIdx > 0 && nextFieldIdx < observedFields.length) {
                        const nextField = observedFields[nextFieldIdx].slice(0, observedFields[nextFieldIdx].length - 11) + 'BaselineStart';
                        const value = args.item[args.column.id];
                        //if (value) {
                        args.item[nextField] = value ? new Date(value).addDays(1).toCleanDateString() : null;
                        args.changedFields = [nextField];
                        //}
                    }
                },
                onBeforeEditCell: function (e, args) {
                    if (DuPont.App.Config.crossSpinEditDisabled) {
                        const currentSpin = DuPont.App.Config.currentSpin;
                        return !currentSpin || currentSpin.toUpperCase() === (args.item.supportedOrganizations || '').toUpperCase();
                    }
                    return true;
                }
            },
            appStatusSaaS:
            {
                keyField: "environmentDetailID",
                historyKeyField: "environmentDetailHistoryID",
                controllerpath: "ApplicationStatus",
                modalId: "appStatusEditModal",
                gridOptions: { enableAddRow: false, frozenColumn: 3 },
                isVirtual: true,
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 60, historyVisibility: visible.always, toolTip: "SsoT AssetID" },
                        { id: "applicationName", name: "App Name", field: "applicationName", sortable: true, filterable: true, width: 180, historyVisibility: visible.never, toolTip: "Application Name" },
                        { id: "statusID", name: "Application Status", field: "statusID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Env.Status", width: 100, formatter: Select2Formatter },
                        { id: "lntOwner", name: "Project Owner", field: "lntOwner", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "lntAppType", name: "Application Type", field: "lntAppType", sortable: true, filterable: true, width: 150, historyVisibility: visible.never },
                        { id: "applicationType", name: "Asset Type", field: "applicationType", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "testScope", name: "Test Scope", field: "testScope", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "complexityID", name: "Complexity", field: "complexityID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Env.Complexity", width: 60, formatter: Select2Formatter },
                        { id: "environmentTypeName", name: "Environment Type Name", field: "environmentTypeName", sortable: true, filterable: true, width: 85, historyVisibility: visible.always },
                        { id: "disposition", name: "Disposition Type", field: "disposition", sortable: true, filterable: true, editor: Editors.Select2, dataSource: { 0: 'None', 1: 'CloneDT', 2: 'CloneOVA', 3: 'DataExportImport', 4: 'DataExtract', 5: 'EucThisIsNotSaaS', 6: 'ExportImport', 7: 'FreshInstall', 8: 'HeterogeneousSystemCopy', 9: 'HomogenousSystemCopy', 10: 'OutOfScope', 11: 'PaaS', 12: 'Preserve', 13: 'SaaSMigration', 14: 'SaaSWithEccBoltOnComponent', 15: 'SiteApp', 16: 'TBD', 17: 'TBDIssue99', 18: 'WebExportImport' }, width: 140, formatter: Select2Formatter },
                        { id: "notes", name: "Notes", field: "notes", sortable: true, filterable: true, editor: Editors.Text, width: 150 },
                        { id: "lntAppHosting", name: "AppHosting", field: "lntAppHosting", sortable: true, filterable: true, width: 120, historyVisibility: visible.never },
                        { id: "expectedStart", name: "ExpectedStart", field: "expectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 110 },
                        { id: "actualStart", name: "ActualStart", field: "actualStart", sortable: true, filterable: true, editor: Editors.Date, width: 100 },

                        { id: "inventory", name: "Inventory", field: "inventory", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        { id: "inventoryBaselineStart", name: "InventoryBaselineStart", field: "inventoryBaselineStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        { id: "inventoryBaselineEnd", name: "InventoryBaselineEnd", field: "inventoryBaselineEnd", sortable: true, filterable: true, width: 165 },
                        { id: "inventoryActualEnd", name: "InventoryActualEnd", field: "inventoryActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        { id: "prepForVendor", name: "PrepForVendor", field: "prepForVendor", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 120 },
                        { id: "prepForVendorBaselineStart", name: "PrepForVendorBaselineStart", field: "prepForVendorBaselineStart", sortable: true, filterable: true, width: 185 },
                        { id: "prepForVendorBaselineEnd", name: "PrepForVendorBaselineEnd", field: "prepForVendorBaselineEnd", sortable: true, filterable: true, width: 185 },
                        { id: "prepForVendorActualEnd", name: "PrepForVendorActualEnd", field: "prepForVendorActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 175 },
                        { id: "purchasingSOW", name: "PurchasingSOW", field: "purchasingSOW", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 125 },
                        { id: "purchasingSOWBaselineStart", name: "PurchasingSOWBaselineStart", field: "purchasingSOWBaselineStart", sortable: true, filterable: true, width: 195 },
                        { id: "purchasingSOWBaselineEnd", name: "PurchasingSOWBaselineEnd", field: "purchasingSOWBaselineEnd", sortable: true, filterable: true, width: 195 },
                        { id: "purchasingSOWActualEnd", name: "PurchasingSOWActualEnd", field: "purchasingSOWActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 190 },
                        { id: "vendorWorkKickoff", name: "VendorWorkKickoff", field: "vendorWorkKickoff", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 140 },
                        { id: "vendorWorkKickoffBaselineStart", name: "VendorWorkKickoffBaselineStart", field: "vendorWorkKickoffBaselineStart", sortable: true, filterable: true, width: 210 },
                        { id: "vendorWorkKickoffBaselineEnd", name: "VendorWorkKickoffBaselineEnd", field: "vendorWorkKickoffBaselineEnd", sortable: true, filterable: true, width: 210 },
                        { id: "vendorWorkKickoffActualEnd", name: "VendorWorkKickoffActualEnd", field: "vendorWorkKickoffActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 205 },
                        { id: "vendorSetupAndEnableRemediation", name: "VendorSetupAndEnableRemediation", field: "vendorSetupAndEnableRemediation", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 235 },
                        { id: "vendorSetupAndEnableRemBaselineStart", name: "VendorSetupAndEnableRemBaselineStart", field: "vendorSetupAndEnableRemBaselineStart", sortable: true, filterable: true, width: 260 },
                        { id: "vendorSetupAndEnableRemBaselineEnd", name: "VendorSetupAndEnableRemBaselineEnd", field: "vendorSetupAndEnableRemBaselineEnd", sortable: true, filterable: true, width: 260 },
                        { id: "vendorSetupAndEnableRemActualEnd", name: "VendorSetupAndEnableRemActualEnd", field: "vendorSetupAndEnableRemActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 255 },
                        { id: "remediationTesting", name: "RemediationTesting", field: "remediationTesting", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 145 },
                        { id: "remTestingBaselineStart", name: "RemTestingBaselineStart", field: "remTestingBaselineStart", sortable: true, filterable: true, width: 175 },
                        { id: "remTestingBaselineEnd", name: "RemTestingBaselineEnd", field: "remTestingBaselineEnd", sortable: true, filterable: true, width: 175 },
                        { id: "remTestingActualEnd", name: "RemTestingActualEnd", field: "remTestingActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        { id: "dataSegregationDowSme", name: "DataSegregationDowSme", field: "dataSegregationDowSme", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 175 },
                        { id: "dataSegregDowSmeBaselineStart", name: "DataSegregDowSmeBaselineStart", field: "dataSegregDowSmeBaselineStart", sortable: true, filterable: true, width: 220 },
                        { id: "dataSegregDowSmeBaselineEnd", name: "DataSegregDowSmeBaselineEnd", field: "dataSegregDowSmeBaselineEnd", sortable: true, filterable: true, width: 220 },
                        { id: "dataSegregDowSmeActualEnd", name: "DataSegregDowSmeActualEnd", field: "dataSegregDowSmeActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 210 },

                        { id: "expectedEnd", name: "ExpectedEnd", field: "expectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 110 },
                        { id: "actualEnd", name: "ActualEnd", field: "actualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 100 },
                        { id: "dnpOwner", name: "D&P Owner", field: "dnpOwner", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "mneOwner", name: "M&E Owner", field: "mneOwner", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "remediationOwner", name: "Remediation Owner", field: "remediationOwner", sortable: true, filterable: true, width: 150, historyVisibility: visible.never },
                        { id: "lntFocalContact", name: "LnT Focal Contact", field: "lntFocalContact", sortable: true, filterable: true, width: 150, historyVisibility: visible.never },
                        { id: "lntSecondaryContact", name: "LnT Secondary Contact", field: "lntSecondaryContact", sortable: true, filterable: true, width: 150, historyVisibility: visible.never },
                        { id: "remediationOrganization", name: "Remediation Organization", field: "remediationOrganization", sortable: true, filterable: true, width: 150, historyVisibility: visible.never },
                        { id: "supportedOrganizations", name: "Project", field: "supportedOrganizations", sortable: true, filterable: true, width: 100, historyVisibility: visible.always },
                        { id: "isDisabled", name: "Is Disabled", field: "isDisabled", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true, historyVisibility: visible.never },
                        { id: "applicationStatus", name: "Application Status", field: "applicationStatus", sortable: true, filterable: true, width: 130, historyVisibility: visible.never },
                        { id: "releaseType", name: "Release Type", field: "releaseType", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "goLiveDate", name: "GoLive Date", field: "goLiveDate", sortable: true, filterable: true, width: 100 },
                        { id: "targetSid", name: "Sid", field: "targetSid", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                setPermissions: function (args) {
                    if (authorizationService.authorize(AuthorizationPolicies.AppStatusAllowEditBaselineDates)) {
                        args.columns
                            .forEach((c, idx, arr) => {
                                if (c.field.endsWith('BaselineStart') || c.field.endsWith('BaselineEnd'))
                                    arr[idx] = Object.assign({}, c, { editor: Editors.Date });
                            });
                    } else if (!authorizationService.authorize(AuthorizationPolicies.AppListUser)) {
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }
                    return $.when();
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
                onFieldChange: function (e, args) {
                    //export, technicalRemediation, functionalRemediation
                    /*
                    if (['exportActualEnd', 'techRemActualEnd', 'funcRemActualEnd'].includes(args.column.id)) {
                        const field = args.column.id.slice(0, args.column.id.length - 9) + 'ExpectedEnd';
                        if (!args.item[field]) {
                            args.item[field] = args.item[args.column.id];
                            args.changedFields = [field];
                        }
                    }
                    */
                    const observedFields = ['inventoryBaselineEnd', 'prepForVendorBaselineEnd', 'purchasingSOWBaselineEnd', 'vendorWorkKickoffBaselineEnd', 'vendorSetupAndEnableRemBaselineEnd', 'remTestingBaselineEnd', 'dataSegregDowSmeBaselineEnd'];
                    const nextFieldIdx = 1 + observedFields.indexOf(args.column.id);
                    if (nextFieldIdx > 0 && nextFieldIdx < observedFields.length) {
                        const nextField = observedFields[nextFieldIdx].slice(0, observedFields[nextFieldIdx].length - 11) + 'BaselineStart';
                        const value = args.item[args.column.id];
                        //if (value) {
                        args.item[nextField] = value ? new Date(value).addDays(1).toCleanDateString() : null;
                        args.changedFields = [nextField];
                        //}
                    }
                },
                onBeforeEditCell: function (e, args) {
                    if (DuPont.App.Config.crossSpinEditDisabled) {
                        const currentSpin = DuPont.App.Config.currentSpin;
                        return !currentSpin || currentSpin.toUpperCase() === (args.item.supportedOrganizations || '').toUpperCase();
                    }
                    return true;
                }
            },
            provisionInfraStatus:
            {
                keyField: "provisionInfraStatusID",
                historyKeyField: "provisionInfraStatusHistoryID",
                controllerpath: "ProvisionInfraStatus",
                //dataLoading: 1, //on reload
                modalId: "provisionInfraStatusEditModal",
                gridOptions: { enableAddRow: false, frozenColumn: 2 },
                isVirtual: true,
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 100, historyVisibility: visible.always },
                        { id: "applicationName", name: "App Name", field: "applicationName", sortable: true, filterable: true, width: 250, historyVisibility: visible.never },
                        { id: "testScope", name: "Test Scope", field: "testScope", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "lntAppHosting", name: "L&T AppHosting", field: "lntAppHosting", sortable: true, filterable: true, width: 120, historyVisibility: visible.never },
                        { id: "notes", name: "Notes", field: "notes", sortable: true, filterable: true, width: 150 },
                        { id: "azureScopeID", name: "Azure Scope", field: "azureScopeID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.AzureScope", width: 110, formatter: Select2Formatter },
                        { id: "buildSheetSubmittedID", name: "Build Sheet Submitted", field: "buildSheetSubmittedID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.BuildSheetSubmitted", width: 170, formatter: Select2Formatter },
                        { id: "resourceGroupSubmittedID", name: "Resource Group Submitted", field: "resourceGroupSubmittedID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.ResourceGroupSubmitted", width: 190, formatter: Select2Formatter },
                        { id: "resourceGroupID", name: "Resource Group ID", field: "resourceGroupID", sortable: true, filterable: true, width: 140, editor: Editors.Text },
                        { id: "resourceGroupCreatedID", name: "Resource Group Created", field: "resourceGroupCreatedID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.ResourceGroupCreated", width: 170, formatter: Select2Formatter },
                        { id: "rGADGroupSubmittedID", name: "RG AD Group Submitted", field: "rGADGroupSubmittedID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.RGADGroupSubmitted", width: 170, formatter: Select2Formatter },
                        { id: "devRGADGroupStatusID", name: "Dev RG AD Group Status", field: "devRGADGroupStatusID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.RGADGroupStatus", width: 170, formatter: Select2Formatter },
                        { id: "qARGADGroupStatusID", name: "QA RG AD Group Status", field: "qARGADGroupStatusID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.RGADGroupStatus", width: 170, formatter: Select2Formatter },
                        { id: "prodRGADGroupStatusID", name: "Prod RG AD Group Status", field: "prodRGADGroupStatusID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.RGADGroupStatus", width: 190, formatter: Select2Formatter },
                        { id: "devProvisioned", name: "Dev Provisioned", field: "devProvisioned", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 130 },
                        { id: "qaProvisioned", name: "QA Provisioned", field: "qaProvisioned", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 120 },
                        { id: "prodProvisioned", name: "Prod Provisioned", field: "prodProvisioned", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 130 },
                        { id: "devAccessConfirmedID", name: "Dev Access Confirmed", field: "devAccessConfirmedID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.AccessConfirmed", width: 170, formatter: Select2Formatter },
                        { id: "qAAccessConfirmedID", name: "QA Access Confirmed", field: "qAAccessConfirmedID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.AccessConfirmed", width: 170, formatter: Select2Formatter },
                        { id: "prodAccessConfirmedID", name: "Prod Access Confirmed", field: "prodAccessConfirmedID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "ProvInfra.AccessConfirmed", width: 170, formatter: Select2Formatter },
                        { id: "supportedOrganizations", name: "Spin", field: "supportedOrganizations", sortable: true, filterable: true, width: 100, historyVisibility: visible.always },
                        { id: "isDisabled", name: "Is Disabled", field: "isDisabled", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true, historyVisibility: visible.never },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
                onBeforeEditCell: function (e, args) {
                    if (DuPont.App.Config.crossSpinEditDisabled) {
                        const currentSpin = DuPont.App.Config.currentSpin;
                        return !currentSpin || currentSpin.toUpperCase() === (args.item.supportedOrganizations || '').toUpperCase();
                    }
                    return true;
                }
            },
            appModernizationStatus:
            {
                keyField: "appModernizationStatusID",
                historyKeyField: "appModernizationStatusHistoryID",
                controllerpath: "AppModernizationStatus",
                //dataLoading: 1, //on reload
                //modalId: "appModernizStatusEditModal",
                gridOptions: { enableAddRow: false, frozenColumn: 2 },
                isVirtual: true,
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 100, historyVisibility: visible.always },
                        { id: "applicationName", name: "App Name", field: "applicationName", sortable: true, filterable: true, width: 250, historyVisibility: visible.never },
                        { id: "appBusinessOwner", name: "App.Business Owner", field: "appBusinessOwner", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "appTechnicalteOwner", name: "App.Technical Owner", field: "appTechnicalOwner", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "applicationLoadID", name: "Application Load", field: "applicationLoadID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "AppModernization.ApplicationLoad", width: 110, formatter: Select2Formatter },
                        { id: "primaryUserRegion", name: "Primary User Region", field: "primaryUserRegion", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "applicationBusinessCriticalityID", name: "App.Business Criticality", field: "applicationBusinessCriticalityID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "AppModernization.ApplicationBusinessCriticality", width: 110, formatter: Select2Formatter },
                        { id: "azureMigrationEstimateID", name: "Azure Migration Estimate", field: "azureMigrationEstimateID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "AppModernization.AzureMigrationEstimate", width: 110, formatter: Select2Formatter },
                        { id: "dataConfidentialityID", name: "Data Confidentiality", field: "dataConfidentialityID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "AppModernization.DataConfidentiality", width: 110, formatter: Select2Formatter },
                        { id: "activeDevelopment", name: "Active Development", field: "activeDevelopment", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true, editor: Editors.Checkbox },
                        { id: "sourceCodeAvailable", name: "Source Code Available", field: "sourceCodeAvailable", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true, editor: Editors.Checkbox },
                        { id: "sourceCodeLocation", name: "Source Code Location", field: "sourceCodeLocation", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "currentlyDeployedID", name: "Currently Deployed", field: "currentlyDeployedID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "AppModernization.CurrentlyDeployed", width: 110, formatter: Select2Formatter },
                        { id: "CurrentURI", name: "Current URI", field: "currentURI", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "CloudAnalysisStackModernizationResult", name: "Cloud Analysis Stack Moderniz.Result", field: "cloudAnalysisStackModernizationResult", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "CloudAnalysisArchitectureModernizationResult", name: "Cloud Analysis Arch.Moderniz.Result", field: "cloudAnalysisArchitectureModernizationResult", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "CloudAnalysisBusinessModernizationResult", name: "Cloud Analysis Business Moderni.Result", field: "cloudAnalysisBusinessModernizationResult", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "cloudAnalysisMigrationStatusID", name: "Cloud Analysis Migration Status", field: "cloudAnalysisMigrationStatusID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "AppModernization.CloudAnalysisMigrationStatus", width: 110, formatter: Select2Formatter },
                        { id: "TechnicalApplicationStack", name: "Technical App.Stack", field: "technicalApplicationStack", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "Technical3rdPartyBuildDependencies", name: "Technical 3rdParty Build Dep.", field: "technical3rdPartyBuildDependencies", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "Technical3rdPartyRuntimeDependencies", name: "Technical 3rdParty Runtime Dep.", field: "technical3rdPartyRuntimeDependencies", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "TechnicalConnectivityNeededToOtherApps", name: "Technical Connectivity Needed To OtherApps", field: "technicalConnectivityNeededToOtherApps", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "TechnicalConnectivityNeededToAD", name: "Technical Connectivity Needed To AD", field: "technicalConnectivityNeededToAD", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true, editor: Editors.Checkbox },
                        { id: "TechnicalInternetFacing", name: "Technical Internet Facing", field: "technicalInternetFacing", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true, editor: Editors.Checkbox },
                        { id: "TechnicalAuthenticationMethod", name: "Technical Authentication Method", field: "technicalAuthenticationMethod", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "TechnicalAccessNeededToOnPremApps", name: "Technical Access Needed To OnPrem Apps", field: "technicalAccessNeededToOnPremApps", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true, editor: Editors.Checkbox },
                        { id: "TechnicalDB", name: "Technical DB", field: "technicalDB", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "notes", name: "Notes", field: "notes", sortable: true, filterable: true, width: 150 },
                        //{ id: "supportedOrganizations", name: "Spin", field: "supportedOrganizations", sortable: true, filterable: true, width: 100, historyVisibility: visible.always },
                        { id: "isDisabled", name: "Is Disabled", field: "isDisabled", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true, historyVisibility: visible.never },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
                onBeforeEditCell: function (e, args) {
                    if (DuPont.App.Config.crossSpinEditDisabled) {
                        const currentSpin = DuPont.App.Config.currentSpin;
                        return !currentSpin || currentSpin.toUpperCase() === (args.item.supportedOrganizations || '').toUpperCase();
                    }
                    return true;
                }
            },
            appSpin:
            {
                keyField: "applicationSpinID",
                historyKeyField: "applicationSpinHistoryID",
                controllerpath: "ApplicationSpins",
                //rootExtraPath: "nodetails",
                //modalId: "uploadModal",
                gridOptions: { editable: true, enableAddRow: false },
                columns:
                    [

                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 100, historyVisibility: visible.always },
                        { id: "applicationName", name: "App Name", field: "applicationName", sortable: true, filterable: true, width: 250, historyVisibility: visible.never },
                        { id: "supportedOrganizations", name: "Project", field: "supportedOrganizations", sortable: true, filterable: true, width: 100 },
                        { id: "testScope", name: "Test Scope", field: "testScope", sortable: true, filterable: true, width: 250, editor: Editors.Select2, lookupName: "AppSpin.LNTTestScope", formatter: Select2Formatter },
                        { id: "disposition", name: "Disposition", field: "disposition", sortable: true, filterable: true, width: 250, editor: Editors.Select2, lookupName: "AppSpin.LNTDisposition", formatter: Select2Formatter },
                        { id: "lntAppHosting", name: "AppHosting", field: "lntAppHosting", sortable: true, filterable: true, width: 250, editor: Editors.Select2, lookupName: "AppSpin.LNTAppHosting", formatter: Select2Formatter },
                        { id: "dnpOwner", name: "DuPont Contact", field: "dnpOwner", sortable: true, filterable: true, width: 250, editor: Editors.Text },
                        { id: "mneOwner", name: "Partner Contact", field: "mneOwner", sortable: true, filterable: true, width: 250, editor: Editors.Text },
                        { id: "lntFocalContact", name: "Focal Contact", field: "lntFocalContact", sortable: true, filterable: true, width: 250, editor: Editors.Text },
                        { id: "remediationOwner", name: "LnT Owner", field: "remediationOwner", sortable: true, filterable: true, width: 250, editor: Editors.Text },
                        { id: "lntSecondaryContact", name: "Secondary Contact", field: "lntSecondaryContact", sortable: true, filterable: true, width: 250, editor: Editors.Text },
                        { id: "remediationOrganizationID", name: "Build Partner", field: "remediationOrganizationID", sortable: true, filterable: true, width: 250, editor: Editors.Select2, lookupName: "AppSpin.RemediationOrganization", formatter: Select2Formatter },
                        { id: "goLiveDate", name: "GoLive Date", field: "goLiveDate", sortable: true, filterable: true, editor: Editors.Date, width: 100 },

                        { id: "isDisabled", name: "Is Disabled", field: "isDisabled", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "ssotVersion", name: "SsoT Version", field: "ssotVersion", sortable: true, filterable: true, width: 100 },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
                onBeforeEditCell: function (e, args) {
                    if (DuPont.App.Config.crossSpinEditDisabled) {
                        const currentSpin = DuPont.App.Config.currentSpin;
                        //return !currentSpin || currentSpin.toUpperCase() === (args.item.supportedOrganizations || '').toUpperCase();
                        return true;

                    }
                    return true;
                }
            },

            appMnAProjectScope:
            {
                keyField: "applicationSpinID",
                historyKeyField: "applicationSpinHistoryID",
                controllerpath: "ApplicationMnAProjectScope",
                //rootExtraPath: "nodetails",
                //modalId: "uploadModal",
                gridOptions: { editable: true, enableAddRow: false },
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 100, historyVisibility: visible.always, editor: Editors.Text },
                        { id: "applicationName", name: "App Name", field: "applicationName", sortable: true, filterable: true, width: 250, historyVisibility: visible.never },
                        { id: "startMigration", name: "Active Build", field: "startMigration", sortable: true, filterable: true, width: 200, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: false, editor: Editors.Checkbox },
                        { id: "supportedOrganizations", name: "Project", field: "supportedOrganizations", sortable: true, filterable: true, width: 100 },


                        { id: "inScope", name: "In Scope", field: "inScope", sortable: true, filterable: true, width: 100, editor: Editors.Select2, dataSource: { 0: 'Yes', 1: 'No' } },
                        { id: "duPontBusiness", name: "DuPont Business", field: "duPontBusiness", sortable: true, filterable: true, width: 200, editor: Editors.Text },
                        { id: "projectScopeNotes", name: "Project Scope Notes", field: "projectScopeNotes", sortable: true, filterable: true, width: 400, editor: Editors.Text },
                        { id: "duPontProjectOwner", name: "DuPont Project Owner", field: "duPontProjectOwner", sortable: true, filterable: true, width: 400, editor: Editors.Text },
                        { id: "nonDuPontProcessAreaLead", name: "Non-DuPont Process Area Lead", field: "nonDuPontProcessAreaLead", sortable: true, filterable: true, width: 400, editor: Editors.Text },
                        { id: "projectITContact", name: "Project IT Contact", field: "projectITContact", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "projectBusinessContact", name: "Project Business Contact", field: "projectBusinessContact", sortable: true, filterable: true, width: 400, editor: Editors.Text },


                        { id: "isDisabled", name: "Is Disabled", field: "isDisabled", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "ssotVersion", name: "SsoT Version", field: "ssotVersion", sortable: true, filterable: true, width: 100 },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.AppMnAAllowEdit)) {
                        //remove the editors from columns
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }
                    return $.when();
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
                //onBeforeEditCell: function (e, args) {
                //    if (DuPont.App.Config.crossSpinEditDisabled) {
                //        const currentSpin = DuPont.App.Config.currentSpin;
                //        return !currentSpin || currentSpin.toUpperCase() === (args.item.supportedOrganizations || '').toUpperCase();
                //    }
                //    return true;
                //}
            },
            appMnADisposition:
            {
                keyField: "applicationSpinID",
                historyKeyField: "applicationSpinHistoryID",
                controllerpath: "ApplicationMnADisposition",
                //rootExtraPath: "nodetails",
                //modalId: "uploadModal",
                gridOptions: { editable: true, enableAddRow: false },
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 100, historyVisibility: visible.always, editor: Editors.Text },
                        { id: "applicationName", name: "App Name", field: "applicationName", sortable: true, filterable: true, width: 250, historyVisibility: visible.never },
                        { id: "startMigration", name: "Active Build", field: "startMigration", sortable: true, filterable: true, width: 200, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: false, editor: Editors.Checkbox },
                        { id: "supportedOrganizations", name: "Project", field: "supportedOrganizations", sortable: true, filterable: true, width: 100 },


                        { id: "sAPInstance", name: "SAPInstance", field: "sAPInstance", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        //{ id: "disposition", name: "Disposition/Migration Type", field: "disposition", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "disposition", name: "Disposition Type", field: "disposition", sortable: true, filterable: true, editor: Editors.Text },
                        { id: "dispositionNotes", name: "Disposition Notes", field: "dispositionNotes", sortable: true, filterable: true, width: 400, editor: Editors.Text },
                        { id: "dispositionFinalized", name: "Disposition Finalized", field: "dispositionFinalized", sortable: true, filterable: true, width: 400, editor: Editors.Text },
                        { id: "dispositionOwnerDuPont", name: "DispositionOwnerDuPont", field: "dispositionOwnerDuPont", sortable: true, filterable: true, width: 400, editor: Editors.Text },
                        { id: "dispositionOwnerNonDuPont", name: "Disposition Owner Non-DuPont", field: "dispositionOwnerNonDuPont", sortable: true, filterable: true, width: 300, editor: Editors.Text },


                        { id: "isDisabled", name: "Is Disabled", field: "isDisabled", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "ssotVersion", name: "SsoT Version", field: "ssotVersion", sortable: true, filterable: true, width: 100 },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.AppMnAAllowEdit)) {
                        //remove the editors from columns
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }
                    return $.when();
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
                //onBeforeEditCell: function (e, args) {
                //    if (DuPont.App.Config.crossSpinEditDisabled) {
                //        const currentSpin = DuPont.App.Config.currentSpin;
                //        return !currentSpin || currentSpin.toUpperCase() === (args.item.supportedOrganizations || '').toUpperCase();
                //    }
                //    return true;
                //}
            },
            appMnA:
            {
                keyField: "applicationSpinID",
                historyKeyField: "applicationSpinHistoryID",
                controllerpath: "ApplicationMnA",
                //rootExtraPath: "nodetails",
                modalId: "applicationsMnAEditModal",
                gridOptions: { editable: true, enableAddRow: false },
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 100, historyVisibility: visible.always, editor: Editors.Text },
                        { id: "applicationName", name: "App Name", field: "applicationName", sortable: true, filterable: true, width: 250, historyVisibility: visible.never },
                        { id: "supportedOrganizations", name: "Project", field: "supportedOrganizations", sortable: true, filterable: true, width: 100 },

                        { id: "startMigration", name: "Active Build", field: "startMigration", sortable: true, filterable: true, width: 200, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: false, editor: Editors.Checkbox },
                        { id: "subprocessteam", name: "SubProcess Team", field: "subprocessteam", sortable: true, filterable: true, width: 250, editor: Editors.Text },
                        //{ id: "apptype", name: "Asset Type", field: "apptype", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "lntOwner", name: "Project Owner", field: "lntOwner", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "lntAppType", name: "Asset Type", field: "lntAppType", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "ApplicationType", name: "Application Type", field: "ApplicationType", sortable: true, filterable: true, width: 100, historyVisibility: visible.never },
                        { id: "vendor", name: "Vendor", field: "vendor", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "duponttechowner", name: "DuPont Tech Owner", field: "duponttechowner", sortable: true, filterable: true, width: 200, editor: Editors.Text },
                        { id: "dupontfunchowner", name: "DuPontFunchOwner", field: "dupontfunchowner", sortable: true, filterable: true, width: 200, editor: Editors.Text },
                        { id: "itservicedeliverysdl", name: "ITServiceDeliverySDL", field: "itservicedeliverysdl", sortable: true, filterable: true, width: 200, editor: Editors.Text },

                        { id: "isDisabled", name: "Is Disabled", field: "isDisabled", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "ssotVersion", name: "SsoT Version", field: "ssotVersion", sortable: true, filterable: true, width: 100 },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.AppMnAAllowEdit)) {
                        //remove the editors from columns
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }
                    return $.when();
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
                //onBeforeEditCell: function (e, args) {
                //    if (DuPont.App.Config.crossSpinEditDisabled) {
                //        const currentSpin = DuPont.App.Config.currentSpin;
                //        return !currentSpin || currentSpin.toUpperCase() === (args.item.supportedOrganizations || '').toUpperCase();
                //    }
                //    return true;
                //}
            },

            site:
            {
                keyField: "siteID",
                historyKeyField: "siteHistoryID",
                controllerpath: "Sites",
                //rootExtraPath: "nodetails",
                //modalId: "uploadModal",
                gridOptions: { enableAddRow: false, frozenColumn: 1 },
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 100, editor: Editors.Text, historyVisibility: visible.always },
                        { id: "regionID", name: "Region", field: "regionID", sortable: true, filterable: true, width: 100, editor: Editors.Select2, lookupName: "SiteRegion", width: 100, formatter: Select2Formatter },
                        { id: "project", name: "Project", field: "project", sortable: true, filterable: true, width: 100, editor: Editors.Text, historyVisibility: visible.always },
                        { id: "currentLegalEntity", name: "Current Legal Entity", field: "currentLegalEntity", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "location", name: "Location", field: "location", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "code", name: "Code", field: "code", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "ownedLeased", name: "Owned or Leased", field: "ownedLeased", sortable: true, filterable: true, width: 250, editor: Editors.Text },
                        { id: "subBusiness", name: "SubBusiness", field: "subBusiness", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "name", name: "Name", field: "name", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "assetType", name: "Asset Type", field: "assetType", sortable: true, filterable: true, width: 110, editor: Editors.Text },
                        { id: "dowID", name: "Heritage ID", field: "dowID", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "waveNumber", name: "Wave Number", field: "waveNumber", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "primarySiteType", name: "Primary Site Type", field: "primarySiteType", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "secondarySiteType", name: "Secondary Site Type", field: "secondarySiteType", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "sharedOrDedicated", name: "Shared Or Dedicated", field: "sharedOrDedicated", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "heritageCompany", name: "Heritage Company", field: "heritageCompany", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "siteLandlord", name: "Site Landlord", field: "siteLandlord", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "buAlignment", name: "BU Alignment", field: "buAlignment", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "siteDisposition", name: "Site Disposition", field: "siteDisposition", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "iTStatus", name: "IT Status", field: "iTStatus", sortable: true, filterable: true, width: 110, editor: Editors.Text },
                        { id: "priority", name: "Priority", field: "priority", sortable: true, filterable: true, width: 110, editor: Editors.Text },
                        { id: "sepGroupType", name: "Sep.Group Type", field: "sepGroupType", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "siteIntegrationLead", name: "Site Integration Lead", field: "siteIntegrationLead", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "countryID", name: "Country", field: "countryID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Country", width: 150, formatter: Select2Formatter },
                        { id: "state", name: "State", field: "state", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "streetAddress", name: "Street Address", field: "streetAddress", sortable: true, filterable: true, width: 110, editor: Editors.Text },
                        { id: "city", name: "City", field: "city", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "zipCode", name: "Zip Code", field: "zipCode", sortable: true, filterable: true, width: 100, editor: Editors.Integer },

                        { id: "userCount", name: "User Count", field: "userCount", sortable: true, filterable: true, width: 120, editor: Editors.Integer },
                        { id: "comments", name: "Comments", field: "comments", sortable: true, filterable: true, width: 120, editor: Editors.Text },

                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.AppSiteAllowManage)) {
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }

                    return $.when();
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
            },
            siteStatus:
            {
                keyField: "siteDetailID",
                historyKeyField: "siteDetailHistoryID",
                controllerpath: "SiteStatus",
                modalId: "siteStatusEditModal",
                gridOptions: { enableAddRow: false, frozenColumn: 3 },
                columns:
                    [
                        { id: "siteRegion", name: "Site Region", field: "siteRegion", sortable: true, filterable: true, width: 80, historyVisibility: visible.never },
                        { id: "siteAssetID", name: "Site Asset ID", field: "siteAssetID", sortable: true, filterable: true, historyVisibility: visible.always },
                        //{ id: "siteCode",       name: "Site Code",       field: "siteCode", sortable: true, filterable: true},
                        { id: "siteName", name: "Site Name", field: "siteName", sortable: true, filterable: true, width: 200, historyVisibility: visible.never },

                        { id: "siteAssetType", name: "Site Asset Type", field: "siteAssetType", sortable: true, filterable: true, historyVisibility: visible.never },

                        { id: "statusID", name: "Status", field: "statusID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Site.Status", width: 100, formatter: Select2Formatter },
                        { id: "notes", name: "Notes", field: "notes", sortable: true, filterable: true, width: 150 },
                        { id: "additionalServersRequired", name: "Additional Servers Required", field: "additionalServersRequired", sortable: true, filterable: true, editor: Editors.Select2YesNo, width: 80, formatter: YesNoFormatter },

                        { id: "expectedStart", name: "ExpectedStart", field: "expectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 110 },
                        { id: "actualStart", name: "ActualStart", field: "actualStart", sortable: true, filterable: true, editor: Editors.Date, width: 100 },

                        { id: "sitePlanningCall", name: "SitePlanningCall", field: "sitePlanningCall", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "sitePlanningCallExpectedStart", name: "SitePlanningCallExpectedStart", field: "sitePlanningCallExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "sitePlanningCallActualStart", name: "SitePlanningCallActualStart", field: "sitePlanningCallActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "sitePlanningCallExpectedEnd", name: "SitePlanningCallExpectedEnd", field: "sitePlanningCallExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "sitePlanningCallActualEnd", name: "SitePlanningCallActualEnd", field: "sitePlanningCallActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "siteVisit", name: "SiteVisit", field: "siteVisit", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "siteVisitExpectedStart", name: "SiteVisitExpectedStart", field: "siteVisitExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "siteVisitActualStart", name: "SiteVisitActualStart", field: "siteVisitActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "siteVisitExpectedEnd", name: "SiteVisitExpectedEnd", field: "siteVisitExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "siteVisitActualEnd", name: "SiteVisitActualEnd", field: "siteVisitActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "dataCollection", name: "DataCollection", field: "dataCollection", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "dataCollectionExpectedStart", name: "DataCollectionExpectedStart", field: "dataCollectionExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "dataCollectionActualStart", name: "DataCollectionActualStart", field: "dataCollectionActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "dataCollectionExpectedEnd", name: "DataCollectionExpectedEnd", field: "dataCollectionExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "dataCollectionActualEnd", name: "DataCollectionActualEnd", field: "dataCollectionActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "playbookDev", name: "PlaybookDev", field: "playbookDev", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "playbookDevExpectedStart", name: "PlaybookDevExpectedStart", field: "playbookDevExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "playbookDevActualStart", name: "PlaybookDevActualStart", field: "playbookDevActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "playbookDevExpectedEnd", name: "PlaybookDevExpectedEnd", field: "playbookDevExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "playbookDevActualEnd", name: "PlaybookDevActualEnd", field: "playbookDevActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "playbookReview", name: "PlaybookReview", field: "playbookReview", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "playbookReviewExpectedStart", name: "PlaybookReviewExpectedStart", field: "playbookReviewExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "playbookReviewActualStart", name: "PlaybookReviewActualStart", field: "playbookReviewActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "playbookReviewExpectedEnd", name: "PlaybookReviewExpectedEnd", field: "playbookReviewExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "playbookReviewActualEnd", name: "PlaybookReviewActualEnd", field: "playbookReviewActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "cutoverPlanDev", name: "CutoverPlanDev", field: "cutoverPlanDev", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "cutoverPlanDevExpectedStart", name: "CutoverPlanDevExpectedStart", field: "cutoverPlanDevExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "cutoverPlanDevActualStart", name: "CutoverPlanDevActualStart", field: "cutoverPlanDevActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "cutoverPlanDevExpectedEnd", name: "CutoverPlanDevExpectedEnd", field: "cutoverPlanDevExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "cutoverPlanDevActualEnd", name: "CutoverPlanDevActualEnd", field: "cutoverPlanDevActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "cutoverPlanSignOff", name: "CutoverPlanSignOff", field: "cutoverPlanSignOff", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "cutoverPlanSignOffExpectedStart", name: "CutoverPlanSignOffExpectedStart", field: "cutoverPlanSignOffExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "cutoverPlanSignOffActualStart", name: "CutoverPlanSignOffActualStart", field: "cutoverPlanSignOffActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "cutoverPlanSignOffExpectedEnd", name: "CutoverPlanSignOffExpectedEnd", field: "cutoverPlanSignOffExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "cutoverPlanSignOffActualEnd", name: "CutoverPlanSignOffActualEnd", field: "cutoverPlanSignOffActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "preMigration", name: "PreMigration", field: "preMigration", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "preMigrationExpectedStart", name: "PreMigrationExpectedStart", field: "preMigrationExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "preMigrationActualStart", name: "PreMigrationActualStart", field: "preMigrationActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "preMigrationExpectedEnd", name: "PreMigrationExpectedEnd", field: "preMigrationExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "preMigrationActualEnd", name: "PreMigrationActualEnd", field: "preMigrationActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "execution", name: "Execution", field: "execution", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "executionExpectedStart", name: "ExecutionExpectedStart", field: "executionExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "executionActualStart", name: "ExecutionActualStart", field: "executionActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "executionExpectedEnd", name: "ExecutionExpectedEnd", field: "executionExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "executionActualEnd", name: "ExecutionActualEnd", field: "executionActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "postMigration", name: "PostMigration", field: "postMigration", sortable: true, filterable: true, editor: Editors.NullableInteger, formatter: NAFormatter, allowsNA: true, cssClass: "text-right", width: 100 },
                        //{ id: "postMigrationExpectedStart", name: "PostMigrationExpectedStart", field: "postMigrationExpectedStart", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "postMigrationActualStart", name: "PostMigrationActualStart", field: "postMigrationActualStart", sortable: true, filterable: true, editor: Editors.Date, width: 160 },
                        //{ id: "postMigrationExpectedEnd", name: "PostMigrationExpectedEnd", field: "postMigrationExpectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 165 },
                        //{ id: "postMigrationActualEnd", name: "PostMigrationActualEnd", field: "postMigrationActualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 160 },

                        { id: "expectedEnd", name: "ExpectedEnd", field: "expectedEnd", sortable: true, filterable: true, editor: Editors.Date, width: 110 },
                        { id: "actualEnd", name: "ActualEnd", field: "actualEnd", sortable: true, filterable: true, editor: Editors.Date, width: 100 },

                        { id: "supportedOrganizations", name: "Spin", field: "supportedOrganizations", sortable: true, filterable: true, width: 100, historyVisibility: visible.always },

                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.AppSiteAllowManage)) {
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }

                    return $.when();
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                }
                //dataToServerParser: function (args) { return args.item; }
            },
            userManager:
            {
                keyField: "id",
                controllerpath: "usermanagement",
                modalId: "userEditModal",
                lookupConfigs: [
                    { name: 'roles', path: 'usermanagement/roles', key: 'id', display: 'name', req: { type: 'GET' } }
                ],
                transformers: {
                },
                //gridOptions: { editable: false, enableAddRow: false },
                isVirtual: true,
                multiSelectFieldName: 'userLookupValues',
                columns:
                    [
                        { id: "legacyCo", name: "Legacy Co", field: "legacyCo", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "currentID", name: "Current ID", field: "currentID", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "futureID", name: "Future ID", field: "futureID", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "firstName", name: "First Name", field: "firstName", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "middleName", name: "Middle Name", field: "middleName", sortable: true, filterable: true, editor: Editors.Text, width: 110 },
                        { id: "lastName", name: "Last Name", field: "lastName", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "email", name: "Email", field: "email", sortable: true, filterable: true, editor: Editors.Text, width: 170, validator: requiredFieldValidator },
                        { id: "userName", name: "User Name", field: "userName", sortable: true, filterable: true, editor: Editors.Text, width: 170, validator: requiredFieldValidator },
                        { id: "userStateIDs", name: "User State", field: "userStateIDs", sortable: true, filterable: true, editor: Editors.S2MultiCheckboxes, width: 180, dataSource: { 0: 'None', 1: 'TrackedUser', 2: 'ExternalUser', 4: 'FutureUser', 8: 'BaseProvisioning' }, formatter: MultiSelect2Formatter, isMultiSelect: true, isCustomMultiSelect: true },
                        { id: "userRoleIDs", name: "User Roles", field: "userRoleIDs", sortable: true, filterable: true, editor: Editors.S2MultiCheckboxes, lookupName: "UserRole", lookupConfig: 'roles', width: 180, formatter: MultiSelect2Formatter, isMultiSelect: true, isCustomMultiSelect: true },
                        //{ id: "workCountryID", name: "Work Country", field: "workCountryID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Country", width: 100, formatter: Select2Formatter },
                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    const userStateValues = [1, 2, 4];
                    var getUserRoleIDsItem = function (item) {
                        return {
                            userRoleIDs: item.userRoles.map(r => r.roleId),
                            userStateIDs: item.userState === 0 ? [0] : userStateValues.filter(v => (v & item.userState) > 0)
                        };
                    };
                    if (args.isModalEdit || args.isItemRefresh) {
                        return $.extend(args.newdata, getUserRoleIDsItem(args.newdata)); //getMultiSelectItem(args.newdata.userRoles, args.multiSelectLkp.data));
                    } else if (args.isVirtual) {
                        return args.newdata.map((item) => {
                            return $.extend(item, getUserRoleIDsItem(item));
                        });
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 }, getUserRoleIDsItem(item)); //getMultiSelectItem(item.userRoles, args.multiSelectLkp.data));
                        });
                    }
                },
                dataToServerParser: function (args) {
                    const item = args.item;
                    if (item.userRoleIDs !== undefined)
                        item.userRoles = item.userRoleIDs.map(id => ({ roleId: id, userId: item.id }));
                    if (item.userStateIDs !== undefined)
                        item.userState = item.userStateIDs.reduce((r, v) => { return v | r; }, 0);
                    return item;
                }
            },
            //Model/Configuration for BaseProvisioning.
            userBaseProvisioning: {
                keyField: "id",
                controllerpath: "userbaseprovisioning",

                columns:
                    [
                        { id: "myAccessId", name: "MyAccess ID", field: "myAccessId", sortable: true, filterable: true, width: 100 },
                        { id: "project", name: "Project", field: "project", sortable: true, filterable: true, width: 100 },
                        { id: "requestId", name: "Request Id", field: "requestId", sortable: true, filterable: true, width: 100 },
                        { id: "workSite", name: "Work Site", field: "workSite", sortable: true, filterable: true, width: 100 },
                        { id: "workAddress1", name: "Work Address1", field: "workAddress1", sortable: true, filterable: true, width: 100 },
                        { id: "workAddress2", name: "Work Address2", field: "workAddress2", sortable: true, filterable: true, width: 100 },
                        { id: "workCity", name: "Work City", field: "workCity", sortable: true, filterable: true, width: 100 },
                        { id: "workPostalCode", name: "Work Postal Code", field: "workPostalCode", sortable: true, filterable: true, width: 100 },
                        { id: "workState", name: "Work State", field: "workState", sortable: true, filterable: true, width: 100 },
                        { id: "workCountry", name: "Work Country", field: "workCountry", sortable: true, filterable: true, width: 100 },
                        { id: "workPhoneNumber", name: "Work Phone Number", field: "workPhoneNumber", sortable: true, filterable: true, width: 100 },
                        { id: "workMobileNumber", name: "Work Mobile Number", field: "workMobileNumber", sortable: true, filterable: true, width: 100 },
                        { id: "jobTitle", name: "Job Title", field: "jobTitle", sortable: true, filterable: true, width: 100 },
                        { id: "currentId", name: "Current Id", field: "currentId", sortable: true, filterable: true, width: 100 },
                        { id: "dupontEmail", name: "Dupont Email", field: "dupontEmail", sortable: true, filterable: true, width: 100 },
                        { id: "displayName", name: "Display Name", field: "displayName", sortable: true, filterable: true, width: 100 },
                        { id: "applications", name: "Applications", field: "applications", sortable: true, filterable: true, width: 100 },
                        { id: "businessUnit", name: "Business Unit", field: "businessUnit", sortable: true, filterable: true, width: 100 },
                        { id: "businessUnitId", name: "Business Unit ID", field: "businessUnitId", sortable: true, filterable: true, width: 100 },
                        { id: "eisSBU", name: "EisSBU", field: "eisSBU", sortable: true, filterable: true, width: 100 },
                        { id: "eisSBUid", name: "EisSBU ID", field: "eisSBUid", sortable: true, filterable: true, width: 100 },
                        { id: "email", name: "Email", field: "email", sortable: true, filterable: true, width: 100 },
                        { id: "firstName", name: "First Name", field: "firstName", sortable: true, filterable: true, width: 100 },
                        { id: "suffix", name: "Suffix", field: "Suffix", sortable: true, filterable: true, width: 100 },
                        { id: "functionId", name: "Function ID", field: "functionId", sortable: true, filterable: true, width: 100 },
                        { id: "identityType", name: "Identity Type", field: "identityType", sortable: true, filterable: true, width: 100 },
                        { id: "lastName", name: "Last Name", field: "lastName", sortable: true, filterable: true, width: 100 },
                        { id: "legalEntity", name: "Legal Entity", field: "legalEntity", sortable: true, filterable: true, width: 100 },
                        { id: "manager", name: "Manager", field: "manager", sortable: true, filterable: true, width: 100 },
                        { id: "middleName", name: "Middle Name", field: "middleName", sortable: true, filterable: true, width: 100 },
                        { id: "sourceOfRecord", name: "Source Of Record", field: "sourceOfRecord", sortable: true, filterable: true, width: 100 },
                        { id: "terminationDate", name: "Termination Date", field: "terminationDate", sortable: true, filterable: true, width: 100, formatter: DateTimeFormatter },
                        { id: "userStatus", name: "User Status", field: "userStatus", sortable: true, filterable: true, width: 100 },
                        { id: "workgroup", name: "Work Group", field: "workgroup", sortable: true, filterable: true, width: 100 },
                        { id: "assignedRoles", name: "Assigned Roles", field: "assignedRoles", sortable: true, filterable: true, width: 100 },
                        { id: "region", name: "Region", field: "region", sortable: true, filterable: true, width: 100 },
                        { id: "adProvisioned", name: "AD Provisioned", field: "adProvisioned", sortable: true, filterable: true, width: 100 },
                        { id: "cpassProvisioned", name: "CPASS Provisioned", field: "cpassProvisioned", sortable: true, filterable: true, width: 100 },
                        { id: "hyperRasProvisioned", name: "HyperRas Provisioned", field: "hyperRasProvisioned", sortable: true, filterable: true, width: 100 },
                        { id: "zScalerProvisioned", name: "ZScaler Provisioned", field: "zScalerProvisioned", sortable: true, filterable: true, width: 100 },
                        { id: "o365Provisioned", name: "O365 Provisioned", field: "o365Provisioned", sortable: true, filterable: true, width: 100 },
                        { id: "ddnet1Check", name: "DDNET1 Check", field: "ddnet1Check", sortable: true, filterable: true, width: 100 },
                        { id: "ddauthCheck", name: "DDAUTH Check", field: "ddauthCheck", sortable: true, filterable: true, width: 100 },
                        { id: "myAccessIdCheck", name: "MyAccess Id Check", field: "myAccessIdCheck", sortable: true, filterable: true, width: 100 },
                        { id: "dupontEmailCheck", name: "Dupont Email Check", field: "dupontEmailCheck", sortable: true, filterable: true, width: 100 },
                        { id: "provisionOrDeprovision", name: "Provision Or Deprovision", field: "provisionOrDeprovision", sortable: true, filterable: true, width: 100 },
                        { id: "baseProvisioningComplete", name: "Base Provisioning Complete", field: "baseProvisioningComplete", sortable: true, filterable: true, width: 100 },
                        { id: "baseDeProvisioningComplete", name: "Base DeProvisioning Complete", field: "baseDeProvisioningComplete", sortable: true, filterable: true, width: 100 },
                        { id: "adCreated", name: "AD Created", field: "adCreated", sortable: true, filterable: true, width: 100, formatter: DateTimeFormatter },
                        { id: "adLastLogon", name: "AD Last Logon", field: "adLastLogon", sortable: true, filterable: true, width: 100, formatter: DateTimeFormatter },
                        { id: "adUsed", name: "AD Used", field: "adUsed", sortable: true, filterable: true, width: 100, formatter: DateTimeFormatter },
                        { id: "zscalerUsed", name: "ZScaler Used", field: "zscalerUsed", sortable: true, filterable: true, width: 100, formatter: DateTimeFormatter },
                        { id: "adDuplicateFlag", name: "AD Duplicate Flag", field: "adDuplicateFlag", sortable: true, filterable: true, width: 100 },
                        { id: "dvsProvisioned", name: "DVS Provisioned", field: "dvsProvisioned", sortable: true, filterable: true, width: 100 },
                        { id: "cpassUsed", name: "CPASS Used", field: "cpassUsed", sortable: true, filterable: true, width: 100, formatter: DateTimeFormatter },
                        { id: "mfaType", name: "MFA Type", field: "mfaType", sortable: true, filterable: true, width: 100 },
                        { id: "mfaEnabled", name: "MFA Enabled", field: "mfaEnabled", sortable: true, filterable: true, width: 100 },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                    ],
                dataFromServerParser: function (args) {
                    if (args.isItemRefresh || args.isVirtual) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                }
            },
            user:
            {
                applications: {
                    key: "id",
                    display: "name",
                    data: []
                },
                keyField: "id",
                historyKeyField: "userHistoryID",
                controllerpath: "users",
                //dataLoading: 1, //on reload
                modalEditIdExtraPath: 'withpermissions',
                modalId: 'userEditModal',
                modalInit: function (args) {
                    const $permissionSelect = $("#permissionSelectID");
                    const $permissionInput = $("#permissionInputID");

                    $permissionSelect.empty();

                    $permissionSelect.off();
                    $permissionInput.off();

                    var keyUpTimeout;
                    $permissionInput.on("keyup paste", function () {
                        if (keyUpTimeout) clearTimeout(keyUpTimeout);
                        keyUpTimeout = setTimeout(filterPermissions, 300);
                    });

                    const filterPermissions = function () {
                        console.log('filterPermissions');
                        if (keyUpTimeout) clearTimeout(keyUpTimeout);
                        $permissionSelect.empty();
                        const searchItems = $permissionInput.val().toUpperCase().split(' ').filter(i => i.length > 1);
                        if (searchItems.length > 0) {

                            var selectedPermissions = $("#userPermissionsTable tbody tr").map(function () {
                                return $(this).data("perm");
                            }).toArray();

                            const appData = args.repositoryObj.applications.data;
                            for (var key in appData) {
                                let item = appData[key];
                                if (
                                    searchItems.every(searchItem => { return item.lookupTextSearch.indexOf(searchItem) > -1 })
                                    && (selectedPermissions.indexOf(item.lookupValueID) < 0)
                                ) {
                                    $permissionSelect[0].appendChild(new Option(item.lookupValueCode, item.lookupValueID))
                                }
                            }

                            //$permissionSelect.trigger('mousedown');
                        }
                    };

                    $permissionSelect.on("change", function () {
                        const $selected = $(this);
                        const $row = $("<tr>");
                        $row.data("perm", parseInt($selected.val()));
                        $row.data("permVal", 2);
                        $row.append($("<td>").text($("#permissionSelectID option:selected").text()));
                        $row.append("<td><input autocomplete='off'></td>");
                        $row.append($("<td>").text("New"));
                        $("#userPermissionsTable tbody").append($row);

                        //$permissionInput.val('');
                        //selected.empty();
                        $("#permissionSelectID option:selected").remove();
                    });

                    $permissionSelect.on("mousedown", function (e) {
                        if (e.target.nodeName === 'SELECT') $(this).val('');
                        //if ($permissionInput.val() === '')
                        //    alert('Specify a text to search through applications!');
                    });

                    const $table = $("#userPermissionsTable tbody");

                    if (!$permissionSelect.length && !$table.length) {
                        return;
                    }

                    const permStatus = {
                        0: 'Pending', 1: 'Implemented', 2: 'Rejected', 3: 'Retracted', 4: 'Not Applicable', 5: 'WIP'
                    };

                    //load permissions
                    $table.empty();
                    if (args.item && args.item.permissions) {
                        for (var i = 0; i < args.item.permissions.length; i++) {
                            const permission = args.item.permissions[i];
                            const app = args.repositoryObj.applications.data[permission.environmentDetailID];

                            var $row = $("<tr>");
                            $row.data("perm", permission.environmentDetailID);
                            $row.data("permId", permission.userAppPermissionID);
                            $row.data("permVal", permission.requestedPermissions);
                            $row.data("referenceID", permission.referenceID);
                            $row.append($("<td>").text(app ? app.lookupValueCode : ''));
                            let $refIdInput = $("<input autocomplete='off' />").attr('value', permission.referenceID);
                            $row.append($("<td>").append($refIdInput));
                            //row.append($("<td>").text(permission.referenceID));
                            var status = permStatus[permission.implementedPermissions];
                            $row.append($("<td>").text(status));
                            $table.append($row);

                            //$permissionSelect.find('option[value = "' + app.lookupValueID + '"]').remove();
                        }
                    }
                },
                //gridOptions: { editable: false, enableAddRow: false },
                isVirtual: true,
                defaultValues: { ddneT1Provisioned: "New", dvsProvisioned: "New", demProvisioned: "New" },
                setLookupDefaultValues: function (defaultValues, lookups) {
                    const userStatusLkp = lookups['UserStatus'];
                    if (userStatusLkp) {
                        for (var key in userStatusLkp) {
                            if (userStatusLkp[key] === 'Active') {
                                defaultValues.userStatusID = key;
                                break;
                            }
                        }
                    }
                },
                multiSelectFieldName: 'userLookupValues',
                columns:
                    [
                        { id: "legacyCo", name: "Legacy Co", field: "legacyCo", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "companyName", name: "Company Name", field: "companyName", sortable: true, filterable: true, editor: Editors.Text, width: 150 },
                        { id: "currentID", name: "Current ID", field: "currentID", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator, historyVisibility: visible.always },
                        { id: "futureID", name: "Future ID", field: "futureID", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "firstName", name: "First Name", field: "firstName", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "middleName", name: "Middle Name", field: "middleName", sortable: true, filterable: true, editor: Editors.Text, width: 110 },
                        { id: "lastName", name: "Last Name", field: "lastName", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "email", name: "Email", field: "email", sortable: true, filterable: true, editor: Editors.Text, width: 170, validator: requiredFieldValidator },
                        { id: "userName", name: "User Name", field: "userName", sortable: true, filterable: true, editor: Editors.Text, width: 170, validator: requiredFieldValidator, historyVisibility: visible.always },
                        { id: "phoneNumber", name: "Phone Number", field: "phoneNumber", sortable: true, filterable: true, editor: Editors.Text, width: 120 },
                        { id: "team", name: "Team", field: "team", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "workAddress1", name: "Work Address 1", field: "workAddress1", sortable: true, filterable: true, editor: Editors.Text, width: 130 },
                        { id: "workAddress2", name: "Work Address 2", field: "workAddress2", sortable: true, filterable: true, editor: Editors.Text, width: 130 },
                        { id: "workCity", name: "Work City", field: "workCity", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "workState", name: "Work State", field: "workState", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "workPostalCode", name: "Work Postal Code", field: "workPostalCode", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "workCountryID", name: "Work Country", field: "workCountryID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Country", width: 120, formatter: Select2Formatter },
                        { id: "regionID", name: "Region", field: "regionID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Region", width: 100, formatter: Select2Formatter },
                        { id: "ddneT1Provisioned", name: "DDNET1 Provisioned", field: "ddneT1Provisioned", sortable: true, filterable: true, width: 120 },
                        { id: "dvsProvisioned", name: "DVS Provisioned", field: "dvsProvisioned", sortable: true, filterable: true, width: 120 },
                        { id: "workpool", name: "Workpool", field: "workpool", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "demProvisioned", name: "DEM Provisioned", field: "demProvisioned", sortable: true, filterable: true, width: 120 },
                        { id: "vdiProvisioned", name: "VDI Provisioned", field: "vdiProvisioned", sortable: true, filterable: true, width: 120 },
                        //{ id: "dvsUrl", name: "DVS Url", field: "dvsUrl", sortable: true, filterable: true, width: 100 },
                        { id: "userTypeIDs", name: "UserTypes", field: "userTypeIDs", sortable: true, filterable: true, editor: Editors.S2MultiCheckboxes, lookupName: "UserType", width: 150, formatter: MultiSelect2Formatter, isMultiSelect: true },
                        { id: "cpass", name: "Cpass", field: "cpass", sortable: true, filterable: true, editor: Editors.Text, width: 80 },
                        { id: "cpassDeliverySite", name: "CpassDeliverySite", field: "cpassDeliverySite", sortable: true, filterable: true, editor: Editors.Text, width: 80 },
                        { id: "mfa", name: "MFA", field: "mfa", sortable: true, filterable: true, editor: Editors.Text, width: 80 },
                        { id: "phibred", name: "Phibred", field: "phibred", sortable: true, filterable: true, editor: Editors.Text, width: 80 },
                        { id: "phibredMFA", name: "PhibredMFA", field: "phibredMFA", sortable: true, filterable: true, editor: Editors.Text, width: 80 },
                        { id: "hyperras", name: "Hyperras", field: "hyperras", sortable: true, filterable: true, editor: Editors.Text, width: 80 },
                        { id: "office365", name: "Office365", field: "office365", sortable: true, filterable: true, editor: Editors.Text, width: 80 },
                        { id: "lotusnotes", name: "Lotusnotes", field: "lotusnotes", sortable: true, filterable: true, editor: Editors.Checkbox, width: 80, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "communicationRequirements", name: "Communication Requirements", field: "communicationRequirements", sortable: true, filterable: true, editor: Editors.Checkbox, width: 80, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "goLiveTypeIDs", name: "Go Live Type", field: "goLiveTypeIDs", sortable: true, filterable: true, editor: Editors.S2MultiCheckboxes, lookupName: "GoLiveType", width: 200, formatter: MultiSelect2Formatter, isMultiSelect: true },
                        { id: "goLiveDate", name: "Go Live Date", field: "goLiveDate", sortable: true, filterable: true, editor: Editors.Date, width: 100 },
                        { id: "dvsLogin", name: "DVS Login", field: "dvsLogin", sortable: true, filterable: true, editor: Editors.Date, width: 100 },
                        { id: "demLogin", name: "DEM Login", field: "demLogin", sortable: true, filterable: true, editor: Editors.Date, width: 100 },
                        { id: "wsLeadApprover", name: "WS LeadApprover", field: "wsLeadApprover", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "userLabelIDs", name: "User Labels", field: "userLabelIDs", sortable: true, filterable: true, editor: Editors.S2MultiCheckboxes, lookupName: "UserLabel", width: 150, formatter: MultiSelect2Formatter, isMultiSelect: true },
                        { id: "userStatusID", name: "User Status", field: "userStatusID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "UserStatus", width: 120, formatter: Select2Formatter },
                        { id: "fileReference", name: "File Reference", field: "fileReference", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dependencies: [{ name: 'applications', url: 'api/applications/maininfo/{spin}' }],
                transformers: {
                    getAll: function (grid, dataview, data, repository) {
                        if (data.applications) {
                            repository.applications.data = data.applications.reduce((result, app) => {
                                result[app.environmentDetailID] =
                                {
                                    lookupValueID: app.environmentDetailID,
                                    lookupValueCode: (
                                        app.environmentTypeCode + ' ' +
                                        app.supportedOrganizations + ' - ' +
                                        app.assetID + ' ' +
                                        app.applicationName +
                                        (app.sid ? '(' + app.sid + ')' : '')
                                    ),
                                    lookupTextSearch:
                                        (
                                            app.environmentTypeCode + ' ' +
                                            app.supportedOrganizations + ' - ' +
                                            app.assetID + ' ' +
                                            app.applicationName +
                                            (app.sid ? '(' + app.sid + ')' : '')
                                        ).toUpperCase()
                                };
                                return result;
                            }, {});
                        }

                        return data.users;
                    }
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return $.extend(args.newdata, getMultiSelectItem(args.newdata[args.multiSelectLkp.multiSelectFieldName], args.multiSelectLkp.data));
                    } else if (args.isVirtual) {
                        return args.newdata.map((item) => {
                            return $.extend(item, getMultiSelectItem(item[args.multiSelectLkp.multiSelectFieldName], args.multiSelectLkp.data));
                        });
                    } else {
                        const multiSelectFieldName = args.multiSelectLkp.multiSelectFieldName;
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 }, getMultiSelectItem(item[multiSelectFieldName], args.multiSelectLkp.data));
                        });
                    }
                },
                dataToServerParser: function (args) {
                    const item = args.item;
                    item.userState = 1;
                    item[args.multiSelectLkp.multiSelectFieldName] =
                        args.multiSelectLkp.fields.reduce((result, field) => {
                            return result.concat((item[field] || []).filter(lv => lv).map(lv => ({ userID: item.id, lookupValueID: lv })));
                        }, []);

                    //////
                    if (args.isModalEdit) {
                        item.permissions =
                            $("#userPermissionsTable tbody tr").map(function () {
                                var $this = $(this);
                                var result = {
                                    environmentDetailID: $this.data("perm"),
                                    requestedPermissions: $this.data("permVal"),
                                    referenceID: $this.find('td>input').val()
                                };

                                var tempId = $this.data("permId");
                                if (tempId) {
                                    result.userAppPermissionID = tempId;
                                }

                                return result;
                            }).get();
                    }
                    //////
                    return item;
                },
                //validateUniqueConstraints: function (args) {
                //    const deferred = $.Deferred();
                //    const uniqueColName = 'currentID';

                //    args.gridComponent.ensureColumnsData([uniqueColName], false)
                //        .done(() => {
                //            const dataView = args.gridComponent.getInnerGrid().getData();
                //            const validationResult = dataView.getItems().reduce(function (result, item) {
                //                const value = item[uniqueColName];
                //                if (result.uniques.indexOf(value) === -1) {
                //                    result.uniques.push(value);
                //                } else if (result.duplicates.indexOf(value) === -1) {
                //                    result.duplicates.push(value);
                //                }
                //                return result;
                //            }, { uniques: [], duplicates: [] });

                //            if (validationResult.duplicates.length > 0) {
                //                const msg = 'Duplicate values found for ' + uniqueColName + '!<br/ >' +
                //                    validationResult.duplicates.join(', ');
                //                DuPont.App.Dialog.ShowMessage($('.h-title').text(), msg);
                //                deferred.reject();
                //            } else {
                //                deferred.resolve();
                //            }
                //        }).fail(deferred.reject);

                //    return deferred.promise();
                //}
            },
            userPermission:
            {
                keyField: "userAppPermissionID",
                historyKeyField: "userAppPermissionHistoryID",
                controllerpath: "userPermissions",
                //dataLoading: 1, //on reload
                modalId: "userEditModal",
                transformers: {
                },
                gridOptions: { editable: true, enableAddRow: false },
                isVirtual: true,
                columns:
                    [
                        { id: "lastName", name: "Last Name", field: "lastName", sortable: true, width: 100, filterable: true, historyVisibility: visible.never },
                        { id: "firstName", name: "First Name", field: "firstName", sortable: true, width: 100, filterable: true, historyVisibility: visible.never },
                        { id: "middleName", name: "Middle Name", field: "middleName", sortable: true, width: 100, filterable: true, historyVisibility: visible.never },
                        { id: "currentID", name: "Current ID", field: "currentID", sortable: true, width: 100, validator: requiredFieldValidator, filterable: true, historyVisibility: visible.always },
                        { id: "futureID", name: "Future ID", field: "futureID", sortable: true, width: 100, filterable: true, historyVisibility: visible.never },
                        { id: "email", name: "Email", field: "email", sortable: true, width: 170, validator: requiredFieldValidator, filterable: true, historyVisibility: visible.never },
                        { id: "ddneT1Provisioned", name: "DDNET1 Provisioned", field: "ddneT1Provisioned", sortable: true, filterable: true, width: 120, historyVisibility: visible.never },
                        { id: "dvsProvisioned", name: "DVS Provisioned", field: "dvsProvisioned", sortable: true, width: 100, filterable: true, historyVisibility: visible.never },
                        { id: "demProvisioned", name: "DEM Provisioned", field: "demProvisioned", sortable: true, width: 100, filterable: true, historyVisibility: visible.never },
                        { id: "dvsUrl", name: "DVS Url", field: "dvsUrl", sortable: true, width: 100, filterable: true, historyVisibility: visible.never },
                        { id: "cpass", name: "Cpass", field: "cpass", sortable: true, width: 100, filterable: true, historyVisibility: visible.never },
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, width: 100, filterable: true, historyVisibility: visible.always },
                        { id: "targetSid", name: "Sid", field: "targetSid", sortable: true, width: 100, filterable: true },
                        { id: "supportedOrganizations", name: "Supported Organizations", field: "supportedOrganizations", sortable: true, width: 100, filterable: true, historyVisibility: visible.always },
                        { id: "applicationName", name: "Application Name", field: "applicationName", sortable: true, width: 250, filterable: true, historyVisibility: visible.never },
                        { id: "environmentTypeName", name: "Environment Type Name", field: "environmentTypeName", sortable: true, width: 160, filterable: true, historyVisibility: visible.always },
                        { id: "implementedPermissions", name: "Status", field: "implementedPermissions", sortable: true, width: 100, filterable: true, editor: Editors.Select2, dataSource: { 0: 'Pending', 1: 'Implemented', 2: 'Rejected', 3: 'Retracted', 4: 'Not Applicable', 5: 'WIP' }, formatter: Select2Formatter },
                        { id: "referenceID", name: "Reference ID", field: "referenceID", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isItemRefresh || args.isVirtual) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (item) { return item; }
                onBeforeEditCell: function (e, args) {
                    if (DuPont.App.Config.crossSpinEditDisabled) {
                        const currentSpin = DuPont.App.Config.currentSpin;
                        return !currentSpin || currentSpin.toUpperCase() === (args.item.supportedOrganizations || '').toUpperCase();
                    }
                    return true;
                }
            },
            goliveuser:
            {
                keyField: "id",
                controllerpath: "goliveusers",
                //modalId: "uploadModal",
                //gridOptions: { editable: false, enableAddRow: false },
                columns:
                    [
                        //{ id: "lastName", name: "LastName", field: "lastName", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "effectiveDate", name: "Effective Date", field: "effectiveDate", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "legacyCo", name: "Legacy Co", field: "legacyCo", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "currentID", name: "Unique Employee ID", field: "currentID", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "firstName", name: "First Name", field: "firstName", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "middleName", name: "Middle Name", field: "middleName", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "lastName", name: "Last Name", field: "lastName", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "employeeStatus", name: "Employee Status", field: "employeeStatus", sortable: true, editor: Editors.Text, width: 100 },
                        //{ id: "employeeContractTypeID", name: "Employee Contract Type", field: "employeeContractTypeID", sortable: true, editor: Editors.Select2, dataSourcePath: "lookupvalues/lookup/Country", valuefield: "lookupValueID", displayfield: "lookupValueCode", width: 100, formatter: Select2Formatter },
                        { id: "email", name: "Email", field: "email", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "workCountryID", name: "Work Country", field: "workCountryID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Country", width: 100, formatter: Select2Formatter },
                        { id: "regionID", name: "Region", field: "regionID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Region", width: 100, formatter: Select2Formatter },
                        { id: "workLocation", name: "Work Location", field: "workLocation", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "workCity", name: "Work City", field: "workCity", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "workState", name: "Work State", field: "workState", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "currentMgrLegacyCo", name: "Current Mgr Legacy Co", field: "currentMgrLegacyCo", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "currentMgrEmployeeID", name: "Current Mgr Employee ID", field: "currentMgrEmployeeID", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "plannedAdjustedReleaseDate", name: "Planned Adjusted Release Date", field: "plannedAdjustedReleaseDate", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "onOffboardingStatus", name: "On Offboarding Status", field: "onOffboardingStatus", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "currentStateLegalEntity", name: "Current State Legal Entity", field: "currentStateLegalEntity", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "remapToNewCoOrCorporate", name: "Remap To New Co Or Corporate", field: "remapToNewCoOrCorporate", sortable: true, editor: Editors.Text, width: 100 },
                        { id: "remapToFunction", name: "Remap To Function", field: "remapToFunction", sortable: true, editor: Editors.Text, width: 100 }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit) {
                        return args.newdata;
                    } else if (args.newItemIdx) {
                        return $.extend(
                            { num: args.newItemIdx, __id: args.newItemIdx }
                            , args.newdata
                        );
                    }
                    else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(
                                { num: idx + 1, __id: idx + 1 }
                                , item
                            );
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
            },
            lookup:
            {
                keyField: "lookupID",
                controllerpath: "lookups",
                gridOptions: { editable: false, enableAddRow: false },
                columns:
                    [
                        //{ id: "lookupID", name: "LookupID", field: "lookupID", sortable: true, width: 80 },
                        { id: "lookupName", name: "LookupName", field: "lookupName", sortable: true, width: 200, validator: requiredFieldValidator }
                    ],
                dataFromServerParser: function (args) {
                    return args.newdata.map((item, idx) => {
                        return $.extend(
                            { num: idx + 1, __id: idx + 1 }
                            , item
                        );
                    });
                },
                //dataToServerParser: function (args) { return args.item; }
            },
            lookupvalue:
            {
                keyField: "lookupValueID",
                controllerpath: "lookupvalues",
                gridOptions: null,
                defaultValues: { lookupValueOrder: 0 },
                lookupConfigs: [
                    { name: 'lookups', path: 'lookups', key: 'lookupID', display: 'lookupName', req: { type: 'GET' } }
                ],
                columns:
                    [
                        { id: "lookupID", name: "Lookup", field: "lookupID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Lookup", lookupConfig: 'lookups', width: 150, formatter: Select2Formatter, validator: requiredFieldValidator },
                        //{ id: "lookupValueID", name: "LookupValueID", field: "lookupValueID", sortable: true, filterable: true, },
                        { id: "lookupValueCode", name: "LookupValueCode", field: "lookupValueCode", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "lookupValueDescription", name: "LookupValueDescription", field: "lookupValueDescription", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "lookupValueOrder", name: "LookupValueOrder", field: "lookupValueOrder", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isItemRefresh || args.isVirtual) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                }
                //dataToServerParser: function (args) { return args.item; }
            },
            azureIaaS:
            {
                keyField: "azureObjectID",
                historyKeyField: "azureObjectHistoryID",
                controllerpath: "Azure",
                dataLoading: -1, // never
                //rootExtraPath: "nodetails",
                //modalId: "uploadModal",
                gridOptions: { enableAddRow: false, frozenColumn: 0 },
                columns:
                    [
                        { id: "dateSubmited", name: "Date Submited", field: "dateSubmited", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter },
                        { id: "status", name: "Status", field: "status", sortable: true, filterable: true, width: 90 },
                        { id: "company", name: "Company", field: "company", sortable: true, filterable: true, width: 100 },
                        { id: "applicationName", name: "Application Name", field: "applicationName", sortable: true, filterable: true, width: 170, historyVisibility: visible.never },
                        { id: "assetID", name: "AssetID", field: "assetID", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "projectManager", name: "ProjectManager", field: "projectManager", sortable: true, filterable: true, width: 170 },
                        { id: "businessPriority", name: "Priority", field: "businessPriority", sortable: true, filterable: true, width: 80 },
                        { id: "downloadLinks", name: "Download", field: "", sortable: true, filterable: true, width: 150, formatter: EnvLinksFormatter, historyVisibility: visible.never },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) { return args.item; }
            },
            spinProgram:
            {
                keyField: "spinProgramID",
                historyKeyField: "",
                controllerpath: "spinprogram",
                //dataLoading: 1, //on reload
                transformers: {
                },
                gridOptions: { editable: false, enableAddRow: false },
                isVirtual: true,
                columns:
                    [
                        { id: "spinProgramID", name: "SpinProgramID", field: "spinProgramID", sortable: true, width: 100, filterable: true },
                        { id: "fileDate", name: "FileDate", field: "fileDate", sortable: true, width: 100, filterable: true, formatter: DateFormatter },
                        { id: "unique_ID", name: "Unique_ID", field: "unique_ID", sortable: true, width: 100, filterable: true },
                        { id: "summary", name: "Summary", field: "summary", sortable: true, width: 100, filterable: true },
                        { id: "workstream", name: "Workstream", field: "workstream", sortable: true, width: 100, filterable: true },
                        { id: "planningLevel", name: "PlanningLevel", field: "planningLevel", sortable: true, width: 100, filterable: true },
                        { id: "name", name: "Name", field: "name", sortable: true, width: 100, filterable: true },
                        { id: "predecessors", name: "Predecessors", field: "predecessors", sortable: true, width: 100, filterable: true },
                        { id: "team", name: "Team", field: "team", sortable: true, width: 100, filterable: true },
                        { id: "del_ID", name: "Del_ID", field: "del_ID", sortable: true, width: 100, filterable: true },
                        { id: "projectName", name: "ProjectName", field: "projectName", sortable: true, width: 100, filterable: true },
                        { id: "siteNumber", name: "SiteNumber", field: "siteNumber", sortable: true, width: 100, filterable: true },
                        { id: "globalRegion", name: "GlobalRegion", field: "globalRegion", sortable: true, width: 100, filterable: true },
                        { id: "status", name: "Status", field: "status", sortable: true, width: 100, filterable: true },
                        { id: "rYGStatus", name: "RYGStatus", field: "rYGStatus", sortable: true, width: 100, filterable: true },
                        { id: "percent_Complete", name: "Percent_Complete", field: "percent_Complete", sortable: true, width: 100, filterable: true },
                        { id: "percent_Planned_Complete", name: "Percent_Planned_Complete", field: "percent_Planned_Complete", sortable: true, width: 100, filterable: true },
                        { id: "sPI", name: "SPI", field: "sPI", sortable: true, width: 100, filterable: true },
                        { id: "remaining_Work_Hrs", name: "Remaining_Work_Hrs", field: "remaining_Work_Hrs", sortable: true, width: 100, filterable: true },
                        { id: "resource_Names", name: "Resource_Names", field: "resource_Names", sortable: true, width: 100, filterable: true },
                        { id: "duration_Workdays", name: "Duration_Workdays", field: "duration_Workdays", sortable: true, width: 100, filterable: true },
                        { id: "start_Date", name: "Start_Date", field: "start_Date", sortable: true, width: 100, filterable: true },
                        { id: "finish_Date", name: "Finish_Date", field: "finish_Date", sortable: true, width: 100, filterable: true },
                        { id: "baseline_Start", name: "Baseline_Start", field: "baseline_Start", sortable: true, width: 100, filterable: true },
                        { id: "baseline_Finish", name: "Baseline_Finish", field: "baseline_Finish", sortable: true, width: 100, filterable: true },
                        { id: "notes", name: "Notes", field: "notes", sortable: true, width: 100, filterable: true },
                        { id: "completedDays", name: "CompletedDays", field: "completedDays", sortable: true, width: 100, filterable: true },
                        { id: "plannedDays", name: "PlannedDays", field: "plannedDays", sortable: true, width: 100, filterable: true },
                        { id: "portfolio", name: "Portfolio", field: "portfolio", sortable: true, width: 100, filterable: true },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isItemRefresh || args.isVirtual) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                }
                //dataToServerParser: function (item) { return item; }
            },
            techTransformation:
            {
                keyField: "techTransformationID",
                historyKeyField: "",
                controllerpath: "techtransformation",
                //dataLoading: 1, //on reload
                transformers: {
                },
                gridOptions: { editable: false, enableAddRow: false },
                isVirtual: true,
                columns:
                    [
                        { id: "techTransformationID", name: "TechTransformationID", field: "techTransformationID", sortable: true, width: 100, filterable: true },
                        { id: "fileDate", name: "FileDate", field: "fileDate", sortable: true, width: 100, filterable: true, formatter: DateFormatter },
                        { id: "status_Date", name: "Status_Date", field: "status_Date", sortable: true, width: 100, filterable: true },
                        { id: "unique_ID", name: "Unique_ID", field: "unique_ID", sortable: true, width: 100, filterable: true },
                        { id: "taskName", name: "Task Name", field: "taskName", sortable: true, width: 100, filterable: true },
                        { id: "percentComplete", name: "PercentComplete", field: "percentComplete", sortable: true, width: 100, filterable: true },
                        { id: "duration", name: "Duration", field: "duration", sortable: true, width: 100, filterable: true },
                        { id: "trackMilestone", name: "TrackMilestone", field: "trackMilestone", sortable: true, width: 100, filterable: true },
                        { id: "baselineDuration", name: "BaselineDuration", field: "baselineDuration", sortable: true, width: 100, filterable: true },
                        { id: "projectName", name: "ProjectName", field: "projectName", sortable: true, width: 100, filterable: true },
                        { id: "plannedPercentComplete", name: "PlannedPercentComplete", field: "plannedPercentComplete", sortable: true, width: 100, filterable: true },
                        { id: "specCo", name: "SpecCo", field: "specCo", sortable: true, width: 100, filterable: true },
                        { id: "calc_CompletedDays", name: "Calc_CompletedDays", field: "calc_CompletedDays", sortable: true, width: 100, filterable: true },
                        { id: "calc_DurationWorkdays", name: "Calc_DurationWorkdays", field: "calc_DurationWorkdays", sortable: true, width: 100, filterable: true },
                        { id: "calc_PlannedPercentComplete", name: "Calc_PlannedPercentComplete", field: "calc_PlannedPercentComplete", sortable: true, width: 100, filterable: true },
                        { id: "calc_PlannedDays", name: "Calc_PlannedDays", field: "calc_PlannedDays", sortable: true, width: 100, filterable: true },
                        { id: "summary", name: "Summary", field: "summary", sortable: true, width: 100, filterable: true },
                        { id: "taskUID", name: "TaskUID", field: "TaskUID", sortable: true, width: 100, filterable: true },
                        { id: "calc_BaselineDuration", name: "Calc_BaselineDuration", field: "calc_BaselineDuration", sortable: true, width: 100, filterable: true },
                        { id: "calc_WorkdaysBaseline", name: "Calc_WorkdaysBaseline", field: "calc_WorkdaysBaseline", sortable: true, width: 100, filterable: true },
                        { id: "start_Date", name: "Start_Date", field: "start_Date", sortable: true, width: 100, filterable: true },
                        { id: "finish_Date", name: "Finish_Date", field: "finish_Date", sortable: true, width: 100, filterable: true },
                        { id: "baseline_Start", name: "Baseline_Start", field: "baseline_Start", sortable: true, width: 100, filterable: true },
                        { id: "baseline_Finish", name: "Baseline_Finish", field: "baseline_Finish", sortable: true, width: 100, filterable: true },
                        { id: "calc_Workdays", name: "Calc_Workdays", field: "calc_Workdays", sortable: true, width: 100, filterable: true },
                        { id: "projectName", name: "ProjectName", field: "projectName", sortable: true, width: 100, filterable: true },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isItemRefresh || args.isVirtual) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                }
                //dataToServerParser: function (item) { return item; }
            },
            sapUserRequests: {
                keyField: "sapUserRequestId",
                historyKeyField: "sapUserRequestHistoryId",
                controllerpath: "sapuserrequests",
                transformers: {},
                gridOptions: { editable: true, enableAddRow: false },
                isVirtual: true,
                columns:
                    [
                        { id: "sapUserRequestId", name: "Id", field: "sapUserRequestId", sortable: false, width: 50, filterable: false, historyVisibility: visible.always },
                        { id: "requestId", name: "RequestId", field: "requestId", sortable: true, width: 100, filterable: true },
                        { id: "myAccessId", name: "MyAccessId", field: "myAccessId", sortable: true, filterable: true, width: 110, validator: requiredFieldValidator, historyVisibility: visible.always },
                        { id: "firstName", name: "First Name", field: "firstName", sortable: true, width: 110 },
                        { id: "middleName", name: "Middle Name", field: "middleName", sortable: true, width: 110 },
                        { id: "lastName", name: "Last Name", field: "lastName", sortable: true, width: 110 },
                        { id: "email", name: "Email", field: "email", sortable: true, filterable: true, width: 170, validator: requiredFieldValidator },
                        { id: "phoneNumber", name: "Phone Number", field: "phoneNumber", sortable: true, filterable: true, width: 120 },
                        { id: "userType", name: "User Type", field: "userType", sortable: true, width: 100, filterable: true, editor: Editors.Text },
                        { id: "businessProcessTeam", name: "BPT", field: "businessProcessTeam", sortable: true, width: 100, filterable: true, editor: Editors.Text },
                        { id: "projectName", name: "Project", field: "projectName", sortable: true, width: 150, filterable: true, editor: Editors.Text },
                        { id: "systemType", name: "System Name Client", field: "systemType", sortable: true, width: 170, filterable: true, editor: Editors.Text },
                        { id: "sapUserRequestStatusId", name: "Provisioning State", field: "sapUserRequestStatusId", sortable: false, width: 150, filterable: true, editor: Editors.Select2, lookupName: "SapUserRequestStatus", formatter: Select2Formatter },
                        { id: "comments", name: "Comments", field: "comments", sortable: false, width: 150, filterable: false, historyVisibility: visible.always, editor: Editors.Text },
                        { id: "accountOwner", name: "Account Owner", field: "accountOwner", sortable: true, width: 150, filterable: true, historyVisibility: visible.always, editor: Editors.Text },
                        { id: "region", name: "Region", field: "region", sortable: true, filterable: true, width: 170, historyVisibility: visible.always, editor: Editors.Text, validator: requiredFieldValidator },
                        { id: "testPhase", name: "Test Phase", field: "testPhase", sortable: true, filterable: true, width: 170, historyVisibility: visible.always, editor: Editors.Text },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                    ],
                setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.SapUserReqAllowEdit)) {
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }

                    return $.when();
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                }
            },
            serviceDeliveryWorkPlan:
            {
                keyField: "serviceDeliveryWorkPlanID",
                historyKeyField: "",
                controllerpath: "serviceDeliveryWorkPlan",
                //dataLoading: 1, //on reload
                transformers: {
                },
                gridOptions: { editable: false, enableAddRow: false },
                isVirtual: true,
                columns:
                    [
                        { id: "serviceDeliveryWorkPlanID", name: "ServiceDeliveryWorkPlanID", field: "serviceDeliveryWorkPlanID", sortable: true, width: 100, filterable: true },
                        { id: "fileDate", name: "FileDate", field: "fileDate", sortable: true, width: 100, filterable: true, formatter: DateFormatter },
                        { id: "unique_ID", name: "Unique_ID", field: "unique_ID", sortable: true, width: 100, filterable: true },
                        { id: "summary", name: "Summary", field: "summary", sortable: true, width: 100, filterable: true },
                        { id: "workstream", name: "Workstream", field: "workstream", sortable: true, width: 100, filterable: true },
                        { id: "planningLevel", name: "PlanningLevel", field: "planningLevel", sortable: true, width: 100, filterable: true },
                        { id: "name", name: "Name", field: "name", sortable: true, width: 100, filterable: true },
                        { id: "predecessors", name: "Predecessors", field: "predecessors", sortable: true, width: 100, filterable: true },
                        { id: "team", name: "Team", field: "team", sortable: true, width: 100, filterable: true },
                        { id: "del_ID", name: "Del_ID", field: "del_ID", sortable: true, width: 100, filterable: true },
                        { id: "projectName", name: "ProjectName", field: "projectName", sortable: true, width: 100, filterable: true },
                        { id: "siteNumber", name: "SiteNumber", field: "siteNumber", sortable: true, width: 100, filterable: true },
                        { id: "globalRegion", name: "GlobalRegion", field: "globalRegion", sortable: true, width: 100, filterable: true },
                        { id: "status", name: "Status", field: "status", sortable: true, width: 100, filterable: true },
                        { id: "rYGStatus", name: "RYGStatus", field: "rYGStatus", sortable: true, width: 100, filterable: true },
                        { id: "percent_Complete", name: "Percent_Complete", field: "percent_Complete", sortable: true, width: 100, filterable: true },
                        { id: "percent_Planned_Complete", name: "Percent_Planned_Complete", field: "percent_Planned_Complete", sortable: true, width: 100, filterable: true },
                        { id: "sPI", name: "SPI", field: "sPI", sortable: true, width: 100, filterable: true },
                        { id: "remaining_Work_Hrs", name: "Remaining_Work_Hrs", field: "remaining_Work_Hrs", sortable: true, width: 100, filterable: true },
                        { id: "resource_Names", name: "Resource_Names", field: "resource_Names", sortable: true, width: 100, filterable: true },
                        { id: "duration_Workdays", name: "Duration_Workdays", field: "duration_Workdays", sortable: true, width: 100, filterable: true },
                        { id: "start_Date", name: "Start_Date", field: "start_Date", sortable: true, width: 100, filterable: true },
                        { id: "finish_Date", name: "Finish_Date", field: "finish_Date", sortable: true, width: 100, filterable: true },
                        { id: "baseline_Start", name: "Baseline_Start", field: "baseline_Start", sortable: true, width: 100, filterable: true },
                        { id: "baseline_Finish", name: "Baseline_Finish", field: "baseline_Finish", sortable: true, width: 100, filterable: true },
                        { id: "notes", name: "Notes", field: "notes", sortable: true, width: 100, filterable: true },
                        { id: "completedDays", name: "CompletedDays", field: "completedDays", sortable: true, width: 100, filterable: true },
                        { id: "plannedDays", name: "PlannedDays", field: "plannedDays", sortable: true, width: 100, filterable: true },
                        { id: "subTeam", name: "SubTeam", field: "subTeam", sortable: true, width: 100, filterable: true },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isItemRefresh || args.isVirtual) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                }
                //dataToServerParser: function (item) { return item; }
            },
            error:
            {
                keyField: "id",
                historyKeyField: "",
                controllerpath: "errors",
                //dataLoading: 1, //on reload
                modalId: "errorEditModal",
                transformers: {
                },
                gridOptions: { editable: false, enableAddRow: false },
                isVirtual: true,
                columns:
                    [
                        { id: "id", name: "Id", field: "id", sortable: true, width: 60, filterable: true },
                        //{ id: "date", name: "Date", field: "date", sortable: true, width: 120, filterable: true, formatter: DateTimeFormatter },
                        { id: "userName", name: "UserName", field: "userName", sortable: true, width: 170, filterable: true },
                        { id: "errorMessage", name: "Error Message", field: "errorMessage", sortable: true, width: 600, filterable: true },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 150, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                }
                //dataToServerParser: function (item) { return item; }
            },
            dbFile:
            {
                keyField: "id",
                historyKeyField: "",
                controllerpath: "dbfiles",
                //dataLoading: 1, //on reload
                modalId: "dbFileEditModal",
                modalInit: function (args) {
                    args.$modal.on(BS_SHOW_MODAL_EVENT, onShowModal);
                    args.$modal.on(BS_HIDE_MODAL_EVENT, onHideModal);

                    const $dbFileUpload = $("#dbFileUpload");

                    function onShowModal(e) {
                        args.$modal.off(BS_SHOW_MODAL_EVENT);

                        $dbFileUpload.off();
                        $dbFileUpload.on("change", function (e) {
                            const file = $(this)[0].files[0];
                            //$("#fileName").val(file.name);
                            //$("#fileContentType").val(file.type);

                            var fr = new FileReader();
                            fr.onload = function () {
                                //Note: The file's result results in a string that cannot be directly decoded as Base64. 
                                //To retrieve only the Base64 encoded string, you must remove data:*/*;base64, from the string.
                                const base64 = fr.result.slice(fr.result.indexOf(';base64,') + 8);
                                $dbFileUpload.data('fileContent', base64);
                                $("#fileName").val(file.name);
                                $("#fileContentType").val(file.type);
                            };
                            fr.readAsDataURL(file);
                        });
                    }

                    function onHideModal(e) {
                        $dbFileUpload.off();
                    }
                },
                showUploadProgress: true,
                transformers: {
                },
                gridOptions: { editable: false, enableAddRow: false },
                isVirtual: true,
                columns:
                    [
                        { id: "id", name: "Id", field: "id", sortable: true, width: 60, filterable: true },
                        { id: "fileName", name: "File Name", field: "fileName", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "fileContentType", name: "File Content Type", field: "fileContentType", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "fileSize", name: "File Size", field: "fileSize", sortable: true, filterable: true, width: 100, formatter: FileSizeFormatter, cssClass: "text-right" },
                        { id: "labels", name: "Labels", field: "labels", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "uploaded", name: "Uploaded", field: "uploaded", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter },
                        { id: "download", name: "Download", field: "download", sortable: false, filterable: true, width: 70, formatter: DownloadLinkFormatter },
                        { id: "imported", name: "Imported", field: "imported", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter },
                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                dataToServerParser: function (args) {
                    const $dbFileUpload = $("#dbFileUpload");
                    if ($dbFileUpload.length > 0 && $dbFileUpload[0].files.length > 0) {
                        //args.item.file = $dbFileUpload[0].files[0];
                        //if (args.updtype === 0) args.changedFields.push('file');
                        args.item.fileContentAsBase64 = $dbFileUpload.data('fileContent');
                        if (args.updtype === 0) args.changedFields.push('fileContentAsBase64');
                    }
                    return args.item;
                },
                customToolbarHandler: function (args) {
                    //if (args.$btn.hasClass('btn-dbfile-download')) {
                    //    //custom code
                    //    return true;
                    //}
                }
            },
            emailMessage:
            {
                keyField: "emailMessageID",
                historyKeyField: "emailMessageHistoryID",
                controllerpath: "emailmessages",
                //dataLoading: 1, //on reload
                modalId: "emailMessageEditModal",
                modalInit: function (args) {
                    const msgType = $('#gridNavbar [data-filter-order=1]').attr('data-filter');
                    $(`#${args.repositoryObj.modalId} .form-control:not(:checkbox)`).prop('readonly', msgType !== 'templates');
                    $(`#${args.repositoryObj.modalId} .form-control:checkbox`).prop('disabled', msgType !== 'templates');

                    $('#contentPreview').html(args.item ? args.item.content : null);

                    args.$modal.on(BS_SHOW_MODAL_EVENT, onShowModal);
                    args.$modal.on(BS_HIDE_MODAL_EVENT, onHideModal);

                    function onShowModal(e) {
                        args.$modal.off(BS_SHOW_MODAL_EVENT);
                        $('#navContentPreviewTab').on(BS_SHOWN_TAB_EVENT, (e) => {
                            $('#contentPreview').html($('#content').val());
                        });
                    }

                    function onHideModal(e) {
                        $('#navContentPreviewTab').off(BS_SHOWN_TAB_EVENT);
                    }
                },
                transformers: {
                },
                gridOptions: { editable: true, enableAddRow: false },
                isVirtual: true,
                columns:
                    [
                        { id: "emailMessageID", name: "EmailMessageID", field: "emailMessageID", sortable: true, width: 60, filterable: true },
                        { id: "templateCode", name: "Template Code", field: "templateCode", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        //{ id: "isTemplate", name: "Is Template", field: "isTemplate", sortable: true, filterable: true, width: 70, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "fromAddresses", name: "From Addresses", field: "fromAddresses", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "toAddresses", name: "To Addresses", field: "toAddresses", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "subject", name: "Subject", field: "subject", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "isHtml", name: "Is Html", field: "isHtml", sortable: true, filterable: true, width: 70, editor: Editors.Checkbox, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "sent", name: "Sent", field: "sent", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter },

                        //{ id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        //{ id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        //{ id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                onBeforeEditCell: function (e, args) {
                    if (['templateCode', 'fromAddresses', 'toAddresses', 'subject', 'isHtml'].includes(args.column.id)) {
                        const msgType = $('#gridNavbar [data-filter-order=1]').attr('data-filter');
                        return msgType === 'templates';
                    }
                    return false;
                }
                //dataToServerParser: function (item) { return item; },
                //customToolbarHandler: function (args) {
                //    //if (args.$btn.hasClass('btn-dbfile-download')) {
                //    //    //custom code
                //    //    return true;
                //    //}
                //}
            },
            nsg:
            {
                keyField: "id",
                historyKeyField: "",
                controllerpath: "nsgList",
                dataLoading: 1, //on reload
                //modalId: "errorEditModal",
                transformers: {
                },
                gridOptions: { editable: false, enableAddRow: false },
                isVirtual: false,
                columns:
                    [
                        //{ id: "id", name: "Id", field: "id", sortable: true, width: 60, filterable: true },
                        { id: "nsgName", name: "NSG Name", field: "nsgName", sortable: true, width: 150, filterable: true },
                        { id: "priority", name: "Priority", field: "priority", sortable: true, width: 150, filterable: true },
                        { id: "name", name: "Name", field: "name", sortable: true, width: 150, filterable: true },
                        { id: "protocol", name: "Protocol", field: "protocol", sortable: true, width: 150, filterable: true },
                        { id: "direction", name: "Direction", field: "direction", sortable: true, width: 150, filterable: true },
                        { id: "sourceAddressPrefix", name: "SourceAddressPrefix", field: "sourceAddressPrefix", sortable: true, width: 150, filterable: true },
                        { id: "sourcePortRange", name: "SourcePortRange", field: "sourcePortRange", sortable: true, width: 150, filterable: true },
                        { id: "destinationAddressPrefix", name: "DestinationAddressPrefix", field: "destinationAddressPrefix", sortable: true, width: 150, filterable: true },
                        { id: "destinationPortRange", name: "DestinationPortRange", field: "destinationPortRange", sortable: true, width: 150, filterable: true },
                        { id: "action", name: "Action", field: "action", sortable: true, width: 150, filterable: true },
                        { id: "isDefault", name: "Is Default", field: "isDefault", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },

                        //{ id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        //{ id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        //{ id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        //{ id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        //{ id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dependencies: [{ name: 'resourceGroups', url: 'api/nsglist/resourcegroups' }],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (item) { return item; }

            },
            dummy:
            {
                keyField: "dummyID",
                historyKeyField: "dummyHistoryID",
                controllerpath: "dummy",
                //dataLoading: 1, //on reload
                modalId: "dummyEditModal",
                modalInit: function (args) {
                },
                showUploadProgress: true,
                transformers: {
                },
                gridOptions: { editable: true, enableAddRow: true },
                isVirtual: true,
                columns:
                    [
                        { id: "dummyID", name: "Dummy Id", field: "dummyID", sortable: true, width: 100, filterable: true },
                        { id: "dummyKey", name: "Dummy Key", field: "dummyKey", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "dummyString", name: "Dummy String", field: "dummyString", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "dummyNumber", name: "Dummy Number", field: "dummyNumber", sortable: true, filterable: true, editor: Editors.Integer, width: 100 },
                        { id: "dummyDropDownID", name: "Dummy DropDown", field: "dummyDropDownID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "DummyDropDown", width: 100, formatter: Select2Formatter },
                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],

                /*setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.MasterPlanAllowEdit)) {
                       //remove the editors from columns
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }
                    return $.when();
                },*/

                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) {
                //    return args.item;
                //},
                customToolbarHandler: function (args) {
                    //if (args.$btn.hasClass('btn-dbfile-download')) {
                    //    //custom code
                    //    return true;
                    //}
                }
            },

            legalEntities:
            {
                keyField: "legalEntityID",
                historyKeyField: "legalEntityHistoryID",
                controllerpath: "LegalEntities",
                //dataLoading: 1, //on reloads
                modalId: "legalEntitiesEditModal",
                modalInit: function (args) {
                },
                showUploadProgress: true,
                transformers: {
                },
                gridOptions: { editable: true, enableAddRow: true },
                isVirtual: false,
                columns:
                    [
                        { id: "legalEntityID", name: "Legal Entity ID", field: "legalEntityID", sortable: true, width: 200, filterable: true },
                        { id: "lec", name: "LEC", field: "lec", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "project", name: "Project", field: "project", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "region", name: "Region", field: "region", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "countryofIncorporation", name: "Country of Incorporation", field: "countryofIncorporation", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "entityFullLegalName", name: "Entity Full Legal Name", field: "entityFullLegalName", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "stepPlanNickname", name: "Step Plan Nickname", field: "stepPlanNickname", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "business", name: "Business", field: "business", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "futureState", name: "Future State", field: "futureState", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "holdingOperating", name: "Holding Operating", field: "futureState", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "ownership", name: "Ownership", field: "ownership", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "heritageEquityOrionFMCDow", name: "Heritage Equity Orion FMCDow", field: "heritageEquityOrionFMCDow", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "dateofIncorporation", name: "Date of Incorporation", field: "dateofIncorporation", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "address", name: "Address", field: "address", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "corporateIDRegistrationNo", name: "Corporate ID RegistrationNo", field: "corporateIDRegistrationNo", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "taxIDNumber", name: "Tax ID Number", field: "taxIDNumber", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "typeofEntity", name: "Type of Entity", field: "typeofEntity", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "managersDirectors", name: "Managers Directors", field: "managersDirectors", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "officers", name: "Officers", field: "officers", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "authorizedSignatures", name: "Authorized Signatures", field: "authorizedSignatures", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner1Name", name: "Owner1 Name", field: "owner1Name", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner1LEC", name: "Owner1 LEC", field: "owner1LEC", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner1Ownership", name: "Owner1 Ownership", field: "owner1Ownership", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner2Name", name: "Owner2 Name", field: "owner2Name", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner2LEC", name: "Owner2 LEC", field: "owner2LEC", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner2Ownership", name: "Owner2 Ownership", field: "owner2Ownership", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner3Name", name: "Owner3 Name", field: "owner3Name", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner3LEC", name: "Owner3 LEC", field: "owner3LEC", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner3Ownership", name: "Owner3 Ownership", field: "owner3Ownership", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner4Name", name: "Owner4 Name", field: "owner4Name", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner4LEC", name: "Owner4 LEC", field: "owner4LEC", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner4Ownership", name: "Owner4 Ownership", field: "owner4Ownership", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner5Name", name: "Owner5 Name", field: "owner5Name", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner5LEC", name: "Owner5 LEC", field: "owner5LEC", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "owner5Ownership", name: "Owner5 Ownership", field: "owner5Ownership", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "comments", name: "Comments", field: "comments", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "inScope", name: "In Scope", field: "inScope", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },

                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                /*
                setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.MasterPlanAllowEdit)) {
                       //remove the editors from columns
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }
                    return $.when();
                },
                */
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) {
                //    return args.item;
                //},
                customToolbarHandler: function (args) {
                    //if (args.$btn.hasClass('btn-dbfile-download')) {
                    //    //custom code
                    //    return true;
                    //}
                }
            },
            legalEntitiesSeparated:
            {
                keyField: "legalEntitySeparatedID",
                historyKeyField: "legalEntitySeparatedHistoryID",
                controllerpath: "LegalEntitiesSeparated",
                //dataLoading: 1, //on reload
                modalId: "legalEntitiesSeparatedEditModal",
                modalInit: function (args) {
                },
                showUploadProgress: true,
                transformers: {
                },
                gridOptions: { editable: true, enableAddRow: true },
                isVirtual: false,
                columns:
                    [
                        { id: "legalEntitySeparatedID", name: "Legal Entity Separated ID", field: "legalEntitySeparatedID", sortable: true, width: 200, filterable: true },
                        { id: "project", name: "Project", field: "project", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "leNumber", name: "LE #", field: "leNumber", sortable: true, filterable: true, editor: Editors.NullableInteger, width: 100, validator: requiredFieldValidator },
                        { id: "entities", name: "Entities", field: "entities", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "futureStateBusinessLine", name: "Future State Business Line", field: "futureStateBusinessLine", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "countryOfFormation", name: "Country Of Formation", field: "countryOfFormation", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "natureOfSeparation", name: "Nature Of Separation", field: "natureOfSeparation", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "futureNBOPCO", name: "Future N&B OPCO", field: "futureNBOPCO", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "nBOPCOLENumber", name: "N&B OPCO LE #", field: "nbopcoleNumber", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "separationTypeLocal", name: "Separation Type Local", field: "separationTypeLocal", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "futureDuPontOPCO", name: "Future DuPont OPCO", field: "futureDuPontOPCO", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "duPontOPCOLeNumber", name: "DuPont OPCO LE #", field: "duPontOPCOLeNumber", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "duPontSeparationTypeLocal", name: "DuPont Separation Type (Local)", field: "duPontSeparationTypeLocal", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "regionalLeaders", name: "Regional Leaders", field: "regionalLeaders", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "legalEntityStructure", name: "Legal Entity Structure", field: "legalEntityStructure", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "systemsAndProcesses", name: "Systems And Processes", field: "systemsAndProcesses", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "rightToOperate", name: "Right To Operate", field: "rightToOperate", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "iP", name: "IP", field: "ip", sortable: true, filterable: true, editor: Editors.NullableInteger, width: 300, validator: requiredFieldValidator },
                        { id: "facilities", name: "Facilities", field: "facilities", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "contractsAndPurchasing", name: "Contracts & Purchasing", field: "contractsAndPurchasing", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "treasuryGovernance", name: "Treasury Governance", field: "treasuryGovernance", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "organizationAndTalent", name: "Organization And Talent", field: "organizationAndTalent", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "businessReadiness", name: "Business Readiness", field: "businessReadiness", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "supplyAndServiceAgreements", name: "Supply And Service Agreements", field: "supplyAndServiceAgreements", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "leader", name: "Leader", field: "leader", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },

                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) {
                //    return args.item;
                //},
                customToolbarHandler: function (args) {
                    //if (args.$btn.hasClass('btn-dbfile-download')) {
                    //    //custom code
                    //    return true;
                    //}
                }
            },
            legalEntitiesFinance:
            {
                keyField: "legalEntityID",
                historyKeyField: "legalEntityHistoryID",
                controllerpath: "LegalEntities",
                //dataLoading: 1, //on reloads
                modalId: "legalEntitiesEditModal",
                modalInit: function (args) {
                },
                showUploadProgress: true,
                transformers: {
                },
                gridOptions: { editable: true, enableAddRow: true },
                isVirtual: false,
                columns:
                    [
                        { id: "legalEntityID", name: "Legal Entity ID", field: "legalEntityID", sortable: true, width: 200, filterable: true },
                        { id: "lec", name: "LEC", field: "lec", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "bpclec", name: "BPC LEC", field: "bpclec", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        { id: "project", name: "Project", field: "project", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "region", name: "Region", field: "region", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "countryofIncorporation", name: "Country of Incorporation", field: "countryofIncorporation", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        { id: "entityFullLegalName", name: "Entity Full Legal Name", field: "entityFullLegalName", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        //{ id: "futureState", name: "Future State", field: "futureState", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "holdingOperating", name: "Holding Operating", field: "holdingOperating", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "lEtype", name: "LEtype", field: "lEtype", sortable: true, filterable: true, editor: Editors.Integer, width: 200 },
                        { id: "separationType", name: "Separation Type", field: "separationType", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "currentERPSystem", name: "Current ERP System", field: "currentERPSystem", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "leCinCurrentERP", name: "LEC in Current ERP", field: "leCinCurrentERP", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "lecFunctionalCurrency", name: "LEC Functional Currency", field: "lecFunctionalCurrency", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "lecCompnayCodeCurrency", name: "LEC Company Code Currency", field: "lecCompnayCodeCurrency", sortable: true, filterable: true, editor: Editors.Text, width: 200 },


                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                /*
                setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.MasterPlanAllowEdit)) {
                       //remove the editors from columns
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }
                    return $.when();
                },
                */
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) {
                //    return args.item;
                //},
                customToolbarHandler: function (args) {
                    //if (args.$btn.hasClass('btn-dbfile-download')) {
                    //    //custom code
                    //    return true;
                    //}
                }
            },
            legalEntitiesSeparatedFinace:
            {
                keyField: "legalEntitySeparatedID",
                historyKeyField: "legalEntitySeparatedHistoryID",
                controllerpath: "LegalEntitiesSeparated",
                //dataLoading: 1, //on reload
                modalId: "legalEntitiesSeparatedEditModal",
                modalInit: function (args) {
                },
                showUploadProgress: true,
                transformers: {
                },
                gridOptions: { editable: true, enableAddRow: true },
                isVirtual: false,
                columns:
                    [
                        { id: "legalEntitySeparatedID", name: "Legal Entity Separated ID", field: "legalEntitySeparatedID", sortable: true, width: 200, filterable: true },
                        { id: "project", name: "Project", field: "project", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "leNumber", name: "LE #", field: "leNumber", sortable: true, filterable: true, editor: Editors.NullableInteger, width: 100, validator: requiredFieldValidator },
                        { id: "bpclec", name: "BPC LEC", field: "bpclec", sortable: true, filterable: true, editor: Editors.Text, width: 100 },
                        //{ id: "region", name: "Region", field: "region", sortable: true, filterable: true, editor: Editors.Text, width: 100, validator: requiredFieldValidator },
                        { id: "countryOfFormation", name: "Country Of Formation", field: "countryOfFormation", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        //{ id: "countryofIncorporation", name: "Country of Incorporation", field: "countryofIncorporation", sortable: true, filterable: true, editor: Editors.Text, width: 300, validator: requiredFieldValidator },
                        //{ id: "entityFullLegalName", name: "Entity Full Legal Name", field: "entityFullLegalName", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        //{ id: "futureState", name: "Future State", field: "futureState", sortable: true, filterable: true, editor: Editors.Text, width: 200, validator: requiredFieldValidator },
                        { id: "holdingOperating", name: "Holding Operating", field: "holdingOperating", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "lEtype", name: "LEtype", field: "lEtype", sortable: true, filterable: true, editor: Editors.Integer, width: 200 },
                        { id: "separationType", name: "Separation Type", field: "separationType", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "currentERPSystem", name: "Current ERP System", field: "currentERPSystem", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "leCinCurrentERP", name: "LEC in Current ERP", field: "leCinCurrentERP", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "lecFunctionalCurrency", name: "LEC Functional Currency", field: "lecFunctionalCurrency", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "lecCompnayCodeCurrency", name: "LEC Company Code Currency", field: "lecCompnayCodeCurrency", sortable: true, filterable: true, editor: Editors.Text, width: 200 },


                        { id: "separationLEC", name: "Separation LEC", field: "separationLEC", sortable: true, filterable: true, editor: Editors.Integer, width: 200 },
                        { id: "separationBPCLEC", name: "Separation BPC LEC", field: "separationBPCLEC", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "futureERPSystem", name: "Future ERP System", field: "futureERPSystem", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "lECinFutureERP", name: "LEC in Future ERP", field: "lECinFutureERP", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "separationFullLegalName", name: "Separation Full Legal Name", field: "separationFullLegalName", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "separationRollout", name: "Separation Rollout", field: "separationRollout", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "futureLECCompanyCodeCurrency", name: "Future LEC Company Code Currency", field: "futureLECCompanyCodeCurrency", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "futureLECFunctionalCurrency", name: "Future LEC Functional Currency", field: "futureLECFunctionalCurrency", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "futureHoldingOperating", name: "Future Holding Operating", field: "futureHoldingOperating", sortable: true, filterable: true, editor: Editors.Text, width: 200 },
                        { id: "separationScenario", name: "Separation Scenario", field: "separationScenario", sortable: true, filterable: true, editor: Editors.Text, width: 200 },


                        { id: "isDeleted", name: "Is Deleted", field: "isDeleted", sortable: true, filterable: true, width: 100, cssClass: "cell-checkbox", formatter: CheckmarkFormatter, isCheckMark: true },
                        { id: "deleted", name: "Deleted", field: "deleted", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "deletedBy", name: "Deleted By", field: "deletedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) {
                //    return args.item;
                //},
                customToolbarHandler: function (args) {
                    //if (args.$btn.hasClass('btn-dbfile-download')) {
                    //    //custom code
                    //    return true;
                    //}
                }
            },

            sitesExtended:
            {
                keyField: "siteExtendedID",
                historyKeyField: "",
                controllerpath: "SitesExtended",
                //dataLoading: 1, //on reload
                //modalId: "sitesExtendedEditModal",
                modalInit: function (args) {
                },
                showUploadProgress: true,
                transformers: {
                },
                gridOptions: { editable: true, enableAddRow: true },
                isVirtual: false,
                columns:
                    [
                        { id: "assetID", name: "Asset ID", field: "assetID", sortable: true, filterable: true, width: 100, editor: Editors.Text, historyVisibility: visible.always },
                        { id: "regionID", name: "Region", field: "regionID", sortable: true, filterable: true, width: 100, editor: Editors.Select2, lookupName: "SiteRegion", width: 100, formatter: Select2Formatter }, { id: "currentLegalEntity", name: "Current Legal Entity", field: "currentLegalEntity", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "project", name: "Project", field: "project", sortable: true, filterable: true, width: 100, editor: Editors.Text, historyVisibility: visible.always },
                        { id: "location", name: "Location", field: "location", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "code", name: "Code", field: "code", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "ownedLeased", name: "Owned or Leased", field: "ownedLeased", sortable: true, filterable: true, width: 250, editor: Editors.Text },
                        { id: "subBusiness", name: "SubBusiness", field: "subBusiness", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "name", name: "Name", field: "name", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "assetType", name: "Asset Type", field: "assetType", sortable: true, filterable: true, width: 110, editor: Editors.Text },
                        { id: "primarySiteType", name: "Primary Site Type", field: "primarySiteType", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "secondarySiteType", name: "Secondary Site Type", field: "secondarySiteType", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "sharedOrDedicated", name: "Shared Or Dedicated", field: "sharedOrDedicated", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "heritageCompany", name: "Heritage Company", field: "heritageCompany", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "spinCompany", name: "Spin Company", field: "spinCompany", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "siteLandlord", name: "Site Landlord", field: "siteLandlord", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "buAlignment", name: "BU Alignment", field: "buAlignment", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "siteDisposition", name: "Site Disposition", field: "siteDisposition", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "itStatus", name: "IT Status", field: "itStatus", sortable: true, filterable: true, width: 110, editor: Editors.Text },
                        { id: "priority", name: "Priority", field: "priority", sortable: true, filterable: true, width: 110, editor: Editors.Text },
                        { id: "sepGroupType", name: "Sep.Group Type", field: "sepGroupType", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "siteIntegrationLead", name: "Site Integration Lead", field: "siteIntegrationLead", sortable: true, filterable: true, width: 150, editor: Editors.Text },
                        { id: "countryID", name: "Country", field: "countryID", sortable: true, filterable: true, editor: Editors.Select2, lookupName: "Country", width: 150, formatter: Select2Formatter },
                        { id: "state", name: "State", field: "state", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "streetAddress", name: "Street Address", field: "streetAddress", sortable: true, filterable: true, width: 110, editor: Editors.Text },
                        { id: "city", name: "City", field: "city", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "zipCode", name: "Zip Code", field: "zipCode", sortable: true, filterable: true, width: 100, editor: Editors.Integer },

                        { id: "comments", name: "Comments", field: "comments", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "preliminaryFutureLegalEntityCode", name: "PRELIMINARY Future LegalEntity Code", field: "preliminaryFutureLegalEntityCode", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "preliminaryFutureLegalEntity", name: "PRELIMINARY Future Legal Entity", field: "preliminaryFutureLegalEntity", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "t_I", name: "T&I", field: "t_I", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "s_C", name: "S&C", field: "s_C", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "e_I", name: "E&I", field: "e_I", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "remainCoTOTAL", name: "Remain Co TOTAL", field: "remainCoTOTAL", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "n_B", name: "N&B", field: "n_B", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "function", name: "Function", field: "function", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "percent_N_B", name: "Percent_N_B", field: "percent_N_B", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "total", name: "Total", field: "total", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "ryg", name: "RYG", field: "ryg", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "remain_Neptune", name: "Remain Or Neptune", field: "remain_Neptune", sortable: true, filterable: true, width: 120, editor: Editors.Text },
                        { id: "percentNeptuneTransfer", name: "Percent Neptune Transfer", field: "percentNeptuneTransfer", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "developRemainCoExitPlan", name: "Develop Remain Co Exit Plan", field: "developRemainCoExitPlan", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "developNeptuneExitPlan", name: "Develop Neptune Exit Plan", field: "developNeptuneExitPlan", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "LeaseToNeptune", name: "Lease To Neptune", field: "leaseToNeptune", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "splitLease_BothStay", name: "SplitLease or Both Stay", field: "splitLease_BothStay", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "summaryAction", name: "Summary Action", field: "summaryAction", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "_remainCo", name: " Percent remainCo", field: "_remainCo", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "_neptune", name: "Percent Neptune", field: "_neptune", sortable: true, filterable: true, width: 300, editor: Editors.Text },
                        { id: "capexRequired", name: "Capex Required", field: "capexRequired", sortable: true, filterable: true, width: 300, editor: Editors.Text },

                        { id: "siteSize", name: "Site Size", field: "siteSize", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "siteSubnets", name: "Site Subnets", field: "siteSubnets", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "clusterLead", name: "Cluster Lead", field: "clusterLead", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "siteExecutionLead", name: "Site Execution Lead", field: "siteExecutionLead", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "whIsolateScope", name: "WH Isolate Scope", field: "whIsolateScope", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "highLevelDiscovery", name: "High Level Discovery", field: "highLevelDiscovery", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "deepDiveDiscovery", name: "Deep Dive Discovery", field: "deepDiveDiscovery", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "tcomConnection", name: "TCOM Connection", field: "tcomConnection", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "siteCarveScope", name: "Site Carve Scope", field: "siteCarveScope", sortable: true, filterable: true, width: 100, editor: Editors.Text },
                        { id: "udCarve", name: "UD Carve", field: "udCarve", sortable: true, filterable: true, width: 100, editor: Editors.Text },

                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }

                    ],


                setPermissions: function (args) {
                    if (!authorizationService.authorize(AuthorizationPolicies.AppSiteAllowManage)) {
                        args.columns
                            .forEach((c, idx, arr) => {
                                arr[idx] = Object.assign({}, c, { editor: null });
                            });
                    }

                    return $.when();
                },
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                },
                //dataToServerParser: function (args) {
                //    return args.item;
                //},
                customToolbarHandler: function (args) {
                    //if (args.$btn.hasClass('btn-dbfile-download')) {
                    //    //custom code
                    //    return true;
                    //}
                }
            },
            project: {
                keyField: "projectID",
                controllerpath: "project",
                //dataLoading: -1, // never
                modalId: "projectEditModal",
                gridOptions: { editable: true, enableAddRow: true },
                isVirtual: false,
                columns:
                    [
                        //{ id: "projectID", name: "Project ID", field: "projectID", sortable: true, filterable: true, width: 100, toolTip: "Project/Deal ID" editor: Editors.Text },
                        { id: "name", name: "Name", field: "name", sortable: true, filterable: true, width: 250, toolTip: "Project/Deal Name", editor: Editors.Text },
                        { id: "shortName", name: "Short Name", field: "shortName", sortable: true, filterable: true, width: 250, toolTip: "Project/Deal Short Name", editor: Editors.Text },
                        { id: "urlBase", name: "Url Base", field: "urlBase", sortable: true, filterable: true, width: 250, toolTip: "Project/Deal Url Path", editor: Editors.Text },
                        { id: "modified", name: "Modified", field: "modified", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "modifiedBy", name: "Modified By", field: "modifiedBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always },
                        { id: "created", name: "Created", field: "created", sortable: true, filterable: true, width: 170, formatter: DateTimeFormatter, historyVisibility: visible.always },
                        { id: "createdBy", name: "Created By", field: "createdBy", sortable: true, filterable: true, width: 170, historyVisibility: visible.always }
                    ],
                dataFromServerParser: function (args) {
                    if (args.isModalEdit || args.isItemRefresh) {
                        return args.newdata;
                    } else {
                        return args.newdata.map((item, idx) => {
                            return $.extend(item, { num: idx + 1, __id: idx + 1 });
                        });
                    }
                }
            }
        };

    function getMultiSelectItem(lookupValues, multiSelectLkpData) {
        return lookupValues && lookupValues.length > 0
            ? lookupValues.reduce((obj, ulv) => {
                const field = multiSelectLkpData[ulv.lookupValueID];
                if (!obj[field]) obj[field] = [];
                obj[field].push(ulv.lookupValueID);
                return obj;
            }, {})
            : null;
    }

    this.getColumns = function (model) {
        return [{ id: "sel", name: "#", field: "num", behavior: "select", cssClass: "cell-selection", headerCssClass: "not-sortable", width: 50, cannotTriggerInsert: true, resizable: false, selectable: false, focusable: false }
        ].concat(repositoryObj[model].columns);
    };

    this.noop = function () { };

    this.isFunction = function (functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    };

    this.getTransformers = function (model) {
        var transformers = { getAll: this.noop, getOne: this.noop, insert: this.noop, update: this.noop, delete: this.noop };
        if (repositoryObj[model].transformers) {
            if (this.isFunction(repositoryObj[model].transformers.getAll)) {
                transformers.getAll = repositoryObj[model].transformers.getAll;
            }

            if (this.isFunction(repositoryObj[model].transformers.insert)) {
                transformers.insert = repositoryObj[model].transformers.insert;
            }

            if (this.isFunction(repositoryObj[model].transformers.update)) {
                transformers.update = repositoryObj[model].transformers.update;
            }

            if (this.isFunction(repositoryObj[model].transformers.delete)) {
                transformers.delete = repositoryObj[model].transformers.delete;
            }
        }

        return transformers;
    };

    this.getRepositoryObject = function (model) {
        let modelItems;

        if (repositoryObj[model]) {
            return repositoryObj[model];
        }
        else if ((modelItems = model.split('#')).length === 2 && modelItems[1] === 'history') {
            const mainModel = repositoryObj[modelItems[0]];
            const columns =
                mainModel.columns
                    .filter(c => c.historyVisibility === undefined || c.historyVisibility > 0)
                    .map(c => {
                        const { id, name, field, sortable, filterable, width, dataSource, formatter, lookupName, lookupConfig, isMultiSelect, historyVisibility } = c;
                        return { id, name, field, sortable: false, filterable, width, dataSource, formatter, lookupName, lookupConfig, isMultiSelect, visibility: historyVisibility };
                    });
            //columns.push(
            //    { id: mainModel.historyKeyField, name: mainModel.historyKeyField, field: mainModel.historyKeyField, sortable: false, filterable: true, width: 165 }
            //);
            const historyModel = {
                keyField: mainModel.historyKeyField,
                parentKeyField: mainModel.keyField,
                controllerpath: mainModel.controllerpath + '/history',
                modalEditIdExtraPath: mainModel.modalEditIdExtraPath,
                modalId: mainModel.modalId,
                modalInit: mainModel.modalInit,
                gridOptions: { editable: false, enableAddRow: false },
                isVirtual: false,
                multiSelectFieldName: mainModel.multiSelectFieldName,
                columns: columns,
                dataFromServerParser: mainModel.dataFromServerParser
            };
            repositoryObj[model] = historyModel;
            return historyModel;
        }
    };

    function submitRequestToApi(requestArgs) {
        return golLiveAppApi.submitRequest(requestArgs);
    }

    // custom editors - select
    //const dateTimeFormat = {
    //    day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'
    //};

    function DateTimeFormatter(row, cell, value, columnDef, dataContext) {
        var dateValue;
        return (value && (dateValue = new Date(value)).getFullYear() > 1900) ?
            formatDate(dateValue.toLocaleString()) :
            //dateValue.toLocaleDateString(undefined, dateTimeFormat).replace(/[^ -~]/g, '') :
            '';
    }

    let hourSepIndex = 0;
    function formatDate(date) {
        const dateChars = date.replace(/[^ -~]/g, '').split('');
        if (dateChars[1] === '/') dateChars.splice(0, 0, '0');
        if (dateChars[4] === '/') dateChars.splice(3, 0, '0');
        if (!hourSepIndex) hourSepIndex = dateChars[10] === ',' ? 13 : 12;
        if (dateChars[hourSepIndex] === ':') dateChars.splice(hourSepIndex - 1, 0, '0');
        return dateChars.join('');
    }

    const dateFormat = { day: '2-digit', month: '2-digit', year: 'numeric' };

    function DateFormatter(row, cell, value, columnDef, dataContext) {
        var dateValue;
        return (value && (dateValue = new Date(value)).getFullYear() > 1900) ?
            //dateValue.toLocaleString() :
            dateValue.toLocaleDateString(undefined, dateFormat).replace(/[^ -~]/g, '') :
            '';
    }

    function FileSizeFormatter(row, cell, value, columnDef, dataContext) {
        return value ? `${value.toLocaleString()} KB` : '';
    }

    function Select2Formatter(row, cell, value, columnDef, dataContext) {
        return columnDef.dataSource ? (columnDef.dataSource[value] || value || '') : value;
        console.write(columnDef.dataSource);
    }

    function YesNoFormatter(row, cell, value, columnDef, dataContext) {
        return value ? 'Yes' : 'No';
    }

    function MultiSelect2Formatter(row, cell, values, columnDef, dataContext) {
        return columnDef.dataSource ?
            (values || []).map((value) => columnDef.dataSource[value]).sort().join() :
            (values || []).join();
    }

    function CheckmarkFormatter(row, cell, value, columnDef, dataContext) {
        return value ? "<img class='cell-checkmark'>" : "";
    }

    function LinkFormatter(row, cell, value, columnDef, dataContext) {
        return '<a href="#">' + value + '</a>';
    }

    function DownloadLinkFormatter(row, cell, value, columnDef, dataContext) {
        //return `<span><a download-url="/api/dbfiles/download/${dataContext.id}" class="cell-link" style="cursor:pointer;color:#337ab7;" target="_self">Download</a></span>`;
        return `<span><a href="#" data-download-url="/api/dbfiles/download/${dataContext.id}" data-toggle="modal" data-target="#downloadModal" class="cell-link"  style="color:#337ab7">Download</a></span>`;
    }

    function EnvLinksFormatter(row, cell, value, columnDef, dataContext) {
        //return '<a href="#">' + value + '</a>';
        if (dataContext.environments) {
            return dataContext
                .environments
                .map(env => `<span><a class="env-download cell-link" style="cursor:pointer;color:#337ab7;">${env.environmentName}</a>&nbsp;</span>`)
                .join();
        }
        else {
            return '<span></span>';
        }

    }

    function NAFormatter(row, cell, value, columnDef, dataContext) {
        return value === null ? "N/A" : value;
    }

    // validators
    function requiredFieldValidator(value) {
        if (value == null || value == undefined || value === '') {
            return { valid: false, msg: "This is a required field" };
        }
        else {
            return { valid: true, msg: null };
        }
    }

}