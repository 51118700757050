import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';

import Modal from '../UI/Modal/Modal';

import { Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';



//import Form from "react-jsonschema-form";

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarButton,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory
} from '../BaseComponents';

import { Alert } from 'reactstrap';

class CloudAzureIaaSImporting extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleSubmit(evt, sender) {
        evt.preventDefault();
        this.props.importAzureObjects(this.props.formIaas);
    }

    onChange = (e) => {
        var curData = this.props.formIaas;
        if (curData.Files) {
            //curData.Files.push( e.target.id , e.target.files[0] );
            curData.Files[e.target.id] = e.target.files[0];
        }
        else {
            curData.Files = [];
            //curData.Files.push({ e.target.id, e.target.files[0] });
            curData.Files[e.target.id] = e.target.files[0];
        }
        this.props.setFormIaas(curData);
    }

    toggleModal = () => {
        this.props.showModal(!this.props.modal);
    }

    //onSubmit = ({ formData }) => {
    //    if (this.props.summary) {
    //        this.props.importedAzureObjects(this.props.formIaas);
    //    }
    //    else {
    //        this.props.importAzureObjects(this.props.formIaas);
    //    }
    //}

    render() {
        let error = this.props.error !== null ? <div className="panel panel-danger errors"><div className="panel-heading"><h3 className="panel-title">Errors</h3></div><ul className="list-group"><li className="list-group-item text-danger">{this.props.error}</li></ul></div> : null;

        return (
            <div>
                <DataGridToolbar
                    title="Import IaaS Deployment Files"
                >
                    <DataGridToolbarButtonsGroup>
                        <DataGridToolbarButton
                            outline
                            bsStyle='primary'
                            onClick={() => this.props.goToRequest()}
                            glyph="plus"
                            glyphColor='green'
                            caption='Add New Request'
                        />

                        <DataGridToolbarButton
                            outline
                            bsStyle='secondary'
                            onClick={() => this.props.goToList()}
                            glyph="list-alt"
                            glyphColor='#4189C7'
                            caption="List All Requests"
                        />
                    </DataGridToolbarButtonsGroup>
                </DataGridToolbar>

                <br />

                <Modal show={this.props.modal.show}
                    closeModal={this.toggleModal}
                    title={this.props.modal.title}
                >
                    <Alert color={this.props.modal.color} transition={{ baseClass: '', timeout: 500 }}>

                        <h4>
                            <Fragment>{this.props.modal.text}</Fragment>
                        </h4>
                    </Alert>
                </Modal>

                <Form id="myForm" onSubmit={this.handleSubmit} onChange={this.handleChange} type="post">

                    <FormGroup row>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="assetID">Asset ID</Label>
                                <Input
                                    defaultValue={this.props.formIaas.assetID}
                                    type="text"
                                    name="assetID"
                                    id="assetID"
                                    className={this.props.validators.assetID === false ? 'is-invalid' : null}
                                    placeholder="Asset ID"
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="company">Company</Label>
                                <Input
                                    defaultValue={this.props.formIaas.company}
                                    type="text"
                                    name="company"
                                    id="company"
                                    className={this.props.validators.company === false ? 'is-invalid' : null}
                                    placeholder="Company"
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="applicationName">Application Name</Label>
                                <Input
                                    type="text"
                                    name="applicationName"
                                    id="applicationName"
                                    placeholder="Application Name"
                                    defaultValue={this.props.formIaas.applicationName}
                                    className={this.props.validators.applicationName === false ? 'is-invalid' : null}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>

                    </FormGroup>

                    <FormGroup row>
                        <Col md={3}>
                            <Label for="azureDeployFile">Deploy File</Label>
                            <Input type="file"
                                name="azureDeployFile"
                                id="azureDeployFile"
                                required
                                onChange={this.onChange}
                            />
                            <FormText color="muted">
                                azuredeploy.json
                            </FormText>
                        </Col>
                        <Col md={3}>
                            <Label for="azureDevFile">DEV File</Label>
                            <Input type="file"
                                name="azureDevFile"
                                id="azureDevFile"
                                required
                                onChange={this.onChange}
                            />
                            <FormText color="muted">
                                parameter-dev.json
                            </FormText>
                        </Col>
                        <Col md={3}>
                            <Label for="azureTestFile">TEST File</Label>
                            <Input type="file"
                                name="azureTestFile"
                                id="azureTestFile"
                                required
                                onChange={this.onChange}
                            />
                            <FormText color="muted">
                                parameter-test.json
                            </FormText>
                        </Col>
                        <Col md={3}>
                            <Label for="azureProdFile">PROD File</Label>
                            <Input type="file"
                                name="azureProdFile"
                                id="azureProdFile"
                                required
                                onChange={this.onChange}
                            />
                            <FormText color="muted">
                                parameter-prod.json
                            </FormText>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md={12}>
                            <button className="btn btn-info btnForm" type='submit'> <i className="glyphicon glyphicon-import" />  Submit </button>
                        </Col>
                    </FormGroup>
                </Form >

            </div>
        );
    }
}

export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(CloudAzureIaaSImporting);
