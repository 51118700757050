import React from 'react';
import { IfAuthorized, AuthorizationPolicies } from '../BaseComponents/Authorization';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarSearchBox,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonImport,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonAdd,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonDelete,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';

import UserEdit from './UserEdit';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

const Toolbar = () => {
    return (
        <DataGridToolbar
            title="Users"
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarSearchBox
                filterOrder="1"
                filter="search"
            />
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonImport />
                <DataGridToolbarButtonExport />
                <DataGridToolbarButtonAdd />
                <DataGridToolbarButtonEdit />
                <IfAuthorized policy={AuthorizationPolicies.UserManager}>
                    <DataGridToolbarButtonDelete />
                </IfAuthorized>
                <DataGridToolbarButtonHistory />
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const Users = () => {
    return (
        <DataGrid model='user'>
            <Toolbar />
            <UserEdit />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, Users, "Users");