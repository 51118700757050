import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';
import { IfAuthorized, AuthorizationPolicies } from '../BaseComponents/Authorization';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarCheckBox,
    DataGridToolbarButton,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonImport,
    DataGridToolbarSplitButton,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';

import DuPont from '../../jscomponents/DuPontApp';


//const AppSpins = props => {
class AppMnAProjectScope extends Component {

    constructor(props) {
        super(props);
    }

    onGridInit = (gridComponent) => {
        this.gridComponent = gridComponent;
    }

    requestPaas = (env) => {
        const currentGridItem = this.gridComponent.getCurrentItem();
        if (currentGridItem) {
            this.props.requestPaas(currentGridItem);
        }
    }


    render() {
        const { tempCurrentSpin, crossSpinDataHidden, multiSpin } = DuPont.App.Config;
        console.log(tempCurrentSpin);
        //const { project } = useParams();
        console.log(`Params.Project: ${this.props.match.params.project}`);
        console.log(`Params:`);
        console.log(this.props);
        const currentSpin = multiSpin ? this.props.match.params.project : tempCurrentSpin;
        return (
            <DataGrid
                model='appMnAProjectScope'
                onGridInit={this.onGridInit}>

                <DataGridToolbar
                    title={currentSpin + " Applications Project Scope"}
                >
                    <DataGridToolbarButtonsGroup>
                        <DataGridToolbarErrorsButton />
                        <DataGridToolbarButtonSave />
                        <DataGridToolbarButtonDiscard />
                    </DataGridToolbarButtonsGroup>{' '}
                    <DataGridToolbarCheckBox
                        id="showAllCheckBox"
                        filterOrder="2"
                        filterWhenChecked="all"
                        filterWhenUnchecked="enabled"
                        caption="Show Disabled Apps"
                    />
                    <DataGridToolbarButtonsGroup>
                        <DataGridToolbarDropdownButton
                            caption="ALL"
                            controlClass={crossSpinDataHidden ? "d-none" : null}
                            glyph="filter"
                            glyphColor='#4189C7'
                            filterOrder="1"
                            filter={crossSpinDataHidden ? currentSpin : "all"}
                            filterReqMsg="Please choose a filter!"
                        >
                            <DataGridToolbarDropdownItem filter="DAS" href="#" caption='DAS' />
                            <DataGridToolbarDropdownItem filter="SPECCO" href="#" caption='SPECCO' />
                            <li className="dropdown-divider" />
                            <DataGridToolbarDropdownItem filter="all" href="#" caption='ALL' />
                        </DataGridToolbarDropdownButton>
                        <DataGridToolbarButtonRefresh />
                        <DataGridToolbarButtonHistory />
                        <IfAuthorized policy={AuthorizationPolicies.AppMnAAllowEdit}>
                            <DataGridToolbarButtonExport />
                        </IfAuthorized>
                        <DataGridToolbarFavoritesSplitButton />
                    </DataGridToolbarButtonsGroup>
                </DataGridToolbar>
            </DataGrid>
        );
    }
};

export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withAITracking(ai.reactPlugin, AppMnAProjectScope, "AppMnAProjectScope"));