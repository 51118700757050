import React, { Component } from 'react';
import Layout from './components/Layout';
import Routing from './components/Routing';
import { UploadModal, DownloadModal, ConfirmModal, GridColumnFilterModal } from './components/BaseComponents/Modals';
import { LoadingIndicator } from './components/BaseComponents';
import { authorizationService } from './components/BaseComponents/Authorization';
import configService from './jscomponents/ConfigService';
import $ from 'jquery';

class App extends Component {

    state = {
        isAuthorizationServiceInitialized: false
    };

    componentDidMount() {
        $('.modal-dialog').draggable({ handle: ".modal-header" });
        if (!authorizationService.isInitialized) {
            //console.log(`App.authInitialize`);
            authorizationService
                .initialize()
                .always(() => {
                    //this.setState({ isAuthorizationServiceInitialized: true });
                    configService
                        .initialize()
                        .always(() => {
                            this.setState({ isAuthorizationServiceInitialized: true });
                        });
                });
        }
    }

    render() {
        return (
            <Layout>
                <Routing />
                <UploadModal />
                <DownloadModal />
                <ConfirmModal />
                <GridColumnFilterModal />
                <LoadingIndicator />
            </Layout>
        );
    }
}

export default App;