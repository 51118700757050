import $ from 'jquery';
import { getToken } from '../adalConfig';

function ExportHandler(args) {

    let exportURL;
    let isDownload;
    let httpMethod;
    const MSG_PROCESSING_FILE = 'Preparing file...';
    const MSG_PROCESSING_FILE_DONE = 'Preparing file...done!';

    const ALERT_SUCCESS = 'alert-success';
    const ALERT_DANGER = 'alert-danger';

    const browser = args.browser;
    const gridComponent = args.gridComponent;

    const $modal = $('#downloadModal');
    const $form = $('#downloadModal form');
    const $statusAlert = $('#downloadModal .status-alert');

    const $progressText = $('#downloadModal .progress-text');
    const $progressBar = $('#downloadModal .progress-bar');
    const $timeText = $('#downloadModal .time-text');
    const $exportRowsOpt = $('#exportRows');
    const $withTrackChangesOpt = $('#withTrackChanges');

    const exportRowOptions = { all: 0, filtered: 1 };

    function init() {
        $modal.on('show.bs.modal', handleShowBsModal);
        $modal.on('hidden.bs.modal', handleHideBsModal);
        $("#downloadFromServer").on('click', handleDownloadFromServerClick);
        $("#downloadModal :button.btn-close").on('click', handleDownloadModalClose);
    }

    function handleShowBsModal(event) {
        const $trigger = $(event.relatedTarget); // Button that triggered the modal

        if (!(exportURL = $trigger.data('export-url'))) {
            isDownload = !!(exportURL = ($trigger.data('download-url')));
        }

        if (isDownload) {
            httpMethod = 'GET';
        } else {
            httpMethod = 'POST';
            const gridIsFiltered = gridComponent && gridComponent.isFiltered();
            $exportRowsOpt
                .val(gridIsFiltered ? exportRowOptions.filtered : exportRowOptions.all)
                .prop('disabled', !gridComponent || !gridIsFiltered);
            $withTrackChangesOpt.prop('checked', $trigger.hasClass('btn-export-track-changes'));
        }

        if (isDownload || canExport()) $("#downloadFromServer").click();

        function canExport() {
            const requiredFilters = $trigger.attr('data-filter-req');
            if (requiredFilters) {
                const $toolbar = $trigger.parents('.grid-toolbar');
                var invalidReqFltMsg;
                requiredFilters.split('|').findIndex((fltOrder) => {
                    //check if doesn't pass required filter test
                    const $flt = $toolbar.find(`[data-filter-order="${fltOrder}"]`);
                    if ($flt.length === 0 || !$flt.attr('data-filter')) {
                        invalidReqFltMsg = $flt.attr('data-filter-req-msg') || 'Required filter not specified!';
                        return true;
                    }
                });
                if (invalidReqFltMsg) {
                    $statusAlert.text(invalidReqFltMsg).removeClass('d-none').removeClass(ALERT_SUCCESS).addClass(ALERT_DANGER);
                    return false;
                }
            }
            return true;
        }
    }

    function handleHideBsModal(event) {
        event.stopImmediatePropagation();
    }

    function handleDownloadFromServerClick() {
        //var filename = $("#upload").val();

        //testAjax(); return;

        const $this = $(this);
        var data = {};

        if (!isDownload) {
            $form.serializeArray().forEach(item => {
                data[item.name] = item.value;
            });

            if ($exportRowsOpt.val() == exportRowOptions.filtered && gridComponent) {
                data.filterIds = gridComponent.getFilteredKeys();
            }
        }

        data.id = uniqueID();
        $this.prop('disabled', true);

        $statusAlert.addClass('d-none');
        $timeText.text('');
        $progressText.text('');
        $progressBar.hide().attr('aria-valuenow', 0).css('width', 0 + '%').show();

        var progressTimerId, elapsedTimerId;

        var start = Date.now();

        elapsedTimerId = setInterval(function () {
            var time = new Date(Date.now() - start).toTimeString();
            $timeText.text(`Elapsed ${time.substring(3, 9)}`);
        }, 1000);

        try {
            //downloadFileWithAjax(); return;

            getToken().then(token =>
                downloadFile(token)
                    .done(function (data, textStatus, xhr) {
                        clearInterval(elapsedTimerId);
                        clearInterval(progressTimerId);
                        const fileName = getFileName(xhr);
                        if (browser === 'IE') {
                            const blobObject = new Blob([data]
                                //, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
                            );
                            window.navigator.msSaveOrOpenBlob(blobObject, fileName);
                        } else {
                            let link = document.createElement('a');
                            link.href = (window.URL || window.webkitURL).createObjectURL(data);
                            link.download = fileName;
                            document.body.appendChild(link);
                            link.click();
                            setTimeout(function () {
                                window.URL.revokeObjectURL(data);
                                document.body.removeChild(link);
                            }, 200);
                        }
                        //if ($progressText.text() === MSG_PROCESSING_FILE) updateProgress(100, MSG_PROCESSING_FILE_DONE);
                        const msg = 'Export successfully executed!';
                        $statusAlert.text(msg).removeClass('d-none').removeClass(ALERT_DANGER).addClass(ALERT_SUCCESS);
                    }).fail((xhr, textStatus, errorThrown) => {
                        clearInterval(elapsedTimerId);
                        clearInterval(progressTimerId);
                        $statusAlert.text('Error encountered!').removeClass('d-none').removeClass(ALERT_SUCCESS).addClass(ALERT_DANGER);
                        //console.log('Upload failed:', jqXHR, errorThrown);
                    }).always(() => {
                        $this.prop('disabled', false);
                    }));
        }
        catch (err) {
            $this.prop('disabled', false);
        }

        function downloadFile(token) {
            const deferred = $.Deferred();
            const xhr = new XMLHttpRequest();
            xhr.open(httpMethod, exportURL, true);
            xhr.responseType = 'blob';
            xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);

            xhr.addEventListener('loadstart', (evt) => updateProgress(0), false);

            xhr.addEventListener("progress", (evt) => {
                if (evt.lengthComputable) {
                    var progress = Math.round((evt.loaded / evt.total) * 100);
                    updateProgress(progress);
                }
            }, false);

            xhr.addEventListener('load', (evt) => {
                const target = evt.currentTarget;
                if (target.readyState == 4 && target.status == 200) {
                    deferred.resolve(target.response, 'success', target);
                } else {
                    deferred.reject(target, 'error');

                    // read error sent from the server
                    //var reader = new FileReader();
                    //reader.onload = function () {
                    //    //alert(reader.result);
                    //    deferred.reject(target, reader.result);
                    //}
                    //reader.readAsText(target.response);
                }
            }, false);

            xhr.addEventListener('error', (evt) => {
                deferred.reject(evt.currentTarget, 'error'); //evt.currentTarget.responseText);
            }, false);

            try {
                xhr.send(JSON.stringify(data));
            } catch (ex) {
                deferred.reject(xhr, "error", ex);
            }

            return deferred.promise();
        }

        function downloadFileWithAjax() {
            return $.ajax({
                type: 'POST',
                url: exportURL,
                contentType: 'application/json; charset=utf-8',
                processData: false,
                data: JSON.stringify(data),
                //cache: false,
                //beforeSend: function (jqXHR, settings) { // for IE
                //    if (browser === 'IE') {
                //        jqXHR.responseType = 'blob';
                //        jqXHR.setRequestHeader('Content-type', 'application/json; charset=utf-8');
                //        //jqXHR.overrideMimeType("text/plain; charset=x-user-defined");
                //    }
                //},
                xhr: function () {
                    var xhr = new window.XMLHttpRequest();

                    //if (browser !== 'IE')
                    xhr.responseType = 'blob';

                    xhr.addEventListener('loadstart', (evt) => updateProgress(0), false);

                    xhr.addEventListener("progress", (evt) => {
                        if (evt.lengthComputable) {
                            var progress = Math.round((evt.loaded / evt.total) * 100);
                            updateProgress(progress);
                            //if (browser === 'IE' && progress === 100) startWatchImportProgress();
                        }
                    }, false);

                    //xhr.addEventListener('error', (evt) => {let debug = 5;}, false);
                    //xhr.addEventListener('abort', (evt) => {let debug = 5;}, false);


                    //if (browser === 'Edge') {
                    //    startWatchImportProgress();
                    //} else
                    //    if (browser !== 'IE') {
                    //        xhr.upload.addEventListener('load', (evt) => startWatchImportProgress(), false);
                    //    }

                    //xhr.addEventListener('load', (evt) => { let x = 5; }, false);

                    return xhr;
                }
            }).done(function (data, textStatus, jqXHR) {
                clearInterval(elapsedTimerId);
                clearInterval(progressTimerId);
                const fileName = getFileName(jqXHR);
                //if (browser === 'IE') {
                //    const blobObject = new Blob([data]
                //        , { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
                //    );
                //    window.navigator.msSaveOrOpenBlob(blobObject, fileName);
                //} else {
                let link = document.createElement('a');
                link.href = (window.URL || window.webkitURL).createObjectURL(data);
                link.download = fileName;
                link.click();
                setTimeout(function () { window.URL.revokeObjectURL(data); }, 200);
                //}
                //if ($progressText.text() === MSG_PROCESSING_FILE) updateProgress(100, MSG_PROCESSING_FILE_DONE);
                const msg = 'Export successfully executed!';
                $statusAlert.text(msg).removeClass('d-none').removeClass(ALERT_DANGER).addClass(ALERT_SUCCESS);;
            }).fail((jqXHR, textStatus, errorThrown) => {
                clearInterval(elapsedTimerId);
                clearInterval(progressTimerId);
                $statusAlert.text(`Error: ${jqXHR.responseJSON || jqXHR.responseText}`).removeClass('d-none').removeClass(ALERT_SUCCESS).addClass(ALERT_DANGER);
                //console.log('Upload failed:', jqXHR, errorThrown);
            }).always(() => {
                $this.prop('disabled', false);
            });
        }

        function getFileName(xhr) {
            var result = "";
            var disposition = xhr.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    result = matches[1].replace(/['"]/g, '');
                }
            }
            return result;
        }

        function updateProgress(percent, actionText) {
            if (!actionText) actionText = 'Downloading file';
            $progressText.text(`${actionText}...${percent}%`);
            $progressBar.attr('aria-valuenow', percent).css('width', percent + '%');
        }

        function resetProgress(actionText) {
            $progressText.text(actionText);
            $progressBar.hide().attr('aria-valuenow', 0).css('width', 0 + '%').show();
        }

        function startWatchExportProgress(id) {
            resetProgress(MSG_PROCESSING_FILE);

            var prevAction = '';
            progressTimerId = setInterval(
                function () {
                    $.ajax({
                        contentType: "application/json;charset=utf-8",
                        type: 'GET',
                        url: '/api/ActionProgress/' + id,
                        dataType: "json",
                        cache: false
                    }).done(function (data) {
                        //console.log(data);
                        if (data) {
                            const currentPercent = Math.round(data.currentValue * 100 / data.maxValue);
                            //console.log(currentPercent);

                            if (prevAction !== data.action) $progressBar.hide();
                            $progressText.text(`${data.action} (${currentPercent || 0}%) ${data.currentValue} of ${data.maxValue}`);
                            $progressBar.attr('aria-valuenow', currentPercent).css('width', currentPercent + '%');
                            if (prevAction !== data.action) $progressBar.show();

                            prevAction = data.action;
                        }
                        else
                            clearInterval(progressTimerId);
                    })
                        // Code to run if the request fails; the raw request and
                        // status codes are passed to the function
                        .fail((jqXHR, textStatus, errorThrown) => {
                            clearInterval(progressTimerId);
                            //console.log('Getting progress failed:', jqXHR, errorThrown);
                        })
                        //Code to run regardless of success or failure;
                        //.always(goLiveApp.loaderHide())
                        ;
                },
                1000);
        }

    }

    function handleDownloadModalClose() {
        resetDialog();
        gridComponent.getInnerGrid().focus();
    }

    function resetDialog() {
        $modal.modal('hide');
        $form[0].reset();
        $timeText.text('')
        $progressText.text('');
        $progressBar.attr('aria-valuenow', 0).css('width', 0 + '%');
        $statusAlert.addClass('d-none');
    }

    function uniqueID() {
        function chr4() {
            return Math.random().toString(16).slice(-4);
        }
        return chr4() + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() + chr4() + chr4();
    }

    function destroy() {
        $modal.off('show.bs.modal', handleShowBsModal);
        $modal.off('hidden.bs.modal', handleHideBsModal);
        $("#downloadFromServer").off('click', handleDownloadFromServerClick);
        $("#downloadModal :button.btn-close").off('click', handleDownloadModalClose);
    }

    init();

    return {
        destroy: destroy
    };

}

export default ExportHandler;