import React from 'react';
import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarDropdownButton,
    DataGridToolbarDropdownItem,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonAdd,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';
import EmailMessageEdit from './EmailMessageEdit';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';

const Toolbar = () => {
    return (
        <DataGridToolbar
            title="Emails"
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarDropdownButton
                    caption="Templates"
                    glyph="filter"
                    glyphColor='#4189C7'
                    filterOrder="1"
                    filter="templates"
                    filterReqMsg="Please choose a filter!"
                >
                    <DataGridToolbarDropdownItem filter="templates" href="#" caption='Templates' />
                    <DataGridToolbarDropdownItem filter="emails" href="#" caption='Emails' />
                </DataGridToolbarDropdownButton>
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonAdd />
                <DataGridToolbarButtonEdit />
                <DataGridToolbarButtonExport />
                <DataGridToolbarButtonHistory />
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const EmailMessages = props => {
    return (
        <DataGrid model='emailMessage'>
            <EmailMessageEdit />
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, EmailMessages, "EmailMessages");