import $ from 'jquery';

function EditModalErrorHandler(gridComponent, modalId) {

    let errors = [];
    let grid;
    let $errorBtn;
    let $errorBadge;

    function init() {
        grid = gridComponent.getInnerGrid();

        $errorBtn = $(".btn-error-editmodal").popover({
            //trigger: 'focus click',
            placement: 'left',
            html: true
            //title: function () { return $errorPopoverTitle.html(); },
            //content: function () { return $errorPopoverContent.html(); }
        });

        $errorBadge = $errorBtn.children('.badge');
        $errorBtn.on('inserted.bs.popover', handleOnInsertedPopover);
        $('body').on('click', '.popover-error-editmodal>.popover-body>div>a', handleOnErrClick);
        $('body').on('click', handleOnBodyClick);
    }

    function handleOnInsertedPopover(e) {
        const errorPopover = $(e.target).data('bs.popover').tip;
        $(errorPopover).addClass('popover-error-editmodal');

        //errors.sort((e1, e2) => { return e1.itemId - e2.itemId });

        $(errorPopover).children('.popover-body').append(
            errors.map((err) => err.text).join('')
        );
    }

    function handleOnBodyClick(e) {
        //only buttons
        if ($(e.target).data('toggle') !== 'popover'
            && $(e.target).parents('[data-toggle="popover"]').length === 0
            && $(e.target).parents('.popover-error-editmodal').length === 0
            && $('.popover-error-editmodal').is(':visible')) {
            $errorBtn.popover('hide');
        }
    }

    function handleOnErrClick(e) {
        const $this = $(this);
        const $modal = $('#' + modalId);
        //$modal.find('.was-validated .form-control:invalid').closest('.form-group').addClass('has-error');
        const $firstInvalid = $modal.find('#'+$this.data('field'));
        if ($firstInvalid.length > 0) {
            const $firstInvalidTab = $firstInvalid.closest('.tab-pane');
            //if the element is on another tab page activate it
            if ($firstInvalidTab.length > 0 && !$firstInvalidTab.hasClass('active')) {
                $modal.off('shown.bs.tab', 'a[data-toggle="tab"]');
                $modal.on('shown.bs.tab', 'a[data-toggle="tab"]', (e) => {
                    $modal.off('shown.bs.tab', 'a[data-toggle="tab"]');
                    $firstInvalid.focus();
                });
                $modal.find('.nav-tabs a[href="#' + $firstInvalidTab[0].id + '"]').tab('show');
            } else {
                $firstInvalid.focus();
            }
        }

        $errorBtn.popover('hide');
        e.preventDefault();
    }

    function add(error) {
        if (error.responseJSON) {
            if (Array.isArray(error.responseJSON)) {
                const columns = grid.getColumns();
                Array.prototype.push.apply(errors,
                    error.responseJSON.map((err) => {
                        const errGridField = err.code.startsWith('Duplicate') ? err.code.slice(9) : null;
                        let colId = '', colName = '';
                        if (errGridField) {
                            let colIdx = grid.getColumnIndex(errGridField);
                            if (colIdx == null && errGridField[0] === errGridField[0].toUpperCase()) {
                                colIdx = columns.findIndex(c => c.id.toUpperCase() === errGridField.toUpperCase());
                            }
                            colName = colIdx > -1 ? columns[colIdx].name : errGridField;
                            colId = colIdx > -1 ? columns[colIdx].id : '';
                        }
                        return { itemId: error.itemId, text: `<div><a href="#" data-itemid='${error.itemId}' data-field='${colId}'><b>${colName || err.code}</b></a> - ${err.description}</div>` };
                    })
                );
            } else {
                const columns = grid.getColumns();
                Object.keys(error.responseJSON).forEach(function (errField) {
                    const errGridField = errField.startsWith('Item.') ? errField.slice(5) : errField;
                    let colIdx = grid.getColumnIndex(errGridField);
                    if (colIdx == null && errGridField[0] === errGridField[0].toUpperCase()) {
                        colIdx = columns.findIndex(c => c.id.toUpperCase() === errGridField.toUpperCase());
                    }
                    const colName = colIdx > -1 ? columns[colIdx].name : errGridField;
                    const colId = colIdx > -1 ? columns[colIdx].id : '';
                    Array.prototype.push.apply(errors,
                        error.responseJSON[errField].map((errText) => {
                            return { itemId: error.itemId, text: `<div><a href="#" data-itemid='${error.itemId}' data-field='${colId}'><b>${colName}</b></a> - ${errText}</div>` };
                        })
                    );
                    //$errorPopoverContent.append(
                    //    error.responseJSON[field].map((errItem) => $(`<div><a href="#" data-itemid='${error.itemId}'>Row#${error.itemId} - <b>${colName}</b></a> - ${errItem}</div>`))
                    //);
                });
            }
            $errorBadge.text(errors.length);
            $errorBtn.removeClass('d-none');
        }
    }

    function reset() {
        $errorBtn.popover('hide');
        $errorBtn.addClass('d-none');
        $errorBadge.text('0');
        errors = [];
    }

    function destroy() {
        reset();
        $('body').off('click');
        $('body').off('click', '.popover-error-editmodal>.popover-body>div>a');
        $errorBtn.off('inserted.bs.popover');
        $errorBtn.popover('dispose');
    }

    init();

    return {
        add: add,
        reset: reset,
        destroy: destroy
    };
};

export default EditModalErrorHandler;