import React, { Component } from 'react';

import {  Button, Col, FormGroup, Label, Input, Card, CardText, CardBody, CardTitle, CardSubtitle, CardLink } from 'reactstrap';


class DataBases extends Component {

    constructor(props) {
        super(props);
        this.dbTier = [
            {
                value: 'B1',
                title: "Basic B1 - 5 DTU's",
                text: '2GB (max) - 30 requests - 300 sessions'
            },
            {
                value: 'S0',
                title: "Standard S0 - 10 DTU's",
                text: '250GB (max) - 60 requests - 600 sessions'
            },
            {
                value: 'S1',
                title: "Standard S1 - 20 DTU's",
                text: '250GB (max) - 90 requests - 900 sessions'
            },
            {
                value: 'S2',
                title: "Standard S2 - 50 DTU's",
                text: '250GB (max) - 120 requests - 1200 sessions'
            },
            {
                value: 'S3',
                title: "Standard S3 - 100 DTU's",
                text: '250GB (max) - 200 requests - 2400 sessions'
            },
            {
                value: 'S4',
                title: "Standard S4 - 200 DTU's",
                text: '250GB (max) - 400 requests - 4800 sessions'
            },
            {
                value: 'S6',
                title: "Standard S6 - 400 DTU's",
                text: '250GB (max) - 800 requests - 9600 sessions'
            },
            {
                value: 'S7',
                title: "Standard S7 - 800 DTU's",
                text: '250GB (max) - 1600 requests - 19200 sessions'
            },
            {
                value: 'S9',
                title: "Standard S9 - 1600 DTU's",
                text: '250GB (max) - 3200 requests - 30000 sessions'
            },
            {
                value: 'S12',
                title: "Standard S12 - 3000 DTU's",
                text: '250GB (max) - 6000 requests - 30000 sessions'
            },
            {
                value: 'P1',
                title: "Premium P1 - 125 DTU's",
                text: '4TB (max) - 200 requests - 30000 sessions - 1GB in memory OLTP - Read Scale-Out'
            },
            {
                value: 'P2',
                title: "Premium P2 - 250 DTU's",
                text: '4TB (max) - 400 requests - 30000 sessions - 2GB in memory OLTP - Read Scale-Out'
            },
            {
                value: 'P4',
                title: "Premium P4 - 500 DTU's",
                text: '4TB (max) - 800 requests - 30000 sessions - 4GB in memory OLTP - Read Scale-Out'
            },
            {
                value: 'P6',
                title: "Premium P6 - 1000 DTU's",
                text: '4TB (max) - 1600 requests - 30000 sessions - 8GB in memory OLTP - Read Scale-Out'
            },
            {
                value: 'P11',
                title: "Premium P11 - 1750 DTU's",
                text: '4TB (max) - 2400 requests - 30000 sessions - 14GB in memory OLTP - Read Scale-Out'
            },
            {
                value: 'P15',
                title: "Premium P15 - 4000 DTU's",
                text: '4TB (max) - 6400 requests - 30000 sessions - 32GB in memory OLTP - Read Scale-Out'
            }
        ];

    }

    render() {

        var currentDbTier = this.dbTier.filter(f => f.value === this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].tier);
        currentDbTier = currentDbTier.length > 0 ? currentDbTier[0] : currentDbTier; 

        return (
            <div className="applicationDBSet">
                <br />
                <FormGroup row>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="name">Database Name</Label>
                            <Input
                                defaultValue={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].name}
                                type="text"
                                name={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.name'}
                                id={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.name'}
                                placeholder="Database Name"
                                required
                                valid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].name ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].name.length > 0 ? true : false) : false}

                                invalid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].name ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].name.length <= 0 ? true : false) : null}
                            />
                            <div className="invalid-feedback help-block">
                                Provide a <b>Database Name</b>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="connStringName">ConnectionString Name</Label>
                            <Input
                                value={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].connStringName}
                                type="text"
                                name={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.connStringName'}
                                id={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.connStringName'}
                                placeholder="APP Connection String Name"
                                required
                                valid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].connStringName ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].connStringName.length > 0 ? true : false) : false}

                                invalid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].connStringName ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].connStringName.length <= 0 ? true : false) : null}
                            />
                            <div className="invalid-feedback help-block">
                                Provide an <b>App Connection String Name</b>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="userName">User Name</Label>
                            <Input
                                value={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].userName}
                                type="text"
                                name={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.userName'}
                                id={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.userName'}
                                placeholder="DB User Name"
                                required
                                valid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].userName ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].userName.length > 0 ? true : false) : false}

                                invalid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].userName ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].userName.length <= 0 ? true : false) : null}
                            />
                            <div className="invalid-feedback help-block">
                                Provide a <b>DB User Name</b>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={1}>
                        <div style={{ textAlign: 'center' }}>
                            <Label>&nbsp;</Label>
                            <Button color="danger" type="button" onClick={() => this.props.removeDataBase(this.props.appIdx, this.props.dbIdx)}><i className="fa fa-trash" /></Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="location">Location</Label>
                            <Input
                                type="select"
                                name={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.location'}
                                id={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.location'}
                                value={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].location}
                                required
                                valid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].location ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].location.length <= 0 ? false : true) : false}
                                invalid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].location ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].location.length <= 0 ? true : false) : true}

                            >
                                <option value=""> Choose an option </option>
                                {
                                    this.props.getEnumValues('locationEnum').map(c => {
                                        return <option key={c.code} value={c.code} >{c.description}</option>;
                                    })
                                }
                            </Input>
                            <div className="invalid-feedback help-block">
                                Select a Location
                                </div>
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="collation">Collation</Label>
                            <Input
                                type="select"
                                name={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.collation'}
                                id={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.collation'}
                                value={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].collation}
                                required
                                valid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].collation ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].collation.length <= 0 ? false : true) : false}
                                invalid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].collation ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].collation.length <= 0 ? true : false) : true}

                            >
                                <option value=""> Choose an option </option>
                                {
                                    this.props.getEnumValues('collationEnum').map(c => {
                                        return <option key={c.code} value={c.code} >{c.description}</option>;
                                    })
                                }
                            </Input>
                            <div className="invalid-feedback help-block">
                                Select a Collation
                                </div>
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="tier">Database Tier</Label>
                            <Input
                                type="select"
                                name={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.tier'}
                                id={'settings.' + this.props.appIdx + '.database.' + this.props.dbIdx + '.tier'}
                                value={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].tier}
                                required
                                valid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].tier ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].tier.length <= 0 ? false : true) : false}
                                invalid={this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].tier ? (this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].tier.length <= 0 ? true : false) : true}

                            >
                                <option value=""> Choose an option </option>
                                {
                                    this.props.getEnumValues('databaseTierEnum').map(c => {
                                        return <option key={c.code} value={c.code} >{c.description}</option>;
                                    })
                                }
                            </Input>
                            <div className="invalid-feedback help-block">
                                Select a Database Tier
                                </div>
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        {
                            this.props.formPaas.settings[this.props.appIdx].database[this.props.dbIdx].tier? <Card>
                                <CardBody>
                                    <CardSubtitle>{currentDbTier.title}</CardSubtitle>
                                    <CardText>{currentDbTier.text}</CardText>
                                    <CardLink href="https://docs.microsoft.com/en-us/azure/sql-database/sql-database-dtu-resource-limits-single-databases" target="_blank">More Info</CardLink>
                                </CardBody>
                            </Card> : null
                        }

                    </Col>



                </FormGroup>
                 
            </div>
        );
    }
}

export default DataBases;
