import $ from 'jquery';
import Slick from '../../src/slick.core';
import _ from 'lodash';
import HyperList from '../../../hyperlist/lib';

export default HeaderFilter;

/*
Based on SlickGrid Header Menu Plugin (https://github.com/mleibman/SlickGrid/blob/master/plugins/slick.headermenu.js)
(Can't be used at the same time as the header menu plugin as it implements the dropdown in the same way)
*/
function HeaderFilter(options) {
    let grid;
    const self = this;
    const handler = new Slick.EventHandler();
    const defaults = {
        buttonImage: "./images/down.png",
        filterImage: "./images/filter.png",
        sortAscImage: "./images/sort-asc.png",
        sortDescImage: "./images/sort-desc.png"
    };
    let $menu;
    let filterDialog;
    let selectAllState;
    const gridComponent = options.gridComponent;

    function init(g) {
        options = $.extend(true, {}, defaults, options);
        grid = g;
        handler
            .subscribe(grid.onHeaderCellRendered, handleHeaderCellRendered)
            .subscribe(grid.onBeforeHeaderCellDestroy, handleBeforeHeaderCellDestroy)
            .subscribe(grid.onClick, handleBodyMouseDown)
            .subscribe(grid.onColumnsResized, columnsResized);

        grid.setColumns(grid.getColumns());

        $(document.body).on("mousedown", handleBodyMouseDown);

        //init filter dialog
        filterDialog = new FilterDialog();
    }

    function destroy() {
        handler.unsubscribeAll();
        $(document.body).off("mousedown", handleBodyMouseDown);
    }

    function handleBodyMouseDown(e) {
        if ($menu && $menu[0] != e.target && !$.contains($menu[0], e.target)) {
            hideMenu();
        }
    }

    function hideMenu() {
		selectAllState = null;
        if ($menu) {
            $menu.remove();
            $menu = null;
        }
    }

    function handleHeaderCellRendered(e, args) {
        //console.log('handleHeaderCellRendered');
        var column = args.column;

        if (column.sortable || column.filterable) {
            var $el = $("<div></div>")
                .addClass("slick-header-menubutton")
                .data("column", column);

            //if (options.buttonImage) {
            //    $el.css("background-image", "url(" + options.buttonImage + ")");
            //}

            setButtonImage($el, (column.filterValues || []).length > 0);

            $el.on("click", function (e) {
                var $menuButton = $(this);
                const column = $menuButton.data("column");
                let fields = [], includeMultiSelectData = false;
                if (column.isMultiSelect /*&& !column.isCustomMultiSelect*/)
                    includeMultiSelectData = true;
                else
                    fields = [column.field];

                gridComponent.ensureColumnsData(fields, includeMultiSelectData)
                    .done(() => showFilter($menuButton));

                // Stop propagation so that it doesn't register as a header click event.
                e.preventDefault();
                e.stopPropagation();
            }).appendTo(args.node);
        }
    }

    function handleBeforeHeaderCellDestroy(e, args) {
        $(args.node)
            .find(".slick-header-menubutton")
            .remove();
    }

    function addMenuItem(menu, columnDef, title, command, image) {
        var $item = $("<div class='slick-header-menuitem'>")
            .data("command", command)
            .data("column", columnDef)
            .on("click", handleMenuItemClick)
            .appendTo(menu);

        var $icon = $("<div class='slick-header-menuicon'>")
            .addClass(`slick-header-menuicon-${command}`)
            .appendTo($item);

        //if (image) {
        //    $icon.css("background-image", "url(" + image + ")");
        //} 

        $("<span class='slick-header-menucontent'></span>")
            .text(title)
            .appendTo($item);
    }

    function showFilter($menuButton) {
            var columnDef = $menuButton.data("column");
            const isMultiSelectColumn = columnDef.isMultiSelect;

            columnDef.filterValues = columnDef.filterValues || [];

            // WorkingFilters is a copy of the filters to enable apply/cancel behaviour
            let workingFilters = columnDef.filterValues.slice(0);

            var filterItems;

            if (workingFilters.length === 0) {
                // Filter based all available values
                filterItems = getFilterValues(grid.getData(), columnDef);
            }
            else {
                // Filter based on current dataView subset
                filterItems = getAllFilterValues(grid.getData().getItems(), columnDef);
            }

            if (!$menu) {
                $menu = $("<div class='slick-header-menu'>").appendTo(document.body);
            }

            $menu.empty();

            addMenuItem($menu, columnDef, 'Sort Ascending', 'sort-asc', options.sortAscImage);
            addMenuItem($menu, columnDef, 'Sort Descending', 'sort-desc', options.sortDescImage);
            addMenuItem($menu, columnDef, 'Custom Filter', 'cust-flt', options.filterImage);

            if (columnDef.filterable === true) {

                var filterOptions = "<label><input type='checkbox' value='-1' />(Select All)</label>";
                const ds = columnDef.dataSource;
                const allowsNA = columnDef.allowsNA;// columnDef.formatter != null && columnDef.formatter.toString().substr(0, 20) === "function NAFormatter";

                if (columnDef.dataSource) filterItems.sort((a, b) => ((!!a && !!b && ds[a] > ds[b]) || !b) ? 1 : -1);

                /*
                const length = Math.min(filterItems.length, 100);
                for (var i = 0; i < length; i++) {
                    const filterItem = filterItems[i];
                    var filtered = workingFilters.includes(filterItem);
                    var filterValue = //((ds ? ds[filterItem] : filterItem) || '').trim() || '(Empty)';
                        (
                            isMultiSelectColumn ?
                                (ds[filterItem] || filterItem) :
                                (allowsNA && (filterItem == null || filterItem === '') ? 'N/A' :
                                    (columnDef.formatter ? columnDef.formatter(-1, -1, filterItem, columnDef, null) : filterItem))
                        );
                    filterValue = filterValue == null ? '(Empty)' : (filterValue.toString().trim() || '(Empty)');

                    filterOptions += "<label><input type='checkbox' value='" + i + "'"
                        + (filtered ? " checked='checked'" : "")
                        + "/>" + filterValue + "</label>";
                }
                */

                var container = document.createElement('div');
                var config = {
                    height: 300,
                    itemHeight: 17,
                    total: filterItems.length +1 ,
                    // Set to true to put into 'chat mode'.
                    reverse: false,
                    scrollerTagName: 'div',

                    generate(i) {
                        const result = document.createElement("div");
                        if (i === 0) {
                            const selectAllChecked = selectAllState ? " checked='checked'" : "";
                            result.innerHTML = `<label><input type='checkbox' value='-1' ${selectAllChecked} />(Select All)</label>`;
                        } else {
                            const filterItem = filterItems[--i];
                            var filtered = selectAllState == null ? workingFilters.includes(filterItem) : selectAllState;
                            var filterValue = //((ds ? ds[filterItem] : filterItem) || '').trim() || '(Empty)';
                                (
                                    isMultiSelectColumn ?
                                        (ds[filterItem] || filterItem) :
                                        (allowsNA && (filterItem == null || filterItem === '') ? 'N/A' :
                                            (columnDef.formatter ? columnDef.formatter(-1, -1, filterItem, columnDef, null) : filterItem))
                                );
                            filterValue = filterValue == null ? '(Empty)' : (filterValue.toString().trim() || '(Empty)');

                            result.innerHTML = "<label><input type='checkbox' value='" + i + "'"
                                + (filtered ? " checked='checked'" : "")
                                + "/>" + filterValue + "</label>";
                        }
                        return result;
                    }
                };

                //window.onresize = e => {
                //    config.height = window.innerHeight;
                //    list.refresh(container, config);
                //};

                var $filter = $("<div class='filter'>")
                    //.append($(filterOptions))
                    .appendTo($menu);

                var list = HyperList.create($filter[0], config);
                //$filter.addClass("container");

                $('<button class="btn btn-success">OK</button>')
                    .appendTo($menu)
                    .on('click', function (ev) {
                        selectAllState = null;
                        columnDef.filterValues = workingFilters.splice(0, workingFilters.length);
                        setButtonImage($menuButton, columnDef.filterValues.length > 0);
                        handleApply(ev, columnDef);
                    });

                $('<button class="btn btn-danger">Clear</button>')
                    .appendTo($menu)
                    .on('click', function (ev) {
                        selectAllState = null;
                        columnDef.filterValues.length = 0;
                        setButtonImage($menuButton, false);
                        handleApply(ev, columnDef);
                    });

                $('<button class="btn btn-secondary">Cancel</button>')
                    .appendTo($menu)
                    .on('click', hideMenu);

                $filter.on('click', ':checkbox', function() {
                    workingFilters = changeWorkingFilter(filterItems, workingFilters, $(this));
                });


            }
            var offset = $menuButton.offset();
            var left = offset.left - $menu.width() + $menuButton.width() - 4;

            $menu.css("top", offset.top + $menuButton.height())
                .css("left", (left > 0 ? left : 0));

            //// Stop propagation so that it doesn't register as a header click event.
            //e.preventDefault();
            //e.stopPropagation();
        }

    function columnsResized() {
        hideMenu();
    }

    function changeWorkingFilter(filterItems, workingFilters, $checkbox) {
        var value = $checkbox.val();
        var $filter = $checkbox.parent().parent().parent();

        if ($checkbox.val() < 0) {
            // Select All
            if ($checkbox.prop('checked')) {
                $(':checkbox', $filter).prop('checked', true);
                //workingFilters = filterItems.slice(0);
                workingFilters.length = 0;
                selectAllState = true;
            } else {
                $(':checkbox', $filter).prop('checked', false);
                workingFilters.length = 0;
                selectAllState = false;
            }
        } else {
            const checked = $checkbox.prop('checked');
            const $checkAll = $(':checkbox[value=-1]', $filter);

            if (!checked) {
                if ($checkAll.prop('checked'))
                    $checkAll.prop('checked', false);

                if (selectAllState && workingFilters.length === 0)
                    Array.prototype.push.apply(workingFilters, filterItems);
            }
            selectAllState = null;

            var index = _.indexOf(workingFilters, filterItems[value]);

            if (checked && index < 0) {
                workingFilters.push(filterItems[value]);
                if (filterItems.length === workingFilters.length) {
                    selectAllState = true;
                    workingFilters.length = 0;
                    if ($checkAll.length > 0) $checkAll.prop('checked', true);
                }
            }
            else {
                if (index > -1) {
                    workingFilters.splice(index, 1);
                }
            }
        }

        return workingFilters;
    }

    function setButtonImage($el, filtered) {
        //var image = "url(" + (filtered ? options.filterImage : options.buttonImage) + ")";

        //const backgroundColor = filtered ? "#a4e5c1" : "inherit";
        //$el.css({ "background-image": image, "background-color": backgroundColor });

        if (filtered) {
            $el.addClass('filtered');
        }
        else {
            $el.removeClass('filtered');
        }
    }

    function handleApply(e, columnDef) {
        hideMenu();

        self.onFilterApplied.notify({ "grid": grid, "column": columnDef }, e, self);

        e.preventDefault();
        e.stopPropagation();
    }

    function getFilterValues(dataView, column) {
        const isMultiSelectColumn = column.isMultiSelect;
        var allValues = [], length = dataView.getLength();
        for (var i = 0; i < length; i++) {
            var value = dataView.getItem(i)[column.field];
            if (value == null) value = '';
            if (isMultiSelectColumn && value) {
                value.forEach(v => {
                    //if (!_.includes(result, v)) 
                    allValues.push(v);
                });
            }
            else //if (!_.includes(result, value)) 
                allValues.push(value);
        }

        const result = options.browser === 'IE'
            ? allValues.filter((itm, i, result) => i == result.indexOf(itm))
            : [...new Set(allValues)]; //Array.from(new Set(allValues));

        //return _.sortBy(seen, function(v) { return v; });
        result.sort((a, b) => a > b ? 1 : -1);
        //seen.quickSort();
        return result;
    }

    function getAllFilterValues(data, column) {
        const isMultiSelectColumn = column.isMultiSelect;
        var columnValues = [], length = data.length;
        for (var i = 0; i < length; i++) {
            var value = data[i][column.field];
            if (value == null) value = '';

            if (isMultiSelectColumn && value) {
                value.forEach(v => {
                    //if (!_.includes(result, v)) 
                    columnValues.push(v);
                });
            }
            else //if (!_.includes(result, value))
                columnValues.push(value);
        }

        const result = options.browser === 'IE'
            ? columnValues.filter((itm, i, result) => i == result.indexOf(itm))
            : [...new Set(columnValues)]; //Array.from(new Set(allValues));

        //return _.sortBy(seen, function(v) { return v; });
        //columnValues = Array.from(new Set(columnValues)); // get distinct values
        result.sort((a, b) => a > b ? 1 : -1);
        return result;
    }

    function setFilterValues(data, column, applyFilter) {
        column.filterValues = getAllFilterValues(data, column);
        const $headerBtn = $(`#${grid.getUID()}${column.id} .slick-header-menubutton`);
        setButtonImage($headerBtn, true);

        if (applyFilter) self.onFilterApplied.notify({ "grid": grid, "column": column }, null, self);
    }

    function handleMenuItemClick(e) {
        var command = $(this).data("command");
        var columnDef = $(this).data("column");

        hideMenu();

        if (command === "cust-flt") {
            filterDialog.Show({
                column: columnDef,
                onYes: function (data) {
                    const fieldName = columnDef.field;
                    const allColumnValues = grid.getData().getItems()
                        .reduce((result, item) => {
                            const val = item[fieldName];
                            if (val != null) {
                                result[val.toString().toUpperCase()] = val.toString();
                            }
                            return result;
                        }, {});

                    data = data.map(i => {
                        const matchedVal = allColumnValues[i[fieldName].toUpperCase()];
                        if (matchedVal) i[fieldName] = matchedVal;
                        return i;
                    });

                    setFilterValues(data, columnDef, true);
                }
            });
        } else {
            self.onCommand.notify({
                "grid": grid,
                "column": columnDef,
                "command": command
            }, e, self);
        }

        e.preventDefault();
        e.stopPropagation();
    }

    function resetFilterButtons() {
        //var image = "url(" + options.buttonImage + ")";
        const backgroundColor = "inherit";
        $('.slick-header .slick-header-menubutton.filtered')
            //.css({ "background-image": image, "background-color": backgroundColor })
            .removeClass('filtered');
    }

    Object.assign(this, {
        init,
        destroy,
        getAllFilterValues,
        resetFilterButtons,
        setFilterValues,

        onFilterApplied: new Slick.Event(),
        onCommand: new Slick.Event()
    });
}

function FilterDialog() {

    var
        $modal,
        $title,
        $filterValues,
        $btnYes,
        $btnNo,
        $btnClose,
        $defaultBtnYes,

        // methods
        init = function () {
            $modal = $('#gridColumnFilterModal');
            $title = $modal.find('.modal-title');
            $filterValues = $modal.find('#filterValues');
            $btnYes = $modal.find('.btn-yes');
            $btnNo = $modal.find('.btn-no');
        },
        show = function (args) {
            var dialogResult = false;

            $title.html(`Filter ${args.column.name}`);

            if (args.column.filterValues) {
                const c = args.column;
                $filterValues.val(c.filterValues
                    .map(v => {
                        const isMultiSelectColumn = c.isMultiSelect;
                        var filterValue = //((ds ? ds[filterItem] : filterItem) || '').trim() || '(Empty)';
                            (
                                isMultiSelectColumn ?
                                    (c.dataSource[v] || v) :
                                    (c.formatter ? c.formatter(-1, -1, v, c, null) : v)
                            );
                        return filterValue;
                    })
                    .join("\r\n"));
            }

            $modal.on('hidden.bs.modal', function () {
                $modal.off('click', '.btn-yes');
                if (!dialogResult && args.onNo) args.onNo();
                return true;
            })

            const field = args.column.field;
            if (args.onYes) {
                $modal.off('click', '.btn-yes');
                $modal.on('click', '.btn-yes', function () {
                    dialogResult = true;

                    const data = $filterValues.val()
                        .split("\n")
                        .filter(s => s > '')
                        .map(function (s) {
                            const result = {};
                            result[field] = s;
                            return result;
                        });
                    args.onYes(data);

                    $modal.modal('hide');
                });
            }

            $modal.modal('show');
        }
        ;


    init();

    return {
        Show: show
    };
}