import React from 'react';
import { IfAuthorized, AuthorizationPolicies } from '../BaseComponents/Authorization';

import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarCheckBox,
    DataGridToolbarSearchBox,
    DataGridToolbarButtonRefresh,
    DataGridToolbarButtonAdd,
    DataGridToolbarButtonEdit,
    DataGridToolbarButtonDelete,
    DataGridToolbarButtonImport,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';
//import SitesExtended from './SitesExtended';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';
import ProjectRequired from '../BaseComponents/ProjectRequired';
import DuPont from '../../jscomponents/DuPontApp';

const Toolbar = () => {
    return (
        <DataGridToolbar
            title="SitesExtended"
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarButtonRefresh />
                <DataGridToolbarButtonImport />
                <DataGridToolbarButtonExport />
                <DataGridToolbarButtonHistory />
                <DataGridToolbarFavoritesSplitButton />            
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const SitesExtended = (props) => {
    const { multiSpin } = DuPont.App.Config;

    if (multiSpin && !props.match.params.project) {
        return (
            <ProjectRequired />
        );
    }

    return (
        <DataGrid model='sitesExtended'>
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, SitesExtended, "SitesExtended");