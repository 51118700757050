import Repository from './Repository';
import Dialog from './DuPontApp.Dialog';

const DuPont = {};

DuPont.App = function () {
    var
        isIEPreVer9 = function () {
            var v = navigator.appVersion.match(/MSIE ([\d.]+)/i);
            return (v ? v[1] < 9 : false);
        },
        server_path = '/api/',
        dialog = new Dialog(),
        repository = new Repository(dialog)
        ;

    return {
        SERVER_PATH: server_path,
        Repository: repository,
        Dialog: dialog,

        //methods
        IsIEPreVer9: isIEPreVer9
    };
}();

export default DuPont;