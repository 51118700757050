import $ from 'jquery';
import ImportErrorHandler from './Import.Errors';
import { getToken } from '../adalConfig';
import { golLiveAppApi } from './Main';

export default ImportHandler;

function ImportHandler(args) {

    let importURL;
    const MSG_PROCESSING_FILE = 'Processing file...';
    const MSG_PROCESSING_FILE_DONE = 'Processing file...done!';

    const ALERT_SUCCESS = 'alert-success';
    const ALERT_DANGER = 'alert-danger';

    const browser = args.browser;
    const gridComponent = args.gridComponent;
    const errors = new ImportErrorHandler();

    const $modal = $('#uploadModal');
    const $form = $('#uploadModal form');
    const $statusAlert = $('#uploadModal .status-alert');

    const $progressText = $('#uploadModal .progress-text');
    const $progressBar = $('#uploadModal .progress-bar');
    const $timeText = $('#uploadModal .time-text');

    function init() {
        $modal.on('show.bs.modal', handleShowBsModal);
        $modal.on('hidden.bs.modal', handleHideBsModal);
        $("#uploadToServer").on('click', handleUploadToServerClick);
        $("#uploadModal :button.btn-close").on('click', handleUploadModalClose);
    }

    function handleShowBsModal(event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        importURL = (button.data('import-url') || '').toLowerCase(); // Extract info from data-* attributes

        if (importURL.endsWith('/applications/import')) {
            $('#isSSoTFullSyncContainer').removeClass('d-none');
            $('#dateContainer').addClass('d-none');
        }
        else if (importURL.endsWith('/spinprogram/import') || importURL.endsWith('/servicedeliveryworkplan/import') || importURL.endsWith('/techtransformation/import')) {
            $('#isSSoTFullSyncContainer').addClass('d-none');
            const today = new Date();
            let monday = new Date();
            monday.setDate(today.getDate() - (today.getDay() || 7) + 1);
            const
                year = monday.getFullYear(),
                month = ('0' + (monday.getMonth() + 1)).slice(-2),
                day = ('0' + monday.getDate()).slice(-2);
            $('#dateContainer').removeClass('d-none');
            $('#date').val(`${year}-${month}-${day}`);
        } else {
            $('#isSSoTFullSyncContainer').addClass('d-none');
            $('#dateContainer').addClass('d-none');
        }
    }

    function handleHideBsModal(event) {
        event.stopImmediatePropagation();
    }

    function handleUploadToServerClick() {
        //var filename = $("#upload").val();

        errors.reset();

        const $this = $(this);

        var formData = new FormData();
        $form.serializeArray().forEach(item => {
            formData.append(item.name, item.value);
        });

        const files = $('#upload')[0].files;

        if (files.length === 0) {
            $statusAlert.text(`Please choose a file to upload!`).removeClass('d-none').removeClass(ALERT_SUCCESS).addClass(ALERT_DANGER);
            return false;
        }

        formData.append('file', files[0]); // myFile is the input type="file" control

        //if(!importURL && DuPont && DuPont.App)
        //    importURL = DuPont.App.Grids[Object.keys(DuPont.App.Grids)[0]].importURL();

        //goLiveApp.loaderShow()

        const id = uniqueID();
        formData.append('id', id);
        $this.prop('disabled', true);

        $statusAlert.addClass('d-none');
        $timeText.text('').hide();
        $progressText.text('');
        $progressBar.hide().attr('aria-valuenow', 0).css('width', 0 + '%').show();

        var progressTimerId, elapsedTimerId;

        var start = Date.now();

        elapsedTimerId = setInterval(function () {
            var time = new Date(Date.now() - start).toTimeString();
            $timeText.text(`Elapsed ${time.substring(3, 9)}`).show();
        }, 1000);

        try {
            getToken().then(token => {
                uploadFile(token)
                    .done(function (data, textStatus, jqXHR) {
                        const msg = data || 'Import successfully executed!';
                        $statusAlert.text(msg).removeClass('d-none').removeClass(ALERT_DANGER).addClass(ALERT_SUCCESS);
                        clearInterval(elapsedTimerId);
                        clearInterval(progressTimerId);
                        if (gridComponent) {
                            resetProgress('Refreshing data...');
                            gridComponent.refresh().then(() => resetProgress('Refreshing data...done!'));
                        }
                        if ($progressText.text() === MSG_PROCESSING_FILE) updateProgress(100, MSG_PROCESSING_FILE_DONE);

                    }).fail((jqXHR, textStatus, errorThrown) => {
                        clearInterval(elapsedTimerId);
                        clearInterval(progressTimerId);
                        const errorText = jqXHR.status === 403 ? "Access denied" : jqXHR.responseJSON ? jqXHR.responseJSON.message : "unknown";
                        $statusAlert.text(`Error: ${errorText}`).removeClass('d-none').removeClass(ALERT_SUCCESS).addClass(ALERT_DANGER);
                        //console.log('Upload failed:', jqXHR, errorThrown);
                        if (jqXHR.responseJSON) errors.add(jqXHR.responseJSON.errors);
                    }).always(() => {
                        $this.prop('disabled', false);
                    });
            });
        }
        catch (err) {
            $this.prop('disabled', false);
        }

        function uploadFile(token) {
            return $.ajax({
                type: 'POST',
                url: importURL,
                headers: { Authorization: `Bearer ${token}` },
                contentType: false,
                processData: false,
                data: formData,
                cache: false,
                xhr: function () {
                    var xhr = new window.XMLHttpRequest();

                    xhr.upload.addEventListener('loadstart', (evt) => updateProgress(0), false);

                    xhr.upload.addEventListener("progress", (evt) => {
                        if (evt.lengthComputable) {
                            var progress = Math.round((evt.loaded / evt.total) * 100);
                            updateProgress(progress);
                            if (browser === 'IE' && progress === 100) startWatchImportProgress();
                        }
                    }, false);

                    if (browser === 'Edge') {
                        startWatchImportProgress();
                    } else
                        if (browser !== 'IE') {
                            xhr.upload.addEventListener('load', (evt) => startWatchImportProgress(), false);
                        }

                    return xhr;
                }
            });
        }

        function updateProgress(percent, actionText) {
            if (!actionText) actionText = 'Uploading file';
            $progressText.text(`${actionText}...${percent}%`);
            $progressBar.attr('aria-valuenow', percent).css('width', percent + '%');
        }

        function resetProgress(actionText) {
            $progressText.text(actionText);
            $progressBar.hide().attr('aria-valuenow', 0).css('width', 0 + '%').show();
        }

        function startWatchImportProgress() {
            resetProgress(MSG_PROCESSING_FILE);

            var prevAction = '';
            progressTimerId = setInterval(
                function () {
                    golLiveAppApi.submitRequest({ url: '/api/ActionProgress/' + id })
                        .done(function (data) {
                            //console.log(data);
                            if (data) {
                                const currentPercent = Math.round(data.currentValue * 100 / data.maxValue);
                                //console.log(currentPercent);

                                if (prevAction !== data.action) $progressBar.hide();
                                $progressText.text(`${data.action} (${currentPercent || 0}%) ${data.currentValue} of ${data.maxValue}`);
                                $progressBar.attr('aria-valuenow', currentPercent).css('width', currentPercent + '%');
                                if (prevAction !== data.action) $progressBar.show();

                                prevAction = data.action;
                            }
                            else
                                clearInterval(progressTimerId);
                        })
                        // Code to run if the request fails; the raw request and
                        // status codes are passed to the function
                        .fail((jqXHR, textStatus, errorThrown) => {
                            clearInterval(progressTimerId);
                            //console.log('Getting progress failed:', jqXHR, errorThrown);
                        })
                        //Code to run regardless of success or failure;
                        //.always(goLiveApp.loaderHide())
                        ;
                },
                1000);
        }


    }

    function handleUploadModalClose() {
        if (gridComponent) {
            resetDialog();
        } else {
            window.location.reload();
        }
    }

    function resetDialog() {
        $modal.modal('hide');
        $form[0].reset();
        $timeText.text('').hide();
        $progressText.text('');
        $progressBar.attr('aria-valuenow', 0).css('width', 0 + '%');
        $statusAlert.addClass('d-none');
        errors.reset();
    }

    function uniqueID() {
        function chr4() {
            return Math.random().toString(16).slice(-4);
        }
        return chr4() + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() + chr4() + chr4();
    }

    function destroy() {
        $modal.off('show.bs.modal', handleShowBsModal);
        $modal.off('hidden.bs.modal', handleHideBsModal);
        $("#uploadToServer").off('click', handleUploadToServerClick);
        $("#uploadModal :button.btn-close").off('click', handleUploadModalClose);
    }

    init();

    return {
        destroy: destroy
    };
}

