'use strict';

import $ from 'jquery';
import { getToken } from '../adalConfig';

const goLiveApp = (function () {
    var api = {
        submitRequest: (requestArgs, progressArgs) => {
            var request = {
                contentType: "application/json;charset=utf-8",
                type: 'GET',
                dataType: "json",
                cache: false,
                headers: {
                    accept: "application/json;odata=verbose"
                }
            };
            $.extend(request, requestArgs);

            if (progressArgs) {
                request.xhr = function () {
                    const xhr = new window.XMLHttpRequest();

                    xhr.upload.addEventListener('loadstart', (evt) => progressArgs.onUploadStart(), false);

                    xhr.upload.addEventListener("progress", (evt) => {
                        if (evt.lengthComputable) {
                            var progress = Math.round((evt.loaded / evt.total) * 100);
                            progressArgs.onUploadProgress(progress);
                            if (browser === 'IE' && progress === 100) progressArgs.onUploadEnd();
                        }
                    }, false);

                    if (browser === 'Edge') {
                        progressArgs.onUploadEnd();
                    } else if (browser !== 'IE') {
                        xhr.upload.addEventListener('load', (evt) => progressArgs.onUploadEnd(), false);
                    }

                    return xhr;
                };
            }

            const deferred = $.Deferred();

            getToken()
                .then(token => {
                    request.headers.Authorization = `Bearer ${token}`;

                    $.ajax(request)
                        .done(deferred.resolve)
                        .fail(deferred.reject);
                })
                .catch(err => {
                    if (err.msg && ["login_required", "interaction_required"].includes(err.msg.toLowerCase())) window.location.reload(true);
                });

            return deferred.promise();
        }
    };

    function camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
            return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
        }).replace(/\s+/g, '');
    }

    var apiGetAll = function (url) {
        return function (data) {
            return $.ajax({
                url: url,
                method: "GET",
                data: data,
                cache: false,
                dataType: "json"
            });
        };
    };

    var apiGetByPath = function (url) {
        return function (path, data) {
            return $.ajax({
                url: url.replace("path", path),
                method: "GET",
                data: data,
                cache: false,
                dataType: "json"
            });
        }
    };

    var apiPut = function (url) {
        return function (path, data) {
            return $.ajax({
                url: url + "/" + path,
                method: "PUT",
                data: JSON.stringify(data),
                dataType: "json",
                contentType: "application/json",
            });
        };
    };

    var registerApiGetAll = function (name, url) {
        api[camelize("getAll " + name)] = apiGetAll(url);
    };

    var registerApiGetByPath = function (name, url) {
        api[camelize("get " + name)] = apiGetByPath(url);
    };

    var registerApiPut = function (name, url) {
        api[camelize("put " + name)] = apiPut(url);
    };

    //var loadingCount = 1;
    var showLoader = function () {
        $(".loadContainer").show();
        //if (loadingCount === 0) {
        //    $(".loadContainer").show();
        //}
        //loadingCount++;
    };

    var hideLoader = function (force) {
        $(".loadContainer").hide();
        //if (force) {
        //    loadingCount = 0;
        //} else {
        //    if (loadingCount > 0) {
        //        loadingCount--;
        //    }            
        //}

        //if (loadingCount === 0) {
        //    $(".loadContainer").hide();
        //}
    };

    var BrowserDetect = function () {
        var
            browser,
            version,
            versionSearchString,
            init = function () {
                browser = searchString(dataBrowser) || "Other";
                version = searchVersion(navigator.userAgent) || searchVersion(navigator.appVersion) || "Unknown";
            },

            searchString = function (data) {
                for (var i = 0; i < data.length; i++) {
                    var dataString = data[i].string;
                    versionSearchString = data[i].subString;

                    if (dataString.indexOf(data[i].subString) !== -1) {
                        return data[i].identity;
                    }
                }
            },

            searchVersion = function (dataString) {
                var index = dataString.indexOf(versionSearchString);
                if (index === -1) {
                    return;
                }

                var rv = dataString.indexOf("rv:");
                if (versionSearchString === "Trident" && rv !== -1) {
                    return parseFloat(dataString.substring(rv + 3));
                } else {
                    return parseFloat(dataString.substring(index + versionSearchString.length + 1));
                }
            },

            dataBrowser = [
                { string: navigator.userAgent, subString: "Edge", identity: "Edge" },
                { string: navigator.userAgent, subString: "MSIE", identity: "IE" },
                { string: navigator.userAgent, subString: "Trident", identity: "IE" },
                { string: navigator.userAgent, subString: "Firefox", identity: "Firefox" },
                { string: navigator.userAgent, subString: "Opera", identity: "Opera" },
                { string: navigator.userAgent, subString: "OPR", identity: "Opera" },

                { string: navigator.userAgent, subString: "Chrome", identity: "Chrome" },
                { string: navigator.userAgent, subString: "Safari", identity: "Safari" }
            ];

        init();

        return {
            browser, version
        }

    }();

    return {
        api: api,
        registerGetAllApi: registerApiGetAll,
        registerGetByPathApi: registerApiGetByPath,
        registerPutApi: registerApiPut,
        loaderShow: showLoader,
        loaderHide: hideLoader,
        IsBrowserIEOrEdge: () => { return ["IE", "Edge"].indexOf(BrowserDetect.browser) > -1; },
        Browser: BrowserDetect.browser,
        BrowserVer: BrowserDetect.version
    };
}());

const browser = goLiveApp.Browser;

export default goLiveApp;

export const golLiveAppApi = goLiveApp.api;

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this === null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        }
    });
}

// https://tc39.github.io/ecma262/#sec-array.prototype.findIndex
if (!Array.prototype.findIndex) {
    Object.defineProperty(Array.prototype, 'findIndex', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this === null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return k.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return k;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return -1.
            return -1;
        },
        configurable: true,
        writable: true
    });
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {

            if (this === null) {
                throw new TypeError('"this" is null or not defined');
            }

            // 1. Let O be ? ToObject(this value).
            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            var n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
            }

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                if (sameValueZero(o[k], searchElement)) {
                    return true;
                }
                // c. Increase k by 1. 
                k++;
            }

            // 8. Return false
            return false;
        }
    });
}

if (!Array.prototype.quickSort) {
    Object.defineProperty(Array.prototype, 'quickSort', {
        value: function () {

            if (this === null) {
                throw new TypeError('"this" is null or not defined');
            }

            // 1. Let O be ? ToObject(this value).
            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            quickSort(o, 0, len - 1, len);

            function quickSort(arr, leftPos, rightPos, arrLength) {
                "use strict";
                var
                    initialLeftPos,
                    initialRightPos,
                    direction,
                    pivot;
                initialLeftPos = leftPos;
                initialRightPos = rightPos;
                direction = true;
                pivot = rightPos;
                while ((leftPos - rightPos) < 0) {
                    if (direction) {
                        if (arr[pivot] < arr[leftPos]) {
                            swap(arr, pivot, leftPos);
                            pivot = leftPos;
                            rightPos--;
                            direction = !direction;
                        } else
                            leftPos++;
                    } else {
                        if (arr[pivot] <= arr[rightPos]) {
                            rightPos--;
                        } else {
                            swap(arr, pivot, rightPos);
                            leftPos++;
                            pivot = rightPos;
                            direction = !direction;
                        }
                    }
                }
                if (pivot - 1 > initialLeftPos) {
                    quickSort(arr, initialLeftPos, pivot - 1, arrLength);
                }
                if (pivot + 1 < initialRightPos) {
                    quickSort(arr, pivot + 1, initialRightPos, arrLength);
                }
            }

            function swap(arr, el1, el2) {
                "use strict";
                var temp = arr[el1];
                arr[el1] = arr[el2];
                arr[el2] = temp;
            }

        }
    });
}

// Production steps of ECMA-262, Edition 6, 22.1.2.1
if (!Array.from) {
    Array.from = (function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
            return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
        };
        var toInteger = function (value) {
            var number = Number(value);
            if (isNaN(number)) { return 0; }
            if (number === 0 || !isFinite(number)) { return number; }
            return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
            var len = toInteger(value);
            return Math.min(Math.max(len, 0), maxSafeInteger);
        };

        // The length property of the from method is 1.
        return function from(arrayLike/*, mapFn, thisArg */) {
            // 1. Let C be the this value.
            var C = this;

            // 2. Let items be ToObject(arrayLike).
            var items = Object(arrayLike);

            // 3. ReturnIfAbrupt(items).
            if (arrayLike == null) {
                throw new TypeError('Array.from requires an array-like object - not null or undefined');
            }

            // 4. If mapfn is undefined, then let mapping be false.
            var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
            var T;
            if (typeof mapFn !== 'undefined') {
                // 5. else
                // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                if (!isCallable(mapFn)) {
                    throw new TypeError('Array.from: when provided, the second argument must be a function');
                }

                // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                if (arguments.length > 2) {
                    T = arguments[2];
                }
            }

            // 10. Let lenValue be Get(items, "length").
            // 11. Let len be ToLength(lenValue).
            var len = toLength(items.length);

            // 13. If IsConstructor(C) is true, then
            // 13. a. Let A be the result of calling the [[Construct]] internal method 
            // of C with an argument list containing the single item len.
            // 14. a. Else, Let A be ArrayCreate(len).
            var A = isCallable(C) ? Object(new C(len)) : new Array(len);

            // 16. Let k be 0.
            var k = 0;
            // 17. Repeat, while k < len… (also steps a - h)
            var kValue;
            while (k < len) {
                kValue = items[k];
                if (mapFn) {
                    A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                } else {
                    A[k] = kValue;
                }
                k += 1;
            }
            // 18. Let putStatus be Put(A, "length", len, true).
            A.length = len;
            // 20. Return A.
            return A;
        };
    }());
}

if (typeof Object.assign != 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
        value: function assign(target, varArgs) { // .length of function is 2
            'use strict';
            if (target == null) { // TypeError if undefined or null
                throw new TypeError('Cannot convert undefined or null to object');
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource != null) { // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        writable: true,
        configurable: true
    });
}

if (!Array.prototype.fill) {
    Object.defineProperty(Array.prototype, 'fill', {
        value: function (value) {

            // Steps 1-2.
            if (this == null) {
                throw new TypeError('this is null or not defined');
            }

            var O = Object(this);

            // Steps 3-5.
            var len = O.length >>> 0;

            // Steps 6-7.
            var start = arguments[1];
            var relativeStart = start >> 0;

            // Step 8.
            var k = relativeStart < 0 ?
                Math.max(len + relativeStart, 0) :
                Math.min(relativeStart, len);

            // Steps 9-10.
            var end = arguments[2];
            var relativeEnd = end === undefined ?
                len : end >> 0;

            // Step 11.
            var final = relativeEnd < 0 ?
                Math.max(len + relativeEnd, 0) :
                Math.min(relativeEnd, len);

            // Step 12.
            while (k < final) {
                O[k] = value;
                k++;
            }

            // Step 13.
            return O;
        }
    });
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}

if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function (search, pos) {
            pos = !pos || pos < 0 ? 0 : +pos;
            return this.substring(pos, pos + search.length) === search;
        }
    });
}

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.toCleanDateString = function () {
    const date = new Date(this.valueOf());
    return date.toLocaleDateString(undefined,
        { day: '2-digit', month: '2-digit', year: 'numeric' }
    ).replace(/[^ -~]/g, '');
};