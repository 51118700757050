import $ from 'jquery';
import 'popper.js';
import DuPont from './DuPontApp';
import { golLiveAppApi } from './Main';

export default FavoritesHandler;

function FavoritesHandler (gridComponent) {

    const ICON_CLASS_ACTIVE = 'fa-star';
    const ICON_CLASS_INACTIVE = 'fa-star-o';
    const model = gridComponent.getDataModel();

    let favorites = {};
    let grid;
    let activeFavoriteId = 0;
    let $favoritesEditBtn;
    let $favoritesBtn;
    let $favoritesList;
    let $favoritesIcon;

    function init() {
        grid = gridComponent.getInnerGrid();

        $favoritesEditBtn = $("#favoritesEditBtn").popover({
            //trigger: 'focus click',
            //placement: 'right',
            html: true
            //title: function () { return $errorPopoverTitle.html(); },
            //content: function () { return $errorPopoverContent.html(); }
        });

        $favoritesBtn = $('#favoritesBtn').attr('disabled', '');
        $favoritesList = $('#favoritesList').empty();
        $favoritesIcon = $favoritesEditBtn.find('i');

        clearActiveFavorite();
        loadFavorites();

        $favoritesEditBtn.on('keyup', handleOnPopoverKeyup);
        $favoritesEditBtn.on('inserted.bs.popover', handleOnInsertedPopover);
        $favoritesEditBtn.on('hide.bs.popover', handleOnHidePopover);
        $favoritesList.on('click', '.dropdown-item', handleOnFavoriteItemClick);
        $favoritesBtn.parent().on('shown.bs.dropdown', handleOnShowDropDown);
        $('body').on('click', handleOnBodyClick);
    }


    function loadFavorites() {
        return submitRequestToApi({ url: `${DuPont.App.SERVER_PATH}/favoritefilters/bymodel/${model}` })
            .done(favItems => {
                if (favItems.length > 0) {
                    favorites = favItems.reduce((result, favItem) => {
                        result[favItem.favoriteFilterID] = JSON.parse(favItem.filterObject);
                        return result;
                    }, {});

                    $favoritesBtn.removeAttr('disabled');

                    $favoritesList.append(
                        favItems.map((favItem) => getNewDropDownItem(favItem.favoriteFilterID, favItem.name)).join('')
                    );
                }
            });
    }

    function submitRequestToApi(requestArgs) {
        return golLiveAppApi.submitRequest(requestArgs);
    }

    function handleOnInsertedPopover(e) {
        const $favoritesPopover = $($(e.target).data('bs.popover').tip);
        $favoritesPopover.addClass('popover-favorites');
        $favoritesPopover.on('click', '.btn-favorites-save,.btn-favorites-remove', handleOnPopoverButtonClick);
        $favoritesPopover.on('keyup', handleOnPopoverKeyup);

        if (activeFavoriteId) {
            const $favoriteItem = $favoritesList.find(`[data-favorite-id=${activeFavoriteId}]`);
            if ($favoriteItem.length > 0) {
                $('#favoriteName').val($favoriteItem.text().trim());   
            }
        }

        $favoritesPopover.find('.btn-favorites-save').text(activeFavoriteId ? 'Rename' : 'Save');
        $favoritesPopover.find('.popover-header').text(activeFavoriteId ? 'Rename/remove favorite' : 'Add favorite');
    }

    function handleOnHidePopover(e) {
        const $favoritesPopover = $($(e.target).data('bs.popover').tip);
        $favoritesPopover.off('click', '.btn-favorites-save,.btn-favorites-remove', handleOnPopoverButtonClick);
        $favoritesPopover.off('keyup');
    }

    function handleOnPopoverButtonClick(e) {
        const $target = $(e.target);
        if ($target.is('.btn-favorites-save')) {
            trySaveFavorite().done(closePopupAndFocusGrid);
        } else if ($target.is('.btn-favorites-remove')) {
            tryRemoveActiveFavorite().done(closePopupAndFocusGrid);
        }

        e.preventDefault();
    }

    function handleOnPopoverKeyup(e) {
        if (e.which === 27) $favoritesEditBtn.popover('hide');
    }

    function closePopupAndFocusGrid() {
        $favoritesEditBtn.popover('hide');
        grid.focus();
    }

    function tryRemoveActiveFavorite() {
        if (activeFavoriteId) {
            const item = {
                favoriteFilterID: activeFavoriteId
            };
            return saveChangesToServer(item, -1)
                .done(() => {
                    updateDropDownItem(activeFavoriteId, null);
                    delete favorites[activeFavoriteId];
                    clearActiveFavorite();
                });
        }
        return $.Deferred().reject();
    }

    function trySaveFavorite() {
        const form = $('#favoritesForm')[0];
        const isValid = form.checkValidity();
        form.classList.add('was-validated');
        if (isValid) {
            const filterObj = gridComponent.persistFavoriteFilter();
            const item = {
                favoriteFilterID: activeFavoriteId,
                name: $('#favoriteName').val(),
                model: model,
                filterObject: JSON.stringify(filterObj)
            };
            const updtype = activeFavoriteId ? 0 : 1;
            return saveChangesToServer(item, updtype)
                .done(result => {
                    updateDropDownItem(result.favoriteFilterID, result.name);
                    favorites[result.favoriteFilterID] = filterObj;
                    if (!activeFavoriteId) setActiveFavorite(result.favoriteFilterID);
                });
        }
        return $.Deferred().reject();
    }

    function saveChangesToServer(item, updtype) {
        let type;
        switch (updtype) {
            case 1: //insert
                type = 'POST';
                break;
            case 0: //update
                type = 'PUT'; //'PATCH';
                break;
            case -1: //delete
                type = 'DELETE';
                break;
            default:
                alert('UpdType ' + updtype + ' not implemented!');
                return;
        }

        const data = updtype > -1 ? item : null;

        return submitRequestToApi({
            url: `${DuPont.App.SERVER_PATH}/favoritefilters${updtype < 1 ? '/' + item.favoriteFilterID : ''}`,
            type: type,
            data: JSON.stringify(data)
        });
    }

    function handleOnFavoriteItemClick(e) {
        const favoriteId = $(this).data('favorite-id');
        const favoriteItem = favorites[favoriteId];
        if (favoriteItem) {
            gridComponent.applyFavoriteFilter(favoriteItem, e.ctrlKey);
            setActiveFavorite(favoriteId);
        }

        grid.focus();
        e.preventDefault();
        //return false; // won't close the dropdown
    }

    function handleOnShowDropDown(e) {
        $favoritesEditBtn.popover('hide');
    }

    function clearActiveFavorite() {
        setActiveFavorite(0);
    }

    function setActiveFavorite(favoriteId) {
        if (activeFavoriteId !== favoriteId) {
            activeFavoriteId = favoriteId;
            $favoritesList.children('.active').removeClass('active');
            if (activeFavoriteId) {
                $favoritesIcon.removeClass(ICON_CLASS_INACTIVE).addClass(ICON_CLASS_ACTIVE);
                $favoritesList.find(`[data-favorite-id=${favoriteId}]`).addClass('active');
            } else {
                $favoritesIcon.removeClass(ICON_CLASS_ACTIVE).addClass(ICON_CLASS_INACTIVE);
            }
        }
    }

    function handleOnBodyClick(e) {
        const $target = $(e.target);
        //only buttons
        if ($target.data('toggle') !== 'popover'
            && $target.parents('[data-toggle="popover"]').length === 0
            && $target.parents('.popover-favorites').length === 0
            && $('.popover-favorites').is(':visible')) {
            $favoritesEditBtn.popover('hide');
        }
    }

    function updateDropDownItem(favoriteId, favoriteName) {
        const $favoriteItem = $favoritesList.find(`[data-favorite-id=${favoriteId}]`);
        if ($favoriteItem.length > 0) {
            if (favoriteName) {
                $favoriteItem.text(favoriteName);
            } else {
                $favoriteItem.remove();
                if ($favoritesList.children().length === 0) $favoritesBtn.attr('disabled', '');
            }
        } else {
            $favoritesList.append(getNewDropDownItem(favoriteId, favoriteName));
            $favoritesBtn.removeAttr('disabled');
        }
    }

    function getNewDropDownItem(favoriteId, favoriteName) {
        return (
            `<button type="button" class="dropdown-item small" href="#" data-favorite-id="${favoriteId}">
                ${favoriteName}
             </button>`
        );
    }

    function reset() {
        $favoritesEditBtn.popover('hide');
        $favoritesBtn.attr('disabled', '');
        $favoritesList.empty();

        favorites = {};
        clearActiveFavorite();
    }

    function destroy() {
        reset();
        $('body').off('click', handleOnBodyClick);
        $favoritesBtn.parent().off('shown.bs.dropdown');
        $favoritesList.off('click', '.dropdown-item');
        $favoritesEditBtn.off('keyup');
        $favoritesEditBtn.off('inserted.bs.popover');
        $favoritesEditBtn.off('hide.bs.popover');
        $favoritesEditBtn.popover('dispose');
    }

    init();

    return {
        reset,
        destroy,
        clearActiveFavorite
    };
};