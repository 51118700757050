import React from 'react';
import goLiveApp from '../../jscomponents/Main';

const AccessDenied = () => {
    goLiveApp.loaderHide();
    return (
        <div>
            <h2>Access denied</h2>
            <p>User might not be registered.</p>
        </div>
    );
};

export default AccessDenied;