import React from 'react';
import {
    DataGrid,
    DataGridToolbar,
    DataGridToolbarButtonsGroup,
    DataGridToolbarErrorsButton,
    DataGridToolbarButtonSave,
    DataGridToolbarButtonDiscard,
    DataGridToolbarButtonRefresh,
    DataGridToolbarSearchBox,
    DataGridToolbarButtonImport,
    DataGridToolbarButtonExport,
    DataGridToolbarButtonHistory,
    DataGridToolbarFavoritesSplitButton
} from '../BaseComponents';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../TelemetryService';
import ProjectRequired from '../BaseComponents/ProjectRequired';
import DuPont from '../../jscomponents/DuPontApp';
import { AuthorizationPolicies, IfAuthorized } from '../BaseComponents/Authorization';

const Toolbar = () => {
    return (
        <DataGridToolbar
            title="Sites List"
        >
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarErrorsButton />
                <DataGridToolbarButtonSave />
                <DataGridToolbarButtonDiscard />
            </DataGridToolbarButtonsGroup>{' '}
            <DataGridToolbarButtonsGroup>
                <DataGridToolbarButtonRefresh />
                <IfAuthorized policy={AuthorizationPolicies.AppSitesAdmin}>
                    <DataGridToolbarButtonImport />
                </IfAuthorized>
                <DataGridToolbarButtonExport />
                <DataGridToolbarButtonHistory />
                <DataGridToolbarFavoritesSplitButton />
            </DataGridToolbarButtonsGroup>
        </DataGridToolbar>
    );
};

const Sites = (props) => {
    const { multiSpin } = DuPont.App.Config;

    if (multiSpin && !props.match.params.project) {
        return (
            <ProjectRequired />
        );
    }

    return (
        <DataGrid model='site'>
            <Toolbar />
        </DataGrid>
    );
};

export default withAITracking(ai.reactPlugin, Sites, "Sites");