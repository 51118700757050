import React from 'react';
import { EditModal } from '../BaseComponents/Modals';
import './UserEdit.css';

const UserEdit = () => {
    return (
        <EditModal id="userEditModal" title="User Edit">
            <div className="">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="nav-info-tab" data-toggle="tab" href="#navinfo" role="tab" aria-controls="navinfo" aria-selected="true">Info</a>
                        <a className="nav-item nav-link" id="nav-permissions-tab" data-toggle="tab" href="#navpermissions" role="tab" aria-controls="navpermissions" aria-selected="true">Permissions</a>
                    </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade in show active" id="navinfo" role="tabpanel" aria-labelledby="nav-info-tab">
                        <div className="form-row mt-1">
                            <div className="form-group col-md-4 small">
                                <label className="control-label" htmlFor="userName">User Name</label>
                                <input type="email" className="form-control form-control-sm" id="userName" name="userName" required />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>User Name</b>.
                                                </div>
                            </div>
                            <div className="form-group col-md-4 small">
                                <label className="control-label" htmlFor="currentID">Current ID</label>
                                <input type="text" className="form-control form-control-sm" id="currentID" name="currentID" required maxLength="50" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Current ID</b>.
                                </div>
                            </div>
                            {/*<div className="form-group col-md-4 small">
                                            <label className="control-label" htmlFor="futureID">Future ID</label>
                                            <input type="text" className="form-control form-control-sm" id="futureID" name="futureID" />
                                            <div className="invalid-feedback help-block">
                                            Please provide a valid <b>Future ID</b>.
                                            </div>
                                            </div>*/}
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-4 small">
                                <label className="control-label" htmlFor="lastName">Last name</label>
                                <input type="text" className="form-control form-control-sm" id="lastName" name="lastName" maxLength="150" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Last name</b>.
                                                    </div>
                            </div>
                            <div className="form-group col-md-4 small">
                                <label className="control-label" htmlFor="firstName">First name</label>
                                <input type="text" className="form-control form-control-sm" id="firstName" name="firstName" maxLength="150" />
                                {/*<div className="valid-feedback">Looks good!</div>*/}
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>First name</b>.
                                                    </div>
                            </div>
                            <div className="form-group col-md-4 small">
                                <label className="control-label" htmlFor="middleName">Middle name</label>
                                <input type="text" className="form-control form-control-sm" id="middleName" name="middleName" maxLength="150" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6 small">
                                <label className="control-label" htmlFor="email">Email</label>
                                <input type="email" className="form-control form-control-sm" id="email" name="email" required />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Email</b>.
                                                </div>
                            </div>
                            <div className="form-group col-md-6 small">
                                <label className="control-label" htmlFor="phoneNumber">Phone Number</label>
                                <input type="tel" className="form-control form-control-sm" id="phoneNumber" name="phoneNumber" maxLength="100" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Phone Number</b>.
                                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6 small">
                                <label className="control-label" htmlFor="workAddress1">Work Address 1</label>
                                <input type="text" className="form-control form-control-sm" id="workAddress1" name="workAddress1" maxLength="300" />
                                {/*<div className="valid-feedback">Looks good!</div>*/}
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Work Address 1</b>.
                                                </div>
                            </div>
                            <div className="form-group col-md-6 small">
                                <label className="control-label" htmlFor="workAddress2">Work Address 2</label>
                                <input type="text" className="form-control form-control-sm" id="workAddress2" name="workAddress2" maxLength="300" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6 small">
                                <label className="control-label" htmlFor="workCity">Work City</label>
                                <input type="text" className="form-control form-control-sm" id="workCity" name="workCity" maxLength="300" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Work City</b>.
                                                </div>
                            </div>
                            <div className="form-group col-md-6 small">
                                <label className="control-label" htmlFor="workLocation">Work Location</label>
                                <input type="text" className="form-control form-control-sm" id="workLocation" name="workLocation" maxLength="300" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Work Location</b>.
                                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-2 small">
                                <label className="control-label" htmlFor="workState">Work State</label>
                                <input type="text" className="form-control form-control-sm" id="workState" name="workState" maxLength="300" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Work State</b>.
                                                </div>
                            </div>
                            <div className="form-group col-md-2 small">
                                <label className="control-label" htmlFor="workPostalCode">Zip</label>
                                <input type="text" className="form-control form-control-sm" id="workPostalCode" name="workPostalCode" maxLength="300" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Zip</b>.
                                                </div>
                            </div>
                            <div className="form-group col-md-2 small">
                                <label className="control-label" htmlFor="workCountryID">Work Country</label>
                                <select type="text" className="form-control form-control-sm" id="workCountryID" name="workCountryID" data-lookup="Country" />
                                <div className="invalid-feedback help-block">
                                    Please provide a valid <b>Work Country</b>.
                                                </div>
                            </div>
                            <div className="form-group col-md-6 small">
                                <label className="control-label" htmlFor="goLiveTypeIDs">Go Live Type</label>
                                <div className="form-control form-control-sm select2-control" tabIndex="1">
                                    <select className="select2-multiple" id="goLiveTypeIDs" name="goLiveTypeIDs" data-lookup="GoLiveType" />
                                </div>
                            </div>
                        </div>
                        {/*<div className="form-row mt-1">
                                        <div className="form-group col-md-4 small">
                                        <label className="control-label" htmlFor="adProvisioned">AD Provisioned</label>
                                        <input type="text" className="form-control form-control-sm" id="adProvisioned" name="adProvisioned" />
                                        </div>
                                        <div className="form-group col-md-4 small">
                                        <label className="control-label" htmlFor="dvsProvisioned">DVS Provisioned</label>
                                        <input type="text" className="form-control form-control-sm" id="dvsProvisioned" name="dvsProvisioned" />
                                        </div>
                                        <div className="form-group col-md-4 small">
                                        <label className="control-label" htmlFor="dvsUrl">DVS Url</label>
                                        <input type="url" className="form-control form-control-sm" id="dvsUrl" name="dvsUrl" />
                                        </div>
                                        </div>*/}
                        <div className="form-row">
                            <div className="form-group col-md-6 small">
                                <label className="control-label" htmlFor="team">Team</label>
                                <input type="text" className="form-control form-control-sm" id="team" name="team" />
                            </div>
                            <div className="form-group col-md-6 small">
                                <label className="control-label" htmlFor="userTypeIDs">UserTypes</label>
                                <div className="form-control form-control-sm select2-control" tabIndex="1">
                                    <select className="select2-multiple" id="userTypeIDs" name="userTypeIDs" data-lookup="UserType" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="navpermissions" role="tabpanel" aria-labelledby="nav-permissions-tab">
                        <div className="form-row">
                            <div className="form-group col-md-12 small">
                                <label className="control-label" htmlFor="permissionSelectID">Add Permissions</label>
                                <div className="searchable-select">
                                    <input type="text" className="form-control form-control-sm" id="permissionInputID" autoComplete="off" />
                                    <select type="text" className="form-control form-control-sm" id="permissionSelectID" data-generic-lookup="applications" />
                                    <i className="fa fa-search" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row permissions-section">
                            <div className="form-group col-md-12 small p-0 permissions-container">
                                <table id="userPermissionsTable" style={{ width: '100%' }}>
                                    <thead>
                                        <tr className="header">
                                            <th style={{ width: '65%' }}>System<div>System</div></th>
                                            <th style={{ width: '25%' }}>ReferenceID<div>ReferenceID</div></th>
                                            <th>Status<div>Status</div></th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </EditModal>
    );
};

export default UserEdit;