import React from 'react';
import { Link } from "react-router-dom";

const HomePageNavItem = props => {
    return (
        <Link className="list-group-item" to={props.to}>
            <i className={props.icon} />
            <span className="ml-1">{props.caption}</span>
        </Link>
    );
};

export default (HomePageNavItem);