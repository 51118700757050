import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/storeObject';

import CloudPaasImporting from './CloudPaasImporting';
import CloudPaasForm from './CloudPaasForm';

import Summary from './Paas/Summary';
import Preview from './Paas/Preview';



class CloudPaas extends Component {
	constructor(props) {
		super(props);

		this.props.requestLookup(['businessPriorityEnum', 'regionsEnum', 'companyEnum', 'locationEnum', 'appTierEnum', 'phpVersionEnum', 'dotNetVersionEnum', 'classificationEnum', 'collationEnum', 'databaseTierEnum']);
	}

	goToAppList = () => {
		this.props.listingAppSpin();
	}


	getEnumValues = (name) => {
		var filteredEnum = this.props.lookups.filter(s => s.id === name)[0];
		if (filteredEnum) {
			return filteredEnum.values;
		}
		else {
			return [];
		}
	}

	parseEnum = (type, name) => {

		var filteredEnum = this.props.lookups.filter(s => s.id === name);
		var enumValues = [];
		var enumNames = [];
		var returningObject = {
			type: type,
			enum: [],
			enumNames: []
		};

		try {
			if (filteredEnum.length > 0) {
				filteredEnum[0].values.forEach(current => {
					if (type === "integer") {
						enumValues.push(parseInt(current.code));
					}
					else {
						enumValues.push(current.code);
					}
					enumNames.push(current.description);
				});
			}
			returningObject.enum = enumValues;
			returningObject.enumNames = enumNames;
		}
		catch (e) {
			console.log(e);
		}
		return returningObject;
	}


	validateForm = () => {

	}

	saveDraft = () => {

	}


	addAppSettings = () => {
		var formData = this.props.formPaas;
		formData.settings.push({
			appServicePlan: {},
			webSite: [],
			database: []
		});
		this.props.setFormPaas(formData);
	}


	addDatabase = (appIdx) => {
		var formData = this.props.formPaas;

		formData.settings[appIdx].database ? formData.settings[appIdx].database.push({}) : formData.settings[appIdx].database = [];
		this.props.setFormPaas(formData);
	}

	removeDatabase = (appIdx, dbIdx) => {
		var formData = this.props.formPaas;
		formData.settings[appIdx].database.splice(dbIdx, 1);
		this.props.setFormPaas(formData);
	}

	addWebApp = (appIdx) => {
		var formData = this.props.formPaas;



		formData.settings[appIdx].webSite ? 
		formData.settings[appIdx].webSite.push({

		}) : formData.settings[appIdx].webSite = [{}];
		this.props.setFormPaas(formData);
	}

	removeWebApp = (appIdx, webAppIdx) => {
		var formData = this.props.formPaas;
		formData.settings[appIdx].webSite.splice(webAppIdx, 1);
		this.props.setFormPaas(formData);
	}

	render() {
		let htmlContent = '<div>Loading...' + this.props.action + ' </div>';

		htmlContent = this.props.action === undefined || this.props.action === 'listing' || this.props.action === null ? (<Redirect to='/' />) : htmlContent;

		htmlContent = this.props.action === 'deployed' || this.props.action === 'requested' ? (
			<Preview />) : htmlContent;

		htmlContent = this.props.action === 'importing' ? (<CloudPaasImporting
			goToList={this.goToInfra}
			goToImport={this.goToImport}
			goToRequest={this.goToRequest}
			closePreviewData={this.closePreviewData}
			parseEnum={this.parseEnum}
			getEnumValues={this.getEnumValues} />) : htmlContent;

		htmlContent = this.props.action === 'inserting' && !this.props.isLoading ? (<CloudPaasForm
			goToList={this.goToAppList}

			//goToImport={this.goToImport}
			//goToRequest={this.goToRequest}

			closePreviewData={this.closePreviewData}
			openPreviewData={this.openPreviewData}
			parseEnum={this.parseEnum}
			getEnumValues={this.getEnumValues}

			toggleTab={this.toggleTab}

			saveDraft={this.saveDraft}

			validateForm={this.validateForm}

			addAppSettings={this.addAppSettings}

			addDatabase={this.addDatabase}
			removeDatabase={this.removeDatabase}

			addWebApp={this.addWebApp}
			removeWebApp={this.removeWebApp}

		/>) : htmlContent;

		htmlContent = this.props.action === 'inserted' ? (<Summary
			goToList={this.goToInfra}
			goToImport={this.goToImport}
			goToRequest={this.goToRequest}
			title="Congratulations!!!"
			message={this.props.summary}
		/>) : htmlContent;


		return (
			<div className="row">
				<div className="col-md-12">
					{htmlContent}
				</div>
			</div>
		);
	}
}
export default connect(
	state => state.storeObjects,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(CloudPaas);
