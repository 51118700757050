import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/storeObject';

import { Button, Col, FormGroup, Label, Input } from 'reactstrap';

class DataDisks extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {
                    this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx] ? (
                        this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm ? (
                            this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.disks ? (

                                <FormGroup row >
                                    {
                                        this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.disks.map((d, diskIdx) => {
                                            return (
                                                <Col key={'environment.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.disks.' + diskIdx} md={1}>
                                                    <FormGroup>
                                                        <span>
                                                            <Label for="diskSize">{diskIdx === 0 ? <b>Disk Size</b> : <b>&nbsp;</b>}</Label>
                                                            <Input
                                                                type="text"
                                                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.disks.' + diskIdx + '.diskSizeGB'}
                                                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.disks.' + diskIdx + '.diskSizeGB'}
                                                                value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.disks[diskIdx].diskSizeGB}
                                                                disabled
                                                            />
                                                        </span>
                                                        <span>
                                                            <Label for="quantity">{diskIdx === 0 ? <b>Quantity</b> : <b>&nbsp;</b>}</Label>
                                                            <Input
                                                                type="number"
                                                                name={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.disks.' + diskIdx + '.quantity'}
                                                                id={'environments.' + this.props.envIdx + '.compute.' + this.props.compIdx + '.vm.disks.' + diskIdx + '.quantity'}
                                                                value={this.props.formIaas.environments[this.props.envIdx].compute[this.props.compIdx].vm.disks[diskIdx].quantity}
                                                                onChange={(obj) => this.props.handleChange(obj)}
                                                            />

                                                        </span>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Label>&nbsp;</Label>
                                                            <Button color="danger" type="button" onClick={() => this.props.removeDataDisk(this.props.envIdx, this.props.compIdx, diskIdx)}><i className="fa fa-trash" /></Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            );
                                        })
                                    }
                                </FormGroup>

                            ) : null
                        ) : null
                    ) : null
                }
            </div>
        );
    }
}
//{this.props.formIaas.environments[this.props.environment].compute[this.props.compute].disks}

export default DataDisks;

