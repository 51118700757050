import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/storeObject';

import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import classnames from 'classnames';

import Compute from './Compute';



class Environments extends Component {

    constructor(props) {
        super(props);
        try {
            this.props.setTab('compute', this.props.formIaas.environments[this.props.envIdx].compute[0].availabilitySet);
        }
        catch (e) {
            console.log("There's no compute" + e);
        }
    }

    render() {

        let sapSidvalidator = null;
        try {
            sapSidvalidator = this.props.validators.environments[this.props.envIdx].sapSid === false ? 'is-invalid' : null;
        }
        catch (e) {
            sapSidvalidator = null;
        }


        return (
            <div>
                <br />
                <FormGroup row>
                    <Col md={1}>
                        <FormGroup>
                            <Label check className="checkBoxMiddleLine">SAP</Label>
                            <Input type="checkbox" className="checkBoxMiddleLine"
                                name={'environments.' + this.props.envIdx + '.isSAP'}
                                id={'environments.' + this.props.envIdx + '.isSAP'}
                                defaultChecked={this.props.formIaas.environments[this.props.envIdx].isSAP}
                            />{' '}
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="sapSid">SAP Sid</Label>
                            <Input
                                defaultValue={this.props.formIaas.environments[this.props.envIdx].sapSid}
                                type="text"
                                name={'environments.' + this.props.envIdx + '.sapSid'}
                                id={'environments.' + this.props.envIdx + '.sapSid'}
                                placeholder="SAP Sid"

                                valid={this.props.formIaas.environments[this.props.envIdx].isSAP === true ? (this.props.formIaas.environments[this.props.envIdx].sapSid ? (this.props.formIaas.environments[this.props.envIdx].sapSid.length > 0 ? true : false) : false) : false}

                                invalid={this.props.formIaas.environments[this.props.envIdx].isSAP === true ? (this.props.formIaas.environments[this.props.envIdx].sapSid ? (this.props.formIaas.environments[this.props.envIdx].sapSid.length <= 0 ? true : false) : true) : null}
                            />
                            <div className="invalid-feedback help-block">
                                Provide a <b>Sap Sid</b>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="environmentsCopyTo">Duplicate To</Label>
                            <Input
                                type="select"
                                name="environmentsCopyTo"
                                id="environmentsCopyTo"
                            >
                                <option value="" defaultValue="">select</option>
                                {
                                    this.props.getEnumValues('environmentsEnum').map(c => {
                                        if (this.props.formIaas.environments) {
                                            if (!this.props.formIaas.environments.filter(e => e.environmentName === c.code)[0]) {
                                                return <option key={c.code} value={c.code}>{c.description}</option>;
                                            }
                                        }
                                        else {
                                            return <option key={c.code} value={c.code}>{c.description}</option>;
                                        }
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={1}>
                        <Label>&nbsp;</Label>
                        <Button type="button" color="primary" onClick={() => this.props.duplicateEnvironment(this.props.envIdx)}><i className="fa fa-files-o" /></Button>
                    </Col>
                    <Col md={3}>
                        <Label>&nbsp;</Label>
                        <Button type="button" color="danger" onClick={() => this.props.removeEnvironment(this.props.environmentName)}><i className="fa fa-trash" />  Remove {this.props.environmentName}</Button>
                    </Col>

                </FormGroup>

                <FormGroup row>
                    <Col md={3}>
                        <legend >Compute Set</legend>
                    </Col>
                    <Col md={2}>
                        <Input
                            type="select"
                            name="computeDropdown"
                            id="computeDropdown"
                            defaultValue={this.props.formIaas.computeDropdown}
                        >
                            <option value=""> Choose an option </option>
                            {
                                this.props.getEnumValues('availabilitySetEnum').map(c => {

                                    if (this.props.formIaas.environments[this.props.envIdx].compute) {
                                        if (!this.props.formIaas.environments[this.props.envIdx].compute.filter(comp => comp.availabilitySet === c.code)[0]) {
                                            return <option key={c.code} value={c.code}>{c.description}</option>;
                                        }
                                    }
                                    else {
                                        return <option key={c.code} value={c.code}>{c.description}</option>;
                                    }
                                })
                            }
                        </Input>
                    </Col>
                    <Col md={2}>
                        <Button color="primary" onClick={() => this.props.addCompute(this.props.envIdx)}><i className="fa fa-plus" /> New Set</Button>
                    </Col>
                </FormGroup>


                <FormGroup row>
                    {
                        this.props.formIaas.environments[this.props.envIdx].compute ?
                            (<Col md={12}>
                                <Nav tabs>
                                    {this.props.formIaas.environments[this.props.envIdx].compute.map(c => {
                                        return (
                                            <NavItem key={'environments' + this.props.envIdx + c.availabilitySet} className={classnames("computeSet", { active: this.props.activeTab.filter(p => p.name === 'compute')[0] ? this.props.activeTab.filter(p => p.name === 'compute')[0].tabId === c.availabilitySet : false })}
                                            >
                                                <NavLink onClick={() => { this.props.toggleTab('compute', c.availabilitySet); }} >
                                                    {c.availabilitySet}
                                                </NavLink>
                                            </NavItem>
                                        );
                                    })}
                                </Nav>
                                <TabContent>

                                    {this.props.formIaas.environments[this.props.envIdx].compute.map((c, cIdx) => {
                                        return (
                                            <TabPane key={'environments' + this.props.envIdx + c.availabilitySet + '-tab'} tabId={c.availabilitySet} className={classnames("computeSet", { active: this.props.activeTab.filter(p => p.name === 'compute')[0] ? this.props.activeTab.filter(p => p.name === 'compute')[0].tabId === c.availabilitySet : false })}>
                                                <Row>
                                                    <Col sm="12">
                                                        <Compute
                                                            formIaas={this.props.formIaas}
                                                            activeTab={this.props.activeTab}

                                                            envIdx={this.props.envIdx}
                                                            environmentName={this.props.formIaas.environments[this.props.envIdx].environmentName}

                                                            computeName={c.availabilitySet}
                                                            compIdx={cIdx}

                                                            getEnumValues={this.props.getEnumValues}
                                                            toggleTab={this.props.toggleTab}

                                                            addDataDisk={this.props.addDataDisk}
                                                            removeDataDisk={this.props.removeDataDisk}

                                                            duplicateCompute={this.props.duplicateCompute}
                                                            removeCompute={this.props.removeCompute}

                                                            addLoadBalancer={this.props.addLoadBalancer}
                                                            removeLoadBalancer={this.props.removeLoadBalancer}

                                                            addProbe={this.props.addProbe}
                                                            removeProbe={this.props.removeProbe}

                                                            addPortMapping={this.props.addPortMapping}
                                                            removePortMapping={this.props.removePortMapping}

                                                            handleChange={this.props.handleChange}
                                                            setTab={this.props.setTab}
                                                        />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        );
                                    })}
                                </TabContent>
                            </Col>)
                            : null
                    }
                </FormGroup>

            </div>
        );
    }
}


export default connect(
    state => state.storeObjects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Environments);